import { styled, useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import { createModelFields } from '../constants';

interface AutogenModelBasicInfoProps {
  description: string;
  displayName: string;
}

const AutogenModelBasicInfo = ({
  description,
  displayName,
}: AutogenModelBasicInfoProps) => {
  const { palette } = useTheme();
  const info = { description, displayName };

  return (
    <Box display='flex' flexDirection='column' rowGap={4}>
      {createModelFields.map(item => {
        return (
          <Box
            display='flex'
            flexDirection='column'
            key={item.key}
            pb={2}
            rowGap={1}
            sx={{ borderBottom: `1px solid ${palette.colors.grey[200]}` }}
          >
            <Typography variant='font16Bold'>{item.label}</Typography>
            <Label>{item.description}</Label>
            <Typography variant='font16'>{info[item.key]}</Typography>
          </Box>
        );
      })}
    </Box>
  );
};

const Label = styled('p')(({ theme }) => ({
  color: theme.palette.colors.grey[600],
  fontSize: '12px',
}));

export default AutogenModelBasicInfo;
