import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import isEqual from 'lodash/fp/isEqual';
import { selectFieldPredictorInputOrigins } from 'src/reducers/triageSettingsReducer/triageSettingsReducer';
import {
  FieldPredictorConfig,
  SalesforceCaseOrigins,
} from 'src/reducers/triageSettingsReducer/types';

export const useGetCurrentFieldPredictorOrigins = (
  fieldPredictorConfig: FieldPredictorConfig | null,
  origins: never[] | SalesforceCaseOrigins[],
  preselectedOrigins?: string[],
) => {
  const fieldPredictorOriginsEnum = useSelector(
    selectFieldPredictorInputOrigins,
  );
  const modelInputEnum =
    // @ts-expect-error legacy code with untyped state
    fieldPredictorConfig?.model_inputs[0]?.schema.properties?.Origin?.enum;
  const fieldPredictorOrigins = modelInputEnum || fieldPredictorOriginsEnum;
  const [originFieldValue, setOriginFieldValue] = useState<string[]>([]);

  const hasUpdatedOrigins = !isEqual(
    [...originFieldValue].sort(),
    [...(fieldPredictorOrigins ?? [])].sort(),
  );

  useEffect(() => {
    if (fieldPredictorOrigins) {
      // if model field_to_predict has origins
      setOriginFieldValue(fieldPredictorOrigins);
      return;
    }

    if (preselectedOrigins?.length) {
      // if there are origins from a different model field_to_predict
      setOriginFieldValue(preselectedOrigins);
    }
  }, [fieldPredictorOrigins, preselectedOrigins]);

  const selectedOrigins = useMemo(() => {
    return originFieldValue.map(value => {
      return (
        origins.find(origin => origin.value === value) ?? {
          label: '',
          value: '',
        }
      );
    });
  }, [origins, originFieldValue]);

  return {
    hasUpdatedOrigins,
    originFieldValue,
    selectedOrigins,
    setOriginFieldValue,
  };
};
