import React, { ReactNode, useEffect, useMemo } from 'react';
import { Box, styled, useTheme } from '@mui/material';
import { IconExternalLink, IconInfoCircle, IconTag } from '@tabler/icons-react';

import {
  Drawer,
  Skeleton,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { quickFeedbackOneToTwoOptions } from '../solve-config/constants';
import { useSolveMetricsQueryParams } from '../workflow-builder/intent-workflows-table/hooks/useSolveMetricsQueryParams';
import { DeflectionInsightCell } from './components/DeflectionInsightCell';
import { ContextVariables } from './ConversationDrawer/ContextVariables';
import { RevampDrawerBody } from './ConversationDrawer/RevampConversationDrawer';
import useGetCSATColor from './hooks/useGetCSATColor';
import useIsCSATEnabled from './hooks/useIsCSATEnabled';
import useIsDeflectionInsightsEnabled from './hooks/useIsDeflectionInsightsEnabled';
import {
  ALLTIME_END,
  ALLTIME_START,
  INTENT_ASSIGNMENT_TOOLTIP,
  NA,
} from './constants';
import ConversationTranscript from './ConversationTranscript';
import { formatCSAT, formatTimestamp } from './helpers';
import IntentBadge from './IntentBadge';
import { UpdateIntentDialog } from './UpdateIntentDialog';
import { conversationChannelToProductMap } from 'src/constants/solve';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { Conversation } from 'src/services/apiInterfaces';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { useGetConversationAggregateMetricsByIntentIdQuery } from 'src/services/solve-conversations';
import { useGetWidgetBreakdownMetricsQuery } from 'src/services/workflow-builder-metrics';
import { isDeflectionInsightsProcessing } from 'src/utils/solve/deflectionInsightsUtils';

export default function ConversationDrawer({
  isLoading = false,
  onClose,
  openEventTrigger,
  selectedConversation,
}: {
  isLoading?: boolean;
  onClose: () => void;
  openEventTrigger: () => void;
  selectedConversation?: Conversation;
}) {
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isChatMetadataRevampEnabled = featureFlags.includes(
    'chat_metadata_revamp',
  );
  const isOpen = !!selectedConversation?.conversation_id && !isLoading;

  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  useEffect(() => {
    if (selectedConversation && !isLoading) {
      openEventTrigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emitTrackingEventCallback, selectedConversation, isLoading]);

  return (
    <Drawer isOpen={isOpen || isLoading} onClose={onClose}>
      {isLoading && (
        <Box p='40px'>
          <Box py='6px'>
            <Skeleton height='24px' width='100%' />
          </Box>
          <Box py='20px'>
            <Skeleton height='400px' width='100%' />
          </Box>
        </Box>
      )}
      {isOpen &&
        (isChatMetadataRevampEnabled ? (
          <RevampDrawerBody selectedConversation={selectedConversation} />
        ) : (
          <DrawerBody selectedConversation={selectedConversation} />
        ))}
    </Drawer>
  );
}

function DrawerBody({
  selectedConversation,
}: {
  selectedConversation: Conversation;
}) {
  const {
    additional_feedback: csatAdditionalFeedback,
    context_variables,
    conversation_id,
    csat_feedback,
    csat_resolve,
    csat_score,
    deflected,
    last_executed_intent_definition_id,
    last_executed_intent_title,
    last_executed_intent_user_query,
    quick_feedback_responses,
    start_datetime,
    tagged_zendesk_ticket_source_id,
    tagged_zendesk_ticket_source_link,
    transcript,
    workflow_tag,
  } = selectedConversation;

  const { channel } = useSolveMetricsQueryParams();
  const { palette } = useTheme();
  const getCSATColor = useGetCSATColor();
  const isCSATEnabled = useIsCSATEnabled();
  const isDeflectionInsightsEnabled = useIsDeflectionInsightsEnabled();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isQuickFeedbackEnabled = featureFlags.includes('quick_feedback');

  // fetch aggregate data for this intent from all time for avg CSAT
  const { data: intentAggregationResponse, isLoading: loadingAggregate } =
    useGetConversationAggregateMetricsByIntentIdQuery(
      {
        autonomousAgentUsed: null,
        end: ALLTIME_END,
        intentId: last_executed_intent_definition_id,
        product: conversationChannelToProductMap[channel],
        start: ALLTIME_START,
      },
      { skip: !last_executed_intent_definition_id },
    );
  // fetch breakdown by intent for total inquiries and deflection rate
  const { data: breakdownResponse, isLoading: loadingBreakdown } =
    useGetWidgetBreakdownMetricsQuery({
      channel,
      from: ALLTIME_START,
      to: ALLTIME_END,
    });

  // pull data for this intent from list of all intent workflow breakdowns
  const intentBreakdown = useMemo(() => {
    if (!breakdownResponse || !last_executed_intent_definition_id) {
      return null;
    }
    return breakdownResponse.breakdown.find(
      row => row.intent_id === last_executed_intent_definition_id,
    );
  }, [breakdownResponse, last_executed_intent_definition_id]);

  const totalConversations = intentBreakdown?.inquiries?.toLocaleString();
  const totalDeflectionRate = intentBreakdown?.deflection_rate.toLocaleString(
    undefined,
    {
      minimumFractionDigits: 0,
      style: 'percent',
    },
  );
  const avgCSAT = formatCSAT(intentAggregationResponse?.avg_csat_score);
  const csatFeedback = csat_feedback?.join(', ');

  const getCsatResolve = () => {
    if (typeof csat_resolve == 'boolean') {
      return csat_resolve ? 'Yes' : 'No';
    }
    return 'n/a';
  };

  return (
    <Box p='40px'>
      <Box py='6px'>
        <Typography variant='font24'>Chat Details</Typography>
      </Box>
      <Box py='24px'>
        <Box display='flex' flexDirection='column' gap='30px'>
          <Box display='flex' flexDirection='column' gap='2px'>
            <Typography variant='font11'>Chat ID</Typography>
            <Typography color={palette.colors.grey[600]} variant='font12Medium'>
              {conversation_id}
            </Typography>
          </Box>
          {tagged_zendesk_ticket_source_id &&
            tagged_zendesk_ticket_source_link && (
              <Box display='flex' flexDirection='column' gap='2px'>
                <Typography variant='font11'>
                  Live Chat ID (ticket created to speak with a live agent)
                </Typography>
                <Typography
                  color={palette.colors.grey[600]}
                  variant='font12Medium'
                >
                  <a
                    href={tagged_zendesk_ticket_source_link}
                    rel='noreferrer'
                    target='_blank'
                  >
                    <Box alignItems='center' display='flex' gap='2px'>
                      {tagged_zendesk_ticket_source_id}
                      <IconExternalLink size={14} />
                    </Box>
                  </a>
                </Typography>
              </Box>
            )}
          <Box display='flex' flexDirection='column' gap='10px'>
            <Box alignItems='center' display='flex' gap='4px'>
              <Typography variant='font11'>Intent Detected</Typography>
              <Tooltip tooltipContent={INTENT_ASSIGNMENT_TOOLTIP}>
                <IconInfoCircle size={16} />
              </Tooltip>
            </Box>
            <Card>
              <Box mb='8px'>
                <IntentBadge
                  intentDefinitionId={last_executed_intent_definition_id}
                  intentName={last_executed_intent_title}
                />
              </Box>
              <TextDivider size='small' text='All-time metrics' />
              <InlineData
                isLoading={loadingBreakdown}
                label='Total # of Chats'
                value={totalConversations}
              />
              <InlineData
                isLoading={loadingBreakdown}
                label='Avg. Deflection rate'
                value={totalDeflectionRate}
              />
              {isCSATEnabled && (
                <InlineData
                  isLoading={loadingAggregate}
                  label='Avg. CSAT'
                  value={avgCSAT}
                />
              )}
              {isQuickFeedbackEnabled &&
                Boolean(quick_feedback_responses?.length) && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    gap='4px'
                    pt='16px'
                  >
                    <Divider />
                    <Box>
                      <Typography
                        color={palette.colors.grey[600]}
                        variant='font12Medium'
                      >
                        Articles:
                      </Typography>
                    </Box>
                    {quick_feedback_responses?.map(
                      (quick_feedback_response, index) => {
                        const chosenStyleOptions =
                          quickFeedbackOneToTwoOptions.find(
                            option =>
                              option.value === quick_feedback_response.style,
                          );

                        return (
                          <Box
                            alignItems='center'
                            display='flex'
                            gap='4px'
                            key={index}
                          >
                            <Typography variant='font12'>
                              <QuickFeedbackArticleLink
                                href={quick_feedback_response.link}
                                rel='noreferrer'
                                target='_blank'
                              >
                                {quick_feedback_response.title}
                              </QuickFeedbackArticleLink>
                            </Typography>
                            {quick_feedback_response.is_useful !== null && (
                              <QuickFeedbackStyleImage
                                src={
                                  chosenStyleOptions?.[
                                    `${
                                      quick_feedback_response.is_useful
                                        ? 'positive'
                                        : 'negative'
                                    }Icons`
                                  ]?.active
                                }
                              />
                            )}
                            {quick_feedback_response.additional_feedback && (
                              <Typography
                                color={palette.colors.grey[600]}
                                variant='font12'
                              >
                                <i>
                                  &quot;
                                  {quick_feedback_response.additional_feedback}
                                  &quot;
                                </i>
                              </Typography>
                            )}
                          </Box>
                        );
                      },
                    )}
                  </Box>
                )}
              {last_executed_intent_user_query && (
                <Box display='flex' flexDirection='column' gap='16px' pt='16px'>
                  <Divider />
                  <Box display='flex' flexDirection='column' gap='6px'>
                    <Typography
                      color={palette.colors.grey[600]}
                      variant='font12Medium'
                    >
                      User says:
                    </Typography>
                    <i>
                      <Typography
                        color={palette.colors.blue[600]}
                        variant='font14'
                      >
                        &quot;
                        {last_executed_intent_user_query}
                        &quot;
                      </Typography>
                    </i>
                  </Box>
                  <TextDivider
                    size='small'
                    text='Reassign to new or existing intent'
                  />
                  <UpdateIntentDialog
                    userQuery={last_executed_intent_user_query}
                  />
                </Box>
              )}
            </Card>
          </Box>
          <Box display='flex' flexDirection='column' gap='10px'>
            <Typography variant='font11'>Chat</Typography>
            <Box
              sx={{
                border: `1px solid ${palette.colors.slate[200]}`,
                borderRadius: '8px',
              }}
            >
              <Box pl='16px' pr='16px' pt='16px'>
                <InlineData
                  label='Timestamp'
                  value={formatTimestamp(start_datetime + 'Z')}
                />
                {workflow_tag && (
                  <InlineData
                    label='Workflow Tag'
                    value={
                      <Box
                        sx={{
                          alignItems: 'center',
                          bgcolor: palette.colors.slate[100],
                          borderRadius: '4px',
                          color: palette.colors.grey[800],
                          display: 'flex',
                          px: 1,
                          py: 0.5,
                        }}
                      >
                        <IconTag color={palette.colors.blue[500]} size={16} />
                        <Typography
                          color={palette.colors.black}
                          noWrap
                          variant='font12'
                        >
                          {workflow_tag}
                        </Typography>
                      </Box>
                    }
                  />
                )}
                <InlineData
                  label='Deflected'
                  value={deflected ? 'True' : 'False'}
                />
                {isCSATEnabled && (
                  <InlineData
                    color={getCSATColor(csat_score)}
                    label='CSAT'
                    value={formatCSAT(csat_score)}
                  />
                )}
                {isCSATEnabled && (
                  <InlineData
                    label='CSAT Resolution Confirmation'
                    value={getCsatResolve()}
                  />
                )}
              </Box>

              <Divider />
              <ContextVariables contextVariables={context_variables} />

              <Box pl='16px' pr='16px'>
                <Box display='flex' flexDirection='column' gap='16px'>
                  {isDeflectionInsightsEnabled && (
                    <DeflectionInsightsData
                      selectedConversation={selectedConversation}
                    />
                  )}
                  {isCSATEnabled && (
                    <>
                      <Divider />
                      <Box display='flex' flexDirection='column' gap='6px'>
                        <Typography
                          color={palette.colors.grey[600]}
                          variant='font12Medium'
                        >
                          CSAT Feedback:
                        </Typography>
                        <i>
                          <Typography
                            color={
                              !!csatFeedback
                                ? palette.colors.blue[600]
                                : palette.colors.grey[600]
                            }
                            variant='font14'
                          >
                            {csatFeedback || NA}
                          </Typography>
                        </i>
                      </Box>
                    </>
                  )}
                  {isCSATEnabled && (
                    <>
                      <Box display='flex' flexDirection='column' gap='6px'>
                        <Typography
                          color={palette.colors.grey[600]}
                          variant='font12Medium'
                        >
                          Additional Feedback:
                        </Typography>
                        <i>
                          <Typography
                            color={
                              !!csatAdditionalFeedback
                                ? palette.colors.blue[600]
                                : palette.colors.grey[600]
                            }
                            variant='font14'
                          >
                            {csatAdditionalFeedback || NA}
                          </Typography>
                        </i>
                      </Box>
                    </>
                  )}
                  <TextDivider size='large' text='Chat' />
                  <Box display='flex' flexDirection='column' gap='6px'>
                    <ConversationTranscript
                      transcript={transcript}
                      variant='drawer'
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export const Card = styled(Box)`
  border: 1px solid ${({ theme }) => theme.palette.colors.slate[200]};
  border-radius: 8px;
  padding: 16px;
`;

const QuickFeedbackStyleImage = styled('img')`
  height: 18px;
  width: 18px;
`;

const QuickFeedbackArticleLink = styled('a')`
  color: ${({ theme }) => theme.palette.colors.blue[600]};
`;

export const TextDivider = ({
  size,
  text,
}: {
  size: 'small' | 'large';
  text: string;
}) => {
  return (
    <Box
      alignItems='center'
      display='flex'
      mb={size === 'large' ? 1 : 0}
      width='100%'
    >
      <Divider />
      <Box px='16px' whiteSpace='nowrap'>
        <Typography variant={size === 'small' ? 'font12' : 'font16Bold'}>
          {text}
        </Typography>
      </Box>
      <Divider />
    </Box>
  );
};

export const InlineData = ({
  color,
  isLoading = false,
  label,
  value = NA,
}: {
  color?: string;
  isLoading?: boolean;
  label: string;
  value?: string | number | ReactNode;
}) => {
  const { palette } = useTheme();

  if (React.isValidElement(value)) {
    return (
      <Box alignItems='center' display='flex'>
        <Typography color={palette.colors.grey[600]} variant='font12Medium'>
          {label}:
        </Typography>
        &nbsp;
        {value}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        wordBreak: 'break-word',
      }}
    >
      <Typography color={palette.colors.grey[600]} variant='font12Medium'>
        {label}:
      </Typography>
      &nbsp;
      <Typography
        color={
          color ||
          (value === NA ? palette.colors.grey[500] : palette.colors.black)
        }
        variant='font12Medium'
      >
        {/* If loading, show a skeleton, otherwise either show the value or fallback to n/a */}
        {isLoading ? <Skeleton width={30} /> : value}
      </Typography>
    </Box>
  );
};

const DeflectionInsightsData = ({
  isLoading = false,
  selectedConversation,
}: {
  isLoading?: boolean;
  selectedConversation: Conversation;
}) => {
  const { palette } = useTheme();
  const {
    dropoff_rating,
    dropoff_reasoning = NA,
    relevance_rating,
    relevance_reasoning = NA,
  } = selectedConversation;
  const isProcessing = isDeflectionInsightsProcessing({
    hasDeflectionInsights: !!relevance_rating,
    isDeflected: selectedConversation.deflected,
    modifiedDate: selectedConversation.modified_date,
  });

  const DeflectionInsight = ({
    badge,
    label,
    value,
  }: {
    badge: React.ReactNode;
    label: string;
    value: string | null;
  }) => {
    return (
      <>
        <Divider />
        <Box display='flex' flexDirection='column' gap='6px'>
          <Typography color={palette.colors.grey[600]} variant='font12Medium'>
            {label}:
          </Typography>
          {badge}
        </Box>

        {!isProcessing && (
          <Box display='flex' flexDirection='column' gap='6px'>
            <Typography color={palette.colors.grey[600]} variant='font12Medium'>
              {label} reasoning:
            </Typography>
            <Typography
              color={
                relevance_reasoning === NA
                  ? palette.colors.grey[500]
                  : palette.colors.grey[600]
              }
              variant='font12Medium'
            >
              {isLoading ? <Skeleton width={30} /> : value}
            </Typography>
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      <DeflectionInsight
        badge={
          <DeflectionInsightCell
            isProcessing={isProcessing}
            metric={relevance_rating}
          />
        }
        label='Relevance'
        value={relevance_reasoning}
      />
      <DeflectionInsight
        badge={
          <DeflectionInsightCell
            isProcessing={isProcessing}
            metric={dropoff_rating}
          />
        }
        label='User engagement'
        value={dropoff_reasoning}
      />
    </>
  );
};

export const Divider = styled('hr')`
  border-width: 0;
  border-bottom-width: thin;
  border-color: ${({ theme }) => theme.palette.colors.slate[200]};
  width: 100%;
`;
