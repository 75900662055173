import React from 'react';
import { Box } from '@mui/material';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';

interface HeaderProps {
  handleSave: () => void;
  model?: VersionedTriageModel;
}

const Header: React.FC<HeaderProps> = ({ handleSave }) => {
  return (
    <Box
      alignItems='center'
      display='flex'
      justifyContent='space-between'
      pb='24px'
      pl='40px'
      pr='40px'
    >
      <Typography variant='font24'>
        Map labels from the model to your helpdesk
      </Typography>
      <Box mr='64px'>
        <Button onClick={handleSave} variant='main'>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default Header;
