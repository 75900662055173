import { ListOption } from '@forethought-technologies/forethought-elements';
import { OriginItem } from '../triage-model-detail-page/types';
import { QuickstartFieldType } from './types';
import { Helpdesk } from 'src/components/app/types';
import {
  JiraTicketField,
  KustomerTicketField,
  ModelPaidPlan,
  SalesforceTicketField,
  SupportedHelpdesk,
  TriageField,
  TriageHelpdeskTicketFields,
  TriageJiraTicketFields,
  TriageKustomerTicketFields,
  TriagePermissions,
  TriageSalesforceTicketFields,
  TriageTypedErrors,
  TriageZendeskTicketFields,
  ZendeskTicketField,
} from 'src/reducers/triageSettingsReducer/types';

export const TOAST_TIMEOUT = 3000;

export const premiumDisabledTooltipText =
  'Contact your CSM to change the status of premium models';

export const getDisabledModelMessage = (
  isHelpdeskNotConfigured: boolean,
  isHelpdeskSupported: boolean,
) => {
  if (isHelpdeskNotConfigured || !isHelpdeskSupported) {
    return `A helpdesk integration must be ${
      !isHelpdeskSupported ? 'selected and ' : ''
    }configured to activate the model`;
  }
  return 'A helpdesk integration must be set up for this model to activate it';
};

export const getIsModelPremium = (modelPaidPlan: ModelPaidPlan | undefined) =>
  modelPaidPlan === 'premium';

export const getTriageConfigDetailUiErrorMessage = (
  error: TriageTypedErrors,
) => {
  if (
    error === 'DUPLICATE_CUSTOM_FIELD_PARAMS' ||
    error === 'INVALID_FIELD_SELECTED'
  ) {
    return 'The model can not save. A Forethought team member will reach out to help soon.';
  }

  return 'The model can not save. Please try again later.';
};

export const getFieldTypesByHelpdeskAndStage = (
  helpdesk: Helpdesk | '',
): QuickstartFieldType[] => {
  switch (helpdesk) {
    case 'zendesk':
      return ['Output field'];
    case 'salesforce':
      return ['Object', 'Origins', 'Output field'];
    default:
      throw new Error(`Helpdesk ${helpdesk} not supported yet`);
  }
};

const formatSalesforceOutputFieldsAsListItem = (
  item: SalesforceTicketField,
): ListOption => {
  return { label: item.label, value: item.name };
};

const formatZendeskOutputFieldsAsListItem = (
  item: ZendeskTicketField,
): ListOption => {
  return { label: item.name, value: item.id.toString() };
};

const formatKustomerOutputFieldsAsListItem = (
  item: KustomerTicketField,
): ListOption => {
  return {
    label: item.title || item.helpdesk_field_id,
    value: item.helpdesk_field_id,
  };
};

const formatJiraOutputFieldsAsListItem = (
  item: JiraTicketField,
): ListOption => {
  return {
    label: item.title || item.helpdesk_field_id,
    value: item.helpdesk_field_id,
  };
};

const HELPDESK_FORMATTING_MAP: Record<
  SupportedHelpdesk,
  (ticketFields: TriageHelpdeskTicketFields) => {
    loading: boolean;
    origins: OriginItem[];
    outputFields: ListOption[];
    permissions?: TriagePermissions;
    preselectedOrigins?: string[];
  }
> = {
  jira: (ticketFields: TriageHelpdeskTicketFields) => {
    const { output_fields: outputFields } =
      ticketFields as TriageJiraTicketFields;
    return {
      loading: false,
      origins: [],
      outputFields: outputFields.map(formatJiraOutputFieldsAsListItem),
    };
  },
  kustomer: (ticketFields: TriageHelpdeskTicketFields) => {
    const { output_fields: outputFields } =
      ticketFields as TriageKustomerTicketFields;
    return {
      loading: false,
      origins: [],
      outputFields: outputFields.map(formatKustomerOutputFieldsAsListItem),
    };
  },
  salesforce: (ticketFields: TriageHelpdeskTicketFields) => {
    const {
      case_origins: origins,
      output_fields: outputFields,
      permissions,
      preselected_origins: preselectedOrigins,
    } = ticketFields as TriageSalesforceTicketFields;
    return {
      loading: false,
      origins,
      outputFields: outputFields.map(formatSalesforceOutputFieldsAsListItem),
      permissions,
      preselectedOrigins,
    };
  },
  zendesk: (ticketFields: TriageHelpdeskTicketFields) => {
    const { output_fields: outputFields } =
      ticketFields as TriageZendeskTicketFields;
    return {
      loading: false,
      origins: [],
      outputFields: outputFields.map(formatZendeskOutputFieldsAsListItem),
    };
  },
};

export const formatTicketFields = (
  ticketFields: TriageHelpdeskTicketFields | undefined,
  helpdesk?: SupportedHelpdesk,
) => {
  if (!ticketFields || !helpdesk || !HELPDESK_FORMATTING_MAP[helpdesk]) {
    return {
      loading: false,
      origins: [],
      outputFields: [],
    };
  }

  return HELPDESK_FORMATTING_MAP[helpdesk](ticketFields);
};

export const prepareTriageFieldsByHelpdesk = ({
  helpdesk,
  isChoosingExistingFieldFlow,
  outputFieldTextInputValue,
  selectedPredictedField,
}: {
  helpdesk: Helpdesk;
  isChoosingExistingFieldFlow: boolean;
  outputFieldTextInputValue: string;
  selectedPredictedField: ListOption | undefined;
}): TriageField => {
  if (!isChoosingExistingFieldFlow) {
    return { id: outputFieldTextInputValue, name: outputFieldTextInputValue };
  }

  switch (helpdesk) {
    case 'zendesk':
      return {
        id: selectedPredictedField?.value,
        name: selectedPredictedField?.label ?? '',
      };
    case 'salesforce':
      return {
        id: selectedPredictedField?.value,
        name: selectedPredictedField?.value ?? '',
      };
    case 'kustomer':
      return {
        id: selectedPredictedField?.value,
        title: selectedPredictedField?.value ?? '',
      };
    case 'jira':
      return {
        id: selectedPredictedField?.value,
        title: selectedPredictedField?.value ?? '',
      };
    default:
      throw new Error(`Helpdesk ${helpdesk} not supported yet`);
  }
};
