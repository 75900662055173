import { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, useTheme } from '@mui/material';
import { IconPlus, IconTrash } from '@tabler/icons-react';

import {
  Button,
  IconButton,
  SelectDropdown,
  TextField,
} from '@forethought-technologies/forethought-elements';
import { FormWithErrors } from '../types';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import HorizontalGripIcon from 'src/components/horizontal-grip-icon/HorizontalGripIcon';
import { RichTextEditor } from 'src/components/rich-text-editor/RichTextEditor';
import { EditorRef } from 'src/components/rich-text-editor/types';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { useGetContextVariablesForTextEditor } from 'src/pages/workflow-builder-edit/email-builder-page/intent-email-builder/hooks';
import { useSwapItem } from 'src/pages/workflow-builder-edit/hooks';
import { useGetContextVariablesQuery } from 'src/services/dashboard-api';
import { emptyForm } from 'src/slices/canvas-workflow-builder/utils';
import {
  selectEditingStepFields,
  setStepFieldValue,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { useAppDispatch } from 'src/store/hooks';
import { FormStepFields } from 'src/types/workflowBuilderAPITypes';
import { convertDynamicListCVChildrenToListOptions } from 'src/utils/solve/dynamicContextVariableUtils';
import { isFormStepFields } from 'src/utils/solve/stepUtils';

const FormStepForm = ({ errorObject }: FormWithErrors<FormStepFields>) => {
  const [touched, setTouched] = useState(false);

  const dispatch = useAppDispatch();
  const fields = useSelector(selectEditingStepFields);
  const { palette } = useTheme();

  const editorRef = useRef<EditorRef>(null);

  const remirrorContextVariables = useGetContextVariablesForTextEditor();
  const { data } = useGetContextVariablesQuery();
  const { context_variables: contextVariables = [] } = data ?? {};

  if (!isFormStepFields(fields)) {
    return null;
  }

  const { form_fields: formFields, prompt } = fields;

  const setPrompt = (prompt: string) => {
    setTouched(true);

    dispatch(setStepFieldValue({ field: 'prompt', value: prompt }));
  };

  const setField = (index: number) => (cvId: string) => {
    setTouched(true);

    const foundCv = contextVariables.find(
      cv => cv.context_variable_id === cvId,
    );
    if (!foundCv) {
      return;
    }

    const formFieldsCopy = [...formFields];

    const updatedForm = {
      ...formFieldsCopy[index],
      dynamic_list_option_mapping:
        foundCv.context_variable_type === 'DYNAMIC_LIST' ? '' : undefined,
      output_variable: foundCv.context_variable_id,
      placeholder: foundCv.context_variable_name,
      prepopulated_field: foundCv.context_variable_id,
    };
    formFieldsCopy[index] = updatedForm;
    dispatch(
      setStepFieldValue({ field: 'form_fields', value: formFieldsCopy }),
    );
  };

  const setDynamicCvListOptionMapping = (index: number) => (value: string) => {
    const formFieldsCopy = [...formFields];

    const updatedForm = {
      ...formFieldsCopy[index],
      dynamic_list_option_mapping: value,
    };
    formFieldsCopy[index] = updatedForm;
    dispatch(
      setStepFieldValue({ field: 'form_fields', value: formFieldsCopy }),
    );
  };

  const setLabel = (index: number) => (value: string) => {
    const formFieldsCopy = [...formFields];

    const updatedForm = {
      ...formFieldsCopy[index],
      placeholder: value,
    };
    formFieldsCopy[index] = updatedForm;
    dispatch(
      setStepFieldValue({ field: 'form_fields', value: formFieldsCopy }),
    );
  };

  const addField = () => {
    setTouched(true);

    dispatch(
      setStepFieldValue({
        field: 'form_fields',
        value: [...formFields, emptyForm],
      }),
    );
  };

  const deleteField = (index: number) => {
    setTouched(true);

    dispatch(
      setStepFieldValue({
        field: 'form_fields',
        value: [...formFields.slice(0, index), ...formFields.slice(index + 1)],
      }),
    );
  };

  const swapOptions = (dragIndex: number, hoverIndex: number) => {
    setTouched(true);

    const formFieldsCopy = [...formFields];
    const temp = formFieldsCopy[hoverIndex];
    formFieldsCopy[hoverIndex] = formFieldsCopy[dragIndex];
    formFieldsCopy[dragIndex] = temp;

    dispatch(
      setStepFieldValue({
        field: 'form_fields',
        value: formFieldsCopy,
      }),
    );
  };

  const labelErrors = formFields.map((currentFormField, currentIndex) =>
    currentFormField.placeholder &&
    formFields.findIndex(
      formField => formField.placeholder === currentFormField.placeholder,
    ) !== currentIndex
      ? 'Label must be unique'
      : '',
  );

  return (
    <>
      <Typography variant='font14Bold'>Forms</Typography>
      <Box
        border={`1px solid ${
          errorObject?.prompt && touched
            ? palette.colors.red[500]
            : palette.colors.grey[300]
        }`}
        borderRadius='4px'
        mb={3}
        mt={1}
      >
        <RichTextEditor
          contextVariables={remirrorContextVariables}
          editorRef={editorRef}
          errorMessage={touched ? errorObject?.prompt : undefined}
          initialContent={prompt}
          label='Forms message'
          onChange={setPrompt}
          placeholder='Write an options-filling message for your customers...'
          withExtraMentions
        />
      </Box>
      <Box mb={1}>
        <Typography variant='font14Bold'>Form fields</Typography>
      </Box>
      <Box display='flex' flexDirection='column' gap='20px'>
        {formFields.map(
          (
            {
              dynamic_list_option_mapping: dynamicListOptionMapping,
              output_variable: cvId,
              placeholder,
            },
            index,
          ) => (
            <Option
              deleteField={deleteField}
              dynamicListOptionMapping={dynamicListOptionMapping}
              error={
                touched
                  ? errorObject?.form_fields?.[index]?.output_variable
                  : undefined
              }
              index={index}
              key={index}
              label={placeholder}
              labelErrors={labelErrors}
              setDynamicCvListOptionMapping={setDynamicCvListOptionMapping(
                index,
              )}
              setField={setField(index)}
              setLabel={setLabel(index)}
              swapOptions={swapOptions}
              value={cvId}
            />
          ),
        )}
      </Box>
      <Box mt={3}>
        <Button
          onClick={addField}
          startIcon={<IconPlus size={20} />}
          variant='ghost'
        >
          Add form field
        </Button>
      </Box>
    </>
  );
};

interface OptionProps {
  deleteField: (index: number) => void;
  dynamicListOptionMapping?: string;
  error?: string;
  index: number;
  label: string;
  labelErrors: string[];
  setDynamicCvListOptionMapping: (value: string) => void;
  setField: (value: string) => void;
  setLabel: (value: string) => void;
  swapOptions: (index: number, index2: number) => void;
  value: string;
}

const Option = ({
  deleteField,
  dynamicListOptionMapping = '',
  error,
  index,
  label,
  labelErrors,
  setDynamicCvListOptionMapping,
  setField,
  setLabel,
  swapOptions,
  value,
}: OptionProps) => {
  const { contextVariables } = useGetContextVariables();

  const foundCv = useMemo(() => {
    return contextVariables.find(cv => cv.context_variable_id === value);
  }, [contextVariables, value]);

  const { dragPreviewRef, handlerId, opacity, ref } = useSwapItem({
    index,
    swap: swapOptions,
  });

  return (
    <Box
      alignItems='center'
      display='flex'
      gap={1}
      key={index}
      ref={dragPreviewRef}
      sx={{
        backgroundColor: 'transparent',
        cursor: 'grab',
        opacity,
        transform: 'translate(0, 0)',
      }}
    >
      <HorizontalGripIcon handlerId={handlerId} ref={ref} />
      <Box alignItems='center' display='flex' flex={1} gap={1}>
        <Box flex={1}>
          <TextField
            aria-label={`Optional label for field ${index + 1}`}
            error={labelErrors[index]}
            onBlur={() =>
              setLabel(label.trim() || foundCv?.context_variable_name || '')
            }
            onChange={event => setLabel(event.target.value)}
            placeholder='Optional form field label'
            shouldErrorMessageTakeUpSpace={false}
            value={label}
          />
        </Box>

        <Box flex={1}>
          <ContextVariableSelectDropdown
            aria-label={`Field ${index + 1}`}
            error={error}
            key={index}
            onChange={setField}
            shouldErrorMessageTakeUpSpace={false}
            shouldIncludeDynamicListContextVariableChildren={false}
            value={value}
          />
        </Box>

        {foundCv?.context_variable_type === 'DYNAMIC_LIST' && (
          <Box flex={1}>
            <SelectDropdown
              // TODO: required id is a remnant of old design, shouldn't be required in ft-elements
              id=''
              onChange={e => setDynamicCvListOptionMapping(e.target.value)}
              options={convertDynamicListCVChildrenToListOptions(foundCv) || []}
              shouldErrorMessageTakeUpSpace={false}
              value={dynamicListOptionMapping}
            />
          </Box>
        )}
      </Box>
      <IconButton
        aria-label={`Delete field ${index + 1}`}
        onClick={() => deleteField(index)}
        variant='ghost'
      >
        <IconTrash size={20} />
      </IconButton>
    </Box>
  );
};

export default FormStepForm;
