import React from 'react';
import { ReactSVG } from 'react-svg';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { Typography } from '@forethought-technologies/forethought-elements';
import emptyAutomationsImage from 'src/assets/images/empty-automations.svg';
import emptyDashboardsImage from 'src/assets/images/empty-dashboards.svg';
import emptyKnowledgeImage from 'src/assets/images/empty-knowledge.svg';

type EmptyStateType = 'automation' | 'recommendation' | 'knowledge';
const getEmptyState = (type: EmptyStateType) => {
  switch (type) {
    case 'automation':
      return {
        image: emptyAutomationsImage,
        text: 'You have not yet automated any topics',
      };
    case 'knowledge':
      return {
        image: emptyKnowledgeImage,
        text: 'Great job! You have currently covered most areas of support with knowledge base articles',
      };
    case 'recommendation':
      return {
        image: emptyDashboardsImage,
        original:
          'We are generating insights to deliver recommended actions for you!',
        text: 'No policy has been generated.',
      };
    default:
      throw new Error(`Unsupported Empty state type ${type}`);
  }
};

const EmptyTabState: React.FC<
  React.PropsWithChildren<{
    type: 'automation' | 'recommendation' | 'knowledge';
  }>
> = ({ type }) => {
  const { palette } = useTheme();
  const { image, text } = getEmptyState(type);

  return (
    <Box
      alignItems='center'
      display='flex'
      flexDirection='column'
      gap='24px'
      justifyContent='center'
      pt='40px'
      textAlign='center'
    >
      <Box maxWidth='600px'>
        <ReactSVG src={image} />
        <Typography color={palette.colors.grey[500]} variant='font24'>
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default EmptyTabState;
