import { useField } from 'formik';

import { useGetIntentsQueryWithProduct } from 'src/hooks/hooks';
import ftOpenApiSchema from 'src/pages/action-builder/forethoughtImportedOpenApiSchema.json';
import mockImportedOpenApiSchema from 'src/pages/action-builder/mockImportedOpenApiSchema.json';
import { useGetActionUsagesQuery } from 'src/services/action-builder/actionBuilderApi';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { Action } from 'src/types/actionBuilderApiTypes';
import { generateNewActionStringWithActionId } from 'src/utils/actionBuilder/helpers';

export const useIsActionActive = (actionId = '') => {
  const { data } = useGetActionUsagesQuery();

  if (actionId.endsWith('template')) {
    return true;
  }

  return (data?.action_id_to_intent_ids[actionId] ?? []).length > 0;
};

export const useGetActionBuilderDetailFormIsActive = () => {
  const [{ value: actionId }] = useField<string | undefined>({
    name: 'actionId',
  });

  return useIsActionActive(actionId);
};

export const useGetIntentIdUsages = (actionId = '') => {
  const { data: intentsData, isLoading: isLoadingIntentsData } =
    useGetIntentsQueryWithProduct();
  const { data: usagesData, isLoading: isLoadingUsages } =
    useGetActionUsagesQuery();

  const allActionUsages = usagesData?.action_id_to_intent_ids[actionId] ?? [];

  return {
    isLoading: isLoadingIntentsData || isLoadingUsages,
    usages: allActionUsages.filter(entityId =>
      intentsData?.intents.some(
        intent => intent.intent_definition_id === entityId,
      ),
    ),
  };
};

const reverseActionUsageWithLists = (
  actionUsageDict: Record<string, string[]>,
): Record<string, string[]> => {
  const entityToActionDict: Record<string, string[]> = {};

  for (const actionId in actionUsageDict) {
    const entityIds = actionUsageDict[actionId];

    for (const entityId of entityIds) {
      if (!entityToActionDict[entityId]) {
        entityToActionDict[entityId] = [];
      }
      if (!entityToActionDict[entityId].includes(actionId)) {
        // Avoid duplicates
        entityToActionDict[entityId].push(actionId);
      }
    }
  }

  return entityToActionDict;
};

export const useGetActionUsagesByEntityId = () => {
  const { data: usagesData, isLoading } = useGetActionUsagesQuery();

  return {
    isLoading: isLoading,
    usages: reverseActionUsageWithLists(
      usagesData?.action_id_to_intent_ids ?? {},
    ),
  };
};

const transformActionsFromOpenApiSchema = (
  openApiSchema: typeof mockImportedOpenApiSchema | typeof ftOpenApiSchema,
): Action[] => {
  const {
    servers: [server],
  } = openApiSchema;
  const baseUrl = server.url;

  return Object.entries(openApiSchema.paths).flatMap(([path, pathValue]) => {
    return Object.entries(pathValue).map(([method, methodValue]) => {
      return {
        action_description: String(
          (methodValue as { summary?: string })?.summary || 'Description',
        ),
        action_fields: {
          method: method.toUpperCase(),
          output_context_variables: {},
          url: `${baseUrl}${path}`,
          ...(method.toLowerCase() !== 'get'
            ? {
                body: {},
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            : {}),
        },
        action_id: generateNewActionStringWithActionId(
          path.replaceAll('/', '') + method,
        ),
        action_name: `${method} ${path.replace(/\//g, ' ')}`,
        action_type: 'api',
        modified_date: new Date().toISOString(),
      } as const;
    });
  });
};

export const useGetImportedOpenApiSchemaActions = () => {
  const { data } = useGetFeatureFlagsQuery();
  if (!data?.feature_flags.includes('imported_actions')) {
    return [];
  }
  return [
    ...transformActionsFromOpenApiSchema(ftOpenApiSchema),
    ...transformActionsFromOpenApiSchema(mockImportedOpenApiSchema),
  ];
};
