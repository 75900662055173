import { SESSION_COPY_PASTE_STORAGE_KEY } from './constants';
import { WorkflowTypes } from './types';
import camelCase from 'lodash/fp/camelCase';
import intersection from 'lodash/fp/intersection';
import { isDynamicArticleSuggestion } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducerHelpers';
import { GroupInsertionData } from 'src/slices/canvas-workflow-builder/types/dataTypes';
import { CanvasWorkflowBuilderState } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { Action, ContextVariable } from 'src/types/actionBuilderApiTypes';
import { SolveWidgetProduct } from 'src/types/types';
import {
  ActionStepList,
  ButtonsStepFields,
  FormField,
  Step,
} from 'src/types/workflowBuilderAPITypes';
import { flattenCvs } from 'src/utils/actionBuilder/helpers';
import {
  getArticleSuggestionActionId,
  getEntryFromArticleSuggestionActionStep,
} from 'src/utils/articleSuggestionUtils';

export const getCamelCasedActionType = (action: Action | undefined) => {
  const actionType = action?.action_type || '';
  if (!actionType) {
    return undefined;
  }

  const camelCasedActionType = camelCase(actionType).substring(1);
  const dataAttributeName = `${
    actionType[0].toUpperCase() || ''
  }${camelCasedActionType}`;

  return dataAttributeName;
};

const bfs = (graph: Record<string, Step>, start: string) => {
  const queue = [start];
  const visited = new Set();
  const result: Record<string, unknown> = {};

  while (queue.length) {
    const vertex = queue.shift();

    if (vertex && !visited.has(vertex)) {
      visited.add(vertex);
      result[vertex] = graph[vertex].step_fields;

      for (const transition of graph[vertex].transitions) {
        const transitionStepId = transition.step_id;
        if (transitionStepId) {
          queue.push(transitionStepId);
        }
      }
    }
  }

  return result;
};

export const handleCopyGroupOfSteps = (
  canvasData: CanvasWorkflowBuilderState,
  stepId: string,
  intentTitle: string,
) => {
  const handleConfigurationFieldsForActions = (
    canvasActionIdToActionComponent: { [k: string]: ActionStepList },
    stepIdToStepFields: Record<string, unknown>,
  ) => {
    const updatedCanvasActionIdToActionComponent: {
      [k: string]: ActionStepList;
    } = {};
    for (const actionKey of Object.keys(canvasActionIdToActionComponent)) {
      const value = { ...canvasActionIdToActionComponent[actionKey] };
      const entryStepId = value?.entry_step_id;

      if (entryStepId) {
        const configFields = value?.config_fields || {};
        const stepFieldsToAdd = stepIdToStepFields?.[entryStepId] || {};
        // stepFieldsToAdd should override configFields if both exists
        value.config_fields = {
          ...configFields,
          ...stepFieldsToAdd,
        };
      }
      updatedCanvasActionIdToActionComponent[actionKey] = value;
    }
    return updatedCanvasActionIdToActionComponent;
  };

  const {
    canvas_action_id_to_action_component: actionGraph,
    steps: stepGraph,
  } = canvasData;

  const stepIdToStepFields = bfs(stepGraph, stepId);
  const stepIds = Object.keys(stepIdToStepFields);
  const stepMap = Object.fromEntries(
    Object.entries(stepGraph).filter(([key]) => stepIds.includes(key)),
  );
  const canvasActionIdToActionComponent = Object.fromEntries(
    Object.entries(actionGraph).filter(([key]) => {
      return Boolean(intersection(actionGraph[key].steps, stepIds).length);
    }),
  );

  const updatedCanvasActionIdToActionComponent =
    handleConfigurationFieldsForActions(
      canvasActionIdToActionComponent,
      stepIdToStepFields,
    );

  const group = {
    canvas_action_id_to_action_component:
      updatedCanvasActionIdToActionComponent,
    entry_step_id: stepId,
    intentTitle,
    step_map: stepMap,
  };

  sessionStorage.setItem(SESSION_COPY_PASTE_STORAGE_KEY, JSON.stringify(group));
  return group;
};

export const handlePasteGroupOfSteps = (
  canvasData: CanvasWorkflowBuilderState,
  lastStepId: string | null,
  actualLastStepId: string | null,
) => {
  const copyStringValue = sessionStorage.getItem(
    SESSION_COPY_PASTE_STORAGE_KEY,
  );
  const {
    actionCaseMap,
    outputVariableValuesMap,
    selectedConditions,
    steps: stepGraph,
  } = canvasData;
  if (copyStringValue) {
    const group: GroupInsertionData = JSON.parse(copyStringValue);
    let parentTransitionId = '';
    // Prioritize getting parent transition id if it is a branch transition step
    if (actualLastStepId) {
      const stepType = stepGraph[actualLastStepId]?.step_type;
      const stepFields = stepGraph[actualLastStepId]?.step_fields as
        | FormField
        | ButtonsStepFields;
      if (stepType === 'condition') {
        const index = selectedConditions?.[actualLastStepId];
        parentTransitionId =
          stepGraph[actualLastStepId]?.transitions[index]?.transition_id ?? '';
      }
      if (stepType === 'buttons') {
        const outputVariableName = stepFields?.output_variable ?? null;
        const outputValue =
          outputVariableValuesMap?.[actualLastStepId]?.[outputVariableName];
        if (outputValue) {
          const transition = stepGraph?.[actualLastStepId]?.transitions.find(
            transition =>
              transition.condition_expression?.values?.includes(outputValue),
          );
          if (transition) {
            parentTransitionId = transition.transition_id ?? '';
          }
        } else if (lastStepId) {
          // Specific scenario for Dynamic article suggestion (pasting below transition)
          const isYesBranchChosen = actionCaseMap[lastStepId];
          const transitionBranches = stepGraph?.[actualLastStepId]?.transitions;
          const transitionId = isYesBranchChosen
            ? transitionBranches?.find(transition =>
                transition?.condition_expression?.values?.includes(
                  'yes_resolve',
                ),
              )?.transition_id
            : transitionBranches?.find(transition =>
                transition?.condition_expression?.values?.includes(
                  'no_resolve',
                ),
              )?.transition_id;
          if (transitionId) {
            parentTransitionId = transitionId;
          }
        }
      }
    }
    // On other stepTypes check for transition without step ids.
    if (!parentTransitionId && actualLastStepId) {
      const transitionLength =
        stepGraph?.[actualLastStepId]?.transitions?.length ?? 0;
      if (transitionLength === 1) {
        const transitionObj = stepGraph?.[actualLastStepId]?.transitions[0];
        parentTransitionId =
          transitionObj?.transition_id && !transitionObj?.step_id
            ? transitionObj.transition_id
            : '';
      }
    }
    const insertGroupPayload: GroupInsertionData = {
      canvas_action_id_to_action_component:
        group.canvas_action_id_to_action_component,
      entry_step_id: group.entry_step_id,
      intentTitle: group.intentTitle,
      parent_step_id: actualLastStepId ?? '',
      parent_transition_id: parentTransitionId,
      step_map: group.step_map,
    };
    return insertGroupPayload;
  }
};

export const findAllUnusableCvsInStepGraph = (
  stepGraph: Record<string, Step>,
  stepId: string,
  contextVariables: ContextVariable[],
) => {
  const flatCvs = flattenCvs(contextVariables);
  const findCvs = (value: string) => {
    const arr = [...value.matchAll(new RegExp('{{.*?}}', 'gi'))].map(a => {
      const cvId = a[0];

      const matchedCv = flatCvs.find(
        cv => cv.context_variable_id === cvId.slice(2, -2),
      )?.context_variable_id;

      if (!matchedCv) {
        return cvId.slice(2, -2);
      }
    });

    return arr.filter(Boolean);
  };

  const findCvsFromStepGraph = (graph: Record<string, Step>, start: string) => {
    const queue = [start];
    const visited = new Set();
    const result = new Set<string>();

    while (queue.length) {
      const vertex = queue.shift();

      if (vertex && !visited.has(vertex)) {
        visited.add(vertex);
        const stepFields = graph[vertex]?.step_fields as Record<
          string,
          unknown
        >;
        const message = (stepFields?.message ?? '') as string;
        if (message) {
          const cvs = findCvs(message) as string[];
          cvs.forEach(value => result.add(value));
        }
        const bodyParameters = (stepFields?.body_parameters ?? '') as string;
        if (bodyParameters) {
          const cvs = findCvs(bodyParameters) as string[];
          cvs.forEach(value => result.add(value));
        }
        const url = (stepFields?.url ?? '') as string;
        if (url) {
          const cvs = findCvs(url) as string[];
          cvs.forEach(value => result.add(value));
        }

        for (const transition of graph[vertex]?.transitions) {
          const transitionStepId = transition.step_id;
          if (transitionStepId) {
            queue.push(transitionStepId);
          }
        }
      }
    }

    return result;
  };

  return Array.from(findCvsFromStepGraph(stepGraph, stepId));
};

export const formatIntentNames = (intentNames: string[]) => {
  if (!intentNames.length) {
    return '';
  }

  if (intentNames.length === 1) {
    return intentNames.join(', ') + ' Workflow';
  }

  return intentNames.join(', ') + ' Workflows';
};

export const hasDynamicArticleSuggestion = () => {
  const copyStringValue = sessionStorage.getItem(
    SESSION_COPY_PASTE_STORAGE_KEY,
  );

  if (!copyStringValue) {
    return false;
  }

  const group: GroupInsertionData = JSON.parse(copyStringValue);
  const stepIdToStepFields = bfs(group.step_map, group.entry_step_id);
  const stepIds = Object.keys(stepIdToStepFields);

  for (const stepId of stepIds) {
    // Article Suggestion
    // If this step doesn't actually belong to article suggestion, then entryStepId will be undefined.
    const actionEntryStepId = getEntryFromArticleSuggestionActionStep(
      stepId,
      group.canvas_action_id_to_action_component,
    );

    if (actionEntryStepId) {
      // if the action is dynamic article suggestion and has no button step, then the parent transition
      // would be the article found branch of the condition step (cond expr is null)
      const actionId = getArticleSuggestionActionId(
        stepId,
        group.canvas_action_id_to_action_component,
      );
      if (isDynamicArticleSuggestion(group, actionId)) {
        return true;
      }
    }
  }
  return false;
};

export const workflowTypeToProduct: Record<WorkflowTypes, SolveWidgetProduct> =
  {
    'api-live': 'api',
    'api-preview': 'api',
    email: 'interactive_email',
    'flamethrower-live': 'workflow_builder',
    'flamethrower-preview': 'workflow_builder',
    'interactive-email-live': 'interactive_email',
    'interactive-email-preview': 'interactive_email',
    'slack-live': 'slack',
    'slack-preview': 'slack',
    'voice-live': 'voice',
    'voice-preview': 'voice',
  };
