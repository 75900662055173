import { useCallback, useMemo, useState } from 'react';
import { Box, useTheme } from '@mui/material';

import {
  Button,
  Checkbox,
  DateRangeFilterButton,
  MultiSelectFilter,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import InsightDownloadCsv from '../common/InsightDownloadCsv';
import OverallCards from '../common/OverallCards';
import TabHeader from '../common/TabHeader';
import { INTENT_FILTER_PARAM_NAME } from '../constants';
import {
  dateRangeToTimestamp,
  numbersToStringWithCommas,
  stringifyDateRange,
} from '../helpers';
import { InsightSortDirection } from '../types';
import {
  initialInsightTopicParams,
  InsightTopicSortColumns,
} from './constants';
import { extractChildItems } from './helper';
import TopicsTable from './TopicsTable';
import { InsightTopicData, InsightTopicDataColumnKeys } from './types';
import isEqual from 'lodash/fp/isEqual';
import partition from 'lodash/fp/partition';
import filterIcon from 'src/assets/images/filter-analytic-icon.svg';
import chatIcon from 'src/assets/images/icon-message.svg';
import {
  useEmitTrackingEventCallback,
  useGetIntentsQueryWithProduct,
  useStateParams,
} from 'src/hooks/hooks';
import {
  CHANNEL_COPY,
  NA,
} from 'src/pages/intent-conversation-analytics/constants';
import { buildQueryFromMultiSelected } from 'src/pages/intent-conversation-analytics/hooks/useConversations';
import useIsChannelFiltersEnabled from 'src/pages/intent-conversation-analytics/hooks/useIsInsightChannelFiltersEnabled';
import {
  useGetChatsSavingsQuery,
  useGetTopicsTableQuery,
} from 'src/services/insights';
import {
  dateRangeDeserialize,
  dateRangeSerialize,
  dateRangeToTimeFilter,
  listDeserialize,
  listSerialize,
  timeFilterParameterValidator,
} from 'src/utils/discover/helpers';
import { CommonIntentWorkflowType, ExportableTableType } from 'src/utils/enums';
import { datePickerRangeOptionsRevamp } from 'src/utils/timeRangeHelpers';

const validator = (param: string) => {
  const list = listDeserialize(param);

  return list.every(item => item.startsWith('workflow_id.'));
};

const SolveInsightsTopics = () => {
  const isChannelFilterEnabled = useIsChannelFiltersEnabled();
  const { palette } = useTheme();
  // State params
  const [sortDirection] = useStateParams<InsightSortDirection>({
    deserialize: (param: string) => (param === 'ASC' ? 'ASC' : 'DESC'),
    initialState: 'DESC',
    paramsName: 'topic_sort_direction',
    serialize: (state: InsightSortDirection) => state.toString(),
  });
  const [sortColumn, setSortColumn] = useStateParams<InsightTopicSortColumns>({
    deserialize: (param: string) => param as InsightTopicSortColumns,
    initialState: initialInsightTopicParams.topicColumnSort,
    paramsName: 'topics_sort_column',
    serialize: (state: InsightTopicSortColumns) => state.toString(),
  });
  const [dateRange, setDateRange] = useStateParams({
    deserialize: dateRangeDeserialize,
    initialState: initialInsightTopicParams.date,
    paramsName: 'date',
    serialize: dateRangeSerialize,
    validator: timeFilterParameterValidator([]),
  });
  const [isTopicGrouped, setIsTopicGrouped] = useStateParams<boolean>({
    deserialize: (value: string) => value === 'true',
    initialState: true,
    paramsName: 'is_grouped',
    serialize: (value: boolean) => value.toString(),
    validator: (param: string) => param === 'true' || param === 'false',
  });
  const timestamps = dateRangeToTimestamp(dateRange);
  const { data: allSavingCardData, isLoading: isAllSavingCardDataLoading } =
    useGetChatsSavingsQuery({
      end_timestamp: timestamps.end_timestamp,
      start_timestamp: timestamps.start_timestamp,
      topics_only: false,
    });

  // Hooks
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const [visibleColumns, setVisibleColumns] = useState<
    InsightTopicDataColumnKeys[]
  >(initialInsightTopicParams.topicColumns);

  const [showOnlyFilter, setShowOnlyFilter] = useState<string[]>([]);
  const { data } = useGetIntentsQueryWithProduct();
  const { intents = [] } = data ?? {};

  const [workflowFilter, setWorkflowFilter] = useStateParams<string[]>({
    deserialize: listDeserialize,
    initialState: [],
    paramsName: `${INTENT_FILTER_PARAM_NAME}_topic`,
    serialize: listSerialize,
    validator,
  });
  const [channels, setChannels] = useStateParams<string[]>({
    deserialize: listDeserialize,
    initialState: [],
    paramsName: 'channels',
    serialize: listSerialize,
  });

  const workflowFilterIds = useMemo(
    () => workflowFilter.map(item => item.slice('workflow_id.'.length)),
    [workflowFilter],
  );

  const tableRequestParams = useMemo(
    () => ({
      channels: buildQueryFromMultiSelected(channels)?.channels,
      data_export_type: ExportableTableType.INSIGHT_TOPICS_TABLE,
      end: timestamps.end_timestamp,
      sort_column: sortColumn,
      sort_direction: sortDirection,
      start: timestamps.start_timestamp,
      workflow_ids: workflowFilterIds,
    }),
    [timestamps, sortColumn, sortDirection, workflowFilterIds, channels],
  );

  const {
    data: topicTableData,
    isFetching: isTopicTableFetching,
    isLoading: isTopicTableLoading,
  } = useGetTopicsTableQuery({
    ...tableRequestParams,
  });

  const isLoading =
    isTopicTableLoading || isTopicTableFetching || isAllSavingCardDataLoading;

  const timeFilter = dateRangeToTimeFilter(dateRange);
  const initialTimeFilter = dateRangeToTimeFilter(
    initialInsightTopicParams.date,
  );

  const areFiltersUsed = Boolean(
    timeFilter.key !== initialTimeFilter.key ||
      workflowFilter.length ||
      showOnlyFilter.length ||
      visibleColumns.toString() !==
        initialInsightTopicParams.topicColumns.toString() ||
      sortColumn !== initialInsightTopicParams.topicColumnSort,
  );

  const costPerTicket =
    allSavingCardData?.misc.cost_per_ticket.toString() ?? NA;

  const treatedTableData = useMemo(() => {
    if (isLoading) {
      const fillerTopic: InsightTopicData = {
        all_workflows: [],
        automation_urls: [],
        chats_count: 0,
        cluster_id: '',
        cluster_name: '',
        csat: 0,
        deflected_chats_count: 0,
        deflected_rate: 0,
        display_name: '',
        engagement_rate: 0,
        insight_subtopics: [],
        non_deflected_chats_count: 0,
        potential_saving: 0,
        realized_saving: 0,
        related_articles: [],
        type: 'workflow',
        workflows: [],
      };
      // use empty data to render loading state
      return Array(10).fill(fillerTopic) as InsightTopicData[];
    }

    if (topicTableData?.data.length) {
      const data = isTopicGrouped
        ? topicTableData?.data
        : extractChildItems(topicTableData?.data);

      // Sort the sub topics
      const sortedTopicTableData = data.map(item => {
        const sortedInsightSubTopics = [...item.insight_subtopics].sort(
          (a, b) => (a.chats_count > b.chats_count ? -1 : 1),
        );
        return { ...item, insight_subtopics: sortedInsightSubTopics };
      });

      // move Others subtopic to be at the bottom of the subtopics
      const organizedTableData = sortedTopicTableData.map(topic => {
        const subtopics = topic.insight_subtopics;
        const [othersSubtopic, normalSubtopics] = partition(
          subtopic => subtopic.cluster_name.endsWith(' - Others'),
          subtopics,
        );

        return {
          ...topic,
          insight_subtopics: normalSubtopics.concat(othersSubtopic),
        };
      });

      return organizedTableData;
    }
    return [];
  }, [isLoading, topicTableData, isTopicGrouped]);

  const getTotalChatsFromTable = treatedTableData.reduce((acc, val) => {
    return acc + val.chats_count;
  }, 0);
  // Get all conversation count
  const getTotalChatsFromSavingCard = allSavingCardData?.misc.chats ?? 0;
  const percentCoverage =
    getTotalChatsFromSavingCard === 0
      ? NA
      : numbersToStringWithCommas({
          number: getTotalChatsFromTable / getTotalChatsFromSavingCard,
          style: 'percent',
        });

  const workflowSelectOptions = intents.map(intent => ({
    label: intent.intent_name,
    value: `workflow_id.${intent.intent_workflow_id}`,
  }));

  const fallbackWorkflowId = intents.find(
    intent => intent.intent_definition_id === CommonIntentWorkflowType.FALLBACK,
  )?.intent_workflow_id;

  const getTopicsTableDownloadUrl = useCallback(() => {
    const url = new URL(`${API_URL}data-export`);
    return url.href;
  }, []);

  const countAllTopics = () => {
    if (!topicTableData) {
      return 0;
    }

    if (!isTopicGrouped) {
      return treatedTableData.length;
    }

    return treatedTableData.reduce((prev, current) => {
      return prev + current.insight_subtopics.length;
    }, 0);
  };

  return (
    <Box
      bgcolor={palette.colors.white}
      display='flex'
      flexDirection='column'
      gap={3}
      height='100%'
      overflow='auto'
      padding='24px 40px'
    >
      <TabHeader
        dateRange={stringifyDateRange(dateRange)}
        scope='main'
        tabName='Topics'
      />
      <OverallCards dateRange={dateRange} tab='topic' />
      <Typography
        color={palette.colors.grey[600]}
        variant='font16'
      >{`Topics created for ${percentCoverage} of Chats. Chats need to contain sufficient dialogue length to be classified as a Topic category.`}</Typography>
      <Box>
        <TopicsTable
          costPerTicket={costPerTicket}
          data={treatedTableData}
          emitTrackingEventCallback={emitTrackingEventCallback}
          filters={
            <Box
              alignItems='center'
              display='flex'
              gap='8px'
              paddingTop='4px'
              width='100%'
            >
              <DateRangeFilterButton
                explicitLabel={true}
                initialValue={initialInsightTopicParams.date}
                onChange={setDateRange}
                options={datePickerRangeOptionsRevamp}
                value={dateRange}
              />
              {isChannelFilterEnabled && (
                <MultiSelectFilter
                  icon={<img src={chatIcon} />}
                  maxHeight={425}
                  onChange={value => setChannels(value)}
                  options={[
                    {
                      label: 'Channel',
                      options: [
                        {
                          label: CHANNEL_COPY.widget,
                          value: 'channels.widget',
                        },
                        { label: CHANNEL_COPY.api, value: 'channels.api' },
                        { label: CHANNEL_COPY.slack, value: 'channels.slack' },
                      ],
                      value: 'channels',
                    },
                  ]}
                  placeholder='Channel'
                  value={channels}
                  variant='secondary'
                />
              )}
              <MultiSelectFilter
                icon={<img src={filterIcon} />}
                isMenuSearchable
                onChange={value => {
                  setWorkflowFilter(value);
                }}
                options={workflowSelectOptions}
                placeholder='Filter by'
                value={workflowFilter}
              />
              <Checkbox
                checked={isTopicGrouped}
                label='Group Topics'
                onChange={event => {
                  setIsTopicGrouped(event.target.checked);
                }}
              />
              {fallbackWorkflowId && (
                <Tooltip tooltipContent="Chats trigger ‘Fallback’ when they can't answer a question due to insufficient knowledge articles or workflow gaps">
                  <Checkbox
                    checked={isEqual(workflowFilter, [
                      `workflow_id.${fallbackWorkflowId}`,
                    ])}
                    label='Gaps only'
                    onChange={event => {
                      setWorkflowFilter(
                        event.target.checked
                          ? [`workflow_id.${fallbackWorkflowId}`]
                          : [],
                      );
                    }}
                  />
                </Tooltip>
              )}
              {areFiltersUsed && (
                <Button
                  onClick={() => {
                    setDateRange(initialInsightTopicParams.date);
                    setVisibleColumns(initialInsightTopicParams.topicColumns);
                    setSortColumn(initialInsightTopicParams.topicColumnSort);
                    setWorkflowFilter([]);
                    setShowOnlyFilter([]);
                  }}
                  size='medium'
                  variant='ghost'
                >
                  <Typography noWrap variant='font14Medium'>
                    Reset filters
                  </Typography>
                </Button>
              )}
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'flex-end',
                  paddingRight: 2,
                }}
              >
                <InsightDownloadCsv
                  filename='topics.csv'
                  requestData={{
                    ...tableRequestParams,
                  }}
                  url={getTopicsTableDownloadUrl()}
                />
              </Box>
            </Box>
          }
          isLoading={isTopicTableLoading}
          isTopicGrouped={isTopicGrouped}
          totalTopicsCount={countAllTopics()}
        />
      </Box>
    </Box>
  );
};

export default SolveInsightsTopics;
