import { useMemo, useState } from 'react';
import { createMRTColumnHelper, MRT_Row } from 'material-react-table';
import Box from '@mui/material/Box';

import {
  InfiniteTable,
  SearchWithDropdown,
  Typography,
} from '@forethought-technologies/forethought-elements';
import EmptyTabState from '../components/EmptyTabState';
import HTMLCell from '../components/HTMLCell';
import NumberValueCell from '../components/NumberValueCell';
import TimestampValueCell from '../components/TimestampValueCell';
import TopicNameCell from '../components/TopicNameCell';
import { POLICY_TABLE_COLUMNS } from './constants';
import get from 'lodash/get';
import {
  DiscoverAutomationValueType,
  DiscoverTopic,
} from 'src/reducers/discoverReducer/types';
import { PolicyRecommendation } from 'src/types/DiscoverTypes';

interface DiscoverPolicyRecommendationTableProps {
  data?: PolicyRecommendation[];
  isError: boolean;
  loading: boolean;
  onMouseEnter: (row: MRT_Row<PolicyRecommendation>) => void;
  onScroll: (scroll: number) => void;
  onSelect: (row: MRT_Row<PolicyRecommendation>) => void;
}

type SearchType = 'policy' | 'topic';

const DiscoverPolicyRecommendationTable = ({
  data,
  isError,
  loading,
  onMouseEnter,
  onScroll,
  onSelect,
}: DiscoverPolicyRecommendationTableProps) => {
  const [searchText, setSearchText] = useState('');
  const [searchType, setSearchType] = useState<SearchType>('policy');
  const columnHelper = createMRTColumnHelper<PolicyRecommendation>();

  const rows = useMemo(() => {
    if (!searchText) {
      return data;
    }
    return data?.filter(row =>
      searchType === 'policy'
        ? row.policy_description
            .toLowerCase()
            .includes(searchText.toLowerCase())
        : row.topic.topic_name.toLowerCase().includes(searchText.toLowerCase()),
    );
  }, [searchText, data, searchType]);

  const columns = useMemo(
    () =>
      POLICY_TABLE_COLUMNS.map(colItem =>
        columnHelper.accessor(colItem.key, {
          Cell: ({ row }: { row: MRT_Row<PolicyRecommendation> }) => {
            const keys = colItem.key.split('.');
            const keyLength = keys.length;
            let value = null;
            switch (keyLength) {
              case 1:
                value = row.original[keys[0] as keyof PolicyRecommendation];
                break;
              case 2:
                value = row.original.topic[keys[1] as keyof DiscoverTopic];
                break;
              case 4:
                // case for 'topic.actionable_value.solve_workflow.XXX'
                value = row.original.topic.actionable_value?.solve_workflow
                  ? row.original.topic.actionable_value.solve_workflow[
                      keys[3] as DiscoverAutomationValueType
                    ]
                  : null;
                break;
              default:
                break;
            }
            const { topic } = row.original;
            const { actionable_value } = topic;

            if (!actionable_value) {
              return (
                <Typography variant='font14'>{value as string}</Typography>
              );
            }

            const columnToComponentMap = {
              created_date: (
                <TimestampValueCell value={row.original.created_date} />
              ),
              policy_description: <HTMLCell value={value as string} />,
              'topic.actionable_value.solve_workflow.cost': (
                <NumberValueCell
                  prefix='$'
                  value={actionable_value?.solve_workflow?.cost}
                />
              ),
              'topic.actionable_value.solve_workflow.volume': (
                <NumberValueCell
                  value={actionable_value?.solve_workflow?.volume}
                />
              ),
              'topic.topic_name': <TopicNameCell topic={topic} />,
            };
            const key = colItem.key;
            return get(columnToComponentMap, key);
          },
          header: colItem.title,
          size: colItem.size,
        }),
      ),
    [columnHelper],
  );

  const handleSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setSearchText(event.target.value);
  };

  return (
    <InfiniteTable
      hasNextPage={false}
      initialSorting={[
        { desc: true, id: 'topic.actionable_value.solve_workflow.cost' },
      ]}
      isError={isError}
      isLoadingFirstPage={loading}
      isLoadingNextPage={false}
      stickyHeaderHeight={0}
      tableOptions={{
        columns,
        data: rows || [],
        displayColumnDefOptions: {
          'mrt-row-actions': {
            header: '',
            muiTableBodyCellProps: {
              align: 'center',
            },
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 130,
          },
        },
        enableBottomToolbar: false,
        enableColumnActions: false,
        enableColumnFilters: false,
        enableExpanding: false,
        enableFilters: false,
        enableFullScreenToggle: false,
        enableGlobalFilter: false,
        enableRowActions: false,
        initialState: {
          columnPinning: {
            right: ['mrt-row-actions'],
          },
        },
        manualSorting: false,
        muiTableBodyRowProps: ({ row }) => ({
          onClick: () => onSelect(row),
          onMouseEnter: () => onMouseEnter(row),
          sx: {
            cursor: 'pointer',
          },
        }),
        muiTableContainerProps: () => ({
          onScroll: event => onScroll(event.currentTarget.scrollTop),
          sx: {
            height: '100%',
            minHeight: rows?.length ? undefined : 'calc(100vh - 200px)',
          },
        }),
        muiTablePaperProps: {
          sx: {
            boxShadow: 'none',
            display: 'grid',
          },
        },
        renderEmptyRowsFallback: () => <EmptyTabState type='recommendation' />,
        renderTopToolbarCustomActions: () => (
          <Box
            sx={{
              width: '300px',
            }}
          >
            <SearchWithDropdown
              disabled={loading}
              onChange={handleSearch}
              onClear={() => setSearchText('')}
              options={[
                { text: 'Policy', value: 'policy' },
                { text: 'Topic', value: 'topic' },
              ]}
              placeholder={
                searchType === 'policy'
                  ? 'Search AutoFlow policy'
                  : 'Search topic'
              }
              selectChange={value => {
                setSearchType(value as SearchType);
              }}
              selectedOption={searchType}
              value={searchText}
            />
          </Box>
        ),
      }}
    />
  );
};

export default DiscoverPolicyRecommendationTable;
