export const formatContextVariables = (
  contextVariables: Record<string, string>,
) => {
  const cvNames = Object.keys(contextVariables);

  const nameNValue = cvNames.map(cvName => {
    return `$${cvName} (${contextVariables[cvName]})`;
  });

  return nameNValue.join(', ');
};

export const formatTimeSpent = (seconds: number) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);

  const hDisplay = h > 0 ? h + ' hr ' : '';
  const mDisplay = m > 0 ? m + ' min ' : '';
  const sDisplay = s > 0 ? s + ' sec ' : '';

  return hDisplay + mDisplay + sDisplay;
};
