export const RADIO_LABEL_STYLE = {
  fontSize: '14px',
  fontWeight: 400,
};

export const TICKET_FIELD_TOOLTIP =
  'Tickets with values in the selected field will be used for model generation.';

export const DATE_RANGE_TOOLTIP =
  'Tickets that were created in the selected period will be used for model generation.';

export const INITIAL_EXPRESSION = {
  booleanOperator: 'and',
  expression: {
    expression_type: 'filter',
    field: '',
    negate: false,
    operator: '=',
    values: [],
  },
};

export const DELETE_TEXTS = {
  description: `This model will be permanently deleted and will not be retrievable.
Are you sure you want to delete the model?`,
  title: 'Delete model',
};

export const ERROR_MESSAGE =
  "We couldn't generate a model with the current ticket filters. Please adjust the filters and try again, as they may be too restrictive and exclude relevant tickets.";

export const GENERATING_MODEL_MESSAGE =
  'Model generation can take several hours, depending on the number of tickets.';
