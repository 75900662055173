import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { IconPlus, IconTrash } from '@tabler/icons-react';

import {
  Button,
  IconButton,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { FormWithErrors } from '../types';
import ContextVariableAutocomplete from 'src/components/context-variable-autocomplete';
import HorizontalGripIcon from 'src/components/horizontal-grip-icon/HorizontalGripIcon';
import { RichTextEditor } from 'src/components/rich-text-editor/RichTextEditor';
import { EditorRef } from 'src/components/rich-text-editor/types';
import { createContextVariableID } from 'src/pages/action-builder/context-variables-settings/helpers';
import { useGetContextVariablesForTextEditor } from 'src/pages/workflow-builder-edit/email-builder-page/intent-email-builder/hooks';
import { useSwapItem } from 'src/pages/workflow-builder-edit/hooks';
import {
  selectCanvasWorkflowBuilder,
  selectEditingStepId,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { emptyButtonOption } from 'src/slices/canvas-workflow-builder/utils';
import {
  CanvasWorkflowBuilderState,
  selectEditingStepFields,
  setStepFieldValue,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { useAppDispatch } from 'src/store/hooks';
import {
  ButtonOption,
  ButtonsStepFields,
} from 'src/types/workflowBuilderAPITypes';
import { isOptionsStepFields } from 'src/utils/solve/stepUtils';

const OptionsForm = ({ errorObject }: FormWithErrors<ButtonsStepFields>) => {
  const [touched, setTouched] = useState(false);
  const editingStepId = useSelector(selectEditingStepId);
  const { palette } = useTheme();
  const canvasData: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );

  const dispatch = useAppDispatch();
  const fields = useSelector(selectEditingStepFields);

  const remirrorContextVariables = useGetContextVariablesForTextEditor();

  const editorRef = useRef<EditorRef>(null);

  if (!isOptionsStepFields(fields)) {
    return null;
  }

  const { options, prompt } = fields;

  const setPrompt = (prompt: string) => {
    setTouched(true);

    dispatch(setStepFieldValue({ field: 'prompt', value: prompt }));
  };

  const setOption =
    (index: number, transitionId?: string | null) => (value: string) => {
      const optionsCopy = [...options];
      optionsCopy[index] = {
        display_name: value,
        transition_id: transitionId,
        value: createContextVariableID(),
      };
      dispatch(setStepFieldValue({ field: 'options', value: optionsCopy }));
    };

  const addOption = () => {
    setTouched(true);

    dispatch(
      setStepFieldValue({
        field: 'options',
        value: [...options, emptyButtonOption],
      }),
    );
  };

  const deleteOption = (index: number) => {
    setTouched(true);

    dispatch(
      setStepFieldValue({
        field: 'options',
        value: [...options.slice(0, index), ...options.slice(index + 1)],
      }),
    );
  };

  const swapOptions = (dragIndex: number, hoverIndex: number) => {
    setTouched(true);

    const optionsCopy = [...options];
    const temp = optionsCopy[hoverIndex];
    optionsCopy[hoverIndex] = optionsCopy[dragIndex];
    optionsCopy[dragIndex] = temp;

    dispatch(
      setStepFieldValue({
        field: 'options',
        value: optionsCopy,
      }),
    );
  };

  const getDeleteMessage = (index: number) => {
    if (!editingStepId) {
      return '';
    }

    const isNewOption =
      index >= canvasData.steps[editingStepId].transitions.length;

    if (isNewOption) {
      return '';
    }

    return canvasData.steps[editingStepId].transitions?.[index]?.step_id
      ? 'Options with with subsequent steps cannot be deleted'
      : '';
  };

  return (
    <>
      <Typography variant='font14Bold'>Options message</Typography>
      <Box
        border={`1px solid ${
          errorObject?.prompt && touched
            ? palette.colors.red[500]
            : palette.colors.grey[300]
        }`}
        borderRadius='4px'
        mb={3}
        mt={1}
      >
        <RichTextEditor
          contextVariables={remirrorContextVariables}
          editorRef={editorRef}
          errorMessage={touched ? errorObject?.prompt : undefined}
          initialContent={prompt}
          label='Options message'
          onChange={setPrompt}
          placeholder='Write an options-filling message for your customers...'
          withExtraMentions
        />
      </Box>
      <Box mb={1}>
        <Typography variant='font14Bold'>Options fields</Typography>
      </Box>
      <Box display='flex' flexDirection='column' gap={1}>
        {options.map(
          (
            { display_name: displayName, transition_id: transitionId },
            index,
          ) => (
            <Option
              deleteMessage={getDeleteMessage(index)}
              deleteOption={deleteOption}
              displayName={displayName}
              errorObject={touched ? errorObject?.options?.[index] : undefined}
              index={index}
              key={index}
              setOption={setOption(index, transitionId)}
              swapOptions={swapOptions}
            />
          ),
        )}
      </Box>
      <Box mt={1}>
        <Button
          onClick={addOption}
          startIcon={<IconPlus size={20} />}
          variant='ghost'
        >
          Add options button
        </Button>
      </Box>
    </>
  );
};

interface OptionProps {
  deleteMessage: string;
  deleteOption: (index: number) => void;
  displayName: string;
  errorObject: ButtonOption | undefined;
  index: number;
  setOption: (value: string) => void;
  swapOptions: (index: number, index2: number) => void;
  transitionId?: string;
}

const Option = ({
  deleteMessage,
  deleteOption,
  displayName,
  errorObject,
  index,
  setOption,
  swapOptions,
}: OptionProps) => {
  const { dragPreviewRef, handlerId, opacity, ref } = useSwapItem({
    index,
    swap: swapOptions,
  });

  return (
    <Box
      alignItems='center'
      display='flex'
      gap={1}
      key={index}
      ref={dragPreviewRef}
      sx={{
        backgroundColor: 'transparent',
        cursor: 'grab',
        opacity,
        transform: 'translate(0, 0)',
      }}
    >
      <HorizontalGripIcon handlerId={handlerId} ref={ref} />
      <Box flex={1}>
        <ContextVariableAutocomplete
          aria-label={`Option ${index + 1}`}
          error={errorObject?.display_name}
          onChange={setOption}
          placeholder='Enter option text or select context variable'
          value={displayName}
        />
      </Box>
      {deleteMessage ? (
        <Tooltip tooltipContent={deleteMessage}>
          <IconButton
            aria-label={`${displayName} #${index}`}
            disabled
            onClick={() => deleteOption(index)}
            variant='ghost'
          >
            <IconTrash size={20} />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton
          aria-label={`${displayName} #${index}`}
          onClick={() => deleteOption(index)}
          variant='ghost'
        >
          <IconTrash size={20} />
        </IconButton>
      )}
    </Box>
  );
};

export default OptionsForm;
