import { useMemo, useState } from 'react';
import { createMRTColumnHelper, MRT_Row } from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Menu, MenuItem } from '@mui/material';

import {
  Dialog,
  Drawer,
  formatUnixTimestampToDateAndTime,
  IconButton,
  SearchBar,
} from '@forethought-technologies/forethought-elements';
import {
  InfiniteTable,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { TABLE_COLUMNS, TEST_RUN_STATUS_TOOLTIP } from './constants';
import DeleteSimulationForm from './DeleteSimulationForm';
import ManageSimulationForm, {
  SimulationFormValues,
} from './ManageSimulationForm';
import TestTicketStatusTableValue from './TestTicketStatusTableValue';
import TriageCreateRun from './TriageCreateRun';
import { NA } from 'src/constants/solve';
import {
  useDeletePredictionTestMutation,
  usePatchPredictionModelMutation,
} from 'src/services/triage/triageApi';
import {
  TriagePredictionLabel,
  TriagePredictionRun,
} from 'src/services/triage/types';
import { formatReadableDate } from 'src/utils/dateUtils';
import { Routes } from 'src/utils/enums';

interface TriageSimulationsTableProps {
  data?: TriagePredictionRun[];
  isLoading: boolean;
}

const TriageSimulationsTable = ({
  data,
  isLoading,
}: TriageSimulationsTableProps) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const columnHelper = createMRTColumnHelper<TriagePredictionLabel>();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] =
    useState<MRT_Row<TriagePredictionLabel> | null>(null);
  const open = Boolean(anchorEl);

  const [isDeleteFormOpen, setIsDeleteFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  const [selectedTest, setSelectedTest] = useState<TriagePredictionRun | null>(
    null,
  );

  const [deletePredictionTest, { isLoading: isDeleting }] =
    useDeletePredictionTestMutation();

  const [patchPredictionTest, { isLoading: isPatching }] =
    usePatchPredictionModelMutation();

  const existingSimulationNames = useMemo(
    () => data?.map(simulation => simulation.name.toLowerCase()) || [],
    [data],
  );

  const handleDeleteFormClose = () => {
    setIsDeleteFormOpen(false);
    setSelectedTest(null);
  };

  const handleEditFormClose = () => {
    setIsEditFormOpen(false);
    setSelectedTest(null);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    row: MRT_Row<TriagePredictionLabel>,
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleRename = () => {
    if (selectedRow) {
      setSelectedTest(selectedRow.original);
      setIsEditFormOpen(true);
    }
    handleClose();
  };

  const handleDelete = () => {
    if (selectedRow) {
      setSelectedTest(selectedRow.original);
      setIsDeleteFormOpen(true);
      handleClose();
    }
  };

  const handleDeleteConfirm = async () => {
    if (selectedTest?.prediction_test_id) {
      try {
        await deletePredictionTest(selectedTest.prediction_test_id).unwrap();
        handleDeleteFormClose();
      } catch (err) {
        console.error('Failed to delete', err);
      }
    }
  };

  const handleSubmit = async (values: SimulationFormValues) => {
    if (selectedTest?.prediction_test_id) {
      try {
        await patchPredictionTest({
          name: values.testName,
          predictionTestId: selectedTest.prediction_test_id,
        }).unwrap();
        handleEditFormClose();
      } catch (err) {
        console.error('Failed to update simulation', err);
      }
    }
  };

  const columns = useMemo(
    () =>
      TABLE_COLUMNS.map(colItem =>
        columnHelper.accessor(colItem.key as keyof TriagePredictionLabel, {
          Cell: ({ row }: { row: MRT_Row<TriagePredictionLabel> }) => {
            const {
              created_at,
              end_datetime,
              start_datetime,
              status,
              ticket_ids,
            } = row.original;
            if (colItem.key === 'created_at') {
              const date = new Date(created_at);
              const value = formatUnixTimestampToDateAndTime(
                Number(date) / 1000,
              );

              return <Typography variant='font14'>{value}</Typography>;
            }
            if (colItem.key === 'test_set_criteria') {
              const tickets = ticket_ids;
              const hasTickets = tickets.length > 0;
              const endDate = end_datetime;
              const startDate = start_datetime;
              if (hasTickets) {
                const value = `Ticket IDs - ${tickets.join(', ')}`;
                return (
                  <Tooltip tooltipContent={value}>
                    <Typography noWrap variant='font14'>
                      {value}
                    </Typography>
                  </Tooltip>
                );
              }
              if (startDate && endDate) {
                const value = `Date range - ${formatReadableDate(
                  new Date(startDate),
                )} - ${formatReadableDate(new Date(endDate))}`;
                return <Typography variant='font14'>{value}</Typography>;
              }
              return <Typography variant='font14'>{NA}</Typography>;
            }
            if (colItem.key === 'status') {
              const testTicketStatus = status;
              return (
                <Tooltip
                  tooltipContent={TEST_RUN_STATUS_TOOLTIP[testTicketStatus]}
                >
                  {TestTicketStatusTableValue({
                    status,
                  })}
                </Tooltip>
              );
            }
            if (colItem.key === 'actions') {
              return (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <IconButton
                    aria-label='More'
                    onClick={event => handleClick(event, row)}
                    size='medium'
                    variant='ghost'
                  >
                    <MoreVertIcon color='primary' />
                  </IconButton>
                </Box>
              );
            }
            return (
              <Typography variant='font14'>
                {row.original[colItem.key as keyof TriagePredictionLabel]}
              </Typography>
            );
          },
          enableSorting: colItem.key !== 'actions',
          header: colItem.title,
          size: colItem.size,
        }),
      ),
    [columnHelper],
  );

  const rows = useMemo(() => {
    if (!data) {
      return [];
    }
    if (!searchText) {
      return data;
    }
    return data.filter(item => {
      return item.name.toLowerCase().includes(searchText);
    });
  }, [data, searchText]);

  const handleTableRowClick = (id: string, modelId: string) => {
    navigate(`${Routes.TRIAGE_SIMULATION_RUNS}/${id}?model_id=${modelId}`);
  };

  return (
    <>
      <InfiniteTable
        hasNextPage={false}
        initialSorting={[{ desc: true, id: 'created_at' }]}
        isError={false}
        isLoadingFirstPage={isLoading}
        isLoadingNextPage={false}
        onLoadMore={() => undefined}
        onSortCallback={() => undefined}
        stickyHeaderHeight={100}
        tableOptions={{
          columns,
          data: rows,
          enableBottomToolbar: false,
          enableColumnActions: false,
          enableColumnFilters: false,
          enableExpanding: false,
          enableFilters: false,
          enableFullScreenToggle: false,
          enableGlobalFilter: false,
          enableRowActions: false,
          initialState: {
            columnPinning: {
              left: ['name'],
              right: ['actions'],
            },
          },
          manualSorting: false,
          muiTableBodyRowProps: function ({
            row,
          }: {
            row: MRT_Row<TriagePredictionRun>;
          }) {
            // eslint-disable-next-line react/prop-types
            const { model_id, prediction_test_id, status } = row.original;
            return {
              onClick: () => {
                if (status === 'success') {
                  handleTableRowClick(prediction_test_id, model_id);
                }
              },
              sx: {
                cursor: status === 'success' ? 'pointer' : undefined,
              },
            };
          },

          renderTopToolbarCustomActions: () => (
            <Box display='flex' justifyContent='space-between' width='100%'>
              <SearchBar
                onChange={event => setSearchText(event.target.value)}
                placeholder='Search by name'
                size='small'
                sx={{ width: 'auto' }}
                value={searchText}
              />
              <TriageCreateRun
                existingSimulationNames={existingSimulationNames}
              />
            </Box>
          ),
        }}
      />

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={handleClose}
        open={open}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem onClick={handleRename}>Rename</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>

      <Dialog
        onClose={handleDeleteFormClose}
        open={isDeleteFormOpen}
        title='Delete this simulation'
      >
        <DeleteSimulationForm
          isLoading={isDeleting}
          onCancel={handleDeleteFormClose}
          onDeleteConfirm={handleDeleteConfirm}
        />
      </Dialog>
      <Drawer isOpen={isEditFormOpen} onClose={handleEditFormClose}>
        <ManageSimulationForm
          currentSimulation={selectedTest as TriagePredictionRun}
          existingSimulationNames={existingSimulationNames}
          handleDisable={() => false}
          isEditMode={true}
          isLoading={isPatching}
          onClose={handleEditFormClose}
          onSubmit={handleSubmit}
          selectedModelId={selectedTest?.model_id || ''}
          selectedVersionId={selectedTest?.version_id || ''}
          testName={selectedTest?.name || ''}
        />
      </Drawer>
    </>
  );
};

export default TriageSimulationsTable;
