import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { Box } from '@mui/material';

import { Badge, Tooltip } from '@forethought-technologies/forethought-elements';
import DiscoverAutomatedBadge from 'src/components/discover-automated-badge/DiscoverAutomatedBadge';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { useGenerateLinkWithSearch } from 'src/hooks/useGenerateLinkWithSearch';
import { DiscoverTopic } from 'src/reducers/discoverReducer/types';
import { replaceIdInRoute } from 'src/utils/discover/helpers';
import { Routes } from 'src/utils/enums';

const TopicNameCell = ({ topic }: { topic: DiscoverTopic }) => {
  const { topic_name } = topic;
  const navigate = useNavigate();
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const linkWithSearch = useGenerateLinkWithSearch(
    Routes.DISCOVER_TOPIC_DETAIL,
  );
  const handleTopicClick = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      topic: { clusterId: string },
    ) => {
      e.stopPropagation();
      emitTrackingEventCallback('policy-recommendation-table-row-clicked', {
        id: topic.clusterId,
        isParentTopic: false,
        scope: 'main',
        tab: 'policy',
        table: 'policy',
      });

      navigate(replaceIdInRoute(linkWithSearch, topic.clusterId));
    },
    [emitTrackingEventCallback, navigate, linkWithSearch],
  );
  return (
    <Tooltip tooltipContent={topic_name}>
      <Box
        onClick={e => handleTopicClick(e, { clusterId: topic.topic_id })}
        sx={{
          maxWidth: '230px',
        }}
      >
        <Badge
          label={
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <DiscoverAutomatedBadge hollow={true} />
              <Box
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {topic_name}
              </Box>
            </Box>
          }
          maxWidth='213px'
          variant='macro'
        />
      </Box>
    </Tooltip>
  );
};

export default TopicNameCell;
