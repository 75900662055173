import { Box, styled, useTheme } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { ICON_SIZE } from 'src/components/dashboard-pages/discover-automation-page/constants';

interface AutomationMetricProps {
  title: string;
  tooltip?: string;
  value: string;
}

const AutomationMetric = ({ title, tooltip, value }: AutomationMetricProps) => {
  const { palette } = useTheme();
  return (
    <Box display='flex' flex={1} flexDirection='column'>
      <Box alignItems='flex-start' display='flex'>
        <Typography color={palette.colors.slate[700]} variant='font24'>
          {value}
        </Typography>
      </Box>
      <Box>
        <Typography color={palette.colors.slate[700]} variant='font12Medium'>
          {title}
        </Typography>
        <Typography color={palette.colors.slate[700]} variant='font12'>
          {tooltip && (
            <Tooltip tooltipContent={tooltip}>
              <TransformedIconInfoCircle
                size={ICON_SIZE}
                stroke={palette.colors.slate[600]}
                strokeWidth={2}
              />
            </Tooltip>
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default AutomationMetric;

const TransformedIconInfoCircle = styled(IconInfoCircle)`
  transform: translateX(3px) translateY(4px);
`;
