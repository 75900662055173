export interface SearchState {
  currentSelectedResult: any;
  finalPageReached: boolean;
  loading: boolean;
  offset: number;
  query: string;
  search: any;
  searchId: string;
}

export interface UserState {
  applications: [];
  avatarColor: string;
  currentApplication: '';
  currentTab?: '';
  insightsTabs?: [];
  role: any;
  user: any;
}

export interface FetchAction {
  activeAgentsAggregate: [];
  activeAgentsBreakdown: [];
  analyticsLoading: boolean;
  answersAggregate: [];
  answersBreakdown: any;
  answersMetricsEndTimestamp: any;
  answersMetricsStartTimestamp: any;
  app: string;
  channels: string[];
  chart: string;
  chartActiveAgentsAnswersMetrics: [];
  chartActiveAgentsAnswersMetricsData: [];
  chartAnswersMetrics: [];
  chartAnswersMetricsData: [];
  chartEngagedTicketsAnswersMetrics: [];
  chartEngagedTicketsAnswersMetricsData: [];
  chartPredictionMetrics?: [];
  chartPredictionMetricsData?: [];
  chartSearchMetrics?: [];
  chartSearchMetricsData?: [];
  chartSolveData: [];
  chartSolveMetrics: [];
  currentSelectedResult?: any;
  dataTypes: {};
  error?: any;
  loading?: boolean;
  modelName?: string;
  modelsId?: any;
  offset: number;
  predictionsBreakdown?: any;
  predictionsEndTimestamp?: any;
  predictionsMetrics?: any;
  predictionsStartTimestamp?: any;
  productSorted?: string;
  query?: string;
  search?: any;
  searchAggregate?: [];
  searchBreakdown?: any;
  searchId?: string;
  searchMetrics?: [];
  searchMetricsEndTimestamp?: any;
  searchMetricsStartTimestamp?: any;
  solveAggregate: [];
  solveBreakdown: [];
  solveMetricsEndTimestamp: number;
  solveMetricsStartTimestamp: number;
  sortAsc?: boolean;
  sortType?: string;
  totals: any;
  type: string;
  y: string[];
  yAxis: string;
}

export type SortObject = {
  field: string;
  sortAsc: boolean;
  tableView: string | undefined;
};

export type FilterObject = {
  key: string;
  name: string;
};

export interface UserAction {
  applications: [];
  avatarColor: string;
  currentApplication: '';
  currentTab?: '';
  insightsTabs?: [];
  role: string;
  type: string;
  user: any;
}

export interface AnalyticsState {
  activeAgentsAggregate: [];
  activeAgentsBreakdown: [];
  analyticsLoading: boolean;
  answersAggregate: [];
  answersBreakdown: any;
  answersCardsTotals: any;
  answersMetricsEndTimestamp: any;
  answersMetricsFinalPageReached: boolean;
  answersMetricsStartTimestamp: any;
  app: string;
  channels: string[];
  chartActiveAgentsAnswersMetrics: [];
  chartActiveAgentsAnswersMetricsData: [];
  chartEngagedTicketsAnswersMetrics: [];
  chartEngagedTicketsAnswersMetricsData: [];
  chartPredictionMetrics?: [];
  chartPredictionMetricsData?: [];
  chartSearchMetrics?: [];
  chartSearchMetricsData?: [];
  chartSolveData: [];
  chartSolveMetrics: [];
  dataTypes: {};
  error?: any;
  modelName?: string;
  modelsId?: any;
  predictionsBreakdown?: any;
  predictionsEndTimestamp?: any;
  predictionsFinalPageReached?: boolean;
  predictionsMetrics?: any;
  predictionsStartTimestamp?: any;
  productSorted?: string;
  searchAggregate?: [];
  searchBreakdown?: any;
  searchMetricsEndTimestamp?: any;
  searchMetricsFinalPageReached?: boolean;
  searchMetricsStartTimestamp?: any;
  solveAggregate: [];
  solveBreakdown: [];
  solveMetricsEndTimestamp: number;
  solveMetricsFinalPageReached: boolean;
  solveMetricsStartTimestamp: number;
  sortAsc?: boolean;
  sortType?: string;
  y: string[];
}

export interface NetworkError {
  error_data: Record<string, unknown>;
  error_message: string;
  error_type: string;
}

export function isDefaultNetworkError(error: unknown): error is NetworkError {
  return (
    typeof error === 'object' &&
    error !== null &&
    typeof (error as Record<string, unknown>).error_message === 'string' &&
    typeof (error as Record<string, unknown>).error_type === 'string' &&
    typeof (error as Record<string, unknown>).error_data === 'object'
  );
}

//redux toolkit query will add the error payload inside a data object
export interface NetworkErrorPayload {
  data: NetworkError;
}

export function isApiError(error: unknown): error is NetworkErrorPayload {
  return (
    typeof error === 'object' &&
    error !== null &&
    typeof (error as Record<string, Record<string, unknown>>).data ===
      'object' &&
    typeof (error as Record<string, Record<string, unknown>>).data
      .error_message === 'string' &&
    typeof (error as Record<string, Record<string, unknown>>).data
      .error_type === 'string' &&
    typeof (error as Record<string, Record<string, unknown>>).data
      .error_type === 'string'
  );
}

export interface DateRange {
  from: Date | undefined;
  to?: Date | undefined;
}

export type SolveWidgetProduct =
  | 'interactive_email'
  | 'workflow_builder'
  | 'api'
  | 'slack'
  | 'voice';
