import { useEffect, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  useTheme,
} from '@mui/material';

import {
  Button,
  SearchBar,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import IntentTestForm from './components/IntentTestForm';
import IntentTestTable from './components/IntentTestTable';
import { csvStringToArray } from './utils';
import partition from 'lodash/fp/partition';
import iconNotepad from 'src/assets/images/notepad.svg';
import Spinner from 'src/components/spinner/Spinner';
import {
  useCreateIntentTestMutation,
  useGetEligibleIntentsQuery,
  useGetIntentTestsQuery,
  useGetIntentTestUsageQuery,
} from 'src/services/intent-tests/intentTestsApi';
import { CreateIntentTestRequest } from 'src/services/intent-tests/types';
import { ConversationChannel } from 'src/types/workflowBuilderAPITypes';
import { hasErrorStatus } from 'src/utils/typeGuards';

const INPUT_NOT_FOUND = 'No valid test input found for these parameters.';

const PredictIntentsPage = () => {
  const { palette } = useTheme();
  const { data: intentTestsResponse } = useGetIntentTestsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const { data: usageData, refetch: refetchUsageData } =
    useGetIntentTestUsageQuery();
  const { data: eligibleIntents } = useGetEligibleIntentsQuery();
  const [createIntentTest] = useCreateIntentTestMutation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [submissionError, setSubmissionError] = useState('');
  const [channel, setChannel] = useState<ConversationChannel>('email');

  useEffect(() => {
    // Refetch usage data when a pusher event modifies the list of intent tests
    refetchUsageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intentTestsResponse]);

  const filteredTests = useMemo(() => {
    if (!intentTestsResponse) {
      return null;
    }
    return intentTestsResponse.intent_tests.filter(test => {
      return test.name.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [intentTestsResponse, searchText]);

  const isEmpty = intentTestsResponse && !filteredTests?.length;

  const intentOptions = useMemo(() => {
    if (!eligibleIntents) {
      return [];
    }
    const wfTypeForChannel =
      channel === 'email' ? 'email' : 'flamethrower-live';
    const [active, inactive] = partition(intent => {
      return intent.workflow_types_enabled.includes(wfTypeForChannel);
    }, eligibleIntents);

    if (active.length && inactive.length) {
      return [
        {
          label: 'Active intents for channel',
          options: active.map(intent => ({
            label: intent.title,
            value: intent.intent_id,
          })),
          value: 'active',
        },
        {
          label: 'Inactive intents for channel',
          options: inactive.map(intent => ({
            label: intent.title,
            value: intent.intent_id,
          })),
          value: 'inactive',
        },
      ];
    }

    return eligibleIntents.map(intent => ({
      label: intent.title,
      value: intent.intent_id,
    }));
  }, [channel, eligibleIntents]);

  const disabledMessage = useMemo(() => {
    if (!usageData) {
      return null;
    }

    if (usageData.remaining_weekly_conversations === 0) {
      return `You have used all of your ${usageData.weekly_conversation_limit.toLocaleString()} weekly conversations.`;
    }

    if (usageData.pending_test_count > 0) {
      return 'Please wait for your pending tests to complete before creating a new one.';
    }

    return null;
  }, [usageData]);

  const testSizeMessage = useMemo(() => {
    if (!usageData) {
      return;
    }
    const { max_test_size, remaining_weekly_conversations } = usageData;

    if (remaining_weekly_conversations < max_test_size) {
      return `You have ${remaining_weekly_conversations.toLocaleString()} conversations remaining this week, so the test will be limited to use the first ${remaining_weekly_conversations.toLocaleString()} conversations for this time range.`;
    }

    return `Note: tests are limited to the first ${max_test_size.toLocaleString()} conversations found for this time range.`;
  }, [usageData]);

  return (
    <>
      <Box
        bgcolor={palette.colors.white}
        display='flex'
        flex={1}
        flexDirection='column'
        height='100%'
      >
        <Box
          alignItems='center'
          display='flex'
          justifyContent='space-between'
          padding='24px 40px 0'
          width='100%'
        >
          <Box flex={1} maxWidth='400px'>
            <SearchBar
              onChange={({ target }) => setSearchText(target.value)}
              onClear={() => setSearchText('')}
              placeholder='Search'
              size='small'
              value={searchText}
            />
          </Box>
          <Tooltip tooltipContent={disabledMessage ?? ''}>
            <Button
              disabled={!!disabledMessage}
              onClick={() => setIsModalOpen(true)}
              variant='secondary'
            >
              Create new test
            </Button>
          </Tooltip>
        </Box>
        <Box display='flex' flex='1' flexDirection='column' padding='16px 40px'>
          {isEmpty ? (
            <EmptyState />
          ) : filteredTests ? (
            <IntentTestTable
              intentTests={filteredTests}
              searchText={searchText}
            />
          ) : (
            <Spinner />
          )}
        </Box>
      </Box>
      <Dialog
        fullWidth
        maxWidth='sm'
        onClose={() => setIsModalOpen(false)}
        open={isModalOpen}
      >
        <DialogTitle
          sx={{
            alignItem: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            m: 0,
            padding: '24px',
          }}
        >
          <Box alignItems='center' display='flex'>
            <Typography variant='font20'>New test</Typography>
          </Box>
          <IconButton aria-label='close' onClick={() => setIsModalOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {eligibleIntents && (
            <IntentTestForm
              channel={channel}
              intentOptions={intentOptions}
              onSubmit={async values => {
                const request: CreateIntentTestRequest = {
                  channel,
                  eligible_intent_ids: values.eligibleIntents,
                  end_datetime: values.dateRange?.to.toISOString(),
                  name: values.name,
                  start_datetime: values.dateRange?.from.toISOString(),
                };

                if (request.channel === 'email') {
                  request.ticket_ids = csvStringToArray(values.ticketIds);
                  if (values.includeFallback) {
                    request.fallback_workflow_id = 'Knowledge_Article';
                  }
                } else if (request.channel === 'widget') {
                  request.conversation_ids = csvStringToArray(
                    values.conversationIds,
                  );
                }

                try {
                  await createIntentTest(request).unwrap();
                  setIsModalOpen(false);
                } catch (error: unknown) {
                  if (hasErrorStatus(error) && error.status === 404) {
                    setSubmissionError(INPUT_NOT_FOUND);
                  }
                }
              }}
              setChannel={setChannel}
              showFallbackOption={channel === 'email'}
              testSizeMessage={testSizeMessage}
            />
          )}
          {submissionError && (
            <FormHelperText error>
              <Typography variant='font14'>{submissionError}</Typography>
            </FormHelperText>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PredictIntentsPage;

function EmptyState() {
  const { palette } = useTheme();
  return (
    <Box alignItems='center' display='flex' justifyContent='center' pt='40px'>
      <Box textAlign='center'>
        <Box mb='24px'>
          <img alt='' src={iconNotepad} />
        </Box>
        <Box mb='8px'>
          <Typography variant='font16Bold'>No tests found</Typography>
        </Box>
        <Box>
          <Typography color={palette.colors.grey[700]} variant='font14'>
            Click &apos;Create new test&apos; to get started.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
