import { FC } from 'react';
import { Box, Grid, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import DiscoverCard from 'src/components/discover-card/DiscoverCard';
import DiscoverMetricTooltip from 'src/components/discover-tooltip/DiscoverMetricTooltip';
import { DISCOVER_CARD_MIN_WIDTH } from 'src/constants/discover';

const CardHeader: FC<{ title: string; tooltip?: string }> = ({
  title,
  tooltip,
}) => {
  const { palette } = useTheme();
  return (
    <Box display='flex'>
      <Typography variant='font16Bold'>{title}</Typography>
      <Box
        alignItems='center'
        color={palette.colors.grey[800]}
        display='flex'
        ml={1}
      >
        {tooltip && <DiscoverMetricTooltip dynamicContent={tooltip} />}
      </Box>
    </Box>
  );
};

const TicketCounterCard: FC<{
  count: number;
  title: string;
  tooltip?: string;
}> = ({ count, title, tooltip }) => (
  <Grid item lg={true} md={true} minWidth={DISCOVER_CARD_MIN_WIDTH} xs={true}>
    <DiscoverCard
      headerComponent={<CardHeader title={title} tooltip={tooltip} />}
    >
      <Box mt={2}>
        <Typography variant='font32'>
          {Number(count || 0).toLocaleString()}
        </Typography>
      </Box>
    </DiscoverCard>
  </Grid>
);

export default TicketCounterCard;
