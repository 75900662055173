import Box from '@mui/material/Box';

import { DateRangeFilterButton } from '@forethought-technologies/forethought-elements';
import TextWithInfo from '../../triage-model-detail-page/common/TextWithInfo';
import AutogenInfo from './AutogenInfo';
import AutogenModelBasicInfo from './AutogenModelBasicInfo';
import { DATE_RANGE_TOOLTIP } from './constants';
import CreateModelOptions from './CreateModelOptions';
import TicketField from './TicketField';
import TicketFilterBuilder from './TicketFilterBuilder';
import { TicketFilterItemValue } from './types';
import { TriageAutoGenStatus } from 'src/reducers/triageSettingsReducer/types';
import { DateRange } from 'src/types/types';
import { datePickerRangeOptionsRevamp } from 'src/utils/timeRangeHelpers';

interface AutogenFormSectionProps {
  description: string;
  displayName: string;
  errors: {
    filters?: string;
    selectedTicket?: string;
  };
  filters: TicketFilterItemValue[];
  isEdit?: boolean;
  isLoading: boolean;
  isManual: boolean;
  selectedDateRange: DateRange;
  selectedTicket: string;
  setFilters: (value: TicketFilterItemValue[]) => void;
  setIsManual: (value: boolean) => void;
  setSelectedDateRange: (value: DateRange) => void;
  setSelectedTicket: (value: string) => void;
  status?: TriageAutoGenStatus;
}

const AutogenFormSection = ({
  description,
  displayName,
  errors,
  filters,
  isEdit,
  isLoading,
  isManual,
  selectedDateRange,
  selectedTicket,
  setFilters,
  setIsManual,
  setSelectedDateRange,
  setSelectedTicket,
  status,
}: AutogenFormSectionProps) => {
  if (isEdit && isManual === true) {
    return null;
  }

  const handleTicketChange = (ticket: string) => {
    setSelectedTicket(ticket);
  };

  const handleDateChange = (range: DateRange | undefined) => {
    if (isLoading) {
      return;
    }
    if (range) {
      setSelectedDateRange(range);
    }
  };

  const isGenerating = status === 'generating' || status === 'collecting_data';
  const isReadOnly = isGenerating && isEdit && !isManual;

  if (isReadOnly) {
    return (
      <Box display='flex' flexDirection='column' rowGap={4}>
        <AutogenModelBasicInfo
          description={description}
          displayName={displayName}
        />
        <CreateModelOptions disabled isLoading={isLoading} value={false} />
        <AutogenInfo
          dateRange={selectedDateRange}
          filters={filters}
          ticket={selectedTicket}
        />
      </Box>
    );
  }

  return (
    <Box display='flex' flexDirection='column' rowGap={4}>
      {isEdit && (
        <AutogenModelBasicInfo
          description={description}
          displayName={displayName}
        />
      )}
      <CreateModelOptions
        isLoading={isLoading}
        onChange={setIsManual}
        value={isManual}
      />
      {!isManual && (
        <>
          <TicketField
            error={errors.selectedTicket}
            isLoading={isLoading}
            onChange={handleTicketChange}
            value={selectedTicket}
          />
          <Box display='flex' flexDirection='column' rowGap={1}>
            <TextWithInfo
              title='Select a period'
              tooltip={DATE_RANGE_TOOLTIP}
              variant='font14Bold'
            />
            <Box
              sx={{
                '&>div': {
                  width: '100%',
                },
                button: {
                  maxWidth: 'unset',
                  width: '100%',
                },
                opacity: isLoading ? 0.5 : 1,
                pointerEvents: isLoading ? 'none' : undefined,
              }}
            >
              <DateRangeFilterButton
                onChange={handleDateChange}
                options={datePickerRangeOptionsRevamp}
                size='medium'
                value={selectedDateRange}
              />
            </Box>
          </Box>
          <TicketFilterBuilder
            error={errors.filters}
            filters={filters}
            isLoading={isLoading}
            onChange={setFilters}
          />
        </>
      )}
    </Box>
  );
};

export default AutogenFormSection;
