import { FC } from 'react';
import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import ChatBannerStats from './ChatBannerStats';
import SimilarTopicSection from './SimilarTopicSection';
import {
  DiscoverRecommendationDetailsResponse,
  DiscoverTopic,
} from 'src/reducers/discoverReducer/types';
import { useGetPaidPlanDetailsQuery } from 'src/services/dashboard-api';
import { getAppCuesId } from 'src/utils/appCuesUtil';
import { deriveTopicNameFromTopic } from 'src/utils/discover/helpers';

const AutomationMainTab: FC<{
  recommendationDetail: DiscoverRecommendationDetailsResponse | null;
  selectedNeighboringTopicIds: string[];
  setSelectedNeighboringTopicIds: (ids: string[]) => void;
  topicToAutomate: DiscoverTopic | null;
}> = ({
  recommendationDetail,
  selectedNeighboringTopicIds,
  setSelectedNeighboringTopicIds,
  topicToAutomate,
}) => {
  const { data: paidPlanData } = useGetPaidPlanDetailsQuery();
  const isSolveEnabled = Boolean(paidPlanData?.enablement_info.flamethrower);
  const { palette } = useTheme();
  const neighboringTopics = recommendationDetail?.neighbor_topics ?? [];

  const selectedNeighboringTopics = selectedNeighboringTopicIds.map(topic => {
    return neighboringTopics.find(neighbor => neighbor.topic_id === topic);
  });

  const { summaries } = topicToAutomate ?? { summaries: [] };

  const handleSummaries = () => {
    const allSummaries = selectedNeighboringTopics.reduce(
      (acc, topic) => acc.concat(topic?.summaries ?? []),
      summaries ?? [],
    );
    // dedupe
    return Array.from(new Set(allSummaries));
  };
  const totalSummaries = handleSummaries();

  return (
    <Box>
      <Box mb='22px' mt='24px'>
        <Typography color={palette.colors.grey[600]} variant='font12Medium'>
          Topic:
        </Typography>{' '}
        <Typography variant='font12Medium'>
          {deriveTopicNameFromTopic(topicToAutomate)}
        </Typography>
      </Box>
      <ChatBannerStats values={recommendationDetail?.chat_value} />

      {isSolveEnabled && (
        <SimilarTopicSection
          neighboringTopics={neighboringTopics}
          selectedNeighboringTopicIds={selectedNeighboringTopicIds}
          setSelectedNeighboringTopicIds={setSelectedNeighboringTopicIds}
        />
      )}
      {Boolean(totalSummaries.length) && (
        <>
          <Typography variant='font11'>
            The workflow will include the following key phrases:
          </Typography>
          <Box
            bgcolor={palette.colors.slate[100]}
            border='1px solid'
            borderColor={palette.colors.slate[200]}
            borderRadius='8px'
            data-appcues-target={getAppCuesId({
              componentType: 'information',
              featureName: 'automationmodal',
              pageName: 'discover',
              subType: 'summaries',
            })}
            display='flex'
            flexDirection='column'
            mb='16px'
            mt='8px'
            p='16px'
          >
            <Box
              display='flex'
              flexDirection='column'
              gap='2px'
              maxHeight='300px'
              overflow='auto'
            >
              {totalSummaries.map(summary => (
                <Typography
                  color={palette.colors.blue[600]}
                  key={summary}
                  variant='font14Medium'
                >
                  <i>&quot;{summary}&quot;</i>
                </Typography>
              ))}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default AutomationMainTab;
