import { PropsWithChildren } from 'react';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import { getAppCuesId } from 'src/utils/appCuesUtil';
import { lightPurpleGradient } from 'src/utils/constants';

interface SummaryContainerProps {
  appCues: {
    componentType: string;
    featureName: string;
    pageName: string;
    subType: string;
  };
  summary: string;
}

const SummaryContainer = ({
  appCues,
  children,
  summary,
}: PropsWithChildren<SummaryContainerProps>) => {
  const { palette } = useTheme();
  return (
    <>
      <Box mb='10px'>
        <Typography variant='font11'>Summary</Typography>
      </Box>
      <Box
        borderRadius='8px'
        data-appcues-target={getAppCuesId(appCues)}
        display='flex'
        flexDirection='column'
        gap='11px'
        mb='22px'
        p='16px'
        sx={{
          background: lightPurpleGradient,
        }}
      >
        <Typography color={palette.colors.slate[700]} variant={'font12Medium'}>
          {summary}
        </Typography>

        {children}
      </Box>
    </>
  );
};

export default SummaryContainer;
