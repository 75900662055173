import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { Drawer } from '@forethought-technologies/forethought-elements';
import {
  useGetEmailChannelData,
  useGetTranslationChannels,
  useGetWidgetChannelData,
} from './hooks';
import {
  getWorkflowBuilderLanguages,
  setIsOverlayVisible,
  setNetworkError,
  setTranslationsTableMode,
} from 'src/actions/workflow-builder/workflowBuilderActions';
import UnsavedChangesModal from 'src/components/unsaved-changes-modal';
import TranslationsPage from 'src/pages/workflow-builder-config/translations/TranslationsPage';
import {
  STALE_EMAIL_TRANSLATION,
  STALE_ENABLED_LANGUAGES_CONFIGURATION,
  STALE_WIDGET_TRANSLATION,
} from 'src/pages/workflow-builder-edit/constants';
import WorkflowConflictMessage from 'src/pages/workflow-builder-edit/WorkflowConflictMessage';
import { selectError } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  selectGlobalWorkflowBuilderOptions,
  setGlobalWorkflowBuilderOptions,
} from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { TranslationsTableMode } from 'src/utils/enums';

const apiErrors = [
  STALE_ENABLED_LANGUAGES_CONFIGURATION,
  STALE_WIDGET_TRANSLATION,
  STALE_EMAIL_TRANSLATION,
];

const TranslationDrawer = () => {
  const dispatch = useAppDispatch();

  const { hasUnsavedChanges, isTranslationDrawerOpen } = useSelector(
    selectGlobalWorkflowBuilderOptions,
  );
  const [isLoading, setIsLoading] = useState(true);
  const error = useSelector(selectError);

  const [channelTabIndex, setChannelTabIndex] = useState(0);

  const channels = useGetTranslationChannels();
  const selectedChannelLabel = channels[channelTabIndex];
  const isWarningModalVisible = apiErrors.includes(error);

  const setHasUnsavedChanges = useCallback(
    (value: boolean) => {
      dispatch(
        setGlobalWorkflowBuilderOptions({
          hasUnsavedChanges: value,
        }),
      );
    },
    [dispatch],
  );

  const getEmailChannelData = useGetEmailChannelData();

  const getWidgetChannelData = useGetWidgetChannelData(setIsLoading);

  useEffect(() => {
    if (isTranslationDrawerOpen) {
      if (channels.length === 1) {
        setChannelTabIndex(0);
      }
      dispatch(getWorkflowBuilderLanguages());
      if (selectedChannelLabel === 'email') {
        getEmailChannelData();
        setIsLoading(false);
      } else {
        getWidgetChannelData();
      }
    }
  }, [
    channels.length,
    getWidgetChannelData,
    isTranslationDrawerOpen,
    dispatch,
    getEmailChannelData,
    selectedChannelLabel,
  ]);

  return (
    <>
      {/**
       * There is logic that ties hasUnsavedChanges to preview 
      this conditional rendering blocks that scenario
       */}
      {hasUnsavedChanges && isTranslationDrawerOpen && (
        <UnsavedChangesModal
          onCancel={() => {
            dispatch(setIsOverlayVisible(false));
          }}
          onDiscard={() => {
            dispatch(setIsOverlayVisible(false));
            dispatch(
              setGlobalWorkflowBuilderOptions({
                hasUnsavedChanges: false,
                isTranslationDrawerOpen: false,
              }),
            );
          }}
        />
      )}
      <Drawer
        isOpen={isTranslationDrawerOpen}
        onClose={() => {
          if (hasUnsavedChanges) {
            dispatch(setIsOverlayVisible(true));
          } else {
            dispatch(setIsOverlayVisible(false));
            dispatch(
              setGlobalWorkflowBuilderOptions({
                isTranslationDrawerOpen: false,
              }),
            );
            dispatch(setTranslationsTableMode(TranslationsTableMode.LIVE));
          }
          setChannelTabIndex(0);
        }}
        width='72%'
      >
        {!isLoading && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              padding: '40px',
              position: 'relative',
            }}
          >
            <TranslationsPage
              channels={channels}
              channelTabIndex={channelTabIndex}
              isOnNavbar
              setChannelTabIndex={setChannelTabIndex}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          </Box>
        )}
      </Drawer>
      <WorkflowConflictMessage
        buttonMessage='Reload'
        message='Reload this page to continue editing from the last
            updated version.'
        onButtonClick={() => {
          dispatch(setNetworkError(''));
          if (selectedChannelLabel === 'email') {
            getEmailChannelData();
          } else {
            getWidgetChannelData();
          }
        }}
        open={isWarningModalVisible}
        title='The multilingual configuration has been updated by another user in your organization'
      />
    </>
  );
};

export default TranslationDrawer;
