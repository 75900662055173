import { forwardRef } from 'react';
import { styled } from '@mui/material/styles';

import HorizontalGripImg from 'src/assets/images/horizontal-grip.svg';

interface HorizontalGripIconProps {
  handlerId: string | symbol | null;
}

const HorizontalGripIcon = forwardRef<
  HTMLImageElement | null,
  HorizontalGripIconProps
>(function HorizontalGripIcon({ handlerId }, ref) {
  return (
    <Image data-handler-id={handlerId} ref={ref} src={HorizontalGripImg} />
  );
});

export default HorizontalGripIcon;

const Image = styled('img')`
  width: 18px;
`;
