import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Box, styled, useTheme } from '@mui/material';

import {
  Button,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import { InsightTopicData } from './types';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { setGlobalDiscoverOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

const AutomatedButton: FC<{ topic: InsightTopicData }> = ({ topic }) => {
  const { palette } = useTheme();
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  if (!topic?.automation_urls?.length) {
    return null;
  }
  return (
    <Tooltip disableHover tooltipContent='View this workflow in Solve'>
      <StyledLink to={decodeURIComponent(topic.automation_urls[0])}>
        <Button
          fullWidth
          hoverBackgroundColor={palette.colors.purple[200]}
          onClick={e => {
            e.stopPropagation();
            emitTrackingEventCallback('discover-view-workflow-button-clicked');
          }}
          variant='ghost'
        >
          View workflow
        </Button>
      </StyledLink>
    </Tooltip>
  );
};

export const AutomationCallToActionButton: FC<{ topic: InsightTopicData }> = ({
  topic,
}) => {
  const dispatch = useAppDispatch();
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const { palette } = useTheme();

  return (
    <Tooltip disableHover tooltipContent='Automate this topic with a workflow'>
      <Button
        fullWidth
        hoverBackgroundColor={palette.colors.purple[200]}
        onClick={e => {
          e.stopPropagation();
          emitTrackingEventCallback('discover-automate-button-clicked');
          dispatch(
            setGlobalDiscoverOptions({
              topicIDToAutomate: topic?.cluster_id,
            }),
          );
        }}
        variant='ghost'
      >
        Automate
      </Button>
    </Tooltip>
  );
};

const CallToActionCell: FC<{ topic: InsightTopicData }> = ({ topic }) => {
  const showAutomateButton = topic?.automation_urls?.length > 0;

  return (
    <Box
      alignItems='center'
      display='flex'
      flexDirection='row'
      gap='4px'
      justifyContent='start'
      width='100%'
    >
      {showAutomateButton && <AutomatedButton topic={topic} />}
      {!showAutomateButton && <AutomationCallToActionButton topic={topic} />}
    </Box>
  );
};

export default CallToActionCell;

const StyledLink = styled(Link)`
  text-decoration: none;
  &:visited {
    color: var(--color-text-primary);
  }
`;
