import { useMemo } from 'react';

import { metricFilterOptions } from 'src/constants/discover';
import {
  DiscoverTopicAggregatedMetricType,
  DiscoverTopicDetailsResponse,
} from 'src/reducers/discoverReducer/types';
import { useGetMetadataQuery } from 'src/services/discover/discoverApi';

export const useGetAvailableMetricFilterOptions = (
  metricFilter: DiscoverTopicAggregatedMetricType = 'volume',
  topicDetailMetricData?: DiscoverTopicDetailsResponse | null,
): {
  label: string;
  value: DiscoverTopicAggregatedMetricType;
}[] => {
  const { data: metadata, isLoading: isMetadataLoading } =
    useGetMetadataQuery();
  const { topic_metric_data_types: availableMetrics } = metadata ?? {
    topic_metric_data_types: [],
  };

  const availableMetricFilterOptions = useMemo(() => {
    const availableMetricsTypes = new Set(
      availableMetrics.map(({ type }) => type),
    );

    const isSentimentAvailable =
      topicDetailMetricData?.metrics?.some(
        metric =>
          metric && metric.name === 'sentiment' && metric.value !== null,
      ) ?? false;

    return metricFilterOptions.filter(item => {
      // Exclude sentiment if it’s not available
      if (item.value === 'sentiment' && !isSentimentAvailable) {
        return false;
      }
      return availableMetricsTypes.has(item.value);
    });
  }, [availableMetrics, topicDetailMetricData]);

  const initialMetricFilterOptions = metricFilterOptions.filter(
    option => option.value === metricFilter,
  );

  return isMetadataLoading || availableMetricFilterOptions.length === 0
    ? initialMetricFilterOptions
    : availableMetricFilterOptions;
};
