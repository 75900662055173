import { Box, Typography, useTheme } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';

import { Tooltip } from '@forethought-technologies/forethought-elements';
import { DateRange } from '../../discover-dashboard-page/types';
import { EmptyStateCard } from '../articles/EmptyStateCard';
import { DONUT_SIZE, INSIGHT_TOOLTIP_COPY } from '../constants';
import {
  dateRangeToTimestamp,
  numbersToStringWithCommas,
  stringifyDateRange,
} from '../helpers';
import { useGetFiltersFromSearch } from '../hooks/useGetFiltersFromSearch';
import GraphCenterElement from '../overview/GraphCenterElement';
import { OverviewCardInformationSection } from '../overview/OverviewCardInformationSection';
import { InsightsTab } from '../types';
import InsightCard from './InsightCard';
import { BarGraphWrapper } from 'src/components/solve-insights-charts/bar-graph/barGraphUtils';
import DonutGraph from 'src/components/solve-insights-charts/donut-graph.tsx/DonutGraph';
import { TEMP_BLUE } from 'src/constants/solve';
import { RelevanceTooltip } from 'src/pages/workflow-builder/intent-workflows-table/containers/WidgetIntentWorkflowsTableContainer';
import {
  useGetTopicsRelevanceQuery,
  useGetWorkflowOverallRelevanceQuery,
} from 'src/services/insights';

interface RelevanceCardProps {
  dateRange: DateRange;
  tab: InsightsTab;
}

const RelevanceCard = ({ dateRange, tab }: RelevanceCardProps) => {
  const theme = useTheme();
  const { palette } = theme;
  const colors = [TEMP_BLUE, palette.colors.blue[400], palette.colors.red[500]];
  const timestamps = dateRangeToTimestamp(dateRange);
  const showComparison = tab === 'overview';
  const isWorkflow = tab === 'workflow';
  const isTopic = tab === 'topic';
  const isChat = tab === 'chat';
  const requestParams = useGetFiltersFromSearch({ dateRange, tab });
  const {
    data: topicData,
    isFetching: isTopicDataFetching,
    isLoading: isTopicDataLoading,
  } = useGetTopicsRelevanceQuery(
    {
      ...requestParams,
      end: timestamps.end_timestamp,
      overall: showComparison || isChat,
      start: timestamps.start_timestamp,
      to_compare: showComparison,
    },
    {
      skip: isWorkflow,
    },
  );
  const {
    data: workflowData,
    isFetching: isWorkflowDataFetching,
    isLoading: isWorkflowDataLoading,
  } = useGetWorkflowOverallRelevanceQuery(
    {
      ...requestParams,
      end: timestamps.end_timestamp,
      start: timestamps.start_timestamp,
    },
    {
      skip: !isWorkflow,
    },
  );

  const data = topicData ?? workflowData;
  const isLoading = isTopicDataLoading || isWorkflowDataLoading;
  const isFetching = isTopicDataFetching || isWorkflowDataFetching;

  const relevanceResponses = [
    data?.misc?.relevant || 0,
    data?.misc?.somewhat_relevant || 0,
    data?.misc?.irrelevant || 0,
  ];

  const totalRelevance = relevanceResponses.reduce(
    (currentTotal, quickFeedbackResponse) =>
      currentTotal + quickFeedbackResponse,
    0,
  );
  const isDataAvailable = data && totalRelevance > 0;
  const donutValues = data?.donut.values;
  const showRelevanceAnalyticCounts = isWorkflow || isTopic || isChat;

  const handleTitle = () => {
    if (isTopic) {
      return `${data?.misc.title} for chats with Topics`;
    }
    return data?.misc.title;
  };

  return (
    <InsightCard
      headerRightText={
        showComparison ? stringifyDateRange(dateRange) : undefined
      }
      isFetching={isFetching}
      isLoading={isLoading}
      title={handleTitle()}
      tooltip={
        <Tooltip
          maxWidth={320}
          tooltipContent={
            <RelevanceTooltip text={INSIGHT_TOOLTIP_COPY.relevance} />
          }
        >
          <IconInfoCircle color='currentColor' height={16} width={16} />
        </Tooltip>
      }
    >
      {isDataAvailable ? (
        <Box display='flex'>
          {showRelevanceAnalyticCounts && (
            <Box
              display='flex'
              flex={1}
              flexDirection='column'
              gap='16px'
              pt='20px'
            >
              {donutValues?.map((donutValue, index) => (
                <Box
                  display='flex'
                  flexDirection='column'
                  gap='2px'
                  key={index}
                >
                  <Typography variant='font16Bold'>
                    {numbersToStringWithCommas({
                      number: relevanceResponses[index],
                    })}
                  </Typography>
                  <Typography variant='font14'>
                    {donutValue.label.toLowerCase()}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
          <Box display='flex' flex={1}>
            <DonutGraph
              centerElement={
                <GraphCenterElement
                  theme={theme}
                  value={data?.misc?.delta_value}
                />
              }
              centerSubText='relevant'
              centerText={numbersToStringWithCommas({
                number: data?.misc?.relevant / totalRelevance,
                style: 'percent',
              })}
              colors={colors}
              containerProps={{
                style: { height: `${DONUT_SIZE}px`, width: `${DONUT_SIZE}px` },
              }}
              data={data.donut}
              disableTooltip
            />
          </Box>
          {showComparison && data?.donut && (
            <Box
              alignSelf='center'
              display='flex'
              flex={2}
              flexDirection='column'
            >
              <BarGraphWrapper
                data={data}
                isComparing={showComparison}
                palette={palette}
                showTooltip={showComparison}
              />
            </Box>
          )}
        </Box>
      ) : (
        <EmptyStateCard variant='feedback' />
      )}
      {showComparison && <OverviewCardInformationSection />}
    </InsightCard>
  );
};

export default RelevanceCard;
