import React from 'react';
import Box from '@mui/material/Box';
import { Palette } from '@mui/material/styles';
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import {
  IconButton,
  Skeleton,
} from '@forethought-technologies/forethought-elements';
import DiscoverHistogramFilterButton from '../components/DiscoverHistogramFilterButton';
import { getTooltipKey } from '../helpers';
import { MetricMultiFilterValue, MetricUnit } from '../types';
import CallToActionCell from './CallToActionCell';
import {
  BUTTON_COLUMN_WIDTH,
  CALL_TO_ACTION_COLUMN_ID,
  EXPANDER_COLUMN_ID,
  NAME_COLUMN_ID,
  NAME_COLUMN_WIDTH,
  NO_PADDING_COLUMNS,
  VALUE_COLUMN_WIDTH,
  ZERO_COLUMN_WIDTH,
  ZERO_WIDTH_COLUMNS,
} from './constants';
import TopicNameCell from './TopicNameCell';
import { TableFilterSettings } from './types';
import ValueCell from './ValueCell';
import cloneDeep from 'lodash/fp/cloneDeep';
import DiscoverMetricTooltip from 'src/components/discover-tooltip/DiscoverMetricTooltip';
import {
  METRIC_FILTERS_SEPARATOR,
  metricUnits,
  UNFORMATTED_LABEL_SEPARATOR,
} from 'src/constants/discover';
import {
  DiscoverMetadata,
  DiscoverTopicAggregatedMetricType,
  DiscoverTopicMetrics,
  DiscoverTopicMetricsTreated,
  DiscoverTopicValue,
  TopicMetricTypeDetails,
} from 'src/reducers/discoverReducer/types';
import {
  deriveTopicNameFromTopic,
  getMetricsFilterLabelsMap,
} from 'src/utils/discover/helpers';
import { TableDataFilter } from 'src/utils/discover/helpers';

/**
 * Applies filters to the given data and returns the filtered result.
 * @param data - The data to be filtered.
 * @param filters - The filter settings to be applied.
 * @returns The filtered data.
 */
export const applyFilters = (
  data: DiscoverTopicMetrics[],
  filters: TableFilterSettings,
) => {
  const { groupFilters, histogramFilters, searchText } = filters;
  const filter = new TableDataFilter(cloneDeep(data), 'V2');
  const showHidden = groupFilters.includes('hidden');
  return filter
    .filterNullMongoTopic()
    .filterByHidden(showHidden)
    .filterByGroupFilters(groupFilters)
    .filterByKeyword(searchText)
    .filterByHistogramFilters(histogramFilters)
    .arrangeForTableV2();
};

/**
 * Gets the string representation of a metric name
 * @param metricType - The metric type.
 * @param metricUnit - The metric unit.
 * @returns The string representation of the metric name.
 */
const getMetricRepresentation = (
  metricType: DiscoverTopicAggregatedMetricType,
  metricUnit: MetricUnit,
  first: string,
  second: string,
  selectedDataMetricType: TopicMetricTypeDetails | undefined,
) => {
  let metricRepresentation = `${second ? second : first}${
    selectedDataMetricType?.data_type === 'seconds' ? ' (hrs)' : ''
  }`;
  if (metricType !== 'sentiment') {
    if (metricUnit === 'value_changed') {
      metricRepresentation = `% of ${metricRepresentation.toLowerCase()} change`;
    }
  } else {
    if (metricUnit === 'value') {
      metricRepresentation = `Starting ${metricRepresentation.toLowerCase()}`;
    } else if (metricUnit === 'value_changed') {
      metricRepresentation = `Ending ${metricRepresentation.toLowerCase()}`;
    } else if (metricUnit === 'value_deviance') {
      metricRepresentation = `Drop in ${metricRepresentation.toLowerCase()}`;
    }
  }
  return metricRepresentation;
};

/**
 * Generates metric columns for the AllTopicsTableV2 component.
 *
 * @param filters - The table filter settings.
 * @param isHistogramFilterOpen - A boolean indicating whether the histogram filter is open.
 * @param metadata - The discover metadata.
 * @param setAnchorElement - A function to set the anchor element.
 * @returns An array of metric columns.
 */
export const generateMetricColumnV2 = ({
  filters,
  isHistogramFilterOpen,
  isLoading,
  metadata,
  setAnchorElement,
}: {
  filters: TableFilterSettings;
  isHistogramFilterOpen: boolean;
  isLoading: boolean;
  metadata: DiscoverMetadata | undefined;
  setAnchorElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}) => {
  const { metricFilters, showPercentChanged } = filters;
  const columnHelper = createColumnHelper<DiscoverTopicMetricsTreated>();
  const { topic_metric_data_types: availableMetrics } = metadata ?? {
    topic_metric_data_types: [],
  };
  const filterLabelsMap = getMetricsFilterLabelsMap();
  const combinedMetricFilters: MetricMultiFilterValue[] = [];
  metricFilters.forEach(metricFilter => {
    metricUnits.forEach(metricUnit => {
      if (metricFilter === 'sentiment' || metricUnit === 'value') {
        combinedMetricFilters.push(`${metricFilter}.${metricUnit}`);
      } else if (metricUnit === 'value_changed' && showPercentChanged) {
        combinedMetricFilters.push(`${metricFilter}.${metricUnit}`);
      }
    });
  });
  return combinedMetricFilters.map(filter => {
    const [metricType, metricUnit] = filter.split(METRIC_FILTERS_SEPARATOR) as [
      DiscoverTopicAggregatedMetricType,
      MetricUnit,
    ];
    const targetHistogram = filters.histogramFilters[metricType];
    const unformattedLabel = filterLabelsMap[filter];

    const [first, second] = unformattedLabel.split(UNFORMATTED_LABEL_SEPARATOR);
    const selectedDataMetricType = availableMetrics.find(
      availableMetric => availableMetric.type === metricType,
    );

    const metricRepresentation = getMetricRepresentation(
      metricType,
      metricUnit,
      first,
      second,
      selectedDataMetricType,
    );
    const metricInfo = { first, metricType, metricUnit, second };
    return columnHelper.accessor(
      row =>
        row.metrics.find(element => element?.name === metricType)?.[metricUnit],
      {
        cell: cellProps =>
          isLoading ? (
            tableLoadingSkeleton
          ) : (
            <ValueCell cellProps={cellProps} metricInfo={metricInfo} />
          ),
        header: () => (
          <Box
            alignItems='center'
            display='flex'
            gap='4px'
            justifyContent='start'
          >
            {metricRepresentation}
            <DiscoverMetricTooltip
              isLight={metricUnit !== 'value' && metricType !== 'sentiment'}
              metricType={getTooltipKey(metricType, metricUnit)}
            />
            <DiscoverHistogramFilterButton
              comparisonType={metricUnit}
              handleOnClick={e => setAnchorElement(e.currentTarget)}
              histogramRangeValue={
                targetHistogram ? targetHistogram[metricUnit] : undefined
              }
              isFocused={filters.selectedHistogramFilter.type === metricUnit}
              isHistogramFilterOpen={isHistogramFilterOpen}
              metricType={metricType}
              setSelectedHistogramFilter={filters.setSelectedHistogramFilter}
            />
          </Box>
        ),
        id: filter,
        maxSize: VALUE_COLUMN_WIDTH,
        minSize: VALUE_COLUMN_WIDTH,
        size: VALUE_COLUMN_WIDTH,
      },
    );
  });
};

export const generateCallToActionColumn = ({
  trackingEventMetadata,
}: {
  trackingEventMetadata: {
    'metrics-selected': DiscoverTopicAggregatedMetricType[];
    page: string;
    'time-period-selected': string;
  };
}) => {
  const columnHelper = createColumnHelper<DiscoverTopicMetricsTreated>();
  return columnHelper.display({
    cell: cellProps => (
      <CallToActionCell
        cellProps={cellProps}
        trackingEventMetadata={trackingEventMetadata}
      />
    ),
    id: CALL_TO_ACTION_COLUMN_ID,
    maxSize: ZERO_COLUMN_WIDTH,
    size: ZERO_COLUMN_WIDTH,
  });
};

export const generateButtonColumns = ({}: {
  trackingEventMetadata: {
    'metrics-selected': DiscoverTopicAggregatedMetricType[];
    page: string;
    'time-period-selected': string;
  };
}) => {
  const columnHelper = createColumnHelper<DiscoverTopicMetricsTreated>();
  return [
    columnHelper.display({
      cell: ({ row }) => {
        return (
          <Box pl={1}>
            {row.getCanExpand() ? (
              <IconButton
                aria-label='expand or collapse row'
                onClick={event => {
                  row.getToggleExpandedHandler()();
                  event.stopPropagation();
                }}
                variant='ghost'
              >
                {row.getIsExpanded() ? (
                  <IconChevronDown color='black' />
                ) : (
                  <IconChevronRight color='black' />
                )}
              </IconButton>
            ) : (
              ''
            )}
          </Box>
        );
      },
      id: EXPANDER_COLUMN_ID,
      maxSize: BUTTON_COLUMN_WIDTH,
      size: BUTTON_COLUMN_WIDTH,
    }),
  ];
};

const generateTopicNameColumn = ({
  isLoading,
  searchText,
}: {
  isLoading: boolean;
  searchText?: string;
}) => {
  const columnHelper = createColumnHelper<DiscoverTopicMetricsTreated>();

  return columnHelper.accessor(row => deriveTopicNameFromTopic(row.topic), {
    cell: cellProps =>
      isLoading ? (
        tableLoadingSkeleton
      ) : (
        <TopicNameCell cellProps={cellProps} searchText={searchText} />
      ),
    header: ({ table }) => {
      if (isLoading) {
        return <>Topic</>;
      }

      const rows = table.getCenterRows();
      const totalNumRows = rows.reduce((prev, curr) => {
        const lengthOfSubRows = curr.subRows.filter(
          subRow => subRow.depth === 1,
        ).length;

        return prev + lengthOfSubRows;
      }, 0);

      return <>Topic ({totalNumRows})</>;
    },

    id: NAME_COLUMN_ID,
    size: NAME_COLUMN_WIDTH,
  });
};

export const generateTableColumns = ({
  isLoading,
  metricColumns,
  searchText,
  trackingEventMetadata,
}: {
  isLoading: boolean;
  metricColumns: ColumnDef<
    DiscoverTopicMetricsTreated,
    DiscoverTopicValue | undefined
  >[];
  searchText?: string;
  trackingEventMetadata: {
    'metrics-selected': DiscoverTopicAggregatedMetricType[];
    page: string;
    'time-period-selected': string;
  };
}) => {
  const callToActionColumn = generateCallToActionColumn({
    trackingEventMetadata,
  });
  const buttonColumns = generateButtonColumns({ trackingEventMetadata });
  const topicNameColumn = generateTopicNameColumn({ isLoading, searchText });

  const columns = [
    ...buttonColumns,
    topicNameColumn,
    ...metricColumns,
    callToActionColumn,
  ];
  return columns;
};

export const getAdditionalCellStyles = (id: string, palette: Palette) => {
  const removePadding = NO_PADDING_COLUMNS.find(column_id => column_id === id);
  const removeWidth = ZERO_WIDTH_COLUMNS.find(column_id => column_id === id);
  const showRightBorder = NAME_COLUMN_ID === id;
  return [
    showRightBorder
      ? { borderRight: `1px solid ${palette.colors.grey[200]}` }
      : {},
    removePadding ? { padding: '0px' } : {},
    removeWidth ? { width: '0px' } : {},
  ];
};

export const tableLoadingSkeleton = <Skeleton />;
