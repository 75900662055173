import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

import {
  Button,
  SelectDropdown,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import { DISCOVER_TEXT } from '../constants';
import { useGetCustomIntents } from '../hooks/useGetCustomIntents';
import { useInterestedButtonClickedMutation } from '../hooks/useInterestedButtonClickedMutation';
import { getDiscoverRecommendations } from 'src/actions/discover/discover';
import AutoflowIcon from 'src/assets/images/autonomous-agent-badge.svg';
import AutoflowWhiteIcon from 'src/assets/images/autonomous-agent-badge-white.svg';
import ClassicIcon from 'src/assets/images/solve-classic-icon.svg';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { clearCacheKeys } from 'src/reducers/discoverReducer/discoverReducer';
import { DiscoverTopic } from 'src/reducers/discoverReducer/types';
import {
  dashboardApi,
  useGetPaidPlanDetailsQuery,
} from 'src/services/dashboard-api';
import { useAutomateTopicMutation } from 'src/services/discover/discoverApi';
import { getDiscoverAutomations } from 'src/slices/data/thunks';
import {
  setGlobalDiscoverOptions,
  setGlobalToastOptions,
} from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { Routes } from 'src/utils/enums';

interface AutomationDrawerActionRowProps {
  canCreateAutomation: boolean;
  policy?: string;
  selectedNeighboringTopicIds: string[];
  topic: DiscoverTopic;
}

const AutomationDrawerActionRow = ({
  canCreateAutomation,
  policy,
  selectedNeighboringTopicIds,
  topic,
}: AutomationDrawerActionRowProps) => {
  const { data: paidPlanData } = useGetPaidPlanDetailsQuery();
  const isSolveEnabled = Boolean(paidPlanData?.enablement_info.flamethrower);
  const [automateTopicMutation, { isLoading: isAutomateTopicLoading }] =
    useAutomateTopicMutation();
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const { data } = useGetCustomIntents(isSolveEnabled);
  const { interestedButtonClicked, isLoading: isInterestedClickedLoading } =
    useInterestedButtonClickedMutation();

  const dispatch = useAppDispatch();
  const [intentWorkflowId, setIntentWorkflowId] = useState('');

  const location = useLocation();

  const { intents } = data ?? { intents: [] };

  const handleInterestedClick = async () => {
    await interestedButtonClicked({
      topic_id: topic.topic_id,
      topic_name: topic.topic_name,
    });
    dispatch(
      setGlobalDiscoverOptions({
        postAutomationActionModalOptions: {
          automatedObjectName: '',
          isClassicCreated: true,
          isOpen: true,
          redirectUrl: '',
        },
      }),
    );
  };

  const refreshAutomatedData = () => {
    if (location.pathname === Routes.DISCOVER_AUTOMATION) {
      dispatch(getDiscoverAutomations());
      dispatch(getDiscoverRecommendations());
    }
  };

  const handleAutomateClick = async (
    automatedObjectId?: string,
    isClassic?: boolean,
  ) => {
    emitTrackingEventCallback(
      automatedObjectId
        ? 'discover-add-to-existing-intent-workflow-clicked'
        : 'discover-automate-with-a-new-workflow-clicked',
      {
        neighbor_topic_ids: selectedNeighboringTopicIds,
        pathname: location.pathname,
        'topic-name': topic.topic_name,
      },
    );
    try {
      const updatedTopic = await automateTopicMutation({
        automated_object_id: automatedObjectId,
        automation_type: 'solve_workflow',
        is_autoflow: !isClassic,
        neighbor_topic_ids: selectedNeighboringTopicIds,
        topic_id: topic.topic_id,
        was_recommended: topic.is_recommended,
      })
        .unwrap()
        .then(payload => {
          dispatch(clearCacheKeys()); // Once we move all discover to query, this can be removed
          return payload;
        });

      refreshAutomatedData();
      dispatch(dashboardApi.util.invalidateTags(['Intents']));

      const redirectUrl =
        (updatedTopic.automation_urls && updatedTopic.automation_urls[0]) ?? '';

      dispatch(
        setGlobalDiscoverOptions({
          postAutomationActionModalOptions: {
            automatedObjectName:
              data?.intents.find(
                intent => intent.intent_workflow_id === automatedObjectId,
              )?.intent_name ?? '',
            isClassicCreated: isClassic ?? true,
            isOpen: true,
            redirectUrl,
          },
        }),
      );
    } catch (error) {
      console.error(error);
      dispatch(
        setGlobalDiscoverOptions({
          topicIDToAutomate: null,
          topicToAutomate: null,
        }),
      );
      dispatch(
        setGlobalToastOptions({
          autoHideDuration: 3000,
          title: 'Something unexpected occurred. Please try again.',
          variant: 'danger',
        }),
      );
    }
  };

  const renderAutomateButton = () => {
    const buttons = [
      <Button
        disabled={isAutomateTopicLoading || !canCreateAutomation}
        fullWidth
        key='classic'
        onClick={() => handleAutomateClick(undefined, true)}
        size='large'
        startIcon={
          <img alt='classic icon' height={20} src={ClassicIcon} width={20} />
        }
        variant={'secondary'}
      >
        Create with Classic
      </Button>,
      <Button
        disabled={isAutomateTopicLoading || !canCreateAutomation}
        fullWidth
        key='autoflow'
        onClick={() => handleAutomateClick(undefined, false)}
        size='large'
        startIcon={
          <img
            height={20}
            src={intentWorkflowId ? AutoflowIcon : AutoflowWhiteIcon}
            width={20}
          />
        }
        variant={intentWorkflowId ? 'secondary' : 'main'}
      >
        Create with AutoFlow {policy ? '(recommended)' : ''}
      </Button>,
    ];

    if (canCreateAutomation) {
      return buttons;
    }

    return (
      <Box>
        {buttons.map(button => (
          <Tooltip
            key={button.key}
            tooltipContent={DISCOVER_TEXT.automationTooFewPhrases}
          >
            {button}
          </Tooltip>
        ))}
      </Box>
    );
  };

  const renderWorkflowSelectDropdown = () => {
    const dropdown = (
      <SelectDropdown
        disabled={intents.length === 0}
        id='automation_workflow_choice'
        onChange={event => {
          emitTrackingEventCallback('discover-intent-workflow-selected', {
            pathname: location.pathname,
            'topic-name': topic.topic_name,
          });
          setIntentWorkflowId(event.target.value);
        }}
        onClear={() => setIntentWorkflowId('')}
        onOpen={() => {
          emitTrackingEventCallback(
            'discover-intent-workflow-dropdown-opened',
            {
              pathname: location.pathname,
              'topic-name': topic.topic_name,
            },
          );
        }}
        options={intents.map(intent => ({
          label: intent.intent_name,
          value: intent.intent_workflow_id,
        }))}
        placeholder='Assign this topic to an existing  workflow'
        value={intentWorkflowId}
      />
    );

    if (intents.length === 0) {
      return (
        <Box
          sx={{
            '& > div': {
              width: '100%',
            },
          }}
        >
          <Tooltip tooltipContent={DISCOVER_TEXT.automationNoExistingWorkflows}>
            {dropdown}
          </Tooltip>
        </Box>
      );
    }

    return dropdown;
  };

  if (!isSolveEnabled) {
    return (
      <Box px='8px'>
        <Button
          disabled={isInterestedClickedLoading}
          fullWidth
          isLoading={isInterestedClickedLoading}
          onClick={handleInterestedClick}
          size='large'
          variant='main'
        >
          I am interested!
        </Button>
      </Box>
    );
  }

  return (
    <Box display='flex' flexDirection='column' px='8px' width='100%'>
      <Box display='flex' gap='10px' justifyContent='center' mb='24px'>
        {renderAutomateButton()}
      </Box>
      {renderWorkflowSelectDropdown()}
      <Box mt='8px'>
        <Button
          disabled={!intentWorkflowId || isAutomateTopicLoading}
          fullWidth
          onClick={() => handleAutomateClick(intentWorkflowId)}
          size='large'
          variant={intentWorkflowId ? 'main' : 'secondary'}
        >
          Assign
        </Button>
      </Box>
    </Box>
  );
};

export default AutomationDrawerActionRow;
