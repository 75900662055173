import Skeleton from 'react-loading-skeleton';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { TriageModelLabel } from '../types';
import { NA } from 'src/constants/solve';
import {
  LabelMetrics,
  TicketMetrics,
  TriageTicket,
} from 'src/services/triage/types';

interface ValueCellProps {
  isLoading?: boolean;
  isPercentage?: boolean;
  itemKey:
    | keyof TriageModelLabel
    | keyof TriageTicket
    | keyof LabelMetrics
    | keyof TicketMetrics;
  returnDefaultValue?: boolean;
  value: number | string | null | boolean;
  wrap?: boolean;
}

const parseValue = (
  value: ValueCellProps['value'],
  isPercentage?: boolean,
  returnDefaultValue?: boolean,
) => {
  if (value === undefined || value === null) {
    return NA;
  }
  if (returnDefaultValue) {
    return value;
  }
  if (isPercentage && typeof value === 'number') {
    return `${Math.round(value * 100.0)}%`;
  }

  if (typeof value === 'number') {
    return value.toLocaleString();
  }

  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }

  return value;
};

const ValueCell = ({
  isLoading,
  isPercentage,
  itemKey,
  returnDefaultValue,
  value,
  wrap,
}: ValueCellProps) => {
  const { palette } = useTheme();
  const dataValue = parseValue(value, isPercentage, returnDefaultValue);
  const showSkeleton =
    isLoading && itemKey !== 'name' && itemKey !== 'description';
  const isNa = dataValue === NA;
  const isTruncated = dataValue.toString().length >= 124;

  return (
    <Tooltip placement='top' tooltipContent={isTruncated ? dataValue : ''}>
      <Box
        sx={{
          '&>span': wrap
            ? {
                display: 'block',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }
            : undefined,
          display: '-webkit-box',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 3,
          width: '100%',
        }}
      >
        {showSkeleton && <Skeleton />}
        {!showSkeleton && (
          <Typography
            color={isNa ? palette.colors.grey[600] : undefined}
            variant='font14'
          >
            {dataValue}
          </Typography>
        )}
      </Box>
    </Tooltip>
  );
};

export default ValueCell;
