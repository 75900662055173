import { useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import { IconPlus } from '@tabler/icons-react';

import {
  Button,
  Skeleton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { INITIAL_EXPRESSION } from './constants';
import {
  addDefaultField,
  getListTypeFieldOptions,
  getQueryExpressionFieldType,
  updateArrayItem,
} from './helpers';
import { TicketFilterItemValue } from './types';
import ExpressionBuilder, {
  FieldOption,
} from 'src/components/expression-builder/ExpressionBuilder';
import { getQueryExpressionTypeByTicketType } from 'src/pages/workflow-builder-config/email/email-automation-page/utils/utils';
import { ConditionExpressions } from 'src/services/apiInterfaces';
import { useGetHelpdeskQuery } from 'src/services/dashboard-api';
import { useGetTriageTicketCustomFieldsQuery } from 'src/services/triage/triageApi';
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter';

interface TicketFilterBuilderProps {
  error?: string;
  filters: TicketFilterItemValue[];
  isLoading: boolean;
  onChange: (value: TicketFilterItemValue[]) => void;
}

const TicketFilterBuilder = ({
  error,
  filters,
  isLoading,
  onChange,
}: TicketFilterBuilderProps) => {
  const { data: helpdeskResponse } = useGetHelpdeskQuery();

  const { helpdesk = '' } = helpdeskResponse || {};

  const { data, isLoading: isLoadingFields } =
    useGetTriageTicketCustomFieldsQuery();

  const fieldOptions: FieldOption[] = useMemo(
    () =>
      data?.static_fields.map(staticField => ({
        category: `${capitalizeFirstLetter(helpdesk)} Static Fields`,
        fieldOptions: staticField.field_options || [],
        label: staticField.title,
        type: getQueryExpressionTypeByTicketType(staticField.type),
        value: staticField.id,
      })) || [],
    [data?.static_fields, helpdesk],
  );

  const handleChange = useCallback(
    (field: Partial<ConditionExpressions>, index: number) => {
      if (isLoading) {
        return;
      }
      const updatedFilters = updateArrayItem(filters, index, field);
      onChange(updatedFilters);
    },
    [filters, onChange, isLoading],
  );

  const handleBooleanOperatorChange = useCallback(
    (booleanOperator: string) => {
      if (isLoading) {
        return;
      }
      const items = filters.map(item => {
        return {
          ...item,
          booleanOperator,
        };
      });
      onChange(items);
    },
    [filters, isLoading, onChange],
  );

  const handleAdd = useCallback(() => {
    const target = filters[filters.length - 1];
    const booleanOperator = target?.booleanOperator || 'and';
    const expression = addDefaultField(
      fieldOptions,
      INITIAL_EXPRESSION.expression,
    );
    const items = filters.concat({
      booleanOperator,
      expression,
    });
    onChange(items);
  }, [fieldOptions, filters, onChange]);

  const handleDelete = useCallback(
    (index: number) => {
      if (isLoading) {
        return;
      }
      const items = filters.filter((_, i) => i !== index);
      onChange(items);
    },
    [filters, isLoading, onChange],
  );

  if (isLoadingFields) {
    return <Skeleton />;
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      rowGap={2}
      sx={{
        opacity: isLoading ? '0.5' : undefined,
        pointerEvents: isLoading ? 'none' : undefined,
      }}
    >
      <Typography variant='font14Bold'>Addtional ticket filter</Typography>
      {filters.map((item, index) => {
        return (
          <Box display='flex' flexDirection='column' key={index} rowGap={1}>
            <ExpressionBuilder
              booleanOperator={index === 0 ? undefined : item.booleanOperator}
              expression={addDefaultField(fieldOptions, item.expression)}
              fieldOptions={fieldOptions}
              fieldType={getQueryExpressionFieldType(
                item.expression.field,
                fieldOptions,
              )}
              index={index}
              isBooleanOperatorDisabled={isLoading || index > 1}
              isDeleteButtonDisabled={isLoading}
              listTypeOptions={getListTypeFieldOptions(
                item.expression.field || '',
                fieldOptions,
              )}
              onDeleteButtonClick={() => handleDelete(index)}
              onSelectBooleanOperator={booleanOperator =>
                handleBooleanOperatorChange(booleanOperator)
              }
              onSelectedField={field =>
                handleChange({ field, values: [] }, index)
              }
              onSelectOperator={operator =>
                handleChange(
                  {
                    negate: operator.negate,
                    operator: operator.value,
                    values: [],
                  },
                  index,
                )
              }
              onValueChange={value => {
                const values = Array.isArray(value) ? value : [value];
                handleChange({ values }, index);
              }}
            />
          </Box>
        );
      })}
      <Button
        disabled={isLoading}
        onClick={handleAdd}
        startIcon={<IconPlus fontSize='small' />}
        variant='secondary'
      >
        Additional ticket filter
      </Button>
      {Boolean(error) && (
        <Typography color='error' variant='font12'>
          {error}
        </Typography>
      )}
    </Box>
  );
};
export default TicketFilterBuilder;
