import { SOLVE_V2_BASE_URL } from '../constants';
import { dashboardApi } from '../dashboard-api';
import { BaseBrand, Brand } from 'src/types/brandTypes';

const brandApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    createBrand: builder.mutation<Brand, BaseBrand>({
      invalidatesTags: ['Brands'],
      query: body => ({
        body,
        method: 'POST',
        url: `${SOLVE_V2_BASE_URL}/brand`,
      }),
    }),
    deleteBrand: builder.mutation<void, { brandId: string }>({
      invalidatesTags: ['Brands'],
      query: ({ brandId }) => ({
        method: 'DELETE',
        url: `${SOLVE_V2_BASE_URL}/brand/${brandId}`,
      }),
    }),
    getBrands: builder.query<Brand[], void>({
      providesTags: ['Brands'],
      query: () => SOLVE_V2_BASE_URL + '/brands',
    }),
    updateBrand: builder.mutation<Brand, { body: BaseBrand; brandId: string }>({
      invalidatesTags: ['Brands'],
      query: ({ body, brandId }) => ({
        body,
        method: 'PUT',
        url: `${SOLVE_V2_BASE_URL}/brand/${brandId}`,
      }),
    }),
    updateBrandTags: builder.mutation<
      Brand,
      { body: string[]; brandId: string }
    >({
      invalidatesTags: ['Brands'],
      query: ({ body, brandId }) => ({
        body,
        method: 'PUT',
        url: `${SOLVE_V2_BASE_URL}/brand/${brandId}/tags`,
      }),
    }),
  }),
});

export const {
  useCreateBrandMutation,
  useDeleteBrandMutation,
  useGetBrandsQuery,
  useUpdateBrandMutation,
  useUpdateBrandTagsMutation,
} = brandApi;
