import { Box, styled, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import IntentBadge from '../IntentBadge';
import { ChatEvent, IntentPredictionChatEvent } from './types';

const mapChatEventToComp = (chatEvent: ChatEvent) => {
  switch (chatEvent.event_type) {
    case 'intent_prediction':
      return (
        <IntentPredictionEventLine
          chatEvent={chatEvent as IntentPredictionChatEvent}
        />
      );
    default:
      return <GenericEventLine chatEvent={chatEvent} />;
  }
};

export const ChatEventsSection = ({
  chatEvents,
}: {
  chatEvents: ChatEvent[];
}) => {
  const { palette } = useTheme();

  return (
    <Box display='flex' flexDirection='column' gap='8px'>
      <Typography color={palette.colors.grey[600]} variant='font12'>
        Events
      </Typography>
      <Box display='flex'>
        <VerticalLine />
        <Box display='flex' flexDirection='column' gap='12px'>
          {chatEvents.map(chatEvent => mapChatEventToComp(chatEvent))}
        </Box>
      </Box>
    </Box>
  );
};

const IntentPredictionEventLine = ({
  chatEvent,
}: {
  chatEvent: IntentPredictionChatEvent;
}) => {
  return (
    <LineWrapper>
      <Circle />
      <IntentBadge
        intentDefinitionId={chatEvent.intent_id}
        intentName={chatEvent.intent_title}
      />
    </LineWrapper>
  );
};

const GenericEventLine = ({ chatEvent }: { chatEvent: ChatEvent }) => {
  const getMessageFromEvent = () => {
    switch (chatEvent.event_type) {
      case 'agent_handoff':
        return 'Attempt to handoff';
      case 'csat_submit':
        return 'CSAT submitted';
      case 'quick_feedback':
        return 'Quick feedback submitted';
      case 'chat_start':
        return 'Chat started';
    }
  };

  return (
    <LineWrapper>
      <Circle />
      <Typography variant='font12'>{getMessageFromEvent()}</Typography>
    </LineWrapper>
  );
};

const Circle = styled('div')`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  border: 1px solid white;
  background-color: black;
`;
const LineWrapper = styled('div')`
  margin-left: -5px;
  display: flex;
  gap: 20px;
  align-items: center;
`;

const VerticalLine = styled('div')`
  width: 2px;
  background-color: black;
  margin-top: 8px;
  margin-bottom: 10px;
`;
