import { ReactNode } from 'react';
import React from 'react';
import { Box, useTheme } from '@mui/material';

import {
  Skeleton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { NA } from '../constants';

export const InlineData = ({
  color,
  isLoading = false,
  label,
  value = NA,
}: {
  color?: string;
  isLoading?: boolean;
  label: string;
  value?: string | number | ReactNode;
}) => {
  const { palette } = useTheme();

  if (React.isValidElement(value)) {
    return (
      <Box alignItems='center' display='flex'>
        <Typography color={palette.colors.grey[600]} variant='font12Medium'>
          {label}:
        </Typography>
        &nbsp;
        {value}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        wordBreak: 'break-word',
      }}
    >
      <Typography color={palette.colors.grey[600]} variant='font12Medium'>
        {label}:
      </Typography>
      &nbsp;
      <Typography
        color={
          color ||
          (value === NA ? palette.colors.grey[500] : palette.colors.black)
        }
        variant='font12Medium'
      >
        {/* If loading, show a skeleton, otherwise either show the value or fallback to n/a */}
        {isLoading ? <Skeleton width={30} /> : value}
      </Typography>
    </Box>
  );
};
