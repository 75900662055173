import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import {
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import DiscoverCard from '../../../discover-card/DiscoverCard';
import DiscoverChart from '../../../discover-chart/DiscoverChart';
import { DiscoverTabMetricCardSkeletonGroup } from '../../../discover-skeleton/DiscoverSkeleton';
import DiscoverMetricTooltip from '../../../discover-tooltip/DiscoverMetricTooltip';
import { MetricMultiFilterValue } from '../../discover-all-topics-page/types';
import { getMetricValue } from '../helpers';
import {
  TopicPeriodicalFilter,
  TopicTimeFilter,
} from 'src/components/app/types';
import DashboardTicketCounterCards from 'src/components/dashboard-pages/discover-dashboard-page/components/DashboardTicketCounterCards';
import {
  DISCOVER_CARD_MIN_WIDTH,
  METRIC_FILTERS_SEPARATOR,
  metricUnits,
} from 'src/constants/discover';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { selectDashboardMetrics } from 'src/reducers/discoverReducer/discoverReducer';
import { DiscoverMetadata } from 'src/reducers/discoverReducer/types';
import { getAppCuesId } from 'src/utils/appCuesUtil';
import {
  getDisplayableValueChanged,
  getMetricLabel,
  getNumberIndicator,
  getPercentChangeColor,
  isSentimentValue,
} from 'src/utils/discover/helpers';

interface DashboardTabMetricCardsProps {
  isMetadataLoading: boolean;
  metadata: DiscoverMetadata | undefined;
  onClick: (value: MetricMultiFilterValue[]) => void;
  timeFilter: TopicTimeFilter;
  timePeriod: TopicPeriodicalFilter;
}

const DashboardTabMetricCards = ({
  isMetadataLoading,
  metadata,
  onClick,
  timeFilter,
  timePeriod,
}: DashboardTabMetricCardsProps) => {
  const { data, loading } = useSelector(selectDashboardMetrics);
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const { overall_metrics: overallMetrics } = data ?? {};
  const { topic_metric_data_types: availableMetrics } = metadata ?? {
    topic_metric_data_types: [],
  };
  const customGridSize = overallMetrics?.metrics.length === 4 ? 6 : true;

  const displayableData = useMemo(() => {
    return availableMetrics.map(({ data_type, type }) => {
      const metric =
        overallMetrics?.metrics.find(metric => metric?.name === type) ?? null;

      const chart =
        overallMetrics?.charts.data.find(
          chart => chart?.name === metric?.name,
        ) ?? null;

      return {
        chart,
        dataType: data_type,
        metric,
      };
    });
  }, [availableMetrics, overallMetrics?.charts.data, overallMetrics?.metrics]);

  return (
    <Grid container spacing={3}>
      {loading || isMetadataLoading ? (
        <DiscoverTabMetricCardSkeletonGroup />
      ) : (
        <>
          <DashboardTicketCounterCards />
          {displayableData.map(({ chart, dataType, metric }) => {
            if (metric) {
              const { name, value, value_changed: valueChanged } = metric;

              if (isSentimentValue(value) || dataType === 'dict') {
                // sentiment and dict data type not yet supported in dashboard
                return null;
              }

              // When the # of metric is 4 then have it static 2 by 2 else auto fit

              return (
                <Grid
                  item
                  key={name}
                  lg={customGridSize}
                  md={customGridSize}
                  minWidth={DISCOVER_CARD_MIN_WIDTH}
                  xs={customGridSize}
                >
                  <DiscoverCard
                    featureName={name}
                    headerComponent={
                      <Box
                        data-appcues-target={getAppCuesId({
                          componentType: 'header',
                          featureName: name,
                          pageName: 'discover',
                          subType: '',
                        })}
                        display='flex'
                      >
                        <Typography variant='font16Bold'>
                          {getMetricLabel(name)}
                        </Typography>
                        <Box
                          alignItems='center'
                          color={theme.palette.colors.grey[800]}
                          display='flex'
                          ml={1}
                        >
                          <DiscoverMetricTooltip metricType={name} />
                        </Box>
                      </Box>
                    }
                    linkText='More analytics'
                    onClick={() => {
                      emitTrackingEventCallback(
                        'discover-clicked-on-top-level-metric',
                        {
                          'metric-selected': name,
                          page: 'Discover Dashboard',
                          'page-area': 'Topic Metric Area',
                          'time-comparison-selected': timePeriod,
                          'time-period-selected': timeFilter,
                        },
                      );

                      onClick(
                        metricUnits
                          .slice(0, 2) // TODO remove slice when deviance is fully removed
                          .map(
                            unit =>
                              `${name}${METRIC_FILTERS_SEPARATOR}${unit}` as const,
                          ),
                      );
                    }}
                  >
                    <Box
                      alignItems='center'
                      data-appcues-target={getAppCuesId({
                        componentType: 'container',
                        featureName: name,
                        pageName: 'discover',
                        subType: 'metriccards',
                      })}
                      display='flex'
                      justifyContent='space-between'
                      mt={1}
                    >
                      <Box flex={1}>
                        <Typography noWrap variant='font32'>
                          {getMetricValue({
                            dataType,
                            metric: name,
                            value,
                          })}
                        </Typography>
                      </Box>
                      <Box flex={1} />
                      <Box alignItems='center' display='flex' flex={2}>
                        <Typography
                          color={getPercentChangeColor(valueChanged ?? 0, name)}
                          variant='font14Bold'
                        >
                          {getNumberIndicator(valueChanged ?? 0)}
                          {getDisplayableValueChanged(valueChanged)}
                        </Typography>
                        <Box
                          data-appcues-target={getAppCuesId({
                            componentType: 'chart',
                            featureName: name,
                            pageName: 'discover',
                            subType: '',
                          })}
                          height={50}
                          minWidth={160}
                          onClick={e => e.stopPropagation()}
                          pl='10%'
                        >
                          <DiscoverChart
                            data={chart}
                            metadata={metadata}
                            trackingEvent={() => {
                              emitTrackingEventCallback(
                                'discover-graph-analyzed',
                                {
                                  'metric-selected': name,
                                  page: 'Discover dashboard',
                                  'page-area': 'Top metrics',
                                  'time-comparison-selected': timePeriod,
                                  'time-period-selected': timeFilter,
                                },
                              );
                            }}
                            useCustomPosition
                            width={163}
                            xLabel={overallMetrics?.charts.x_axis_label}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </DiscoverCard>
                </Grid>
              );
            }
          })}
        </>
      )}
    </Grid>
  );
};

export default DashboardTabMetricCards;
