import React from 'react';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { Typography } from '@forethought-technologies/forethought-elements';
import DiscoverChart from '../../discover-chart/DiscoverChart';
import {
  getEmptyTopicMetricCardTimeMessage,
  getTopicMetricCardTimeMessage,
} from './helpers';
import MetricHeader from './MetricHeader';
import SentimentMetricHeader from './SentimentMetricHeader';
import { TopicTimeFilter } from 'src/components/app/types';
import {
  DiscoverMetadata,
  DiscoverMetricChart,
  DiscoverTopicMetric,
  DiscoverTopicValue,
} from 'src/reducers/discoverReducer/types';
import {
  getDisplayableValueChanged,
  getDisplayableValueDeviance,
  getNumberIndicator,
  getPercentChangeColor,
} from 'src/utils/discover/helpers';

interface TopicChartCardProps {
  chart: DiscoverMetricChart | null;
  chartTrackingEvent: () => void;
  chartType?: string;
  isFocusedMetric?: boolean;
  metadata: DiscoverMetadata | undefined;
  metric: DiscoverTopicMetric;
  timeFilter: TopicTimeFilter;
  volumeMetricValue?: DiscoverTopicValue;
  xLabel?: string[];
}

const TopicMetricCardDetail: React.FC<
  React.PropsWithChildren<TopicChartCardProps>
> = ({
  chart,
  chartTrackingEvent,
  chartType,
  isFocusedMetric,
  metadata,
  metric,
  timeFilter,
  volumeMetricValue,
  xLabel,
}) => {
  const theme = useTheme();
  const {
    name,
    value_changed: valueChanged,
    value_deviance: valueDeviance,
  } = metric;

  const isPreviousLineEmpty =
    !chart || chart.previous_line.every(value => !value);
  const isValueChangedAvailable = typeof valueChanged === 'number';
  const isSentimentMetric = name === 'sentiment';

  return (
    <>
      <Box minHeight={82.5}>
        {isSentimentMetric ? (
          <SentimentMetricHeader
            metric={metric}
            volumeMetricValue={volumeMetricValue}
          />
        ) : (
          <MetricHeader metadata={metadata} metric={metric} />
        )}
        <Box mb={1.25} />
        <AnalyticsContainer>
          {isValueChangedAvailable && !isPreviousLineEmpty ? (
            <>
              <Typography
                color={getPercentChangeColor(valueChanged ?? 0, name)}
                variant='font14Bold'
              >
                {getNumberIndicator(valueChanged)}
                {getDisplayableValueChanged(valueChanged)}
              </Typography>
              <Typography
                color={theme.palette.colors.grey[700]}
                variant='font14'
              >
                {isSentimentMetric && (
                  <>
                    {`${
                      valueChanged > 0 ? 'increase' : 'decrease'
                    } in volume that ended with lower sentiment `}
                  </>
                )}
                {getTopicMetricCardTimeMessage(timeFilter, chart)}
              </Typography>
            </>
          ) : (
            <Typography color={theme.palette.colors.grey[600]} variant='font14'>
              <i>{getEmptyTopicMetricCardTimeMessage(timeFilter)}</i>
            </Typography>
          )}
        </AnalyticsContainer>
        <Box mb={1.25} />
        <AnalyticsContainer>
          <Typography
            color={getPercentChangeColor(valueDeviance ?? 0, name)}
            variant='font14Bold'
          >
            {getNumberIndicator(valueDeviance)}
            {getDisplayableValueDeviance(valueDeviance)}
          </Typography>
          <Typography color={theme.palette.colors.grey[700]} variant='font14'>
            {isSentimentMetric && (
              <>
                of <strong>Negative</strong>{' '}
              </>
            )}
            {typeof valueDeviance === 'number' ? 'more than other topics' : ''}
          </Typography>
        </AnalyticsContainer>
      </Box>
      <Box mt={9.25}>
        <ChartContainer isFocusedMetric={isFocusedMetric}>
          <DiscoverChart
            chartType={chartType}
            data={chart}
            metadata={metadata}
            showCrosshair={isFocusedMetric}
            showTitle={isFocusedMetric}
            showXAxisGridLines={isFocusedMetric}
            showXYLabel={isFocusedMetric}
            trackingEvent={chartTrackingEvent}
            xLabel={xLabel}
          />
        </ChartContainer>
      </Box>
    </>
  );
};

export default TopicMetricCardDetail;

const AnalyticsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 2px;
`;

const ChartContainer = styled('div')<{ isFocusedMetric?: boolean }>`
  height: ${props => (props.isFocusedMetric ? 300 : 90)}px;
  width: 100%;
`;
