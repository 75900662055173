import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import {
  SelectDropdown,
  Tabs,
} from '@forethought-technologies/forethought-elements';
import { DateRange } from '../discover-dashboard-page/types';
import { useScrollIntoArticleView } from './hooks/useScrollIntoArticleView';
import { TICKET_FETCH_ERROR } from './constants';
import { getTopicNameFromUrl } from './helpers';
import RecommendationSection from './RecommendationSection';
import TopicArticlesTable from './TopicArticlesTable';
import TopicDescription from './TopicDescription';
import TopicDetailChartSection from './TopicDetailChartSection';
import TopicDetailHeader from './TopicDetailHeader';
import TopicDetailTicketSection from './TopicDetailTicketSection';
import {
  TopicPeriodicalFilter,
  TopicSortFilter,
} from 'src/components/app/types';
import DiscoverErrorPage from 'src/components/discover-error-page';
import {
  datePickerRangeOptions,
  DISCOVER_SHARED_PARAM_NAMES,
  initialMetricFilterDropdownValue,
  initialPeriodicalDropdownValue,
  initialSortDropdownValue,
  metricFilterOptions,
  periodicalFilterOptions,
  timeFilterOptions,
} from 'src/constants/discover';
import { useDataFilter } from 'src/hooks/discover/useDataFilter';
import { useGetAvailableMetricFilterOptions } from 'src/hooks/discover/useGetAvailableMetricFilterOptions';
import { useGetTopicDetailMetrics } from 'src/hooks/discover/useGetTopicDetailMetrics';
import { usePersistSearchParams } from 'src/hooks/discover/usePersistSearchParams';
import { useEmitTrackingEventCallback, useStateParams } from 'src/hooks/hooks';
import { selectDiscoverArticlesByPath } from 'src/reducers/discoverReducer/discoverReducer';
import { DiscoverTopicAggregatedMetricType } from 'src/reducers/discoverReducer/types';
import {
  useGetAllTopicTicketsQuery,
  useGetMetadataQuery,
} from 'src/services/discover/discoverApi';
import { useGetOrgConfigQuery } from 'src/services/settings/settingsApi';
import { selectIsSidebarExpanded } from 'src/slices/ui/uiSlice';
import { getAppCuesId } from 'src/utils/appCuesUtil';
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter';
import {
  a11yDiscoverTopicDetailSubTabPanelProps,
  a11yDiscoverTopicDetailSubTabProps,
  a11yDiscoverTopicDetailTabProps,
  dateRangeDeserialize,
  dateRangeSerialize,
  dateRangeToTimeFilter,
  genericParameterValidator,
  genericSerializeAndDeserialize,
  isTimePeriodValid,
  timeFilterParameterValidator,
} from 'src/utils/discover/helpers';

const TopicDetailPage = () => {
  usePersistSearchParams();

  const theme = useTheme();
  const [dateRange, setDateRange] = useStateParams({
    deserialize: dateRangeDeserialize,
    initialState: datePickerRangeOptions[2].value,
    paramsName: DISCOVER_SHARED_PARAM_NAMES.TIME_FILTER,
    serialize: dateRangeSerialize,
    validator: timeFilterParameterValidator(timeFilterOptions),
  });
  const { search } = useLocation();

  const { topicId = '' } = useParams<'topicId'>();

  const timeFilter = dateRangeToTimeFilter(dateRange);
  const [periodicalFilter, setPeriodicalFilter] =
    useStateParams<TopicPeriodicalFilter>({
      deserialize: genericSerializeAndDeserialize as (
        str: string,
      ) => TopicPeriodicalFilter,
      initialState: initialPeriodicalDropdownValue,
      paramsName: DISCOVER_SHARED_PARAM_NAMES.PERIODICAL_FILTER,
      serialize: genericSerializeAndDeserialize,
      validator: genericParameterValidator(periodicalFilterOptions),
    });
  const [sortFilterValue, setSortFilterValue] = useState<TopicSortFilter>(
    initialSortDropdownValue,
  );

  const {
    dataFilterQuery,
    isLoading: isFilterLoading,
    queryFilterOptions,
    queryFilters,
    setQueryFilters,
  } = useDataFilter();
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const [chartType, setChartType] = useState('spline');

  const {
    data: topicDetailMetricData,
    error: topicError,
    loading: isTopicDetailMetricDataLoading,
  } = useGetTopicDetailMetrics({
    dataFilterQuery,
    interval: periodicalFilter,
    timeFilter: timeFilter,
    topicId,
  });

  const { data: metadata } = useGetMetadataQuery();
  const { data: orgConfigData } = useGetOrgConfigQuery();
  const shouldShowArticleTable = orgConfigData?.is_content_generation_enabled;
  const isSearchTicketEnabled = orgConfigData?.is_ticket_keyword_search_enabled;

  const tabs = shouldShowArticleTable
    ? [
        { Component: TopicDetailTicketSection, name: 'Tickets' },
        { Component: TopicArticlesTable, name: 'Generated articles' },
      ]
    : [{ Component: TopicDetailTicketSection, name: 'Tickets' }];
  const topicName = capitalizeFirstLetter(
    isTopicDetailMetricDataLoading || !topicDetailMetricData
      ? getTopicNameFromUrl(search)
      : topicDetailMetricData.topic?.topic_name || '',
  );
  const [tabIndex, setTabIndex] = useStateParams({
    deserialize: tabName => tabs.findIndex(tab => tab.name === tabName),
    initialState: 0,
    paramsName: DISCOVER_SHARED_PARAM_NAMES.TAB,
    serialize: tabIndex => tabs[tabIndex].name,
    validator: (parameter: string) =>
      Boolean(tabs.find(tab => tab.name === parameter)),
  });
  const [metricFilter, setMetricFilter] =
    useStateParams<DiscoverTopicAggregatedMetricType>({
      deserialize: genericSerializeAndDeserialize as (
        str: string,
      ) => DiscoverTopicAggregatedMetricType,
      initialState: initialMetricFilterDropdownValue,
      paramsName: DISCOVER_SHARED_PARAM_NAMES.METRIC_FILTER,
      serialize: genericSerializeAndDeserialize,
      validator: genericParameterValidator(metricFilterOptions),
    });
  const [searchText, setSearchText] = useStateParams({
    deserialize: value => value,
    initialState: '',
    paramsName: DISCOVER_SHARED_PARAM_NAMES.TOPIC_TICKET_SEARCH,
    serialize: value => value,
  });

  const {
    data: topicTicketData,
    error: ticketError,
    isFetching: isTopicTicketDataFetching,
    isLoading: isTopicTicketDataLoading,
  } = useGetAllTopicTicketsQuery({
    dataFilterQuery,
    searchText,
    timeFilter,
    topicId,
  });

  const isSidebarExpanded = useSelector(selectIsSidebarExpanded);
  const articleData = useSelector(selectDiscoverArticlesByPath(''));

  const availableMetricFilterOptions = useGetAvailableMetricFilterOptions(
    metricFilter,
    topicDetailMetricData,
  );

  const handleDateRangeOnChange = (dateRange: DateRange) => {
    const timeFilter = dateRangeToTimeFilter(dateRange);
    setDateRange(dateRange);
    if (!isTimePeriodValid(periodicalFilter, timeFilter)) {
      setPeriodicalFilter('daily');
    }
    emitTrackingEventCallback('discover-time-frame-selected', {
      page: 'Topic view',
      'time-period-selected': timeFilter.isCustom ? 'custom' : timeFilter.key,
    });
  };

  const handleOnChange = (
    selectedMetric: DiscoverTopicAggregatedMetricType,
  ) => {
    setMetricFilter(selectedMetric);
    const isMetricFilterWithoutHighestAndLowest = selectedMetric === 'volume';
    const isHighestOrLowestSortFilter =
      sortFilterValue === 'highest' || sortFilterValue === 'lowest';

    if (isMetricFilterWithoutHighestAndLowest && isHighestOrLowestSortFilter) {
      setSortFilterValue('newest');
    }
    emitTrackingEventCallback('discover-metric-selected', {
      'metric-selected': selectedMetric,
      page: 'Topic view',
      'time-comparison-selected': periodicalFilter,
      'time-period-selected': timeFilter,
      topic: topicDetailMetricData?.topic?.topic_name,
    });
  };

  const showBannerSection = Boolean(
    !isTopicDetailMetricDataLoading && topicDetailMetricData?.topic,
  );
  // When sidebar is open create a custom breakpoint to change layout
  const customBreakpointWithSidebar = showBannerSection ? 1475 : 1075;
  const breakpoint = showBannerSection
    ? theme.breakpoints.values.lg
    : theme.breakpoints.values.md;
  // Depending on the banner being rendered use the correct breakpoint
  const isLargerScreen = useMediaQuery(
    `(min-width:${
      isSidebarExpanded ? customBreakpointWithSidebar : breakpoint
    }px)`,
  );

  const tabPositionRef = useScrollIntoArticleView(
    topicDetailMetricData?.articles.length,
  );

  if (topicError || ticketError) {
    const ticketErrorText = ticketError ? TICKET_FETCH_ERROR : '';
    const error = topicError?.error_type ?? ticketErrorText ?? '';
    return <DiscoverErrorPage errorType={error} />;
  }

  const tabComponentProps = {
    articleData: articleData,
    data: topicTicketData,
    displayName: topicDetailMetricData?.topic?.topic_display_name,
    emitArticleClickedTrackingEvent: ({ value }: { value: string }) =>
      emitTrackingEventCallback('discover-clicked-on-article', {
        page: 'Topic view',
        'page-area': 'Articles',
        topic: topicName,
        topicId,
        value: value,
      }),
    emitSortedTrackingEvent: ({ value }: { value: string }) =>
      emitTrackingEventCallback('discover-sorted-articles', {
        page: 'Topic view',
        'page-area': 'Articles',
        topic: topicName,
        topicId,
        value: value,
      }),
    isLoading: isTopicTicketDataLoading || isTopicTicketDataFetching,
    isSearchTicketEnabled,
    metadata: metadata,
    metricData: topicDetailMetricData,
    metricFilter: metricFilter,
    searchText,
    setSearchText,
    setSortFilterValue: setSortFilterValue,
    sortFilterValue: sortFilterValue,
    timeFilter: timeFilter,
    topicId: topicId,
    topicName: topicDetailMetricData?.topic?.topic_name ?? '',
  } as const;

  return (
    <Box
      bgcolor={theme.palette.colors.white}
      padding='26px 40px'
      position='relative'
    >
      <TopicDescription
        description={topicDetailMetricData?.topic?.description}
      />
      <TopicDetailHeader
        analyticFilterOptions={queryFilterOptions}
        chartType={chartType}
        handleDateRangeChange={handleDateRangeOnChange}
        handlePeriodicalChange={setPeriodicalFilter}
        isFilterLoading={isFilterLoading}
        isLargerScreen={isLargerScreen}
        isNewTopic={Boolean(topicDetailMetricData?.topic?.is_new_topic)}
        loading={isTopicDetailMetricDataLoading}
        periodicalFilter={periodicalFilter}
        queryFilters={queryFilters}
        setChartType={setChartType}
        setQueryFilters={setQueryFilters}
        timeFilter={timeFilter}
        topicDetailMetricData={topicDetailMetricData}
        topicId={topicId}
        topicName={topicName}
      />
      <Box
        alignItems={isLargerScreen ? 'center' : 'flex-start'}
        display='flex'
        flexDirection={isLargerScreen ? 'row' : 'column-reverse'}
        justifyContent='space-between'
        pt={2}
      >
        <Box flex={1} width='100%'>
          {isLargerScreen ? (
            <Tabs
              a11yProps={a11yDiscoverTopicDetailTabProps}
              aria-label='discover topic detail tabs'
              ContainerProps={{
                borderBottom: '1px solid' + theme.palette.colors.slate[200],
              }}
              onChange={(_, selectedIndex) => {
                const selectedMetric =
                  availableMetricFilterOptions[selectedIndex].value;
                handleOnChange(selectedMetric);
              }}
              tabs={availableMetricFilterOptions.map(option => {
                if (option.label === 'Sentiment') {
                  return 'Change in sentiment';
                }
                return option.label;
              })}
              typographyVariant='font14Bold'
              value={availableMetricFilterOptions.findIndex(
                option => option.value === metricFilter,
              )}
              variant='fullWidth'
            />
          ) : (
            <SelectDropdown
              id='metric-dropdown'
              inputTypographyVariant='font20Medium'
              onChange={e => {
                const newFilter = e.target
                  .value as DiscoverTopicAggregatedMetricType;
                handleOnChange(newFilter);
              }}
              options={availableMetricFilterOptions}
              placeholder='Select metric type'
              value={metricFilter}
            />
          )}
          <TopicDetailChartSection
            chartType={chartType}
            data={topicDetailMetricData}
            isLoading={isTopicDetailMetricDataLoading}
            metadata={metadata}
            metricFilter={metricFilter}
            timeFilter={timeFilter}
            topicName={topicDetailMetricData?.topic?.topic_name ?? ''}
          />
        </Box>
        <RecommendationSection
          automatedObject={topicDetailMetricData?.automated_object}
          isLargerScreen={isLargerScreen}
          isLoading={isTopicDetailMetricDataLoading}
          topic={topicDetailMetricData?.topic}
          trackingEventMetadata={{
            'metric-filter': metricFilter,
            'time-comparison': periodicalFilter,
            'time-period': timeFilter.key,
          }}
        />
      </Box>

      <Box
        data-appcues-target={getAppCuesId({
          componentType: 'container',
          featureName: 'tickets',
          pageName: 'topicdetail',
          subType: '',
        })}
        mt={5}
        position='relative'
      >
        {/* this ghost div is needed to align scroll with tabs -69px sticky-header */}
        <div ref={tabPositionRef} style={{ position: 'absolute', top: -69 }} />
        <Tabs
          a11yProps={a11yDiscoverTopicDetailSubTabProps}
          aria-label='discover topic details tabs'
          ContainerProps={{
            borderBottom: '1px solid' + theme.palette.colors.slate[200],
          }}
          onChange={(_, value) => {
            setTabIndex(value);
            emitTrackingEventCallback('discover-topic-detail-tab-changed', {
              to_tab: tabs[value].name,
            });
          }}
          tabs={tabs.map(({ name }) => name)}
          typographyVariant='font20'
          value={tabIndex}
        />
        {tabs.map(({ Component, name }, index) => (
          <div
            hidden={tabIndex !== index}
            key={name}
            role='tabpanel'
            {...a11yDiscoverTopicDetailSubTabPanelProps(index)}
          >
            {tabIndex === index && <Component {...tabComponentProps} />}
          </div>
        ))}
      </Box>
    </Box>
  );
};

export default TopicDetailPage;
