import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

import {
  Button,
  Drawer,
  Tabs,
} from '@forethought-technologies/forethought-elements';
import { CREATE_INTENT_WITH_LESS_THAN_FIVE_PHRASES_MIN_REQUIRED_PHRASES } from '../constants';
import useAutomationTopic from '../hooks/useAutomationTopic';
import { useGetDiscoverRecommendations } from '../hooks/useGetDiscoverRecommendationDetails';
import AutomationDrawerActionRow from './AutomationDrawerActionRow';
import AutomationMainTab from './AutomationMainTab';
import GeneratedPolicyTab from './GeneratedPolicyTab';
import { TOAST_TIMEOUT } from 'src/components/dashboard-pages/triage-config-detail-page/helpers';
import Spinner from 'src/components/spinner';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import {
  selectGlobalDiscoverOptions,
  setGlobalDiscoverOptions,
  setGlobalToastOptions,
} from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { deriveTopicNameFromTopic } from 'src/utils/discover/helpers';

const tabs = ['Create Workflow', 'Generated Policy'];

const AutomationDrawer = () => {
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [selectedNeighboringTopicIds, setSelectedNeighboringTopicIds] =
    useState<string[]>([]);
  const { postAutomationActionModalOptions } = useSelector(
    selectGlobalDiscoverOptions,
  );

  const {
    loading: isTopicLoading,
    topic: topicToAutomate,
    topicId,
  } = useAutomationTopic();

  const { data: recommendationData, loading } =
    useGetDiscoverRecommendations(topicId);
  const [selectedTab, setSelectedTab] = useState(0);
  const copyPolicy = useCallback(() => {
    if (!recommendationData?.policy_description) {
      return;
    }
    emitTrackingEventCallback('discover-automation-copy-policy', {
      pathname: location.pathname,
      'topic-name': topicToAutomate?.topic_name,
    });
    navigator.clipboard.writeText(recommendationData?.policy_description);
    dispatch(
      setGlobalToastOptions({
        autoHideDuration: TOAST_TIMEOUT,
        title: 'Policy copied to clipboard',
        variant: 'main',
      }),
    );
  }, [
    dispatch,
    recommendationData?.policy_description,
    emitTrackingEventCallback,
    location.pathname,
    topicToAutomate?.topic_name,
  ]);

  const isOpen = Boolean(
    topicId && topicToAutomate && !postAutomationActionModalOptions.isOpen,
  );
  const isLoadingContent = loading || isTopicLoading;
  const { summaries } = topicToAutomate ?? { summaries: [] };

  const minRequiredPhrases =
    CREATE_INTENT_WITH_LESS_THAN_FIVE_PHRASES_MIN_REQUIRED_PHRASES;

  const canCreateAutomation = Number(summaries?.length) >= minRequiredPhrases;

  return (
    <Drawer
      footerContent={
        topicToAutomate &&
        !isLoadingContent &&
        (selectedTab === 0 ? (
          <AutomationDrawerActionRow
            canCreateAutomation={canCreateAutomation}
            policy={recommendationData?.policy_description}
            selectedNeighboringTopicIds={selectedNeighboringTopicIds}
            topic={topicToAutomate}
          />
        ) : (
          <Button fullWidth onClick={copyPolicy} variant='secondary'>
            Copy
          </Button>
        ))
      }
      isOpen={isOpen}
      onClose={() => {
        emitTrackingEventCallback('discover-automation-modal-closed', {
          pathname: location.pathname,
          'topic-name': topicToAutomate?.topic_name,
        });
        dispatch(
          setGlobalDiscoverOptions({
            topicIDToAutomate: null,
            topicToAutomate: null,
          }),
        );
        setSelectedNeighboringTopicIds([]);
      }}
      width='628px'
    >
      {isLoadingContent && (
        <Box mt='40px' mx='40px'>
          <Spinner />
        </Box>
      )}
      {!isLoadingContent && (
        <Box
          mt='40px'
          mx='40px'
          sx={{
            '& .MuiTab-root': {
              padding: '10px 16px',
            },
          }}
        >
          <Tabs
            a11yProps={index => ({
              'aria-controls': `topic-automation-tabpanel-${index}`,
              id: `topic-automation-tab-${index}`,
            })}
            aria-label='Can select the automation action to take from here'
            onChange={(_, value) => setSelectedTab(value)}
            tabs={tabs}
            typographyVariant='font18'
            value={selectedTab}
          />
          {selectedTab === 0 ? (
            <AutomationMainTab
              recommendationDetail={recommendationData}
              selectedNeighboringTopicIds={selectedNeighboringTopicIds}
              setSelectedNeighboringTopicIds={setSelectedNeighboringTopicIds}
              topicToAutomate={topicToAutomate}
            />
          ) : (
            <GeneratedPolicyTab
              policy={recommendationData?.policy_description}
              topicName={deriveTopicNameFromTopic(topicToAutomate)}
            />
          )}
        </Box>
      )}
    </Drawer>
  );
};

export default AutomationDrawer;
