import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Box, styled, ThemeProvider } from '@mui/material';

import { useGetTemplateAutoflowWorkflow } from '../workflow-builder-edit/hooks';
import { ApiStepPreviewLog } from './preview-logs/ApiStepPreviewLog';
import { ArticleSuggestionStepPreviewLog } from './preview-logs/ArticleSuggestionStepPreviewLog';
import { AutoflowPreviewLog } from './preview-logs/AutoflowPreviewLog';
import { InfoPreviewLog } from './preview-logs/InfoPreviewLog';
import { IntentPredictionPreviewLog } from './preview-logs/IntentPredictionPreviewLog';
import { LanguageChangePreviewLog } from './preview-logs/LanguageChangePreviewLog';
import { BlueText } from './preview-logs/TextComponents';
import { autoflowColors, darkModePurple } from './constants';
import { useGetColorMode } from './hooks';
import {
  getTemplateIntentWorkflowId,
  useGetPreviewLogs,
  useGetThemeBasedOnMode,
} from './utils';
import {
  ApiStepPreviewLogProps,
  ArticleSuggestionStepPreviewLogProps,
  AutoflowPreviewLogProps,
  GoToPreviewLogProps,
  InfoPreviewLogProps,
  IntentPredictionPreviewLogProps,
  LanguageChangePreviewLogProps,
  PreviewLogProps,
  PreviewLogType,
} from 'src/slices/workflow-preview/types';
import { selectPreviewConversationId } from 'src/slices/workflow-preview/workflowPreviewSlice';

const mapLogToComp = (previewLog: PreviewLogProps, colorMode: string) => {
  switch (previewLog.log_type) {
    case PreviewLogType.INTENT_PREDICTION:
      return (
        <>
          <Separator colorMode={colorMode} />
          <IntentPredictionPreviewLog
            previewLog={previewLog as IntentPredictionPreviewLogProps}
          />
        </>
      );
    case PreviewLogType.API_STEP:
      return (
        <ApiStepPreviewLog previewLog={previewLog as ApiStepPreviewLogProps} />
      );
    case PreviewLogType.ARTICLE_SUGGESTION_STEP:
      return (
        <ArticleSuggestionStepPreviewLog
          previewLog={previewLog as ArticleSuggestionStepPreviewLogProps}
        />
      );
    case PreviewLogType.LANGUAGE_CHANGE:
      return (
        <LanguageChangePreviewLog
          previewLog={previewLog as LanguageChangePreviewLogProps}
        />
      );
    case PreviewLogType.AUTOFLOW:
      return (
        <AutoflowPreviewLog
          previewLog={previewLog as AutoflowPreviewLogProps}
        />
      );
    case PreviewLogType.WARNING:
      return (
        <InfoPreviewLog
          isWarning
          message={(previewLog as InfoPreviewLogProps).message}
          title={(previewLog as InfoPreviewLogProps).title}
        />
      );
    case PreviewLogType.INFO:
      return (
        <InfoPreviewLog
          message={(previewLog as InfoPreviewLogProps).message}
          title={(previewLog as InfoPreviewLogProps).title}
        />
      );
    case PreviewLogType.GOTO_STEP:
      return (
        <InfoPreviewLog
          title={`Routing to "${
            (previewLog as GoToPreviewLogProps).intent_title || 'Unknown Intent'
          }"`}
        />
      );
    default:
      return null;
  }
};

export const InfoConsole = () => {
  const theme = useGetThemeBasedOnMode();
  const previewLogs = useGetPreviewLogs();
  const infoConsoleEndRef = useRef<HTMLDivElement>(null);
  const colorMode = useGetColorMode();

  useEffect(() => {
    if (infoConsoleEndRef) {
      infoConsoleEndRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [previewLogs]);

  return (
    <ThemeProvider theme={theme}>
      <Box display='flex' flexDirection='column' gap='16px' overflow='auto'>
        <InfoConsoleContainer colorMode={colorMode}>
          <ConversationIdText />
          <AutoflowTemplateDescription />
          {previewLogs.map(previewLog => mapLogToComp(previewLog, colorMode))}
          <Box ref={infoConsoleEndRef} sx={{ clear: 'both', mt: '-24px' }} />
        </InfoConsoleContainer>
      </Box>
    </ThemeProvider>
  );
};

const ConversationIdText = () => {
  const conversationId = useSelector(selectPreviewConversationId);

  return (
    <div>
      <span>Chat ID: </span>
      <BlueText>{conversationId || 'Chat not started'}</BlueText>
    </div>
  );
};

const AutoflowTemplateDescription = () => {
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const previewFromIntentId = params.get('intentId');
  const { template } = useGetTemplateAutoflowWorkflow(
    getTemplateIntentWorkflowId(previewFromIntentId ?? ''),
  );

  return (
    <>
      {template?.name && (
        <Box display='flex' flexDirection='column' mb={2}>
          <span style={{ color: darkModePurple }}>Customer Issue:{'\n'}</span>
          <span style={{ color: autoflowColors.dark.default }}>
            {template.name}
          </span>
        </Box>
      )}
      {template?.policy_description && (
        <Box display='flex' flexDirection='column' mb={2}>
          <span style={{ color: darkModePurple }}>
            Policy description:{'\n'}
          </span>
          <span
            dangerouslySetInnerHTML={{
              __html: template.policy_description,
            }}
            style={{ color: autoflowColors.dark.default }}
          />
        </Box>
      )}
    </>
  );
};

const InfoConsoleContainer = styled('div')<{ colorMode: string }>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-family: 'Fira Code';
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: ${props =>
    props.colorMode === 'dark'
      ? props.theme.palette.colors.white
      : props.theme.palette.colors.black};
`;

const Separator = styled('div')<{ colorMode: string }>`
  height: 1px;
  background-color: ${props =>
    props.colorMode === 'dark'
      ? props.theme.palette.colors.grey[800]
      : props.theme.palette.colors.slate[200]};
`;
