import { Box } from '@mui/material';

import {
  SelectDropdown,
  Skeleton,
} from '@forethought-technologies/forethought-elements';
import { useGetDataSourcePredictedFields } from '../../triage-config-detail-page/hooks/useGetDataSourcePredictedFields';
import TextWithInfo from '../../triage-model-detail-page/common/TextWithInfo';
import { TICKET_FIELD_TOOLTIP } from './constants';

interface TicketFieldProps {
  error?: string;
  isLoading: boolean;
  onChange: (fieldValue: string) => void;
  value: string;
}

const TicketField = ({
  error,
  isLoading,
  onChange,
  value,
}: TicketFieldProps) => {
  const { loading, outputFields } = useGetDataSourcePredictedFields();

  if (loading) {
    return <Skeleton />;
  }

  return (
    <Box display='flex' flexDirection='column' rowGap={1}>
      <TextWithInfo
        title='Pick a ticket field'
        tooltip={TICKET_FIELD_TOOLTIP}
        variant='font14Bold'
      />
      <SelectDropdown
        disabled={isLoading}
        error={error}
        id='auto-gen-ticket-field'
        isMenuSearchable
        onChange={event => onChange(event.target.value)}
        options={outputFields}
        placeholder='Select'
        value={value}
      />
    </Box>
  );
};

export default TicketField;
