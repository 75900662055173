import {
  addPercentageValue,
  numToPercentage,
  numToString,
} from '../triage-model-detail-page/helpers';
import { OverviewMapValue } from '../triage-model-detail-page/types';
import { LabelMetrics, LabelOverallMetric } from 'src/services/triage/types';

export const TABS = ['Labels', 'Tickets'];

const ACCURACY_TOOLTIP =
  "This indicates the accuracy of Triage's label predictions. The calculation formula is Correct Predictions/Finalized tickets x 100";
const PREDICTIONS_COUNT_TOOLTIP =
  'Tickets where Triage successfully predicted and assigned this label';
const CORRECT_PREDICTIONS_TOOLTIP =
  'Tickets where the Predicted Label matches the Ground Truth Label and meets the accuracy criteria are considered correct predictions.';
const RECALL_TOOLTIP =
  'A higher recall indicates the model is effectively identifying most of the relevant tickets for labels, minimizing missed predictions. The recall for label "A" is calculated as: (Number of tickets where both the Ground Truth and Predicted Label are "A") / (Total number of tickets where the Ground Truth Label is "A") × 100.';

export const labelOverviewInfoMap: Record<
  keyof LabelOverallMetric,
  OverviewMapValue
> = {
  accuracy: {
    formatValue: numToPercentage,
    key: 'Accuracy',
    tooltip: ACCURACY_TOOLTIP,
  },
  correct_predictions_count: {
    formatValue: numToString,
    key: 'Correct predictions',
    tooltip: CORRECT_PREDICTIONS_TOOLTIP,
  },
  predictions_count: {
    formatValue: addPercentageValue,
    key: 'Predicted tickets',
    tooltip: PREDICTIONS_COUNT_TOOLTIP,
  },
  total_tickets_count: {
    formatValue: numToString,
    key: 'Total tickets',
    tooltip: 'Total number of tickets that Forethought has access to.',
  },
};

export const labelTableInfoMap: Record<
  Exclude<
    keyof LabelMetrics,
    'description' | 'label_name' | 'finalized_tickets_count'
  >,
  OverviewMapValue
> = {
  accuracy: {
    formatValue: numToPercentage,
    key: 'Accuracy',
    tooltip: ACCURACY_TOOLTIP,
  },
  accurate_count: {
    formatValue: numToString,
    key: 'Correct prediction',
    tooltip: CORRECT_PREDICTIONS_TOOLTIP,
  },
  predictions_count: {
    formatValue: addPercentageValue,
    key: 'Predicted tickets',
    tooltip: PREDICTIONS_COUNT_TOOLTIP,
  },
  recall: {
    formatValue: numToPercentage,
    key: 'Recall',
    tooltip: RECALL_TOOLTIP,
  },
};

export const TICKET_SEARCH_TYPE_OPTIONS = [
  {
    text: 'Ticket',
    value: 'keyword',
  },
  {
    text: 'Ticket ID',
    value: 'ticket_id',
  },
];

export const FILTER_KEY_ACTUAL_PREDICTION_LABEL =
  'actual_prediction_label_filter_';
