import { formatToDateAndTime } from '@forethought-technologies/forethought-elements';
import { TicketFilterItemValue } from './AutogenFormSection/types';
import { CreateModelFormValue } from './types';
import { NA } from 'src/constants/solve';
import { Routes } from 'src/utils/enums';

export const lastSync = (syncDateTime: string | null) => {
  if (!syncDateTime) {
    return NA;
  }
  return formatToDateAndTime(syncDateTime);
};

export const getTriageModelsPageUrl = (modelName: string) => {
  return Routes.TRIAGE_MODELS.replace(':modelId', modelName);
};

export const validateAutogenFilters = ({
  filters,
}: {
  filters?: TicketFilterItemValue[];
}) => {
  if (!filters || !filters.length) {
    return true;
  }
  const isFilterValid = filters.every(filter => {
    if (filter.expression.operator === 'empty') {
      return true;
    }
    if (!filter.expression.values?.filter(Boolean)?.length) {
      return false;
    }
    return true;
  });

  return isFilterValid;
};

export const createAutogenRequest = (values: CreateModelFormValue) => {
  const {
    dateRange: selectedDateRange,
    description,
    displayName: model_name,
    filters,
    selectedTicket,
  } = values;
  return {
    end_date: selectedDateRange.to?.valueOf() || 0,
    filter_operator: filters.length > 0 ? filters[0].booleanOperator : '',
    filters: filters.map(filter => ({
      field: filter.expression.field || '',
      negate: filter.expression.negate,
      operator: filter.expression.operator || '',
      values: filter.expression.values || [],
    })),
    model_description: description,
    model_name,
    start_date: selectedDateRange.from?.valueOf() || 0,
    ticket_field: selectedTicket,
  };
};
