import Skeleton from 'react-loading-skeleton';
import { Box, SelectChangeEvent } from '@mui/material';

import {
  ListOption,
  SelectDropdown,
  TextField,
} from '@forethought-technologies/forethought-elements';
import { LABEL_MAPPING_ERROR_MESSAGE } from './constants';
import {
  CurrentOrgHelpdeskDetail,
  TagDefinitionResponse,
} from 'src/reducers/triageSettingsReducer/types';

interface LabelInputProps {
  disabled: boolean;
  handleLabelMappingChange: (
    e: SelectChangeEvent<string> | React.ChangeEvent<HTMLInputElement>,
    label: TagDefinitionResponse,
  ) => void;
  helpdesk: CurrentOrgHelpdeskDetail | null;
  isLoadingField: boolean;
  label: TagDefinitionResponse;
  outputFieldLabelOptions?: ListOption[];
  value: string;
}

const LabelInput = ({
  disabled,
  handleLabelMappingChange,
  helpdesk,
  isLoadingField,
  label,
  outputFieldLabelOptions,
  value,
}: LabelInputProps) => {
  if (isLoadingField) {
    return <Skeleton width='50%' />;
  }

  if (!outputFieldLabelOptions || outputFieldLabelOptions.length === 0) {
    return (
      <TextField
        aria-label='label mapping'
        multiline
        onChange={e => handleLabelMappingChange(e, label)}
        placeholder={`The typed value must match exactly with the value in ${helpdesk?.display_name}`}
        value={value}
      />
    );
  }

  const error =
    Boolean(outputFieldLabelOptions?.length) &&
    !outputFieldLabelOptions?.find(option => option.value === value) &&
    LABEL_MAPPING_ERROR_MESSAGE;

  if (helpdesk) {
    const formattedHelpdeskName =
      helpdesk.name.charAt(0).toUpperCase() + helpdesk.name.slice(1);

    return (
      <Box
        sx={{
          '.MuiSelect-select': {
            whiteSpace: 'normal',
          },
        }}
      >
        <SelectDropdown
          disabled={disabled}
          error={error}
          id='label-mapping'
          onChange={e => handleLabelMappingChange(e, label)}
          options={outputFieldLabelOptions || []}
          placeholder={`Select ${formattedHelpdeskName} Field Option`}
          startAdornment={
            <Box
              alt='helpdesk logo'
              component='img'
              src={helpdesk.avatar}
              sx={{ height: '24px', marginRight: '5px', width: '24px' }}
            />
          }
          value={value}
        />
      </Box>
    );
  }

  return null;
};

export default LabelInput;
