import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { Typography } from '@forethought-technologies/forethought-elements';
import {
  DiscoverMetadata,
  DiscoverTopicMetric,
} from 'src/reducers/discoverReducer/types';
import {
  getAdditionalDataByMetricType,
  getDisplayableDataByMetricType,
  isSentimentValue,
} from 'src/utils/discover/helpers';

interface MetricHeaderProps {
  metadata: DiscoverMetadata | undefined;
  metric: DiscoverTopicMetric;
}

const MetricHeader = ({ metadata, metric }: MetricHeaderProps) => {
  const theme = useTheme();

  const { name, value } = metric;

  if (isSentimentValue(value)) {
    return null;
  }

  const { topic_metric_data_types: availableMetrics } = metadata ?? {
    topic_metric_data_types: [],
  };
  const dataType =
    availableMetrics.find(item => item.type === name)?.data_type ?? 'unknown';

  const displayableData = getDisplayableDataByMetricType({
    dataType,
    metric: name,
    value,
  });

  const additionalData = getAdditionalDataByMetricType(metric);
  return (
    <Box alignItems='end' display='flex' gap='8px'>
      <Typography color={theme.palette.colors.black} variant='font32'>
        {typeof value === 'number' ? displayableData : <i>{displayableData}</i>}
      </Typography>
      {additionalData && (
        <Typography color={theme.palette.colors.grey[700]} variant='font16'>
          {additionalData}
        </Typography>
      )}
    </Box>
  );
};

export default MetricHeader;
