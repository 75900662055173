import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { autoflowColors, INTENT_NOT_PREDICTED } from '../constants';
import { confidenceScoreToWord } from '../utils';
import { InfoPreviewLog } from './InfoPreviewLog';
import { GreenText, GreyText } from './TextComponents';
import aaBadgeSrc from 'src/assets/images/autonomous-agent-badge.svg';
import { useGetIntentsQueryWithProduct } from 'src/hooks/hooks';
import { UpdateIntentDialog } from 'src/pages/intent-conversation-analytics/UpdateIntentDialog';
import { IntentPredictionPreviewLogProps } from 'src/slices/workflow-preview/types';
import { setPreviewColorMode } from 'src/slices/workflow-preview/workflowPreviewSlice';
import { useAppDispatch } from 'src/store/hooks';
import { Routes } from 'src/utils/enums';

export const IntentPredictionPreviewLog = ({
  previewLog,
}: {
  previewLog: IntentPredictionPreviewLogProps;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { data } = useGetIntentsQueryWithProduct();

  const {
    autoflow_policy: policy,
    confidence_scores: confidenceScores,
    intents_predicted: intentsPredicted = [],
    is_executing_autoflow: isAutoflow,
    is_executing_draft: isDraft,
    user_query: userQuery,
  } = previewLog;

  const [firstIntentPredicted] = intentsPredicted;

  const intentPredicted = useMemo(() => {
    if (!data?.intents) {
      return firstIntentPredicted;
    }

    return (
      data?.intents.find(
        intent => intent.intent_definition_id === firstIntentPredicted,
      ) ?? firstIntentPredicted
    );
  }, [data?.intents, firstIntentPredicted]);

  const otherIntents = intentsPredicted.slice(1);
  const otherConfidenceScores = confidenceScores.slice(1);

  useEffect(() => {
    if (isAutoflow) {
      dispatch(setPreviewColorMode('dark'));
    } else if (firstIntentPredicted !== INTENT_NOT_PREDICTED) {
      dispatch(setPreviewColorMode('light'));
    }
  }, [isAutoflow, dispatch, firstIntentPredicted]);

  if (firstIntentPredicted === INTENT_NOT_PREDICTED) {
    return (
      <Box display='flex' flexDirection='column' gap='4px'>
        <InfoPreviewLog isWarning title='Intent not predicted' />
        <Typography color={theme.palette.colors.grey[500]} variant='font16'>
          Reassign to new or existing intent
        </Typography>
        <Box
          border={`1px solid ${theme.palette.colors.slate[200]}`}
          borderRadius={2}
          display='flex'
          flexDirection='column'
          gap='16px'
          padding='16px'
        >
          <UpdateIntentDialog userQuery={userQuery ?? 'new phrase'} />
        </Box>
      </Box>
    );
  }

  return (
    <Box display='flex' flexDirection='column'>
      <span>Intent detection:</span>
      <span>
        {isAutoflow && (
          <>
            <img
              alt='autoflow'
              src={aaBadgeSrc}
              style={{ height: 20, marginBottom: '-4px', width: 20 }}
            />{' '}
          </>
        )}
        <GreenText>
          {typeof intentPredicted === 'string' ? (
            intentPredicted
          ) : (
            <Link
              rel='noopener noreferrer'
              target='_blank'
              to={{
                pathname: Routes.WORKFLOW_BUILDER_EDIT,
                search: `?intentId=${intentPredicted.intent_definition_id}&workflowId=${intentPredicted.intent_workflow_id}`,
              }}
            >
              {intentPredicted.intent_name}
            </Link>
          )}{' '}
          {isDraft ? '(Draft) ' : '(Published) '}
        </GreenText>
        <GreyText>
          [Confidence score: {confidenceScoreToWord(confidenceScores[0])}]
        </GreyText>
      </span>
      {otherIntents.length > 0 && (
        <>
          <Box height='24px' />
          <span>Other intent detection</span>
          {otherIntents.map((intentName, index) => {
            return (
              <GreyText key={intentName}>
                {intentName} [Confidence score:{' '}
                {confidenceScoreToWord(otherConfidenceScores[index])}]
              </GreyText>
            );
          })}
        </>
      )}
      {policy && (
        <>
          <Box mt={2}>
            <span>Policy description:{'\n'}</span>
            <span
              dangerouslySetInnerHTML={{ __html: policy }}
              style={{
                color: autoflowColors[theme.palette.mode].default,
              }}
            />
          </Box>
          <Box mb={-2} mt={2}>
            <span>Conversation History:{'\n'}</span>
          </Box>
        </>
      )}
    </Box>
  );
};
