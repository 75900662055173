import { BuilderView } from 'src/pages/workflow-builder-edit/types';

export enum PreviewLogType {
  API_STEP = 'api_step',
  ARTICLE_SUGGESTION_STEP = 'article_suggestion_step',
  AUTOFLOW = 'autoflow',
  GOTO_STEP = 'goto_step',
  INFO = 'info',
  INTENT_PREDICTION = 'intent_prediction',
  LANGUAGE_CHANGE = 'language_change',
  WARNING = 'warning',
}

export interface IntentFilter {
  intentId: string;
  intentName: string;
  isAutoflow: boolean;
  isChecked: boolean;
  isDraft: boolean;
}

export interface WorkflowPreviewSliceState {
  apiPreview: ApiPreviewState;
  channel: BuilderView;
  colorMode: 'dark' | 'light';
  conversationId: string | null;
  emailPreview: EmailPreviewState;
  intentFilters: IntentFilter[];
  language: string;
  previewLogs: PreviewLogProps[];
  workflowCvs: { name: string; value: string }[];
  workflowTag: string | null;
}

export interface EmailPreviewState {
  emailConversationId: string | null;
  responseEmail: string;
  stage:
    | 'email-form'
    | 'email-template'
    | 'email-template-error'
    | 'interactive-widget';
  token: string | null;
}

/**
 * Widget preview types
 */
export type PreviewFromMode = 'landing-page' | 'classic-workflow' | 'autoflow';

export type IntentFiltersMode =
  | 'live-traffic'
  | 'current-intent-and-live-traffic'
  | 'customized';

export type PreviewLogProps =
  | ArticleSuggestionStepPreviewLogProps
  | ApiStepPreviewLogProps
  | IntentPredictionPreviewLogProps
  | LanguageChangePreviewLogProps
  | AutoflowPreviewLogProps
  | InfoPreviewLogProps
  | GoToPreviewLogProps;

export interface ArticleSuggestionStepPreviewLogProps {
  articles_predicted: string[];
  confidence_scores: number[];
  log_type: PreviewLogType.ARTICLE_SUGGESTION_STEP;
}

export interface ApiStepPreviewLogProps {
  action_name?: string;
  input_params: Record<string, string>;
  log_type: PreviewLogType.API_STEP;
  output_params: Record<string, string>;
  url_used?: string;
}

export interface IntentPredictionPreviewLogProps {
  autoflow_policy?: string;
  confidence_scores: number[];
  intents_predicted: string[];
  is_executing_autoflow?: boolean;
  is_executing_draft?: boolean;
  log_type: PreviewLogType.INTENT_PREDICTION;
  user_query?: string;
}

export interface LanguageChangePreviewLogProps {
  language_code: string;
  log_type: PreviewLogType.LANGUAGE_CHANGE;
}

export interface AutoflowPreviewLogProps {
  agent_response?: string;
  agent_thoughts: string[];
  log_type: PreviewLogType.AUTOFLOW;
  user_query?: string;
}

export interface InfoPreviewLogProps {
  log_type: PreviewLogType.INFO | PreviewLogType.WARNING;
  message?: string;
  title: string;
}
export interface GoToPreviewLogProps {
  intent_id?: string;
  intent_title?: string;
  log_type: PreviewLogType.GOTO_STEP;
}

export type IntentFiltersForIFrame = Record<
  string,
  { is_autoflow: boolean; is_draft: boolean }
>;

export type IntentFiltersForEmail = Record<string, { is_draft: boolean }>;

/**
 * Api preview types
 */
export interface ApiPreviewState {
  conversation_id: string | null;
  history: SolveApiTransaction[];
  isLoading: boolean;
  isStream: boolean;
}

export type SolveApiTransaction =
  | HistoricalSolveApiRequest
  | SolveApiResponse
  | SolveApiStreamingResponse
  | ForethoughtError;

export interface SolveApiRequest {
  context_variables: Record<string, string>;
  conversation_id?: string;
  intent_filters_for_preview?: IntentFiltersForIFrame;
  is_preview: boolean;
  query?: string;
  solve_api_token: string;
  stream?: boolean;
}

export interface ForethoughtError {
  message: string;
  status: number;
}

export type HistoricalSolveApiRequest = Omit<
  SolveApiRequest,
  'solve_api_token' | 'intent_filters_for_preview' | 'is_preview'
>;

export function isHistoricalSolveApiRequest(
  message: unknown,
): message is HistoricalSolveApiRequest {
  return (message as HistoricalSolveApiRequest)?.stream !== undefined;
}

export function isSolveApiResponse(
  message: unknown,
): message is SolveApiResponse {
  return (message as SolveApiResponse)?.widget_components !== undefined;
}

export function isSolveApiStreamingResponse(
  message: SolveApiTransaction,
): message is SolveApiStreamingResponse {
  return (message as SolveApiStreamingResponse)?.requestId !== undefined;
}

export function isForethoughtError(error: unknown): error is ForethoughtError {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof error.message === 'string'
  );
}

export enum Format {
  html = 'html',
  markdown = 'markdown',
}

export enum ComponentType {
  ARTICLE = 'article',
  ARTICLE_ANSWER = 'article_answer',
  BUTTON = 'button',
  FORM = 'form',
  HYPERLINK_REDIRECT = 'hyperlink_redirect',
  IMAGE = 'image',
  SALESFORCE_HANDOFF = 'salesforce_handoff',
  TEXT = 'text',
  ZENDESK_HANDOFF = 'zendesk_handoff',
}

export interface TextApiWidgetComponent {
  format: Format;
  message: string;
  type: ComponentType.TEXT;
}

export interface Button {
  display_name: string;
  value: string;
}

export interface ButtonApiWidgetComponent {
  buttons: Button[];
  format: Format;
  message: string;
  output_variable: string;
  type: ComponentType.BUTTON;
}

export interface FormField {
  input_type: string;
  list_options: [];
  output_variable: string;
  placeholder: string;
}

export interface FormApiWidgetComponent {
  form_fields: FormField[];
  format: Format;
  message: string;
  type: ComponentType.FORM;
}

export interface Image {
  description: string;
  url: string;
}

export interface ImageApiWidgetComponent {
  images: Image[];
  type: ComponentType.IMAGE;
}

export interface Article {
  confidence_level: string;
  confidence_score: number;
  date_created: string;
  date_updated: string;
  document_id: string;
  full_html: string;
  link: string;
  preview: string;
  source_type: string;
  title: string;
}

export interface ArticleAnswerApiWidgetComponent {
  articles: Article[];
  paraphrased_answer: string;
  type: ComponentType.ARTICLE_ANSWER;
}

export interface ArticleApiWidgetComponent {
  articles: Article[];
  type: ComponentType.ARTICLE;
}

export interface HyperlinkRedirectApiWidgetComponent {
  destination_link: string;
  type: ComponentType.HYPERLINK_REDIRECT;
}

export interface BaseHandoffApiWidgetComponent {
  visitor_email: string | null;
  visitor_name: string | null;
  visitor_question: string | null;
}

export interface ZendeskApiWidgetComponent
  extends BaseHandoffApiWidgetComponent {
  type: ComponentType.ZENDESK_HANDOFF;
}

export interface SalesforceApiWidgetComponent
  extends BaseHandoffApiWidgetComponent {
  type: ComponentType.SALESFORCE_HANDOFF;
}

export type ApiWidgetComponent =
  | TextApiWidgetComponent
  | ButtonApiWidgetComponent
  | FormApiWidgetComponent
  | ImageApiWidgetComponent
  | HyperlinkRedirectApiWidgetComponent
  | ArticleAnswerApiWidgetComponent
  | ArticleApiWidgetComponent
  | ZendeskApiWidgetComponent
  | SalesforceApiWidgetComponent;

export interface SolveApiResponse {
  conversation_id: string;
  widget_components: ApiWidgetComponent[];
}

export interface SolveApiStreamingResponse extends SolveApiResponse {
  requestId: number;
}
