import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material';

import {
  Badge,
  elevations,
  theme,
} from '@forethought-technologies/forethought-elements';
import { getIsModelPremium } from '../triage-config-detail-page/helpers';
import { checkModelIsAutogen } from './AutogenFormSection/helpers';
import CardFooter from './CardFooter';
import { getTriageModelsPageUrl } from './helpers';
import { TriageModelDetail } from 'src/reducers/triageSettingsReducer/types';

interface TriageModelCardProps {
  openEditDrawer: (modelId: string) => void;
  triageModel: TriageModelDetail;
}

const TriageModelCard: React.FC<
  React.PropsWithChildren<TriageModelCardProps>
> = ({ openEditDrawer, triageModel }) => {
  const {
    description,
    display_name,
    icon,
    is_self_serve,
    model_id,
    model_paid_plan,
    name,
    status,
    versioned_model_status,
  } = triageModel;
  const isSelfServe = Boolean(is_self_serve);
  const isActive = status === 'enabled';
  const showPremiumBadge = getIsModelPremium(model_paid_plan);
  const destination =
    isSelfServe && model_id
      ? getTriageModelsPageUrl(model_id)
      : `/triage-settings/${name}`;
  const isAutoGen = checkModelIsAutogen(versioned_model_status);

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    if (isAutoGen) {
      event.preventDefault();
      openEditDrawer(model_id);
    }
  };

  return (
    <CardContainer
      data-testid='triage-container'
      id={`configure-${name}`}
      key={name}
      onClick={handleClick}
      to={destination}
    >
      <CardContentContainer>
        <CardContentSubContainer>
          <CardIconContainer>
            <Icon size={36} src={icon} />
            {isSelfServe && <Badge label='Custom' variant='macro' />}
            {!isSelfServe && showPremiumBadge && (
              <Badge label='Premium' variant='macro' />
            )}
          </CardIconContainer>
          <CardTitleText>{display_name}</CardTitleText>
          <CardContentText>{description}</CardContentText>
        </CardContentSubContainer>
      </CardContentContainer>
      <CardFooter isActive={isActive} status={versioned_model_status} />
    </CardContainer>
  );
};

const CardContainer = styled(Link)`
  border-style: solid;
  border-width: 1px;
  border-color: ${theme.palette.colors.slate[200]};
  border-radius: 6.66px;
  cursor: pointer;
  &:hover {
    box-shadow: ${elevations.z2};
  }
  text-decoration: none;
`;

const CardContentContainer = styled('div')`
  height: 184px;
`;

const CardContentSubContainer = styled('div')`
  padding: 28px 24px 42px 24px;
`;

const CardIconContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const CardTitleText = styled('p')`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${theme.palette.colors.black};
  margin: 0px;
`;

const CardContentText = styled('p')`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin: 0px;
  padding-top: 5px;
  overflow: hidden;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${theme.palette.colors.grey[700]};
`;

const Icon = styled('img')<{
  size: number;
}>`
  height: ${props => props.size}px;
  width: ${props => props.size}px;
`;

export default TriageModelCard;
