import moment from 'moment';
import { IconDiscountCheck } from '@tabler/icons-react';

import articleIcon from 'src/assets/images/article-icon.svg';
import discoverNewIconSmall from 'src/assets/images/discover-new-icon-small.svg';
import { TopicTimeFilter } from 'src/components/app/types';
import {
  MetricMultiFilterValue,
  MetricUnit,
} from 'src/components/dashboard-pages/discover-all-topics-page/types';
import DiscoverAutomatedBadge from 'src/components/discover-automated-badge/DiscoverAutomatedBadge';
import FilterButtonIcon from 'src/components/discover-filter-button-icon/FilterButtonIcon';
import {
  DiscoverReportedTicketReason,
  DiscoverTopicAggregatedMetricType,
  DiscoverTopicSentimentValue,
  DiscoverTopicTicketsMetricType,
} from 'src/reducers/discoverReducer/types';
import {
  convertNumberOfDaysToTimeStamp,
  getCurrentMonthRange,
  getPreviousMonthRange,
} from 'src/utils/timeRangeHelpers';

export const initialMetricFilterDropdownValue = 'volume';
export const initialMetricFiltersDropdownValue: MetricMultiFilterValue[] = [
  'volume.value',
  'volume.value_changed',
];
export const initialTimeFilterDropdownValue = 'last_30_days';
export const initialPeriodicalDropdownValue = 'daily';
export const initialSortDropdownValue = 'newest';
export const initialSearchTextValue = '';
export const initialGroupFilters = [];

export const timeFilterOptions = [
  { label: 'Last 7 days', value: 'last_7_days' },
  { label: 'Last 14 days', value: 'last_14_days' },
  { label: 'Last 30 days', value: 'last_30_days' },
  { label: 'Last 3 months', value: 'last_3_months' },
  { label: 'This month', value: 'this_month' },
  { label: 'Previous month', value: 'past_month' },
] as const;

export const datePickerRangeOptions: TopicTimeFilter[] = [
  {
    isCustom: false,
    key: 'last_7_days',
    label: 'Last 7 days',
    value: {
      from: new Date(Date.now() - convertNumberOfDaysToTimeStamp(7)),
      to: new Date(),
    },
  },
  {
    isCustom: false,
    key: 'last_14_days',
    label: 'Last 14 days',
    value: {
      from: new Date(Date.now() - convertNumberOfDaysToTimeStamp(14)),
      to: new Date(),
    },
  },
  {
    isCustom: false,
    key: 'last_30_days',
    label: 'Last 30 days',
    value: {
      from: new Date(Date.now() - convertNumberOfDaysToTimeStamp(30)),
      to: new Date(),
    },
  },
  {
    isCustom: false,
    key: 'last_3_months',
    label: 'Last 3 months',
    value: {
      from: new Date(moment().subtract(3, 'months').format('YYYY/MM/DD hh:mm')),
      to: new Date(),
    },
  },
  {
    isCustom: false,
    key: 'this_month',
    label: 'This month',
    value: getCurrentMonthRange(),
  },
  {
    isCustom: false,
    key: 'past_month',
    label: 'Previous month',
    value: getPreviousMonthRange(),
  },
];

export const initialMetricFiltersDropdownValueV2: Array<DiscoverTopicAggregatedMetricType> =
  ['volume', 'first_resolution_time'];

export const initialColumnVisibility = {
  first_resolution_time: true,
  volume: true,
};

export const initialShowPercentChanged = true;

export const initialDateRangeValue = datePickerRangeOptions[2].value;

export const metricFilterOptions: {
  label: string;
  value: DiscoverTopicAggregatedMetricType;
}[] = [
  { label: 'Discover categorized tickets', value: 'volume' },
  { label: 'First resolution time', value: 'first_resolution_time' },
  { label: 'Full resolution time', value: 'full_resolution_time' },
  { label: 'Agent replies', value: 'number_of_agent_replies' },
  { label: 'Reply time', value: 'reply_time' },
  {
    label: 'First contact resolution',
    value: 'first_contact_resolution',
  },
  { label: 'Sentiment', value: 'sentiment' },
];

export const ticketMetricFilterOptions: {
  label: string;
  value: DiscoverTopicTicketsMetricType;
}[] = [
  { label: 'Resolved on first contact?', value: 'resolved_on_first_contact' },
  { label: 'First resolution time', value: 'first_resolution_time' },
  { label: 'Full resolution time', value: 'full_resolution_time' },
  { label: 'Agent replies', value: 'number_of_agent_replies' },
  { label: 'Average reply time', value: 'reply_time' },
  { label: 'Sentiment', value: 'sentiment' },
];

export const sortFilterOptions = [
  { label: 'Date created (newest)', value: 'newest' },
  { label: 'Date created (oldest)', value: 'oldest' },
  { label: 'Highest', value: 'highest' },
  { label: 'Lowest', value: 'lowest' },
] as const;

export const periodicalFilterOptions = [
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
] as const;

export const groupedMultiSelectFilterOptions = [
  {
    label: 'Recommended',
    optionStartAdornment: <IconDiscountCheck size={20} />,
    value: 'recommended',
  },
  {
    label: 'Automated',
    optionStartAdornment: <DiscoverAutomatedBadge size={20} />,
    value: 'automated',
  },
  {
    label: 'New topics',
    optionStartAdornment: <img alt='New topic' src={discoverNewIconSmall} />,
    value: 'new-topics',
  },
  {
    label: 'Hidden',
    optionStartAdornment: <FilterButtonIcon dropdownType='show-all' />,
    value: 'hidden',
  },
  {
    label: 'Article generated',
    optionStartAdornment: (
      <img alt='Article' src={articleIcon} style={{ marginRight: '1px' }} />
    ),
    value: 'article-generated',
  },
];

export const metricUnits: MetricUnit[] = [
  'value',
  'value_changed',
  'value_deviance',
];

export const normalizedSentimentMetricToSentimentMetricMap: Record<
  MetricUnit,
  keyof Pick<
    DiscoverTopicSentimentValue,
    'starting_sentiment' | 'ending_sentiment' | 'negative_change'
  >
> = {
  value: 'starting_sentiment',
  value_changed: 'ending_sentiment',
  value_deviance: 'negative_change',
};

export const sentimentTypes = ['negative', 'neutral', 'positive'] as const;

export const MAX_TICKET_BODY_CHARS = 650; // one line = 130 * 5 lines

export const TICKETS_PER_PAGE = 10;
export const ARTICLES_PER_PAGE = 10;

export const WATCH_ICON_SIZE = 20;
export const WATCH_ICON_STROKE = 2;

export const DISCOVER_CARD_MIN_WIDTH = 425;

export const MAX_TOPIC_NAME_LENGTH = 200;

export const DEBOUNCED_TRACKING_EVENT_TIMEOUT = 1000; // 1 second
export const DEBOUNCED_AUTOMATION_TRACKING_EVENT_TIMEOUT = 30000; // 30 seconds

export const ERROR_DATABASE_IS_UNAVAILABLE = 'DATABASE_IS_UNAVAILABLE';

export const DATE_RANGE_SEPARATOR = '_';

// histogram
export const NUMBER_OF_BUCKETS = 15;
export const RANGE_SIZE_MULTIPLIER = 50;
export const TRACK_HEIGHT = 1;

export const DISCOVER_SHARED_PARAM_NAMES = {
  GROUP_FILTERS: 'group-filters',
  GROUP_TOPIC: 'is-grouped',
  METRIC_FILTER: 'metric',
  METRIC_FILTERS: 'metrics',
  METRIC_FILTERS_V2: 'met',
  NAME: 'name',
  PERIODICAL_FILTER: 'period',
  QUERY_FILTER: 'query-filter',
  SEARCH: 'search',
  SEARCH_TYPE: 'topic-search-type',
  SELECTED_ARTICLE_ID: 'selected-article-id',
  SHOW_PERCENT_CHANGED: 'show-percent-changed',
  SOURCE: 'source',
  TAB: 'tab',
  TIME_FILTER: 'time-range',
  TOPIC_TICKET_SEARCH: 'topic-ticket-search',
} as const;

export const SUPPORT_EMAIL = 'support@forethought.ai';

export const reportTicketReasonOptions: {
  label: string;
  value: DiscoverReportedTicketReason;
}[] = [
  { label: 'Miscategorized', value: 'miscategorized' },
  { label: 'Template ticket', value: 'template_ticket' },
  { label: 'Other', value: 'other' },
  { label: 'Spam', value: 'spam' },
];

export const PATCHING_TOPIC_TABLE_ROW_CLASS_NAME = 'patching-topic';

export const DISCOVER_SEARCH_PARAMS_LOCAL_STORAGE_KEY = 'discoverSearchParams';

export const METRIC_FILTERS_SEPARATOR = '.' as const;

export const UNFORMATTED_LABEL_SEPARATOR = '{}' as const;

export const initialSearchType = 'topic';
