import React, { useState } from 'react';
import Box from '@mui/material/Box';

import {
  Button,
  Dialog,
  TextField,
} from '@forethought-technologies/forethought-elements';
import { TOAST_TIMEOUT } from '../triage-config-detail-page/helpers';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';
import { useUpdateModelInfoMutation } from 'src/services/triage/triageApi';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

interface RenameVersionDialogProps {
  isOpen: boolean;
  model?: VersionedTriageModel;
  onClose: () => void;
  onSuccess: () => void;
}

const RenameVersionDialog = ({
  isOpen,
  model,
  onClose,
  onSuccess,
}: RenameVersionDialogProps) => {
  const [value, setValue] = useState(model?.version_name || '');
  const [mutate, { isLoading }] = useUpdateModelInfoMutation();
  const dispatch = useAppDispatch();

  const handleChange = (versionName: string) => {
    setValue(versionName);
  };

  const handleRename = () => {
    if (!model) {
      return;
    }
    mutate({
      modelId: model.model_id,
      versionId: model.version_id,
      versionName: value,
    })
      .unwrap()
      .then(onSuccess)
      .catch(() =>
        dispatch(
          setGlobalToastOptions({
            autoHideDuration: TOAST_TIMEOUT,
            title: 'Rename version request failed',
            variant: 'danger',
          }),
        ),
      );
  };

  return (
    <Dialog
      footer={
        <>
          <Button disabled={isLoading} onClick={onClose} variant='ghost'>
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            isLoading={isLoading}
            onClick={handleRename}
            variant='main'
          >
            Rename
          </Button>
        </>
      }
      hideCloseButton
      onClose={onClose}
      open={isOpen}
      title='Rename Version'
      variant='sm'
    >
      <RenameVersionForm
        onChange={handleChange}
        versionName={model?.version_name}
      />
    </Dialog>
  );
};

const RenameVersionForm = ({
  onChange,
  versionName,
}: {
  onChange: (versionName: string) => void;
  versionName?: string;
}) => {
  const [value, setValue] = useState(versionName || '');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value);
  };

  return (
    <Box width='400px'>
      <TextField
        aria-label='rename input field'
        label='Version name'
        onBlur={() => onChange(value)}
        onChange={handleChange}
        value={value}
      />
    </Box>
  );
};

export default RenameVersionDialog;
