import { ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IconTag } from '@tabler/icons-react';

import { useGetSelectedBrand } from '../hooks';
import ExpandedWidget from './ExpandedWidget';
import MinimizedWidget from './MinimizedWidget';
import { useIsFeatureFlagEnabled } from 'src/hooks/hooks';
import { Brand } from 'src/types/brandTypes';

type StaticSolveWidgetType = 'expanded' | 'minimized';

interface StaticSolveWidgetProps {
  children?: ReactNode;
  type?: StaticSolveWidgetType;
}

const StaticSolveWidget = ({
  children,
  type = 'expanded',
}: StaticSolveWidgetProps) => {
  if (type === 'minimized') {
    return (
      <StaticSolveWidgetContainer>
        <MinimizedWidget>{children}</MinimizedWidget>
      </StaticSolveWidgetContainer>
    );
  }

  return (
    <StaticSolveWidgetContainer>
      <ExpandedWidget>{children}</ExpandedWidget>
    </StaticSolveWidgetContainer>
  );
};

const StaticSolveWidgetContainer = ({ children }: { children: ReactNode }) => {
  const isMultibrandEnabled = useIsFeatureFlagEnabled('multibrand_enabled');
  const selectedBrand = useGetSelectedBrand();
  const [params] = useSearchParams();

  if (
    selectedBrand &&
    isMultibrandEnabled &&
    params.get('channel') === 'widget'
  ) {
    return (
      <>
        <BrandTagLabels brand={selectedBrand} />
        {children}
      </>
    );
  }

  return <>{children}</>;
};

const BrandTagLabels = ({ brand }: { brand: Brand }) => {
  return (
    <Box
      alignItems='center'
      alignSelf='center'
      display='flex'
      gap={0.75}
      overflow='hidden'
      py={3.5}
      width='588px'
    >
      <Box alignItems='center' display='flex' height='26px'>
        <Typography noWrap variant='font14Bold'>
          &lsquo;{brand.brand_name}&rsquo;
          <Typography
            color={theme => theme.palette.colors.grey[500]}
            noWrap
            variant='font14Bold'
          >
            {' '}
            for
          </Typography>
        </Typography>
      </Box>
      {brand.tags.map(tag => (
        <BrandTag key={tag}>
          <Typography
            color={theme => theme.palette.grey[800]}
            noWrap
            variant='font12Medium'
          >
            {tag}
          </Typography>
        </BrandTag>
      ))}
      {!brand.tags.length && (
        <Typography
          color={theme => theme.palette.colors.red[500]}
          sx={{ textDecoration: 'underline' }}
          variant='font14Bold'
        >
          Tag missing
        </Typography>
      )}
    </Box>
  );
};

const BrandTag = ({ children }: { children?: ReactNode }) => {
  const { palette } = useTheme();
  return (
    <Box
      alignItems='center'
      bgcolor={palette.colors.white}
      borderRadius='4px'
      display='flex'
      gap='2px'
      px={1}
      py={0.5}
    >
      <IconTag color={palette.colors.blue[500]} size={16} />
      {children}
    </Box>
  );
};

export default StaticSolveWidget;
