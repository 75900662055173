import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Box, styled, useTheme } from '@mui/material';

import {
  Button,
  Checkbox,
  Toggle,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useGetSelectedBrand } from '../hooks';
import { useDebouncedSubmitConfigForRTE } from '../hooks/useDebouncedSubmitConfig';
import { useGetRemirrorContextVariables } from '../hooks/useGetRemirrorContextVariables';
import { LaunchButton } from '../launch-button/LaunchButton';
import { RTEContainer } from '../styledComponents';
import { RichTextEditor } from 'src/components/rich-text-editor/RichTextEditor';
import { EditorRef } from 'src/components/rich-text-editor/types';
import {
  useIsFeatureFlagEnabled,
  useSolveConfigTrackingEventAction,
} from 'src/hooks/hooks';
import { selectIsSolveLiteEnabled } from 'src/reducers/userReducer/userReducer';
import {
  confirmAssetUploadComplete,
  createAssetUploadLinkAPI,
  deleteAssetAPI,
} from 'src/services/workflow-builder/workflowBuilderApi';
import {
  selectIsValidationFailing,
  selectWidgetConfiguration,
  setProactiveChatGreeting,
  setStartUploadingImage,
  setWidgetDataByKey,
  showWarningModal,
  updateWidgetConfiguration,
} from 'src/slices/solve-config/solveConfigSlice';
import { useAppDispatch } from 'src/store/hooks';
import {
  Routes,
  SolveConfigTrackingEventTypes,
  SolveConfigurationTypes,
} from 'src/utils/enums';

const PromptTabContent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const dispatchTrackingAction = useSolveConfigTrackingEventAction();

  const widgetConfiguration = useSelector(selectWidgetConfiguration);
  const isValidationFailing = useSelector(selectIsValidationFailing);
  const isSolveLiteEnabled = useSelector(selectIsSolveLiteEnabled);
  const editorRef = useRef<EditorRef>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isDeletingImage, setisDeletingImage] = useState<boolean>(false);

  const {
    display_proactive_chat,
    display_proactive_chat_intents,
    help_button_image,
    manually_selected_top_intents,
    num_top_intents,
    proactive_chat_greeting,
  } = widgetConfiguration;

  const embedHref = `${location.pathname}?tab=theme`;

  const isPromptEmpty = proactive_chat_greeting?.trim() === '';
  const remirrorContextVariables = useGetRemirrorContextVariables();
  const debouncedSubmitConfig = useDebouncedSubmitConfigForRTE();
  const isMultibrandEnabled = useIsFeatureFlagEnabled('multibrand_enabled');
  const selectedBrand = useGetSelectedBrand();

  const onChangeToggleField = (
    key: 'display_proactive_chat' | 'display_proactive_chat_intents',
  ) => {
    if (isValidationFailing) {
      dispatch(showWarningModal());
      return;
    }

    const value = !widgetConfiguration[key];
    dispatch(setWidgetDataByKey({ key, value }));

    const updatedWidgetConfiguration = {
      ...widgetConfiguration,
      [key]: value,
    };

    debouncedSubmitConfig(updatedWidgetConfiguration, key, value.toString());
  };

  const handleProactiveChatGreetingChange = (html: string) => {
    dispatch(setProactiveChatGreeting(html));
    const updatedWidgetConfiguration = {
      ...widgetConfiguration,
      proactive_chat_greeting: html,
    };
    debouncedSubmitConfig(
      updatedWidgetConfiguration,
      'proactive_chat_greeting',
      html,
    );
  };

  const handleLaunchButtonImageChange = async () => {
    // show loading state in header properly
    dispatch(setStartUploadingImage());
    const file = fileInputRef.current?.files?.[0];

    if (file) {
      const fileExtension = file.name.split('.').slice(-1).pop();

      const asset = await createAssetUploadLinkAPI({
        content_type: file.type,
        file_extension: fileExtension ?? '',
      });

      fetch(asset.url, {
        body: file,
        headers: {
          'Content-Type': file.type,
        },
        method: 'PUT',
      })
        .then(async () => {
          await confirmAssetUploadComplete(asset.asset_id);
          /**
           * *Note* Eventually if we have the ability to revert to default launch button
           * use_help_button_image_as_icon would be false
           */
          const updatedWidgetConfiguration = {
            ...widgetConfiguration,
            help_button_image: asset.file_name,
            use_help_button_image_as_icon: true,
          };

          dispatch(
            updateWidgetConfiguration({
              brandId: selectedBrand?.brand_id,
              isMultibrandEnabled,
              widgetConfiguration: updatedWidgetConfiguration,
            }),
          );
          dispatchTrackingAction(
            SolveConfigTrackingEventTypes.CONFIGURATION_UPDATE,
            {
              configuration_type: SolveConfigurationTypes.WIDGET,
              type: 'help_button_image',
              value: asset.file_name,
            },
          );
        })
        .catch(console.error);
    }
  };

  const handleImageDelete = async () => {
    if (isDeletingImage) {
      return;
    }

    setisDeletingImage(true);
    try {
      await deleteAssetAPI(help_button_image || '');
      const updatedWidgetConfiguration = {
        ...widgetConfiguration,
        help_button_image: null,
        use_help_button_image_as_icon: false,
      };
      dispatch(
        updateWidgetConfiguration({
          brandId: selectedBrand?.brand_id,
          isMultibrandEnabled,
          widgetConfiguration: updatedWidgetConfiguration,
        }),
      );
    } catch (error) {
      console.error('Failed to delete image', error);
    } finally {
      setisDeletingImage(false);
    }
  };

  const handleEmbedNavigate = () => {
    navigate({
      pathname: isSolveLiteEnabled
        ? Routes.SOLVE_LITE
        : Routes.SOLVE_CONFIGURATION,
      search: '?tab=theme',
    });
  };

  return (
    <Box>
      <ProactivePromptTitleContainer>
        <Typography variant='font16Bold'>Proactive prompt</Typography>
      </ProactivePromptTitleContainer>
      <ToggleContainer>
        <Toggle
          checked={display_proactive_chat}
          label='Display proactive prompt to open chat window'
          onChange={() => onChangeToggleField('display_proactive_chat')}
        />
      </ToggleContainer>
      {display_proactive_chat && (
        <div>
          <RTEContainer hasError={isPromptEmpty}>
            <RichTextEditor
              contextVariables={remirrorContextVariables}
              editorRef={editorRef}
              initialContent={proactive_chat_greeting || ''}
              onChange={handleProactiveChatGreetingChange}
            />
          </RTEContainer>
          {isPromptEmpty && (
            <Typography
              color={theme.palette.colors.red[500]}
              variant='font14Medium'
            >
              Enter a Prompt message. Proactive prompt cannot be empty.
            </Typography>
          )}
          {!isSolveLiteEnabled && (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Checkbox
                checked={display_proactive_chat_intents}
                label={
                  <Typography variant='font14'>
                    Display Intent options
                  </Typography>
                }
                onChange={() =>
                  onChangeToggleField('display_proactive_chat_intents')
                }
              />
              {display_proactive_chat_intents &&
                manually_selected_top_intents?.length === 0 &&
                num_top_intents === 0 && (
                  <Typography
                    color={theme.palette.colors.grey[600]}
                    variant='font12'
                  >
                    You haven&apos;t selected intent options in the{' '}
                    <Link
                      onClick={handleEmbedNavigate}
                      style={{
                        cursor: 'pointer',
                        lineHeight: '20px',
                        textDecoration: 'underline',
                      }}
                      to={embedHref}
                    >
                      Theme section
                    </Link>
                  </Typography>
                )}
            </Box>
          )}
        </div>
      )}
      <LaunchButtonTitleContainer>
        <Typography variant='font16Bold'>Launch button</Typography>
      </LaunchButtonTitleContainer>
      <LaunchButtonContainer>
        <LaunchButton />
      </LaunchButtonContainer>
      <Box display='flex' justifyContent='center'>
        <Button onClick={() => fileInputRef.current?.click()} variant='ghost'>
          Change image
        </Button>
        {help_button_image && (
          <Button
            disabled={isDeletingImage}
            onClick={handleImageDelete}
            variant='secondary'
          >
            {isDeletingImage ? 'Resetting...' : 'Reset to default'}
          </Button>
        )}
        <input
          accept='image/*'
          hidden
          onChange={handleLaunchButtonImageChange}
          ref={fileInputRef}
          type='file'
        />
      </Box>
    </Box>
  );
};

export default PromptTabContent;

const ProactivePromptTitleContainer = styled('div')`
  margin-bottom: 18px;
`;

const LaunchButtonTitleContainer = styled('div')`
  margin-top: 42px;
  margin-bottom: 13px;
`;

const ToggleContainer = styled('div')`
  margin-bottom: 20px;
`;

const LaunchButtonContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  border: 1px solid ${props => props.theme.palette.colors.grey[300]};
  border-radius: 6px;
  padding: 20px;
`;
