import { SolveConfigContentContainer, ToolbarContainer } from '..';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { VerticalTabBar } from '@forethought-technologies/forethought-elements';
import ApiKeyContent from '../api/api-key-tab-content/ApiKeyContent';
import { useGetApiTabIndex } from '../hooks/useGetTabIndex';
import StaticSolveWidget from '../static-solve-widget/StaticSolveWidget';
import { Channel } from '../types';
import { getSolveApiConfigTabs } from '../utils';
import { useSolveConfigTrackingEventAction } from 'src/hooks/hooks';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  selectIsValidationFailing,
  showWarningModal,
} from 'src/slices/solve-config/solveConfigSlice';
import { useAppDispatch } from 'src/store/hooks';
import { Routes, SolveConfigTrackingEventTypes } from 'src/utils/enums';

export default function ApiTab({ channel }: { channel: Channel }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const query = params.get('tab') ?? '';

  const apiTabIndex = useGetApiTabIndex();
  const isValidationFailing = useSelector(selectIsValidationFailing);

  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const dispatchTrackingAction = useSolveConfigTrackingEventAction();

  const isSolveKnowledgeConfigRevamp = featureFlags.includes(
    'knowledge_article_workflow',
  );

  const apiTabsFiltered = getSolveApiConfigTabs({
    isSolveKnowledgeConfigRevamp,
  });

  const handleApiTabOnClick = (index: number) => {
    // if validation is failing, we show the warning modal and exit the function
    if (isValidationFailing) {
      dispatch(showWarningModal());
      return;
    }
    const tab = apiTabsFiltered[index].id;
    navigate(
      {
        pathname: Routes.SOLVE_CONFIGURATION,
        search: `?channel=${channel}&tab=${tab}`,
      },
      { replace: true },
    );
    dispatchTrackingAction(SolveConfigTrackingEventTypes.TAB_NAVIGATION, {
      tab: tab,
    });
  };

  const handleRenderApiConfigurationBasedOnTabType = () => {
    switch (query) {
      case 'api_key':
        return <ApiKeyContent />;
      default:
        return <ApiKeyContent />;
    }
  };

  const isFullWidthContent = query === 'more';

  return (
    <>
      {!isFullWidthContent && (
        <SolveConfigContentContainer>
          <StaticSolveWidget type='minimized'>
            {handleRenderApiConfigurationBasedOnTabType()}
          </StaticSolveWidget>
        </SolveConfigContentContainer>
      )}
      <ToolbarContainer
        isFullScreen={isFullWidthContent}
        isTabSelected={typeof apiTabIndex === 'number'}
      >
        <VerticalTabBar
          setTabIndex={handleApiTabOnClick}
          tabBodyWidth={520}
          tabIndex={apiTabIndex}
          tabs={apiTabsFiltered}
        />
      </ToolbarContainer>
    </>
  );
}
