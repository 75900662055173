import { useCallback, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IconTrash } from '@tabler/icons-react';

import {
  Button,
  Checkbox,
  Dialog,
  IconButton,
  SelectDropdown,
  TextField,
} from '@forethought-technologies/forethought-elements';
import { NONE_RTE_INPUT_DEBOUNCE_DELAY } from '../constants';
import { useGetSelectedBrand } from '../hooks';
import debounce from 'lodash/fp/debounce';
import {
  useGetBrandsQuery,
  useUpdateBrandMutation,
  useUpdateBrandTagsMutation,
} from 'src/services/brand/brandApi';
import {
  useCreateWorkflowTagsMutation,
  useDeleteWorkflowTagMutation,
  useGetWorkflowTagsV2Query,
} from 'src/services/workflow-builder-metrics';
import { BaseBrand } from 'src/types/brandTypes';

const useBrandConfig = () => {
  const selectedBrand = useGetSelectedBrand() ?? {
    brand_id: '',
    brand_name: '',
    is_default: false,
    tags: [],
  };
  const [brandName, setBrandName] = useState(selectedBrand.brand_name);
  const [isDefault, setIsDefault] = useState(selectedBrand.is_default);
  const [tags, setTags] = useState(selectedBrand.tags);

  const [updateBrand] = useUpdateBrandMutation();
  const [updateBrandTagsMutation] = useUpdateBrandTagsMutation();

  const debouncedUpdateBrand = useMemo(
    () =>
      debounce(NONE_RTE_INPUT_DEBOUNCE_DELAY, async (brand: BaseBrand) => {
        updateBrand({ body: brand, brandId: selectedBrand.brand_id });
      }),
    [selectedBrand?.brand_id, updateBrand],
  );

  const debouncedUpdateTags = useMemo(
    () =>
      debounce(NONE_RTE_INPUT_DEBOUNCE_DELAY, async (tags: string[]) => {
        updateBrandTagsMutation({
          body: tags,
          brandId: selectedBrand.brand_id,
        });
      }),
    [selectedBrand.brand_id, updateBrandTagsMutation],
  );

  const updateBrandName = useCallback(
    (brandName: string) => {
      setBrandName(brandName);
      debouncedUpdateBrand({
        brand_name: brandName,
        is_default: selectedBrand.is_default,
      });
    },
    [debouncedUpdateBrand, selectedBrand.is_default],
  );

  const toggleDefaultBrand = useCallback(() => {
    setIsDefault(!isDefault);
    debouncedUpdateBrand({
      brand_name: selectedBrand?.brand_name || '',
      is_default: !selectedBrand?.is_default,
    });
  }, [
    debouncedUpdateBrand,
    isDefault,
    selectedBrand?.brand_name,
    selectedBrand?.is_default,
  ]);

  const updateBrandTags = useCallback(
    (tags: string[]) => {
      setTags(tags);
      debouncedUpdateTags(tags);
    },
    [debouncedUpdateTags],
  );

  return {
    brandId: selectedBrand.brand_id,
    brandName,
    isDefault,
    tags,
    toggleDefaultBrand,
    updateBrandName,
    updateBrandTags,
    updateUiBrandTags: setTags,
  };
};

export const BrandTabContent = () => {
  const selectedBrand = useGetSelectedBrand();
  return (
    <Box display='flex' flexDirection='column' gap={2}>
      <Typography variant='font14'>
        This section lets you create unique widget designs for different{' '}
        <strong>brands or user segments</strong> , giving each a personalized
        look. You can control which users see a widget by applying tags, so only
        those in the tagged segments will see it. This ensures targeted
        visibility and a tailored experience for your audience.
      </Typography>
      <BrandConfiguration key={selectedBrand?.brand_id || ''} />
    </Box>
  );
};

const BrandConfiguration = () => {
  const brand = useGetSelectedBrand();
  const { brandName, isDefault, toggleDefaultBrand, updateBrandName } =
    useBrandConfig();

  if (!brand) {
    return null;
  }

  return (
    <>
      <Box display='flex' flexDirection='column' gap={0.5}>
        <Typography variant='font16Bold'>Brand name</Typography>
        <Typography
          color={theme => theme.palette.colors.grey[600]}
          variant='font12'
        >
          The name serves as a label for accessing and filtering workflows and
          insights.
        </Typography>
        <TextField
          aria-label='Brand name'
          description='Keep it under 50 characters'
          onChange={e => updateBrandName(e.target.value)}
          value={brandName}
        />
      </Box>
      <WorkflowTagSelect />
      <Box display='flex' flexDirection='column'>
        <Checkbox
          checked={isDefault}
          disabled={isDefault && brand?.is_default}
          label='Set as default'
          onChange={toggleDefaultBrand}
        />
        <Typography
          color={theme => theme.palette.colors.grey[600]}
          ml='31px'
          mt='-8px'
          variant='font12'
        >
          Enable this brand by default if no specific tags are assigned or found
          in the{' '}
          <Typography
            color={theme => theme.palette.primary.main}
            variant='font12'
          >
            embedded script.
          </Typography>
        </Typography>
      </Box>
    </>
  );
};

const WorkflowTagSelect = () => {
  const { data: brands = [] } = useGetBrandsQuery();
  const { data = [] } = useGetWorkflowTagsV2Query();
  const { brandId, tags, updateBrandTags } = useBrandConfig();
  const [tagToDelete, setTagToDelete] = useState<string>('');
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const tagToBrandMap = useMemo(() => {
    const map = new Map<string, string>();
    brands.forEach(brand => {
      brand.tags.forEach(tag => {
        if (brand.brand_id !== brandId) {
          map.set(tag, brand.brand_name);
        }
      });
    });
    return map;
  }, [brandId, brands]);

  return (
    <>
      <Box display='flex' flexDirection='column' gap={0.5}>
        <Typography variant='font16Bold'>Tags</Typography>
        <Typography
          color={theme => theme.palette.colors.grey[600]}
          variant='font12'
        >
          Tag this brand to associate it with specific segments.
        </Typography>
        <SelectDropdown
          addNewButtonText='Create new tag'
          id='brand-tags'
          isMenuSearchable
          multiple
          onAddNewClick={e => {
            e.stopPropagation();
            setIsCreateDialogOpen(true);
          }}
          onChange={e => {
            if (!Array.isArray(e.target.value)) {
              return;
            }

            updateBrandTags(e.target.value);
          }}
          onClose={() => setIsSelectOpen(false)}
          onOpen={() => setIsSelectOpen(true)}
          open={isSelectOpen && !tagToDelete && !isCreateDialogOpen}
          options={data.map(tag => ({
            disabled: tagToBrandMap.has(tag),
            label: tagToBrandMap.has(tag)
              ? `${tag} (in use '${tagToBrandMap.get(tag)}')`
              : tag,
            optionEndAdornment: (
              <IconButton
                aria-label='Delete tag'
                onClick={e => {
                  e.stopPropagation();
                  setTagToDelete(tag);
                }}
                variant='ghost'
              >
                <IconTrash size={20} />
              </IconButton>
            ),
            value: tag,
          }))}
          placeholder='Assign tags'
          value={tags}
        />
      </Box>
      <DeleteTagDialog
        onClose={() => setTagToDelete('')}
        onSuccess={() =>
          updateBrandTags(tags.filter(tag => tag !== tagToDelete))
        }
        tagToDelete={tagToDelete}
      />
      <CreateTagDialog
        isOpen={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
      />
    </>
  );
};

const DeleteTagDialog = ({
  onClose,
  onSuccess,
  tagToDelete,
}: {
  onClose: () => void;
  onSuccess: () => void;
  tagToDelete: string;
}) => {
  const [deleteWorkflowTagMutation, { isLoading }] =
    useDeleteWorkflowTagMutation();

  return (
    <Dialog
      footer={
        <>
          <Button onClick={onClose} variant='ghost'>
            Cancel
          </Button>
          <Button
            isLoading={isLoading}
            onClick={async () => {
              await deleteWorkflowTagMutation({
                tagName: tagToDelete,
              }).unwrap();
              onSuccess();
              onClose();
            }}
            variant='danger'
          >
            Delete
          </Button>
        </>
      }
      hideCloseButton
      onClose={onClose}
      open={Boolean(tagToDelete)}
      title='Delete tag'
    >
      <Box maxWidth='550px'>
        <Typography variant='font14' whiteSpace='pre-wrap'>
          Deleting this tag will remove it from the branded widget and any
          associated workflows, but the widget and workflows themselves will
          remain unchanged.{'\n\n'}Are you sure you want to delete{' '}
          <strong>{tagToDelete}</strong>? This action cannot be undone.
        </Typography>
      </Box>
    </Dialog>
  );
};

const CreateTagDialog = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [newWorkflowTagName, setNewWorkflowTagName] = useState('');
  const [createWorkflowTagsMutation, { isLoading }] =
    useCreateWorkflowTagsMutation();
  const { data = [] } = useGetWorkflowTagsV2Query();
  const doesTagExist = new Set(data).has(newWorkflowTagName);

  return (
    <Dialog
      footer={
        <>
          <Button onClick={onClose} variant='ghost'>
            Cancel
          </Button>
          <Button
            disabled={doesTagExist}
            isLoading={isLoading}
            onClick={async () => {
              await createWorkflowTagsMutation({
                tags: [newWorkflowTagName],
              }).unwrap();
              onClose();
              setNewWorkflowTagName('');
            }}
            variant='main'
          >
            Save
          </Button>
        </>
      }
      hideCloseButton
      onClose={onClose}
      open={isOpen}
      title='Create new tag'
    >
      <Box minWidth='300px' mt='1px'>
        <TextField
          aria-label='Create tag'
          error={doesTagExist && 'Tag already exists'}
          onChange={e => setNewWorkflowTagName(e.target.value)}
          placeholder='Tag name'
          value={newWorkflowTagName}
        />
      </Box>
    </Dialog>
  );
};
