import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { IconPlus, IconSettingsFilled } from '@tabler/icons-react';

import {
  Button,
  FilterButton,
  IconButton,
  SearchBar,
  Tabs,
} from '@forethought-technologies/forethought-elements';
import { templateTypes } from '../constants';
import { useGetImportedOpenApiSchemaActions } from '../hooks';
import ActionTemplateModal from '../template-modal';
import ActionBuilderTable from './action-builder-table';
import ActionDeleteDialog from './action-delete-dialog';
import ActionSettingsModal from './action-settings-modal';
import CollectionsTab from './collections-tab';
import { clearSelectedAction } from 'src/actions/action-builder-actions/actionBuilderActions';
import FilterButtonIcon from 'src/components/discover-filter-button-icon/FilterButtonIcon';
import { useStateParams } from 'src/hooks/hooks';
import { useGetActionBuilderActionsQuery } from 'src/services/action-builder/actionBuilderApi';
import { useAppDispatch } from 'src/store/hooks';
import { replaceActionIdInRoute } from 'src/utils/actionBuilder/helpers';
import { genericSerializeAndDeserialize } from 'src/utils/discover/helpers';

const sortFilterOptions = [
  { label: 'Date created (newest)', value: 'newest' },
  { label: 'Date created (oldest)', value: 'oldest' },
];

const a11yName = 'action-builder';

const a11yTabProps = (index: number) => {
  return {
    'aria-controls': `${a11yName}-tabpanel-${index}`,
    id: `${a11yName}-tab-${index}`,
  };
};

const a11yTabPanelProps = (index: number) => {
  return {
    'aria-labelledby': `${a11yName}-tab-${index}`,
    id: `${a11yName}-tabpanel-${index}`,
  };
};

const tabs = [
  {
    Component: ActionBuilderTable,
    name: 'Available Actions',
    value: 'actions',
  },
  { Component: CollectionsTab, name: 'Collections', value: 'collections' },
  {
    Component: ActionBuilderTable,
    name: 'Imported Actions',
    value: 'imported',
  },
] as const;

const ActionBuilderV2 = () => {
  const [tab, setTab] = useStateParams({
    deserialize: genericSerializeAndDeserialize,
    initialState: 'actions',
    paramsName: 'tab',
    serialize: genericSerializeAndDeserialize,
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { palette } = useTheme();

  const [searchText, setSearchText] = useState('');
  const [dateSortFilter, setDateSortFilter] = useState<'newest' | 'oldest'>(
    'newest',
  );
  const [actionIdToDelete, setActionIdToDelete] = useState('');
  const [shouldShowSettingsModal, setShouldShowSettingsModal] = useState(false);

  const { data } = useGetActionBuilderActionsQuery({});
  const { actions = [] } = data ?? {};

  const importedActions = useGetImportedOpenApiSchemaActions();

  const apiActions = useMemo(() => {
    const actionsToShow = tab === 'imported' ? importedActions : actions;

    return actionsToShow
      .filter(
        ({ action_type: actionType }) =>
          ['api', 'template_api'].includes(actionType) ||
          templateTypes.includes(actionType),
      )
      .filter(({ action_name: actionName }) => {
        const lowerCaseSearchText = searchText.toLowerCase();
        return actionName.toLowerCase().includes(lowerCaseSearchText);
      })
      .sort((a, b) =>
        dateSortFilter === 'newest'
          ? new Date(b.modified_date ?? '').valueOf() -
            new Date(a.modified_date ?? '').valueOf()
          : new Date(a.modified_date ?? '').valueOf() -
            new Date(b.modified_date ?? '').valueOf(),
      );
  }, [tab, importedActions, actions, searchText, dateSortFilter]);

  const filteredTabs = useMemo(() => {
    return tabs
      .filter(
        ({ name }) => importedActions.length > 0 || name !== 'Imported Actions',
      )
      .map(({ name }) => name);
  }, [importedActions.length]);

  const onCloseDialog = () => setActionIdToDelete('');
  const tabIndex = tabs.findIndex(({ value }) => value === tab);

  return (
    <>
      <Box bgcolor={palette.background.default} flex={1}>
        <Box px={4}>
          <Box
            alignItems='center'
            boxShadow={'0px 1px 0px 0px' + palette.colors.slate[200]}
            display='flex'
            justifyContent='space-between'
            my={3}
          >
            <Tabs
              a11yProps={a11yTabProps}
              aria-label='Action builder'
              onChange={(_, value) => setTab(tabs[value]?.value)}
              tabs={filteredTabs}
              typographyVariant='font20'
              value={tabIndex}
            />
            <Box
              display='flex'
              gap={1}
              sx={{
                'button:first-child': {
                  color: palette.colors.grey[700],
                },
              }}
            >
              <IconButton
                aria-label='Settings'
                onClick={() => setShouldShowSettingsModal(true)}
                variant='ghost'
              >
                <IconSettingsFilled />
              </IconButton>
              <Button
                onClick={() => {
                  dispatch(clearSelectedAction());
                  navigate(replaceActionIdInRoute(''));
                }}
                startIcon={<IconPlus />}
                variant='main'
              >
                Create new action
              </Button>
            </Box>
          </Box>
          <Box display='flex' gap={1} mb={4}>
            <Box width='300px'>
              <SearchBar
                onChange={({ target }) => setSearchText(target.value)}
                onClear={() => setSearchText('')}
                placeholder='Search'
                size='small'
                value={searchText}
              />
            </Box>
            {tab === 'actions' && (
              <FilterButton
                aria-label='Sort actions'
                initialValue='newest'
                onChange={value => {
                  setDateSortFilter(value as 'newest' | 'oldest');
                }}
                options={sortFilterOptions}
                startAdornment={<FilterButtonIcon dropdownType='ticket' />}
                value={dateSortFilter}
              />
            )}
          </Box>
          {tabs.map(({ Component, name }, index) => (
            <div
              hidden={tabIndex !== index}
              key={name}
              role='tabpanel'
              {...a11yTabPanelProps(index)}
            >
              {tabIndex === index && (
                <Component
                  actions={apiActions}
                  searchText={searchText}
                  setActionIdToDelete={setActionIdToDelete}
                />
              )}
            </div>
          ))}
        </Box>
      </Box>
      <ActionDeleteDialog
        actionIdToDelete={actionIdToDelete}
        onClose={onCloseDialog}
      />
      <ActionSettingsModal
        isOpen={shouldShowSettingsModal}
        onClose={() => setShouldShowSettingsModal(false)}
      />
      <ActionTemplateModal onFinished={() => setTab('actions')} />
    </>
  );
};

export default ActionBuilderV2;
