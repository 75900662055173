import {
  IconArrowDownToArc,
  IconBrush,
  IconCode,
  IconDots,
  IconFileInfo,
  IconLicense,
  IconMessage2,
  IconMicrophone,
  IconMoodSmileBeam,
  IconSpeakerphone,
  IconUsers,
} from '@tabler/icons-react';

import ApiKeyTabContent from './api/api-key-tab-content/ApiKeyTabContent';
import BannerTabContent from './banner-tab-content/BannerTabContent';
import { BrandTabContent } from './brand-tab-content/BrandTabContent';
import CodeTabContent from './code-tab-content/CodeTabContent';
import CsatQuickFeedbackParentTabContent from './csat-tab-content/CsatQuickFeedbackParentTabContent';
import FallbackTabContent from './fallback-tab-content/FallbackTabContent';
import KnowledgeTabContent from './knowledge-tab-content/KnowledgeTabContent';
import MoreTabContent from './more-content/MoreTabContent';
import PrivacyConsentTabContent from './privacy-consent-tab-content/PrivacyConsentTabContent';
import PromptTabContent from './prompt-tab-content/PromptTabContent';
import { SlackIntegrationTabContent } from './slack/slack-integration-tab-content/SlackIntegrationTabContent';
import ThemeTabContent from './theme-tab-content/ThemeTabContent';
import { ToneTabContent } from './tone-tab-content';
import {
  QuickFeedbackStyleOption,
  SolveApiConfigTab,
  SolveWidgetConfigTab,
  StyleOption,
} from './types';
import bwSmileBad from 'src/assets/images/csat-config-pics/bw-smile-bad.svg';
import bwSmileExcellent from 'src/assets/images/csat-config-pics/bw-smile-excellent.svg';
import bwSmileGood from 'src/assets/images/csat-config-pics/bw-smile-good.svg';
import bwSmileOkay from 'src/assets/images/csat-config-pics/bw-smile-okay.svg';
import bwSmileTerrible from 'src/assets/images/csat-config-pics/bw-smile-terrible.svg';
import emptyStar from 'src/assets/images/csat-config-pics/empty-star.svg';
import smileBad from 'src/assets/images/csat-config-pics/smile-bad.svg';
import smileExcellent from 'src/assets/images/csat-config-pics/smile-excellent.svg';
import smileGood from 'src/assets/images/csat-config-pics/smile-good.svg';
import smileOkay from 'src/assets/images/csat-config-pics/smile-okay.svg';
import smileTerrible from 'src/assets/images/csat-config-pics/smile-terrible.svg';
import star from 'src/assets/images/csat-config-pics/star.svg';
import thumbDown from 'src/assets/images/csat-config-pics/thumb-down.svg';
import thumbUp from 'src/assets/images/csat-config-pics/thumb-up.svg';
import quickFeedbackBwSmileExcellent from 'src/assets/images/quick-feedback-config-pics/bw-smile-excellent.svg';
import quickFeedbackBwSmileExcellentActive from 'src/assets/images/quick-feedback-config-pics/bw-smile-excellent-active.svg';
import quickFeedbackBwSmileTerrible from 'src/assets/images/quick-feedback-config-pics/bw-smile-terrible.svg';
import quickFeedbackBwSmileTerribleActive from 'src/assets/images/quick-feedback-config-pics/bw-smile-terrible-active.svg';
import quickFeedbackHeartExcellent from 'src/assets/images/quick-feedback-config-pics/heart-excellent.svg';
import quickFeedbackHeartExcellentActive from 'src/assets/images/quick-feedback-config-pics/heart-excellent-active.svg';
import quickFeedbackHeartTerrible from 'src/assets/images/quick-feedback-config-pics/heart-terrible.svg';
import quickFeedbackHeartTerribleActive from 'src/assets/images/quick-feedback-config-pics/heart-terrible-active.svg';
import quickFeedbackThumbDown from 'src/assets/images/quick-feedback-config-pics/thumb-down.svg';
import quickFeedbackThumbDownActive from 'src/assets/images/quick-feedback-config-pics/thumb-down-active.svg';
import quickFeedbackThumbUp from 'src/assets/images/quick-feedback-config-pics/thumb-up.svg';
import quickFeedbackThumbUpActive from 'src/assets/images/quick-feedback-config-pics/thumb-up-active.svg';

export const SOLVE_WIDGET_CONFIG_TABS = [
  {
    component: <BrandTabContent />,
    Icon: IconUsers,
    label: 'Brand',
    title: 'Branded widget',
  },
  {
    component: <ThemeTabContent />,
    Icon: IconBrush,
    label: 'Theme',
    title: 'Theme',
  },
  {
    component: <ToneTabContent />,
    Icon: IconMicrophone,
    label: 'Tone',
    title: 'Tone of voice',
  },
  {
    component: <PromptTabContent />,
    Icon: IconMessage2,
    label: 'Prompt',
    title: 'Prompt',
  },
  {
    component: <KnowledgeTabContent />,
    Icon: IconFileInfo,
    label: 'Knowledge',
    title: 'Knowledge base',
  },
  {
    component: <FallbackTabContent />,
    Icon: IconArrowDownToArc,
    label: 'Fallback',
    title: 'Fallback',
  },
  {
    component: <CsatQuickFeedbackParentTabContent />,
    Icon: IconMoodSmileBeam,
    label: 'CSAT',
    title: 'CSAT',
  },
  {
    component: <PrivacyConsentTabContent />,
    Icon: IconLicense,
    label: 'Privacy',
    title: 'Privacy and consent',
  },
  {
    component: <CodeTabContent />,
    Icon: IconCode,
    label: 'Embed',
    title: 'Embed',
  },
  {
    component: <BannerTabContent />,
    Icon: IconSpeakerphone,
    label: 'Banner',
    title: 'Banner',
  },
  {
    component: <MoreTabContent />,
    Icon: IconDots,
    isFullWidth: true,
    label: 'More',
  },
] as const satisfies Readonly<SolveWidgetConfigTab[]>;

export const SOLVE_API_CONFIG_TABS = [
  {
    component: <ApiKeyTabContent />,
    Icon: IconCode,
    id: 'api_key',
    label: 'API key',
    title: 'API key',
  },
] as const satisfies Readonly<SolveApiConfigTab[]>;

export const SOLVE_SLACK_CONFIG_TABS = [
  {
    component: <SlackIntegrationTabContent />,
    Icon: IconCode,
    id: 'integrate',
    label: 'Integrate',
    title: 'Integrate',
  },
];

export const TAG_SELECTION_TYPE = 'tag_selection_enabled';
export const FREE_FORM_QUERY_TYPE = 'free_form_intent_detection_enabled';

export const KNOWLEDGE_RETRIEVAL_INTENT_DEF_ID = 'Knowledge_Article';
export const FALLBACK_INTENT_DEF_ID = 'Fallback';

export type INTENT_OPTION_TYPES = 'no_display' | 'auto' | 'manual' | '';

export const INTENT_OPTIONS = [
  {
    label: 'Do not display intents as selectable choices',
    value: 'no_display',
  },
  {
    label: '# of auto listings ranked by 30-day inquiry volume',
    value: 'auto',
  },
  { label: 'Select intent options manually', value: 'manual' },
];

export const PLACEHOLDER_INTENTS = [
  'Example intent 01',
  'Example intent 02',
  'Example intent 03',
];

export const INTENT_OPTION_MAXIMUM = 11;
export const INTENT_OPTION_MINIMUM = 4;

export const REQUEST_FORM_URL =
  'https://support.forethought.ai/hc/en-us/requests/new';

export const TABS_WITH_MINIMIZED_WIDGET = ['prompt', 'code'];

export const NON_RTE_LOADING_STATE_DEBOUNCE_DELAY = 1200;
// Noticed slight flickering if user types quickly,
// slight increase in delay stopped this issue
export const RTE_LOADING_STATE_DEBOUNCE_DELAY = 3100;

export const RTE_INPUT_DEBOUNCE_DELAY = 2900;
export const NONE_RTE_INPUT_DEBOUNCE_DELAY = 1000;

export const CUSTOM_PUBLIC_CONNECTOR_TYPE = 'public';

export const ALERT_REPLACE_TITLE = 'Replace and connect';
export const ALERT_PROGRESS_TITLE = 'Your bot is being built!';
export const ALERT_SUCCESS_TITLE = 'Success!';
export const ALERT_ERROR_TITLE = 'Failed to connect Knowledge base';
export const ALERT_REPLACE_MESSAGE =
  'Clicking this will replace the current knowledge base source with the selected one.';

export const ALERT_PROGRESS_MESSAGE =
  'SupportGPT is creating a personalized support bot for you using AI technology and your help center data. Please allow up to 10 minutes for the knowledge base to be connected.';
export const ALERT_SUCCESS_MESSAGE =
  "Your knowledge base is now connected to your support bot, and it's ready to provide helpful responses to your customers.";
export const ALERT_ERROR_MESSAGE =
  "We're sorry, but it seems there's an issue with connecting your knowledge base to your support bot. Please try again later or contact our support team for assistance.";

export const ADD_CUSTOM_URL_BUTTON_TEXT = '+ Add a custom URL to connect';
export const CHAT_HEADER_PLACEHOLDER = 'Enter support team name';

export const GREETING_LINE_PLACEHOLDER = 'Craft your greeting message';

// Privacy consent config related consts
export const PRIVACY_POLICY_PLACEHOLDER = 'See our Privacy and Cookies Policy.';
export const PROMPT_HEADER_PLACEHOLDER = 'Privacy policy header';
export const CALL_TO_ACTION_LABEL_PLACEHOLDER = 'Accept Terms';
export const FIVE_MB_IN_BYTES = 5 * 1000000;
export const DEFAULT_BANNER_IMAGE =
  'https://d3tnn7lar6ozas.cloudfront.net/e231ef9f-bc0b-466b-b3a1-87614d720ea3.png';

// CSAT config related consts
export const csatOneToFiveOptions: StyleOption[] = [
  {
    icon: star,
    icons: [emptyStar, emptyStar, emptyStar, emptyStar, emptyStar],
    label: 'Stars',
    selectedIcon: star,
    value: 'star',
  },
  {
    icon: smileExcellent,
    icons: [smileTerrible, smileBad, smileOkay, smileGood, smileExcellent],
    label: 'Smiles',
    value: 'smile',
  },
  {
    icon: bwSmileExcellent,
    icons: [
      bwSmileTerrible,
      bwSmileBad,
      bwSmileOkay,
      bwSmileGood,
      bwSmileExcellent,
    ],
    label: 'BW smiles',
    value: 'bw_smile',
  },
];

export const csatOneToTwoOptions: StyleOption[] = [
  {
    icon: thumbUp,
    icons: [thumbUp, thumbDown],
    label: 'Thumbs',
    value: 'thumbs',
  },
];

export const quickFeedbackOneToTwoOptions: QuickFeedbackStyleOption[] = [
  {
    label: 'Thumbs',
    negativeIcons: {
      active: quickFeedbackThumbDownActive,
      default: quickFeedbackThumbDown,
    },
    positiveIcons: {
      active: quickFeedbackThumbUpActive,
      default: quickFeedbackThumbUp,
    },
    value: 'thumbs',
  },
  {
    label: 'BW Smiles',
    negativeIcons: {
      active: quickFeedbackBwSmileTerribleActive,
      default: quickFeedbackBwSmileTerrible,
    },
    positiveIcons: {
      active: quickFeedbackBwSmileExcellentActive,
      default: quickFeedbackBwSmileExcellent,
    },
    value: 'bw_smile',
  },
  {
    label: 'Hearts',
    negativeIcons: {
      active: quickFeedbackHeartTerribleActive,
      default: quickFeedbackHeartTerrible,
    },
    positiveIcons: {
      active: quickFeedbackHeartExcellentActive,
      default: quickFeedbackHeartExcellent,
    },
    value: 'hearts',
  },
];
