import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import ManageModelForm from '../triage-model-detail-page/drawers-and-dialogs/ManageModelForm';
import { createAutogenFilters } from '../triage-model-detail-page/helpers';
import { checkModelIsAutogen } from './AutogenFormSection/helpers';
import Spinner from 'src/components/spinner';
import { TriageAutoGenStatus } from 'src/reducers/triageSettingsReducer/types';
import { useGetModelByIdQuery } from 'src/services/triage/triageApi';

interface EditModelProps {
  modelId: string;
  onClose: () => void;
}

const EditModel = ({ modelId, onClose }: EditModelProps) => {
  const { data, isError, isFetching, isLoading } = useGetModelByIdQuery(
    { modelId },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  if (isError) {
    return (
      <Box
        alignItems='center'
        display='flex'
        height='300px'
        justifyContent='center'
      >
        <Typography variant='font16'>
          Failed to load model. Please try again.
        </Typography>
      </Box>
    );
  }

  if (isLoading || isFetching) {
    return (
      <Box alignItems='center' display='flex' justifyContent='center'>
        <Spinner />
      </Box>
    );
  }
  const isAutoGen = checkModelIsAutogen(data?.status as TriageAutoGenStatus);

  const initialValues = {
    dateRange: {
      from: new Date(data?.autogeneration_data?.start_date || 0),
      to: new Date(data?.autogeneration_data?.end_date || 0),
    },
    description: data?.model_description,
    displayName: data?.model_name,
    filters: createAutogenFilters(data?.autogeneration_data),
    isManual: !isAutoGen,
    selectedTicket: data?.autogeneration_data?.ticket_field,
  };

  return (
    <ManageModelForm
      initialValues={initialValues}
      isAutoGen={isAutoGen}
      isEdit
      modelId={modelId}
      onClose={onClose}
      status={data?.status}
    />
  );
};

export default EditModel;
