import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { DateRange } from '../../discover-dashboard-page/types';
import { EmptyStateCard } from '../articles/EmptyStateCard';
import {
  dateRangeToTimestamp,
  numbersToStringWithCommas,
  percentageValueAsString,
  stringifyDateRange,
} from '../helpers';
import { useGetFiltersFromSearch } from '../hooks/useGetFiltersFromSearch';
import { OverviewCardInformationSection } from '../overview/OverviewCardInformationSection';
import { InsightsTab } from '../types';
import InsightCard from './InsightCard';
import { BarGraphWrapper } from 'src/components/solve-insights-charts/bar-graph/barGraphUtils';
import { DonutGraphWrapper } from 'src/components/solve-insights-charts/donut-graph.tsx/donutGraphUtils';
import useGetCSATColor from 'src/pages/intent-conversation-analytics/hooks/useGetCSATColor';
import {
  useGetCommonCSATQuery,
  useGetCSATQuery,
  useGetOverallCSATQuery,
  useGetWorkflowOverallCSATQuery,
} from 'src/services/insights';
import { CommonCsatResponse } from 'src/services/insights/types';

interface CsatCardProps {
  dateRange: DateRange;
  tab?: InsightsTab;
}

export const CsatCard = ({ dateRange, tab = 'article' }: CsatCardProps) => {
  const theme = useTheme();
  const timestamps = dateRangeToTimestamp(dateRange);
  const showComparison = tab === 'overview';
  const requestParams = useGetFiltersFromSearch({ dateRange, tab });
  const getCSATColor = useGetCSATColor();
  // Article tab endpoint
  const {
    data: articleCsatData,
    isFetching: isArticleCsatFetching,
    isLoading: isArticleCsatLoading,
  } = useGetCSATQuery(
    { ...requestParams, ...timestamps },
    { skip: tab !== 'article' },
  );
  // Topic tab endpoint
  const {
    data: commonCsatData,
    isFetching: isCommonCsatFetching,
    isLoading: isCommonCsatLoading,
  } = useGetCommonCSATQuery(
    { ...requestParams, ...timestamps },
    { skip: tab !== 'topic' },
  );
  // Overview and Chats tab endpoint
  const {
    data: overallCsatData,
    isFetching: isOverallCsatFetching,
    isLoading: isOverallCsatLoading,
  } = useGetOverallCSATQuery(
    { ...requestParams, ...timestamps, to_compare: tab === 'overview' },
    { skip: tab !== 'overview' && tab !== 'chat' },
  );
  // Workflow tab endpoint
  const {
    data: workflowCsatData,
    isFetching: isWorkflowCsatFetching,
    isLoading: isWorkflowCsatLoading,
  } = useGetWorkflowOverallCSATQuery(
    {
      ...requestParams,
      end: timestamps.end_timestamp,
      start: timestamps.start_timestamp,
    },
    { skip: tab !== 'workflow' },
  );
  const csatData =
    articleCsatData ?? commonCsatData ?? overallCsatData ?? workflowCsatData;
  const isLoading =
    isArticleCsatLoading ||
    isCommonCsatLoading ||
    isOverallCsatLoading ||
    isWorkflowCsatLoading;
  const isFetching =
    isArticleCsatFetching ||
    isCommonCsatFetching ||
    isOverallCsatFetching ||
    isWorkflowCsatFetching;
  const isCsatDataAvailable = csatData && csatData.misc.total_response !== 0;
  return (
    <InsightCard
      headerRightText={
        showComparison ? stringifyDateRange(dateRange) : undefined
      }
      isFetching={isFetching}
      isLoading={isLoading}
      title={`${csatData?.misc.title} ${
        tab === 'topic' ? 'for chats with Topics' : ''
      }`}
    >
      {isCsatDataAvailable ? (
        <Box alignItems='center' display='flex' flex={1}>
          <Box
            display='flex'
            flexDirection='column'
            gap='16px'
            height='100%'
            justifyContent='center'
            pr='16px'
            width={csatData && csatData.donut ? undefined : '33%'}
          >
            {showComparison && csatData ? (
              <DonutGraphWrapper
                data={csatData as CommonCsatResponse}
                theme={theme}
              />
            ) : (
              <Box
                alignItems='center'
                display='flex'
                flexDirection='column'
                gap='4px'
              >
                <Typography
                  color={getCSATColor(csatData?.misc.value)}
                  variant='font32'
                >
                  {csatData?.misc.value}
                </Typography>
                <Typography variant='font14'>
                  {csatData?.misc.sub_title}
                </Typography>
              </Box>
            )}
          </Box>
          <Box display='flex' flexDirection='column'>
            <Box alignItems='flex-end' display='flex' gap='16px'>
              <Typography variant='font14'>Total responses</Typography>
              <Typography variant='font16Bold'>
                {numbersToStringWithCommas({
                  number: csatData?.misc.total_response,
                })}
              </Typography>
              {showComparison &&
                typeof csatData?.misc?.delta_total_response === 'number' && (
                  <Typography
                    color={
                      csatData?.misc?.delta_total_response >= 0
                        ? theme.palette.colors.green[500]
                        : theme.palette.colors.red[500]
                    }
                    variant='font14Medium'
                  >
                    {percentageValueAsString(
                      csatData.misc.delta_total_response,
                    )}
                  </Typography>
                )}
            </Box>
            {csatData && (
              <BarGraphWrapper
                data={csatData}
                isComparing={showComparison}
                palette={theme.palette}
                showTooltip={showComparison}
              />
            )}
          </Box>
        </Box>
      ) : (
        <EmptyStateCard variant='csat' />
      )}
      {showComparison && <OverviewCardInformationSection />}
    </InsightCard>
  );
};
