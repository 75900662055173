import {
  ALL_INTENTS,
  ALL_RESPONSES,
  AllOptions,
  EmailTranslationFilterType,
} from './EmailTranslationsTable';
import { EmailTranslation } from 'src/pages/workflow-builder-edit/types';
import {
  ConversationChannel,
  TranslationChannel,
} from 'src/types/workflowBuilderAPITypes';
import { FlatCv } from 'src/utils/actionBuilder/helpers';

export const translationChannelToConversationChannel = (
  translationChannel: TranslationChannel,
): ConversationChannel => {
  return translationChannel === 'email' ? 'email' : 'widget';
};

export const translationChannelToLabelMap: Record<TranslationChannel, string> =
  {
    api: 'API',
    email: 'Email',
    interactive_email: 'Interactive Email',
    slack: 'Slack',
    voice: 'Voice',
    widget: 'Widget',
  };

export const formatContextVariableForDisplayInDiv = (
  contextVariables: FlatCv[],
  message: string,
): string => {
  let newMessage = message;
  contextVariables.forEach((cv: FlatCv) => {
    if (message?.includes(cv?.context_variable_id ?? '')) {
      const formatedMessage = newMessage.replaceAll(
        `{{${cv.context_variable_id}}}`,
        `$${cv.context_variable_name}`,
      );
      newMessage = formatedMessage;
    }
  });
  return newMessage;
};

export const getStepTranslationCopies = (
  sourceTextMap: { [key: string]: [number[], string[]] },
  currentIndex: number,
  sourceText: string,
): { affectedIntents: Set<string>; indicesOfCopies: number[] } => {
  // exclude the current index from the results
  const indices = sourceTextMap[sourceText][0];
  const intentNames = sourceTextMap[sourceText][1];
  const indexToRemove = indices.indexOf(currentIndex);

  const filteredIndices = indices.filter((_, index) => index !== indexToRemove);
  const filteredIntentNames = new Set(
    intentNames.filter((_, index) => index !== indexToRemove),
  );

  return {
    affectedIntents: filteredIntentNames,
    indicesOfCopies: filteredIndices,
  };
};

export const getEmailTranslationFilterOptions = (
  allOptionsValue: AllOptions,
  filterType: EmailTranslationFilterType,
  sourceEmailTranslations: Array<EmailTranslation>,
) => {
  const idToName = new Set<string>();
  idToName.add(allOptionsValue);
  sourceEmailTranslations.forEach(data => {
    const intentName = data.intent_name;
    const emailConfigurationName = data.intent_email_configuration_name;
    if (filterType === 'intent' && intentName) {
      idToName.add(intentName);
    } else if (filterType === 'response' && emailConfigurationName) {
      idToName.add(emailConfigurationName);
    }
  });

  return Array.from(idToName).map(name => ({
    label: name,
    value: name,
  }));
};

export const getResponseOptionsList = (
  selectedIntentFilter: string,
  sourceEmailTranslations: Array<EmailTranslation>,
): Array<{ label: string; value: string }> => {
  const matchingTranslations =
    selectedIntentFilter === ALL_INTENTS
      ? sourceEmailTranslations
      : sourceEmailTranslations.filter(
          translation => translation.intent_name === selectedIntentFilter,
        );

  return getEmailTranslationFilterOptions(
    ALL_RESPONSES,
    'response',
    matchingTranslations,
  );
};

export const getEmailTranslationCopiesData = (
  sourceTextMap: {
    [key: string]: {
      intentNames: Array<string>;
      translationIds: Array<string>;
    };
  },
  currentTranslationId: string,
  sourceText: string,
  currentIntentName: string,
): { affectedIntents: Set<string>; translationIdOfCopies: string[] } => {
  const ids = sourceTextMap[sourceText].translationIds;
  const intentNames = sourceTextMap[sourceText].intentNames;
  //exclude current row data from results
  const filteredIds = ids.filter(id => id !== currentTranslationId);
  const filteredIntentNames = intentNames.filter(
    intentName => intentName !== currentIntentName,
  );

  return {
    affectedIntents: new Set(filteredIntentNames),
    translationIdOfCopies: filteredIds,
  };
};
