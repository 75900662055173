import { Box } from '@mui/material';

import HtmlComponent from 'src/components/html-component/HtmlComponent';

const HTMLCell = ({ value }: { value: string }) => {
  return (
    <Box
      sx={{
        '& ol': {
          marginBlockStart: '0',
          paddingBlockStart: '0',
          paddingInlineStart: '14px',
        },
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        width: '100%',
      }}
    >
      <HtmlComponent content={value as string} />
    </Box>
  );
};

export default HTMLCell;
