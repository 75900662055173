import { FormEvent } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { numbersToStringWithCommas } from '../solve-insights/helpers';
import { TRIAGE_LLM_SUPPORTED_HELPDESKS } from '../triage-models-overview-page/constants';
import { validateAutogenFilters } from '../triage-models-overview-page/helpers';
import { CreateModelFormValue } from '../triage-models-overview-page/types';
import { addLabelErrorMessages, REQUIRED_TRAINING_PHRASES } from './constants';
import {
  IntegrationChangeParams,
  TagFormValue,
  TriageModelLabelOverview,
} from './types';
import {
  FieldPredictorConfig,
  FieldToPredictOutput,
  HelpdeskResponseV2,
  ModelInput,
  TagDefinitionResponse,
  TagPhrase,
  TriageAutoGenStatus,
  TrueValue,
  VersionedTriageModel,
} from 'src/reducers/triageSettingsReducer/types';
import { customFieldHelpdesks } from 'src/utils/constants';

export const isModelReadOnly = (model?: VersionedTriageModel) =>
  Boolean(model?.is_published);

export const hasComponent = <T extends string>(
  condition: T | undefined,
  component: React.ReactNode,
) => Boolean(condition) && component;

export const convertFormPhrasesToTrainingPhrases = (
  thisLabelsPhrases: Array<string | undefined>,
  tagId: string,
  existingPhrases: TagPhrase[],
): TagPhrase[] => {
  const otherTagPhrases = existingPhrases.filter(
    phrase => phrase.tag_id !== tagId,
  );
  const onlyPopulatedNewPhrases = thisLabelsPhrases.filter(Boolean) as string[];
  const newPhrases = onlyPopulatedNewPhrases.map(phrase => {
    const existingPhrase = existingPhrases.find(
      existingPhrase =>
        existingPhrase.tag_id === tagId &&
        existingPhrase.phrase_text === phrase,
    );
    if (existingPhrase) {
      return {
        phrase_id: existingPhrase.phrase_id,
        phrase_text: phrase,
        tag_id: tagId,
      };
    } else {
      return {
        phrase_id: uuidv4(),
        phrase_text: phrase,
        tag_id: tagId,
      };
    }
  });
  return [...newPhrases, ...otherTagPhrases];
};
export const numToString = (value: number) =>
  numbersToStringWithCommas({ number: value });

export const numToPercentage = (value: number | null) =>
  value === null ? null : `${Math.round(value * 100.0)}%`;

export const getTagFormValuesFromModel = (
  model: VersionedTriageModel,
  tagId: string | null,
): TagFormValue | undefined => {
  if (!tagId) {
    return undefined;
  }
  const label = model.labels.find(label => label.tag_id === tagId);
  if (!label) {
    return undefined;
  }
  const phrases = model.phrases.filter(phrase => phrase.tag_id === tagId);
  const trainingPhrases = phrases.map(phrase => phrase.phrase_text);
  return {
    addTrainingPhrase: '',
    description: label.description,
    name: label.title,
    trainingPhrases,
  };
};

export const mergeLabelIntoExistingLabels = (
  newLabel: TagDefinitionResponse,
  existingLabels: TagDefinitionResponse[],
): TagDefinitionResponse[] => {
  const newLabelList = [...existingLabels];
  const existingLabelIndex = existingLabels.findIndex(
    existingLabel => existingLabel.tag_id === newLabel.tag_id,
  );
  if (existingLabelIndex !== -1) {
    newLabelList[existingLabelIndex] = newLabel;
    return newLabelList;
  } else {
    return [...newLabelList, newLabel];
  }
};

export const convertFormTagToTagDefinition = (
  title: string,
  description: string,
  existingLabels: TagDefinitionResponse[],
  modelName: string,
  tagId: string | null,
  model: VersionedTriageModel,
): TagDefinitionResponse => {
  const existingLabel = existingLabels.find(
    existingLabel => existingLabel.tag_id === tagId,
  );
  const valueMapping = model.model.model_output_formatter.value_mapping;
  if (existingLabel) {
    const mappedValue = valueMapping
      ? (valueMapping[existingLabel.title] as string)
      : '';
    return {
      description: description,
      is_active: existingLabel.is_active,
      model_name: modelName,
      output_field_value: mappedValue || existingLabel.output_field_value,
      tag_id: existingLabel.tag_id,
      title: title,
    };
  } else {
    return {
      description: description,
      is_active: true,
      model_name: modelName,
      output_field_value: null,
      tag_id: uuidv4(),
      title: title,
    };
  }
};

const HELPDESK_TO_OUTPUT_OBJECT_MAP: { [key: string]: string } = {
  jira: 'issue',
  kustomer: 'kustomer_conversation',
  salesforce: 'Case',
  zendesk: 'ticket',
};

const handleModelInput = (
  mappedOrigins: string[] | undefined,
  modelInputs: ModelInput[],
  helpdesk: string,
) => {
  if (!mappedOrigins) {
    return modelInputs;
  }
  return modelInputs?.map(input => {
    const isKustomer = helpdesk === 'kustomer';
    const isJira = helpdesk === 'jira';
    const schema = {
      ...input.schema,
      properties: {
        ...(input.schema.properties || {}),
        Origin: {
          enum: mappedOrigins || [],
        },
      },
    };
    return {
      ...input,
      schema,
      ...(isKustomer && {
        text_a_field_name: 'name',
        text_b_field_name: 'preview',
      }),
      ...(isJira && {
        text_a_field_name: 'summary',
        text_b_field_name: 'description',
      }),
    };
  });
};

export const deriveNewModelFromIntegrationChange = (
  updateData: IntegrationChangeParams,
  model: VersionedTriageModel,
  helpdeskData: HelpdeskResponseV2,
): FieldPredictorConfig => {
  const currentModel = model.model;

  const mappedOrigins = updateData.origins?.map(origin => origin.value);

  if (updateData.isOriginChange) {
    return {
      ...currentModel,
      model_inputs: handleModelInput(
        mappedOrigins,
        currentModel.model_inputs,
        helpdeskData.helpdesk,
      ),
    };
  }

  if (updateData.accuracyCheckValue) {
    return {
      ...currentModel,
      model_output_formatter: {
        ...currentModel.model_output_formatter,
        accuracy_check_method: updateData.accuracyCheckValue,
      },
    };
  }

  if (updateData.groundTruthValue) {
    const currentTrueValue = currentModel.true_value[0];
    return {
      ...currentModel,
      true_value: [
        {
          ...currentTrueValue,
          field_id: updateData.groundTruthValue,
          field_name: updateData.groundTruthValue,
        },
      ],
    };
  }

  // handle the user picking the default label
  if (updateData.defaultLabel !== undefined) {
    return {
      ...currentModel,
      model_output_formatter: {
        ...currentModel.model_output_formatter,
        not_predicted_value: updateData.defaultLabel,
      },
    };
  }

  // handle the user picking the output field
  if (!updateData.select) {
    return currentModel;
  }
  const currentHelpdesk = helpdeskData.helpdesk;
  if (!TRIAGE_LLM_SUPPORTED_HELPDESKS.includes(currentHelpdesk)) {
    return currentModel;
  }
  const helpdeskOutputObject = HELPDESK_TO_OUTPUT_OBJECT_MAP[currentHelpdesk];
  if (!helpdeskOutputObject) {
    return currentModel;
  }
  const newTrueValue: TrueValue = {
    field_id: updateData.select,
    field_name: updateData.select,
    object_name: helpdeskOutputObject,
    type: 'field',
  };

  const outputValue = { [updateData.select]: '@value' };

  const isHelpdeskWithCustomFields = (helpdesk: string): boolean => {
    return customFieldHelpdesks.includes(helpdesk);
  };

  const writtenObject = isHelpdeskWithCustomFields(currentHelpdesk)
    ? { custom_fields: outputValue }
    : outputValue;

  const newOutputs: FieldToPredictOutput = {
    create_new_object: false,
    object_name: helpdeskOutputObject,
    written_object: writtenObject as FieldToPredictOutput['written_object'],
  };

  return {
    ...currentModel,
    model_inputs: handleModelInput(
      mappedOrigins,
      currentModel.model_inputs,
      helpdeskData.helpdesk,
    ),
    model_output_formatter: {
      ...currentModel.model_output_formatter,
      accuracy_check_method: 'exact_match',
    },
    outputs: [newOutputs],
    true_value: [newTrueValue],
  };
};

export const createSaveLabelTooltip = ({
  isModelEditable,
  trainingPhrases,
}: {
  isModelEditable: boolean;
  trainingPhrases: string[];
}) => {
  if (!isModelEditable) {
    return 'Labels cannot be edited for a published version.';
  }
  if (trainingPhrases.length < REQUIRED_TRAINING_PHRASES) {
    return addLabelErrorMessages.trainingPhrases;
  }
  return '';
};

export const addPercentageValue = (
  value: number,
  overview?: TriageModelLabelOverview,
) => {
  const percentageValue =
    value && overview?.eligible_count ? value / overview.eligible_count : null;
  const percentage = percentageValue
    ? `(${(percentageValue * 100).toFixed(2)}%)`
    : '';

  return `${numToString(value)} ${percentage}`;
};

export const createAutogenFilters = (
  autogenData?: VersionedTriageModel['autogeneration_data'],
) => {
  if (!autogenData) {
    return [];
  }
  return autogenData.filters.map(filter => {
    return {
      booleanOperator: autogenData.filter_operator,
      expression: {
        ...filter,
        expression_type: '',
      },
    };
  });
};

export const modelFormValidator = (values: CreateModelFormValue) => {
  const errors: Partial<Omit<CreateModelFormValue, 'filters'>> & {
    filters?: string;
  } = {};

  if (values.isManual === false && !values.selectedTicket) {
    errors.selectedTicket = 'Ticket is required';
  }

  if (values.isManual === false && values.filters.length) {
    const isValid = validateAutogenFilters({
      filters: values.filters,
    });
    if (!isValid) {
      errors.filters = 'Field values are required';
    }
  }

  if (values.isManual === false && values.filters.length) {
    const isValid = validateAutogenFilters({
      filters: values.filters,
    });
    if (!isValid) {
      errors.filters = 'Field values are required';
    }
  }
  if (!values.displayName.trim()) {
    errors.displayName = 'Model name is required';
  }

  if (!values.description.trim()) {
    errors.description = 'Model description is required';
  }

  return errors;
};

export const hasModelInfoChanged = (
  values: CreateModelFormValue,
  initialFormValues: Partial<CreateModelFormValue>,
) => {
  return (
    values.displayName !== initialFormValues.displayName ||
    values.description !== initialFormValues.description
  );
};

export const invalidFormElementSubmitted = (
  event: FormEvent<HTMLFormElement>,
) => {
  return (event.target as Element).classList.contains('MuiPaper-root');
};

export const verifyIsAutogen = (status: TriageAutoGenStatus) => {
  if (!status) {
    return false;
  }
  const autogenStatuses = ['generating', 'collecting_data', 'errored'];
  return autogenStatuses.includes(status);
};
