import { SOLVE_V2_BASE_URL } from '../constants';
import { dashboardApi } from '../dashboard-api';

interface VoicePreviewRequest {
  to_number: string;
}

interface VoicePreviewResponse {
  from_number: string;
}

const voicePreviewApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    initializeVoicePreview: builder.mutation<
      VoicePreviewResponse,
      VoicePreviewRequest
    >({
      query: body => ({
        body,
        method: 'POST',
        url: `${SOLVE_V2_BASE_URL}/voice/preview`,
      }),
    }),
  }),
});

export const { useInitializeVoicePreviewMutation } = voicePreviewApi;
