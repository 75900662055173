import { Box, styled, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import {
  checkModelIsAutogen,
  checkModelIsGenerating,
} from './AutogenFormSection/helpers';
import { GENERATING_STATUS_COLOR } from './constants';
import { TriageAutoGenStatus } from 'src/reducers/triageSettingsReducer/types';

interface CardFooterProps {
  isActive: boolean;
  status: TriageAutoGenStatus;
}

const CardFooter = ({ isActive, status }: CardFooterProps) => {
  const { palette } = useTheme();

  const colors = {
    collecting_data: GENERATING_STATUS_COLOR,
    errored: palette.colors.red[500],
    generating: GENERATING_STATUS_COLOR,
  };

  if (checkModelIsAutogen(status)) {
    const background = colors[status as 'errored' | 'generating'];
    const isGenerating = checkModelIsGenerating(status);

    return (
      <CardSubContainer
        sx={{
          background,
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
        }}
      >
        <Box alignItems='center' display='flex' height='38px' px={2}>
          {isGenerating ? (
            <Typography color={palette.colors.black} variant='font12'>
              Generation in progress
            </Typography>
          ) : (
            <Typography color={palette.colors.white} variant='font12'>
              Generation Failed
            </Typography>
          )}
        </Box>
      </CardSubContainer>
    );
  }

  return (
    <CardSubContainer>
      <CardStatusContainer>
        <StatusIcon isEnabled={isActive} />
        <CardStatusText isActive={isActive}>
          {isActive ? 'Active' : 'Inactive'}
        </CardStatusText>
      </CardStatusContainer>
    </CardSubContainer>
  );
};

export default CardFooter;

const CardSubContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  borderColor: theme.palette.colors.slate[200],
  borderTopStyle: 'solid',
  borderTopWidth: '1px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  maxHeight: '38px',
  textAlign: 'right',
}));

const CardStatusContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
`;

const CardStatusText = styled('p')<{ isActive: boolean }>(
  ({ isActive, theme }) => ({
    color: isActive
      ? theme.palette.colors.black
      : theme.palette.colors.grey[400],
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '18px',
    paddingLeft: '8px',
  }),
);

const StatusIcon = styled('div')<{ isEnabled: boolean }>(
  ({ isEnabled, theme }) => ({
    backgroundColor: isEnabled
      ? theme.palette.colors.green[500]
      : theme.palette.colors.grey[400],
    borderRadius: '50%',
    height: '12px',
    width: '12px',
  }),
);
