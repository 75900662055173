import { memo, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu } from '@mui/material';

import {
  Table,
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { TableLabelContainer } from '../discover-dashboard-page/styles';
import DiscoverHistogramFilterButton from './components/DiscoverHistogramFilterButton';
import DiscoverHistogramChart from './components/HistogramFilter';
import {
  getCellRendererById,
  getTooltipKey,
  metricUnitToCellRenderer,
} from './helpers';
import {
  HistogramFilters,
  MetricMultiFilterValue,
  MetricUnit,
  SelectedHistogramFilter,
} from './types';
import isEqual from 'lodash/fp/isEqual';
import SearchNotFoundIcon from 'src/assets/images/search-not-found-icon.svg';
import {
  GroupedMultiSelectFilterOptions,
  TopicTimeFilter,
} from 'src/components/app/types';
import DiscoverMetricTooltip from 'src/components/discover-tooltip/DiscoverMetricTooltip';
import {
  METRIC_FILTERS_SEPARATOR,
  PATCHING_TOPIC_TABLE_ROW_CLASS_NAME,
  UNFORMATTED_LABEL_SEPARATOR,
} from 'src/constants/discover';
import { useGetObservableContainerHeight } from 'src/hooks/discover/useGetObservableContainerHeight';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { useGenerateLinkWithSearch } from 'src/hooks/useGenerateLinkWithSearch';
import {
  DiscoverAllTopicsResponse,
  DiscoverTopicAggregatedMetricType,
} from 'src/reducers/discoverReducer/types';
import { useGetMetadataQuery } from 'src/services/discover/discoverApi';
import {
  comparatorFunction,
  getMetricsFilterLabelsMap,
  getPercentageOfReturnedTickets,
  overrideDiscoverSearchParams,
  replaceIdInRoute,
  TableDataFilter,
} from 'src/utils/discover/helpers';
import { Routes } from 'src/utils/enums';

const filterLabelsMap = getMetricsFilterLabelsMap();

export interface AllTopicsTableProps {
  data?: DiscoverAllTopicsResponse;
  groupFilters: GroupedMultiSelectFilterOptions[];
  histogramFilters: HistogramFilters;
  isLoading: boolean;
  metricFilters: MetricMultiFilterValue[];
  searchText: string;
  selectedHistogramFilter: SelectedHistogramFilter;
  setHistogramFilters: (value: HistogramFilters) => void;
  setSelectedHistogramFilter: (value: SelectedHistogramFilter) => void;
  timeFilter: TopicTimeFilter;
}

const AllTopicsTable = ({
  data,
  groupFilters,
  histogramFilters,
  isLoading,
  metricFilters,
  searchText,
  selectedHistogramFilter,
  setHistogramFilters,
  setSelectedHistogramFilter,
  timeFilter,
}: AllTopicsTableProps) => {
  const { data: metadata } = useGetMetadataQuery();

  const navigate = useNavigate();
  const linkWithSearch = useGenerateLinkWithSearch(
    Routes.DISCOVER_TOPIC_DETAIL,
  );

  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const containerHeight = useGetObservableContainerHeight(
    'all-topics-table-container',
  );
  const { topic_metric_data_types: availableMetrics } = metadata ?? {
    topic_metric_data_types: [],
  };

  const isHistogramFilterOpen = Boolean(anchorElement);

  const handleOnCloseHistogramFilter = () => {
    setAnchorElement(null);
  };

  const generateMetricColumn = useCallback(
    (filter: MetricMultiFilterValue) => {
      const unformattedLabel = filterLabelsMap[filter];

      const [first, second] = unformattedLabel.split(
        UNFORMATTED_LABEL_SEPARATOR,
      );
      const [metric, metricUnit] = filter.split(METRIC_FILTERS_SEPARATOR) as [
        DiscoverTopicAggregatedMetricType,
        MetricUnit,
      ];

      const targetHistogram = histogramFilters[metric];

      const selectedDataMetricType = availableMetrics.find(
        availableMetric => availableMetric.type === metric,
      );

      return {
        cellRenderer: metricUnitToCellRenderer(metric, metricUnit),
        columnHeaderAdornment: (
          <DiscoverHistogramFilterButton
            comparisonType={metricUnit}
            handleOnClick={e => setAnchorElement(e.currentTarget)}
            histogramRangeValue={
              targetHistogram ? targetHistogram[metricUnit] : undefined
            }
            isFocused={selectedHistogramFilter.type === metricUnit}
            isHistogramFilterOpen={isHistogramFilterOpen}
            metricType={metric}
            setSelectedHistogramFilter={setSelectedHistogramFilter}
          />
        ),
        comparatorFunction,
        id: filter,
        label: (
          <TableLabelContainer>
            {first}
            {metricUnit === 'value' &&
            selectedDataMetricType?.data_type === 'seconds'
              ? ' (hrs)'
              : ''}{' '}
            <DiscoverMetricTooltip
              isLight={metricUnit !== 'value' && metric !== 'sentiment'}
              metricType={getTooltipKey(metric, metricUnit)}
            />
            <br />
            {second}
          </TableLabelContainer>
        ),
        width: '200px',
      };
    },
    [
      availableMetrics,
      histogramFilters,
      isHistogramFilterOpen,
      selectedHistogramFilter.type,
      setSelectedHistogramFilter,
    ],
  );

  const trackingEventMetadata = useMemo(() => {
    return {
      'metrics-selected': metricFilters,
      page: 'All Topics',
      'time-period-selected': timeFilter.key,
    };
  }, [metricFilters, timeFilter.key]);

  const columns = useMemo(() => {
    return [
      {
        cellRenderer: getCellRendererById('isBookmarked', {
          trackingEventMetadata,
        }),
        hideSortIcon: true,
        id: 'isBookmarked',
        label: '',
        width: '35px',
      },
      {
        cellRenderer: getCellRendererById('name', {
          percentageOfTicketVolume: getPercentageOfReturnedTickets(data),
          searchText,
        }),
        id: 'name',
        label: `Topic (${data?.breakdown.length ?? 0})`,
        minWidth: '400px',
      },
      ...metricFilters.map(generateMetricColumn),
      {
        align: 'center' as const,
        cellRenderer: getCellRendererById('automationCallToAction', {
          trackingEventMetadata,
        }),
        hideSortIcon: true,
        id: 'callToAction',
        label: '',
        width: '200px',
      },
    ];
  }, [
    data,
    generateMetricColumn,
    metricFilters,
    searchText,
    trackingEventMetadata,
  ]);

  const filter = new TableDataFilter(data?.breakdown ?? [], 'V1');
  const summaryRowFilter = new TableDataFilter(
    data?.aggregate ? [data.aggregate] : [],
    'V1',
  );
  const showHidden = groupFilters.includes('hidden');

  return (
    <>
      <Menu
        anchorEl={anchorElement}
        onClose={handleOnCloseHistogramFilter}
        open={isHistogramFilterOpen}
        sx={{
          '& .MuiMenu-list': {
            paddingTop: '8px',
          },
        }}
      >
        {data && (
          <DiscoverHistogramChart
            data={data}
            handleOnClose={handleOnCloseHistogramFilter}
            histogramFilters={histogramFilters}
            selectedHistogramFilter={selectedHistogramFilter}
            setHistogramFilters={setHistogramFilters}
          />
        )}
      </Menu>
      <Table
        columns={columns}
        defaultOrder='desc'
        defaultOrderBy='volume.value'
        emptyStateProps={{
          children: (
            <Typography color={theme.palette.colors.grey[700]} variant='font14'>
              Adjust your search terms or filters
            </Typography>
          ),
          icon: <img src={SearchNotFoundIcon} />,
          title: 'No results',
        }}
        isLoading={isLoading}
        onClickRow={({ id, name, topic }) => {
          emitTrackingEventCallback('discover-clicked-on-topic', {
            'metric-selected': metricFilters,
            page: 'All Topics',
            'time-period-selected': timeFilter,
            topic: name,
            value: topic?.is_bookmarked,
          });

          navigate(
            overrideDiscoverSearchParams(replaceIdInRoute(linkWithSearch, id), {
              name: topic?.topic_display_name || topic?.topic_name || name,
              source: 'all-topics',
            }),
          );
        }}
        onSortCallback={() => {
          emitTrackingEventCallback(
            'discover-sorted-table',
            trackingEventMetadata,
          );
        }}
        overscanCount={5}
        rows={filter
          .filterNullMongoTopic()
          .filterByHidden(showHidden)
          .filterByGroupFilters(groupFilters)
          .filterByKeyword(searchText)
          .filterByHistogramFilters(histogramFilters)
          .arrangeForTable()}
        shouldDisablePagination
        shouldVirtualizeTableRows
        summaryRow={summaryRowFilter.arrangeForTable()[0]}
        tableBodyHeight={containerHeight ? `${containerHeight}px` : '85vh'}
        tableRowStyles={({ topic }) => ({
          bgcolor: topic?.is_hidden ? 'rgba(0, 0, 0, 0.04)' : undefined,
          [`&.${PATCHING_TOPIC_TABLE_ROW_CLASS_NAME}`]: {
            bgcolor: 'rgba(0, 0, 0, 0.04)',
          },
        })}
      />
    </>
  );
};

const customComparator = (
  prevProps: AllTopicsTableProps,
  nextProps: AllTopicsTableProps,
) => {
  return isEqual(prevProps, nextProps);
};

const MemoizedAllTopicsTable = memo(AllTopicsTable, customComparator);

export default MemoizedAllTopicsTable;
