import Box from '@mui/material/Box';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { CreateModelFormValue } from '../types';
import { checkModelIsGenerating, getMessage } from './helpers';
import isEqual from 'lodash/isEqual';
import { TriageAutoGenStatus } from 'src/reducers/triageSettingsReducer/types';

interface AutogenFooterProps {
  disabled?: boolean;
  initialValues: Partial<CreateModelFormValue>;
  isLoading: boolean;
  onShowDelete: () => void;
  status: TriageAutoGenStatus;
  values: Partial<CreateModelFormValue>;
}

const AutogenFooter = ({
  disabled,
  initialValues,
  isLoading,
  onShowDelete,
  status,
  values,
}: AutogenFooterProps) => {
  const disabledRetryButton = isEqual(values, initialValues);
  const isGenerating = checkModelIsGenerating(status);
  const hasError = status === 'errored';
  const showMessage = isGenerating || hasError;

  return (
    <Box
      bgcolor='white'
      bottom={0}
      display='flex'
      flexDirection='column'
      position='fixed'
      px={4}
      py={3}
      right={0}
      rowGap={2}
      sx={{
        borderBottomLeftRadius: '16px',
        boxShadow: '0px -4px 8px 0px rgba(0, 0, 0, 0.15)',
      }}
      width={628}
    >
      {showMessage && (
        <Typography variant='font14'>
          {getMessage({ hasError, isGenerating })}
        </Typography>
      )}
      <Box columnGap={1} display='grid' gridTemplateColumns='1fr 4fr'>
        <Button disabled={isLoading} onClick={onShowDelete} variant='danger'>
          Delete
        </Button>
        {status === 'errored' && (
          <Button
            disabled={disabled || disabledRetryButton}
            isLoading={isLoading}
            type='submit'
            variant='main'
          >
            Retry
          </Button>
        )}
        {isGenerating && (
          <Button disabled variant='main'>
            Generating model...
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default AutogenFooter;
