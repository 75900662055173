import { useState } from 'react';
import { styled, useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import TriageLabelMappingDrawer from 'src/components/triage-label-mapping-drawer';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';
import { LabelMetrics } from 'src/services/triage/types';

interface AddLabelFormProps {
  label: LabelMetrics & { tag_id?: string };
  model: VersionedTriageModel;
}

const SimulationLabelsDetails = ({ label, model }: AddLabelFormProps) => {
  const { palette } = useTheme();
  const [showLabelMapping, setShowLabelMapping] = useState(false);

  const greyColor = palette.colors.grey[600];
  const lightColor = palette.colors.grey[400];
  const slateBgColor = palette.colors.slate[100];

  const { model: modelData } = model;
  const modelValueMapping = modelData.model_output_formatter.value_mapping;

  const filteredPhrases = model.phrases.filter(
    phrase => phrase.tag_id === label.tag_id,
  );

  return (
    <>
      <SimulationLabelsDetailsContainer>
        <Box
          display='flex'
          flexDirection='column'
          overflow='auto'
          rowGap='32px'
        >
          <Typography variant='font20'>View label</Typography>

          {/* Label Section */}
          <Box display='flex' flexDirection='column' rowGap='8px'>
            <Typography variant='font16Bold'>Label</Typography>
            <Typography color={greyColor} variant='font12'>
              The label will appear as the predicted field value in the ticket
              when the model makes this prediction.
            </Typography>
            <Typography variant='font14Bold'>{label.label_name}</Typography>
            {Boolean(label.label_name) && (
              <>
                <Typography color={lightColor} variant='font12'>
                  Label value mapped. See{' '}
                  <Box
                    component='span'
                    onClick={() => setShowLabelMapping(true)}
                    sx={{
                      color: palette.colors.purple[500],
                      cursor: 'pointer',
                    }}
                  >
                    ‘Mapping label values’
                  </Box>
                </Typography>
                {!(
                  !modelValueMapping || !modelValueMapping[label.label_name]
                ) && (
                  <Box
                    alignItems='center'
                    bgcolor={slateBgColor}
                    borderRadius='4px'
                    padding='4px 8px'
                    style={{
                      lineHeight: '1.2',
                    }}
                    sx={{
                      maxWidth: '100%',
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                    }}
                  >
                    <Typography color={greyColor} variant='font12'>
                      {modelValueMapping[label.label_name]}
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </Box>

          <Box display='flex' flexDirection='column' rowGap='8px'>
            <Typography variant='font16Bold'>Description</Typography>
            <Typography color={greyColor} variant='font12'>
              Briefly describe your customer&apos;s action or query to guide the
              model in predicting the accurate label.
            </Typography>
            <Typography variant='font14'>{label.description}</Typography>
          </Box>

          <Box display='flex' flexDirection='column' rowGap='8px'>
            <Typography variant='font16Bold'>Training phrases</Typography>
            <Typography color={greyColor} variant='font12'>
              Input training phrases for AI to better classify tickets within
              this label.
            </Typography>
            {filteredPhrases.map(phrase => (
              <Typography key={phrase.phrase_id} variant='font14'>
                {phrase.phrase_text}
              </Typography>
            ))}
          </Box>
        </Box>
      </SimulationLabelsDetailsContainer>
      <TriageLabelMappingDrawer
        modelId={model.model_id}
        onCloseDrawer={() => setShowLabelMapping(false)}
        showDrawer={showLabelMapping}
        showIcon={false}
        versionId={model.version_id}
      />
    </>
  );
};

const SimulationLabelsDetailsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 32px;
`;

export default SimulationLabelsDetails;
