import React from 'react';
import { Box, styled, useTheme } from '@mui/material';

import {
  Skeleton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { DeflectionInsightCell } from '../components/DeflectionInsightCell';
import { NA } from '../constants';
import { Conversation } from 'src/services/apiInterfaces';
import { isDeflectionInsightsProcessing } from 'src/utils/solve/deflectionInsightsUtils';

export const DeflectionInsightsData = ({
  isLoading = false,
  selectedConversation,
}: {
  isLoading?: boolean;
  selectedConversation: Conversation;
}) => {
  const { palette } = useTheme();
  const {
    dropoff_rating,
    dropoff_reasoning = NA,
    relevance_rating,
    relevance_reasoning = NA,
  } = selectedConversation;
  const isProcessing = isDeflectionInsightsProcessing({
    hasDeflectionInsights: !!relevance_rating,
    isDeflected: selectedConversation.deflected,
    modifiedDate: selectedConversation.modified_date,
  });

  const DeflectionInsight = ({
    badge,
    label,
    value,
  }: {
    badge: React.ReactNode;
    label: string;
    value: string | null;
  }) => {
    return (
      <>
        <Divider />
        <Box display='flex' flexDirection='column' gap='6px'>
          <Typography color={palette.colors.grey[600]} variant='font12Medium'>
            {label}:
          </Typography>
          {badge}
        </Box>

        {!isProcessing && (
          <Box display='flex' flexDirection='column' gap='6px'>
            <Typography color={palette.colors.grey[600]} variant='font12Medium'>
              {label} reasoning:
            </Typography>
            <Typography
              color={
                relevance_reasoning === NA
                  ? palette.colors.grey[500]
                  : palette.colors.grey[600]
              }
              variant='font12Medium'
            >
              {isLoading ? <Skeleton width={30} /> : value}
            </Typography>
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      <DeflectionInsight
        badge={
          <DeflectionInsightCell
            isProcessing={isProcessing}
            metric={relevance_rating}
          />
        }
        label='Relevance'
        value={relevance_reasoning}
      />
      <DeflectionInsight
        badge={
          <DeflectionInsightCell
            isProcessing={isProcessing}
            metric={dropoff_rating}
          />
        }
        label='User engagement'
        value={dropoff_reasoning}
      />
    </>
  );
};

const Divider = styled('hr')`
  border-width: 0;
  border-bottom-width: thin;
  border-color: ${({ theme }) => theme.palette.colors.slate[200]};
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
`;
