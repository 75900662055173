import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import NotFound from '../triage-version-control-detail/NotFound';
import SimulationLabelsOverview from './SimulationLabelsOverview';
import SimulationLabelsTable from './SimulationLabelsTable';
import Spinner from 'src/components/spinner';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';
import { useGetPredictionTestResultsQuery } from 'src/services/triage/triageApi';

const SimulationLabelsTab = ({ model }: { model: VersionedTriageModel }) => {
  const predictionTestId = window.location.pathname.split('/')[2];
  const { data: tableData, isLoading } =
    useGetPredictionTestResultsQuery(predictionTestId);
  const { labels_metrics: labelTableData = [] } = tableData || {};

  if (isLoading) {
    return <Spinner />;
  }

  if (!model || !tableData?.label_overall_metrics) {
    return <NotFound />;
  }

  const modelVersionLink = `/triage-settings/version-control/${model.model_id}?version=${model.version_id}`;

  const enrichedLabelTableData = labelTableData.map(label => {
    const matchedLabel = model.labels.find(l => l.title === label.label_name);
    return matchedLabel ? { ...label, tag_id: matchedLabel.tag_id } : label;
  });

  return (
    <Box display='grid' gap={1} gridTemplateRows='auto 24px auto'>
      <Typography variant='font24'>Simulation labels</Typography>
      <Typography variant='font14'>
        Simulation test for{' '}
        <Link to={modelVersionLink}>
          {model.formal_model_name} model and {model.version_name} version
        </Link>
      </Typography>
      <SimulationLabelsOverview
        data={tableData.label_overall_metrics}
        isLoading={isLoading}
      />
      <SimulationLabelsTable
        data={enrichedLabelTableData}
        isDataLoading={isLoading}
        model={model}
      />
    </Box>
  );
};

export default SimulationLabelsTab;
