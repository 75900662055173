import { ListOption } from '@forethought-technologies/forethought-elements';
import isEqual from 'lodash/isEqual';
import {
  PatchTriageModelRequest,
  TagDefinitionResponse,
  TriageTicketFieldsResponse,
  VersionedTriageModel,
} from 'src/reducers/triageSettingsReducer/types';

export const determineOutputFieldValueOptions = (
  selectedOutputField: string,
  ticketFields: TriageTicketFieldsResponse | undefined,
): ListOption[] | undefined => {
  if (!selectedOutputField || !ticketFields) {
    return [];
  }
  if (ticketFields.data_source === 'zendesk') {
    return ticketFields.ticket_fields.output_fields
      .find(field => field.id.toString() === selectedOutputField)
      ?.field_options?.map(option => ({
        label: option.name,
        value: option.value,
      }));
  } else if (ticketFields.data_source === 'salesforce') {
    return ticketFields.ticket_fields.output_fields
      .find(field => field.name === selectedOutputField)
      ?.field_options?.map(option => ({
        label: option.name,
        value: option.value,
      }));
  } else if (
    ticketFields.data_source === 'kustomer' ||
    ticketFields.data_source === 'jira'
  ) {
    return ticketFields.ticket_fields.output_fields
      .find(field => field.helpdesk_field_id === selectedOutputField)
      ?.field_options?.map(option => ({
        label: option.label,
        value: option.value,
      }));
  }
  return [];
};

export const determineOutputFieldOptions = (
  ticketFields: TriageTicketFieldsResponse | undefined,
): ListOption[] => {
  if (!ticketFields) {
    return [];
  }
  if (ticketFields.data_source === 'zendesk') {
    return (
      ticketFields.ticket_fields.output_fields.map(field => ({
        label: field.name,
        value: field.id.toString(),
      })) || []
    );
  } else if (ticketFields.data_source === 'salesforce') {
    return (
      ticketFields.ticket_fields.output_fields.map(field => ({
        label: field.label,
        value: field.name,
      })) || []
    );
  } else if (ticketFields.data_source === 'kustomer') {
    return (
      ticketFields.ticket_fields.output_fields.map(field => ({
        label: field.title ?? '',
        value: field.id,
      })) || []
    );
  } else if (ticketFields.data_source === 'jira') {
    return (
      ticketFields.ticket_fields.output_fields.map(field => ({
        label: field.title ?? '',
        value: field.id,
      })) || []
    );
  }
  return [];
};

export const labelToObj = (labels?: Array<TagDefinitionResponse>) => {
  if (!labels) {
    return {};
  }
  return labels.reduce(
    (prev, current) => ({
      ...prev,
      [current.title]: current.output_field_value,
    }),
    {},
  );
};

export const checkLabelEquality = (
  currentPatchModel: PatchTriageModelRequest['body'],
  targetedModel?: VersionedTriageModel,
) => {
  const labelObj = labelToObj(currentPatchModel.labels);

  const noChangeInLabels = isEqual(
    labelObj,
    Object.entries(labelToObj(targetedModel?.labels)).reduce(
      (prev, current) => {
        if (!targetedModel) {
          return prev;
        }
        const { value_mapping: vm } =
          targetedModel?.model.model_output_formatter;
        const [key, value] = current;

        if (vm && vm[key]) {
          return {
            ...prev,
            [key]: vm[key],
          };
        }

        return {
          ...prev,
          [key]: value,
        };
      },
      {},
    ),
  );

  return noChangeInLabels;
};
