import PostAutomationActionDrawer from './automation/PostAutomationActionDrawer';
import Article from './article';
import AutomationDrawer from './automation';
import DiscoverToast from './DiscoverToast';

const GlobalDiscover = () => {
  return (
    <>
      <AutomationDrawer key='predrawer' />
      <PostAutomationActionDrawer key='postdrawer' />
      <DiscoverToast />
      <Article />
    </>
  );
};

export default GlobalDiscover;
