import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import isPropValid from '@emotion/is-prop-valid';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';

import { Typography } from '@forethought-technologies/forethought-elements';
import { tabs } from '../constants';
import {
  useGetHandoffConfigurationForWorkflow,
  useGetIsTemplateIntentWorkflow,
} from '../hooks';
import { BuilderView } from '../types';
import { LabelBadge } from 'src/components/label-badge';
import {
  selectCanvasWorkflowBuilder,
  selectIsFallback,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { CanvasWorkflowBuilderState } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';

interface BuilderTopBarProps {
  contentRight: React.ReactNode;
  onTabClick?: (view: BuilderView) => void;
  view?: BuilderView;
}

const BuilderTopBar = ({
  contentRight,
  onTabClick,
  view,
}: BuilderTopBarProps) => {
  const { palette } = useTheme();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};

  const canvasData: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  const isFallback = useSelector(selectIsFallback);

  const isTemplateAutoflowWorkflow = useGetIsTemplateIntentWorkflow();

  const handoffConfiguration = useGetHandoffConfigurationForWorkflow();
  const isHandoff = handoffConfiguration !== undefined;
  const isCatchAll = canvasData.intent_id === 'general-handoff';

  const enabledTabs = useMemo(() => {
    const solveEmailOtherQuestionsEnabled = featureFlags.includes(
      'solve_email_other_questions_enabled',
    );

    const isSolveEmailEnabled = featureFlags.includes('solve_email_enabled');
    const isSolveWidgetEnabled = featureFlags.includes('solve_widget_enabled');
    const isSolveApiEnabled = featureFlags.includes('solve_api');
    const isSolveSlackEnabled = featureFlags.includes('solve_slack');
    const isInteractiveEmailEnabled =
      featureFlags.includes('interactive_email');
    const isSolveVoiceEnabled = featureFlags.includes('solve_voice');
    const isInteractiveEmailOnly = isInteractiveEmailEnabled && isFallback;

    const conditions: Record<BuilderView, boolean> = {
      api: isSolveApiEnabled && !isTemplateAutoflowWorkflow,
      email:
        (isSolveEmailEnabled &&
          !isHandoff &&
          !isFallback &&
          !(
            view === 'widget' &&
            isCatchAll &&
            !solveEmailOtherQuestionsEnabled
          ) &&
          !isTemplateAutoflowWorkflow) ||
        isInteractiveEmailOnly,

      slack: isSolveSlackEnabled,
      voice: isSolveVoiceEnabled,
      widget: isSolveWidgetEnabled,
    };

    return tabs.filter(({ view }) => conditions[view]);
  }, [
    featureFlags,
    isHandoff,
    view,
    isCatchAll,
    isTemplateAutoflowWorkflow,
    isFallback,
  ]);

  const shouldShowTabs = enabledTabs.length > 1;

  return (
    <Container hasNoTabs={!shouldShowTabs}>
      <NavBar>
        {enabledTabs.length > 1 &&
          enabledTabs.map(({ beta, label, view: tabView }) => {
            const isSelected = tabView === view;

            return (
              <Tab
                aria-label={`${tabView} tab`}
                isSelected={isSelected}
                key={tabView}
                onClick={() => onTabClick && onTabClick(tabView)}
              >
                <Box alignItems='center' display='flex'>
                  <Typography
                    color={
                      isSelected ? palette.text.primary : palette.text.disabled
                    }
                    variant='font16Medium'
                  >
                    {label}
                  </Typography>
                  {beta && <LabelBadge badgeLabel='Beta' />}
                </Box>
              </Tab>
            );
          })}
      </NavBar>
      {contentRight}
    </Container>
  );
};

export default BuilderTopBar;

const Container = styled('div', {
  shouldForwardProp: prop => isPropValid(String(prop)) && prop !== 'hasNoTabs',
})<{
  hasNoTabs: boolean;
}>`
  display: flex;
  padding: ${props => (props.hasNoTabs ? '8px' : '0')} 40px;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.palette.colors.slate[200]};
  background: ${props => props.theme.palette.background.default};
  height: 48px;
  gap: 8px;
`;

const NavBar = styled('nav')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const PublishButtonContainer = styled('div')<{ minWidth: string }>`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
  min-width: ${props => props.minWidth};
`;

const Tab = styled('a', {
  shouldForwardProp: prop => isPropValid(String(prop)) && prop !== 'isSelected',
})<{
  isSelected: boolean;
}>`
  text-decoration: none;
  padding: 11px 17px;
  font-weight: 600;
  border-bottom: 1px solid
    ${props =>
      props.isSelected
        ? `${props.theme.palette.colors.purple[500]}`
        : 'transparent'};
  cursor: pointer;
`;
