import { memo } from 'react';
import { Box, useTheme } from '@mui/material';

import OverallCards from '../common/OverallCards';
import TabHeader from '../common/TabHeader';
import { stringifyDateRange } from '../helpers';
import { initialInsightWorkflowParams } from './constants';
import WorkflowsTable from './WorkflowsTable';
import { useStateParams } from 'src/hooks/hooks';
import {
  dateRangeDeserialize,
  dateRangeSerialize,
  timeFilterParameterValidator,
} from 'src/utils/discover/helpers';

const SolveInsightsWorkflows = () => {
  const { palette } = useTheme();
  // State params
  const [dateRange, setDateRange] = useStateParams({
    deserialize: dateRangeDeserialize,
    initialState: initialInsightWorkflowParams.date,
    paramsName: 'date',
    serialize: dateRangeSerialize,
    validator: timeFilterParameterValidator([]),
  });

  return (
    <Box
      bgcolor={palette.colors.white}
      display='flex'
      flexDirection='column'
      gap={3}
      height='100%'
      overflow='auto'
      padding='24px 40px'
    >
      <TabHeader
        dateRange={stringifyDateRange(dateRange)}
        scope='main'
        tabName='Workflows'
      />
      <OverallCards dateRange={dateRange} tab='workflow' />
      <Box>
        <WorkflowsTable
          dateRange={dateRange}
          setDateRange={setDateRange}
          tab='workflow'
        />
      </Box>
    </Box>
  );
};

const MemoizedSolveInsightsWorkflows = memo(SolveInsightsWorkflows);

export default MemoizedSolveInsightsWorkflows;
