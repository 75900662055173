import { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from 'react-table';
import { Box } from '@mui/material';

import {
  Badge,
  Pagination,
  SelectDropdown,
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import EditTranslationCell from './EditTranslationCell';
import {
  HeaderCell,
  HeaderCellContainer,
  Row,
  RowCell,
  Table,
} from './EditTranslationPhrasesTab';
import {
  DebouncedGlobalSearchBar,
  TypeColumnFilter,
} from './EditTranslationPhrasesTabHelper';
import { getStepTranslationCopies } from './helpers';
import { formatContextVariableForDisplayInDiv } from './helpers';
import {
  loadUsableContextVariablesForTranslation,
  restoreConfigurationTranslation,
  restoreTranslation,
  setDraftTranslations,
} from 'src/actions/workflow-builder/workflowBuilderActions';
import { useGetIntentsQueryWithProduct } from 'src/hooks/hooks';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { formatContextVariableForPersistence } from 'src/pages/workflow-builder-edit/handoff-configuration/salesforce/helpers';
import {
  selectDraftTranslations,
  selectIsloading,
  selectStepLevelTranslationsId,
  selectStepTranslations,
  selectTranslationsTableMode,
  selectUsableContextVariablesForTranslation,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { SolveWidgetProduct } from 'src/types/types';
import {
  ConfigurationTranslation,
  isStepTranslation,
  StepTranslation,
} from 'src/types/workflowBuilderAPITypes';
import { flattenCvs } from 'src/utils/actionBuilder/helpers';
import { TranslationsTableMode } from 'src/utils/enums';
import { flattenDynamicContextVariables } from 'src/utils/solve/dynamicContextVariableUtils';

interface WidgetTranslationsTableProps {
  isTranslationsLoading: boolean;
  languageOptions: Array<{ label: string; value: string }>;
  onSelectLanguageChange: (language: string) => void;
  product?: SolveWidgetProduct;
  selectedLanguage: string;
}

const useWidgetTranslationTableData = (product: SolveWidgetProduct) => {
  const { data: intentsResponse } = useGetIntentsQueryWithProduct(undefined, {
    productOverride: product,
  });
  const draftTranslations = useSelector(selectDraftTranslations); // translations that is being modified on FE

  return useMemo(() => {
    const draftTranslationsWithIndex = draftTranslations.map(
      (draft, index) => ({
        ...draft,
        index,
      }),
    );
    return draftTranslationsWithIndex.filter(translation => {
      if (!isStepTranslation(translation)) {
        return true;
      }

      return intentsResponse?.intents.find(intent =>
        product === 'interactive_email'
          ? intent.interactive_email_workflow_id ===
            translation.intent_workflow_id
          : intent.intent_workflow_id === translation.intent_workflow_id,
      );
    });
  }, [draftTranslations, intentsResponse?.intents, product]);
};

const WidgetTranslationsTable = ({
  isTranslationsLoading,
  languageOptions,
  onSelectLanguageChange,
  product = 'workflow_builder',
  selectedLanguage,
}: WidgetTranslationsTableProps) => {
  const dispatch = useDispatch();
  const tableMode = useSelector(selectTranslationsTableMode);
  const draftTranslations = useSelector(selectDraftTranslations); // translations that is being modified on FE
  const translationsConfig = useSelector(selectStepTranslations); // translations sent from BE
  const isRestoringTranslations = useSelector(selectIsloading);

  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: false,
    shouldIncludeTriageContextVariables: true,
  });
  const flattenedContextVariables = useMemo(() => {
    return flattenCvs(contextVariables);
  }, [contextVariables]);
  const stepLevelTranslationsId = useSelector(selectStepLevelTranslationsId);
  const usableContextVariables = useSelector(
    selectUsableContextVariablesForTranslation(contextVariables),
  );
  const data = useWidgetTranslationTableData(product);
  const isStepLevelMode = stepLevelTranslationsId !== '';

  const allowPageResetRef = useRef(true);

  // This is to control whether the page index should be reset
  // we should allow the page to be reset once the table is rendered, and disallow
  // reset while the user is typing in one of the editable cells
  const setAllowPageResetRef = (flag: boolean) => {
    allowPageResetRef.current = flag;
  };

  // header for the table
  const columns = useMemo<
    Column<(StepTranslation | ConfigurationTranslation) & { index: number }>[]
  >(
    () => [
      {
        accessor: 'bucket_id',
        // @ts-expect-error `StepTranslation | ConfigurationTranslation` union type doesn't work properly
        Cell: data => data.row.original.display_name,
        // @ts-expect-error `StepTranslation | ConfigurationTranslation` union type doesn't work properly
        Filter: TypeColumnFilter,
        filter: 'equals',
        Header: 'Intent',
      },
      {
        accessor: 'source_text',
        // @ts-expect-error `StepTranslation | ConfigurationTranslation` union type doesn't work properly
        Cell: data =>
          formatContextVariableForDisplayInDiv(
            flattenedContextVariables,
            data.row.original.source_text,
          ),
        Header: () => (
          <Box alignItems='center' display='flex' gap='12px'>
            <span>Phrases</span>
            <Box bgcolor={theme.palette.colors.slate[100]}>
              {tableMode === TranslationsTableMode.DRAFT && (
                <Badge label='Draft' variant='outlined' />
              )}
            </Box>
          </Box>
        ),
      },
      {
        accessor: 'target_text',
        Header: 'Translation',
      },
    ],
    [flattenedContextVariables, tableMode],
  );

  const handleUpdateTranslation = (index: number, newTargetText: string) => {
    // we should disallow auto reset page index when updating a translation
    setAllowPageResetRef(false);
    const newTranslations = [...draftTranslations];
    newTranslations[index] = {
      ...newTranslations[index],
      target_text: formatContextVariableForPersistence(
        flattenedContextVariables,
        newTargetText,
      ),
    };
    dispatch(setDraftTranslations(newTranslations));
  };

  const handleReplaceAll = (indices: number[], newTargetText: string) => {
    // we should disallow auto reset page index when updating translations
    setAllowPageResetRef(false);
    const newTranslations = [...draftTranslations];
    const newFormattedTargetText = formatContextVariableForPersistence(
      usableContextVariables,
      newTargetText,
    );
    indices.forEach(index => {
      newTranslations[index] = {
        ...newTranslations[index],
        target_text: newFormattedTargetText,
      };
    });
    dispatch(setDraftTranslations(newTranslations));
  };

  const handleRestoreTranslation = (
    translation: StepTranslation | ConfigurationTranslation,
    rowIndex: number,
  ) => {
    if (isStepTranslation(translation)) {
      dispatch(
        restoreTranslation(
          tableMode,
          translation.intent_workflow_id,
          translation.step_id,
          translation.element_path,
          selectedLanguage,
          rowIndex,
        ),
      );
    } else {
      dispatch(
        restoreConfigurationTranslation(
          translation.configuration_id,
          selectedLanguage,
          rowIndex,
          translation.bucket_id === 'Workflow tag'
            ? translation.source_text
            : undefined,
          translation.intent_id,
          translation.configuration_path,
        ),
      );
    }
  };

  // maps from the source text to the indices of all the translations records that have the same source text,
  // and the intent names that contain these translations
  const sourceTextMap = useMemo(() => {
    const map: { [key: string]: [number[], string[]] } = {};

    translationsConfig.forEach((translation, index) => {
      if (!(translation.source_text in map)) {
        map[translation.source_text] = [[], []];
      }
      map[translation.source_text][0].push(index);
      map[translation.source_text][1].push(translation.display_name);
    });
    return map;
  }, [translationsConfig]);

  const {
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    page,
    pageCount,
    pageOptions,
    prepareRow,
    setGlobalFilter,
    state,
  } = useTable(
    {
      autoResetFilters: false,
      autoResetGlobalFilter: false,
      autoResetPage: allowPageResetRef.current,
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 40 },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <Box
        sx={{
          '.MuiFormControl-root': {
            width: 'fit-content',
          },
          '.MuiInputBase-root': {
            height: '100%',
          },
          display: 'flex',
          gap: '8px',
          height: '32px',
        }}
      >
        <DebouncedGlobalSearchBar
          ariaLabel='search translations'
          initialValue={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        {!isStepLevelMode &&
          headerGroups.map(headerGroup =>
            headerGroup.headers.map(
              (column, index) => index === 0 && column.render('Filter'),
            ),
          )}
        <SelectDropdown
          id='select-language'
          onChange={e => {
            onSelectLanguageChange(e.target.value);
          }}
          options={languageOptions}
          value={selectedLanguage}
        />
      </Box>
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => {
            const { key, ...restHeaderGroupProps } =
              headerGroup.getHeaderGroupProps();
            return (
              <Row key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column, index) => {
                  const { key, ...restColumn } = column.getHeaderProps();
                  return (
                    <HeaderCell
                      key={key}
                      {...restColumn}
                      isFirstColumn={index === 0}
                    >
                      <HeaderCellContainer>
                        <Typography variant='font14Bold'>
                          {column.render('Header')}
                        </Typography>
                      </HeaderCellContainer>
                    </HeaderCell>
                  );
                })}
              </Row>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            const { key, ...restRowProps } = row.getRowProps();
            return (
              <Row key={key} {...restRowProps}>
                {row.cells.map(cell => {
                  const { key, ...restCellProps } = cell.getCellProps();
                  const translation = cell.row.original;
                  const { affectedIntents, indicesOfCopies } =
                    getStepTranslationCopies(
                      sourceTextMap,
                      translation.index,
                      translation.source_text,
                    );

                  return cell.column.id === 'target_text' ? (
                    <EditTranslationCell
                      allContextVariables={flattenedContextVariables}
                      cell={cell}
                      intentsToReplace={affectedIntents}
                      isDisabled={translation.source_text === ''}
                      isRestoringTranslation={isRestoringTranslations}
                      isTranslationEmpty={
                        translation.target_text.trim() === '' &&
                        translation.source_text !== ''
                      }
                      isTranslationsLoading={isTranslationsLoading}
                      mentionsData={
                        !isStepTranslation(cell.row.original)
                          ? []
                          : flattenDynamicContextVariables(
                              usableContextVariables,
                            ).map(({ fullLabel, id, label }) => ({
                              display: fullLabel || label,
                              id,
                            }))
                      }
                      onReplaceAll={() =>
                        handleReplaceAll(
                          indicesOfCopies,
                          translation.target_text,
                        )
                      }
                      onRestoreTranslation={() =>
                        handleRestoreTranslation(translation, translation.index)
                      }
                      onTextAreaFocus={() => {
                        if (isStepTranslation(translation)) {
                          dispatch(
                            loadUsableContextVariablesForTranslation(
                              translation.intent_workflow_id,
                              translation.step_id,
                              translation.intent_workflow_version,
                            ),
                          );
                        }
                      }}
                      onUpdateTranslations={newTargetText =>
                        handleUpdateTranslation(
                          translation.index,
                          newTargetText,
                        )
                      }
                      repeatedTranslationsLength={indicesOfCopies.length}
                      selectedLanguage={selectedLanguage}
                      setAllowPageResetRef={setAllowPageResetRef}
                      shouldOverrideTranslation={translation.is_override}
                      value={translation.target_text}
                    />
                  ) : (
                    <RowCell
                      key={key}
                      {...restCellProps}
                      isFirstColumn={cell.column.id === 'intent_workflow_id'}
                    >
                      {cell.render('Cell')}
                    </RowCell>
                  );
                })}
              </Row>
            );
          })}
        </tbody>
      </Table>
      {pageCount > 1 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '8px',
          }}
        >
          <Pagination
            count={pageOptions.length}
            onChange={(_, page) => gotoPage(page - 1)}
            page={state.pageIndex + 1}
          />
        </Box>
      )}
    </Box>
  );
};

export default WidgetTranslationsTable;
