import styled from '@emotion/styled';
import { Box } from '@mui/material';

import PolicyCardValue from '../../common/banner-layout/PolicyCardValue';
import costReductionIcon from 'src/assets/images/discover-cost-reduction-icon-white.svg';
import timeReductionIcon from 'src/assets/images/discover-time-reduction-icon-white.svg';
import volumeReductionIcon from 'src/assets/images/discover-volume-reduction-icon-white.svg';
import { DISCOVER_CARD_TEXT } from 'src/components/global-discover/constants';
import {
  useGetMetadataQuery,
  useGetRecommendedPoliciesQuery,
} from 'src/services/discover/discoverApi';
import { getAppCuesId } from 'src/utils/appCuesUtil';
import {
  getCostReductionCopy,
  getDiscoverBannerValues,
} from 'src/utils/discover/helpers';

interface DiscoverPolicyAutomationBannerProps {
  hasScrolled: boolean;
}

const DiscoverPolicyAutomationBanner = ({
  hasScrolled,
}: DiscoverPolicyAutomationBannerProps) => {
  const { data: recommendationData, isLoading } =
    useGetRecommendedPoliciesQuery();
  const { banner_recommendation_value: recommendationValue } =
    recommendationData || {};

  const viewMode = hasScrolled ? 'minimized' : 'full';
  const hasNoData = !recommendationValue;

  const { costValue, fullResolutionTimeValue, volumeValue } =
    getDiscoverBannerValues(recommendationValue, true);

  const { data } = useGetMetadataQuery();
  const { policyTooltip: costReductionTooltip } = getCostReductionCopy(
    data?.ticket_cost_info,
  );

  if (!recommendationValue && !isLoading) {
    return null;
  }

  return (
    <Box
      data-appcues-target={getAppCuesId({
        componentType: 'container',
        featureName: 'recommendation',
        pageName: 'automation',
        subType: 'banner',
      })}
    >
      <PolicyCardValue
        costReduction={{
          emptyStateIcon: hasNoData ? (
            <Image src={costReductionIcon} viewMode={viewMode} />
          ) : null,
          tooltip: costReductionTooltip,
          value: costValue,
        }}
        loading={isLoading}
        resolutionTimeReduction={{
          emptyStateIcon: hasNoData ? (
            <Image src={timeReductionIcon} viewMode={viewMode} />
          ) : null,
          value: fullResolutionTimeValue,
        }}
        title={DISCOVER_CARD_TEXT.automationPageBannerTitle}
        viewMode={viewMode}
        viewType='policy'
        volumeReduction={{
          emptyStateIcon: hasNoData ? (
            <Image src={volumeReductionIcon} viewMode={viewMode} />
          ) : null,
          tooltip: DISCOVER_CARD_TEXT.volumeDescription,
          value: volumeValue + (hasScrolled ? ' tickets' : ''),
        }}
      />
    </Box>
  );
};

const Image = styled('img')<{ viewMode: string }>`
  width: ${props => (props.viewMode === 'minimized' ? '20px' : '32px')};
`;

export default DiscoverPolicyAutomationBanner;
