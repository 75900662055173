import React from 'react';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import { IconInfoCircle, IconX } from '@tabler/icons-react';

import {
  Button,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import BaseModal from 'src/components/base-modal';
import { ListOption } from 'src/components/reusable-components/autocomplete-dropdown/types';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';

const emptyOption = {
  label: '',
  value: '',
};

const validateListOption = (option: ListOption, options: ListOption[]) => {
  const validate = (toValidate: keyof ListOption) => {
    const item = option[toValidate];
    const itemMessage = item?.trim() === '';
    if (toValidate === 'label') {
      return itemMessage;
    }
    const items = options.map(v => v[toValidate]);
    const hasDuplicateValue = new Set(items).size !== items.length;

    return itemMessage || hasDuplicateValue;
  };

  return [validate('label'), validate('value')] as const;
};

type CVListOptionsProps = {
  isOpen: boolean;
  onClose: () => void;
  options: ListOption[];
  updateListOptions: (options: ListOption[]) => void;
  usageByEntity: string[];
};

const CVListOptionsPicker: React.FC<
  React.PropsWithChildren<CVListOptionsProps>
> = ({
  isOpen,
  onClose,
  options,
  updateListOptions,
  usageByEntity,
}: CVListOptionsProps) => {
  const dispatch = useDispatch();

  const handleOptionAddition = (input: ListOption, index: number) => {
    if (index === options.length) {
      updateListOptions([...options, input]);
      return;
    }

    const newOptions = [...options];
    newOptions[index] = input;
    updateListOptions(newOptions);
  };
  const handleOptionDeletion = (optionIndex: number) => {
    const updatedOptions = options.filter((_, index) => index !== optionIndex);
    updateListOptions(updatedOptions);
  };

  const isValid = options.every(option =>
    validateListOption(option, options).every(v => !v),
  );

  const onCloseWithValidation = () => {
    if (!isValid) {
      dispatch(
        setGlobalToastOptions({
          autoHideDuration: 3000,
          title:
            'Please ensure all fields are complete and no values are duplicated',
          variant: 'warning',
        }),
      );
      return;
    }
    onClose();
  };

  return (
    <BaseModal
      headerTitle={<Typography variant='font16Bold'>List items</Typography>}
      isOpen={isOpen}
      onClose={onCloseWithValidation}
    >
      <Box height='450px' overflow='auto'>
        <Box alignItems='center' display='flex' gap={1} mb={1} px={3}>
          <Box
            alignItems='center'
            display='flex'
            flex={1}
            gap='1px'
            maxWidth='50%'
          >
            <Typography variant='font14Bold'>Label</Typography>
            <Tooltip tooltipContent='User facing value that is displayed in the drop-down'>
              <IconInfoCircle size={16} />
            </Tooltip>
          </Box>
          <Box
            alignItems='center'
            display='flex'
            flex={1}
            gap='2px'
            maxWidth='50%'
            ml='-16px'
          >
            <Typography variant='font14Bold'>Value</Typography>
            <Tooltip tooltipContent='Actual value used in the system'>
              <IconInfoCircle size={16} />
            </Tooltip>
          </Box>
        </Box>
        {[...options, { ...emptyOption }].map((option, index) => (
          <Row
            {...option}
            index={index}
            isDisabled={!!usageByEntity?.includes(option.value)}
            isLast={index === options.length}
            key={index}
            onChange={handleOptionAddition}
            onDelete={handleOptionDeletion}
            validations={validateListOption(option, options)}
          />
        ))}
      </Box>
      <Box display='flex' justifyContent='flex-end' p={3}>
        <Button disabled={!isValid} onClick={onClose} variant='main'>
          Done
        </Button>
      </Box>
    </BaseModal>
  );
};

const Row = ({
  index,
  isDisabled,
  isLast,
  label,
  onChange,
  onDelete,
  validations,
  value,
}: ListOption & {
  index: number;
  isDisabled: boolean;
  isLast: boolean;
  onChange: (input: ListOption, index: number) => void;
  onDelete: (index: number) => void;
  validations: readonly [boolean, boolean];
}) => {
  const [labelError, valueError] = isLast ? [false, false] : validations;

  return (
    <Box
      alignItems='center'
      borderTop={theme =>
        isDisabled ? `1px solid ${theme.palette.colors.slate[200]}` : ''
      }
      display='flex'
      gap={1}
      mb={1}
      px={3}
    >
      <TextField
        aria-label='Displayable Label'
        disabled={isDisabled}
        error={labelError}
        onChange={({ target }) => {
          const newValue = value === label ? target.value : value;
          onChange({ label: target.value, value: newValue }, index);
        }}
        placeholder='Start typing to add a new list item'
        value={label}
      />
      <TextField
        aria-label='End Value'
        disabled={isDisabled}
        error={valueError}
        onChange={({ target }) => {
          onChange({ label, value: target.value }, index);
        }}
        value={value}
      />
      <Box flex={1} minWidth='24px'>
        {isLast ? null : (
          <Tooltip
            tooltipContent={
              isDisabled
                ? 'Unable to delete this list option as it is currently in use in an expression.'
                : ''
            }
          >
            <Box>
              <IconButton
                aria-label='Remove row'
                disabled={isDisabled}
                onClick={() => onDelete(index)}
                variant='secondary'
              >
                <IconX />
              </IconButton>
            </Box>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

export default CVListOptionsPicker;
