import Box from '@mui/material/Box';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { TOAST_TIMEOUT } from '../../triage-config-detail-page/helpers';
import { DELETE_TEXTS } from './constants';
import { useDeleteModelMutation } from 'src/services/triage/triageApi';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

interface AutogenDeleteSectionProps {
  modelId?: string;
  onClose: () => void;
}

const AutogenDeleteSection = ({
  modelId,
  onClose,
}: AutogenDeleteSectionProps) => {
  const [mutate, { isLoading }] = useDeleteModelMutation();
  const dispatch = useAppDispatch();

  const handleDelete = async () => {
    if (!modelId || isLoading) {
      return;
    }
    mutate(modelId)
      .unwrap()
      .then(() => {
        dispatch(
          setGlobalToastOptions({
            autoHideDuration: TOAST_TIMEOUT,
            title: 'Model deleted successfully',
            variant: 'default',
          }),
        );
        onClose();
      })
      .catch(() => {
        dispatch(
          setGlobalToastOptions({
            autoHideDuration: TOAST_TIMEOUT,
            title: 'Failed to delete model',
            variant: 'danger',
          }),
        );
      });
  };

  return (
    <Box
      alignItems='center'
      display='flex'
      flexDirection='column'
      height='100svh'
      justifyContent='center'
      p={4}
      rowGap={4}
      sx={{ textAlign: 'center' }}
    >
      <Typography variant='font24'>{DELETE_TEXTS.title}</Typography>
      <Typography variant='font16'>{DELETE_TEXTS.description}</Typography>
      <Box display='grid' gap={1} gridTemplateColumns='1fr 4fr' width='100%'>
        <Button disabled={isLoading} onClick={onClose} variant='secondary'>
          Cancel
        </Button>
        <Button isLoading={isLoading} onClick={handleDelete} variant='danger'>
          Delete
        </Button>
      </Box>
    </Box>
  );
};

export default AutogenDeleteSection;
