import { FC, useMemo } from 'react';
import { Box, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';

import { Skeleton } from '@forethought-technologies/forethought-elements';
import useTicketCounter from '../hooks/useTicketCounter';
import processChannels from '../utils/processChannels';
import TicketCounterCard from './TicketCounterCard';
import { DISCOVER_TEXT } from 'src/components/global-discover/constants';
import { DISCOVER_CARD_MIN_WIDTH } from 'src/constants/discover';
import { useGetMetadataQuery } from 'src/services/discover/discoverApi';

interface TicketCounterCardItem {
  count: number;
  title: string;
  tooltip?: string;
}

const DashboardTicketCounterCards: FC = () => {
  const { palette } = useTheme();
  const {
    all_tickets,
    filter_tickets,
    isError: isTicketCounterError,
    isLoading: isTicketCounterLoading,
    ml_tickets,
  } = useTicketCounter();
  const { data } = useGetMetadataQuery();
  const channels = data?.channels ?? [];
  const joinedChannels = processChannels(channels);
  const ticketCounterCardItems: TicketCounterCardItem[] = useMemo(() => {
    return [
      {
        count: all_tickets,
        title: 'Total tickets',
        tooltip: DISCOVER_TEXT.totalTicketTooltip,
      },
      {
        count: filter_tickets,
        title: 'Filtered tickets',
        tooltip: DISCOVER_TEXT.filteredTicketTooltip + joinedChannels,
      },
      {
        count: ml_tickets,
        title: 'Discover uncategorized tickets',
        tooltip: DISCOVER_TEXT.mlTicketTooltip,
      },
    ];
  }, [all_tickets, filter_tickets, ml_tickets, joinedChannels]);
  if (isTicketCounterError && !isTicketCounterLoading) {
    return null;
  }
  return (
    <>
      {!isTicketCounterLoading
        ? ticketCounterCardItems.map(item => (
            <Grid
              item
              key={item.title}
              lg={true}
              md={true}
              minWidth={DISCOVER_CARD_MIN_WIDTH}
              xs={true}
            >
              <TicketCounterCard
                count={item.count}
                title={item.title}
                tooltip={item.tooltip}
              />
            </Grid>
          ))
        : ticketCounterCardItems.map(item => (
            <Grid
              item
              key={`skele${item.title}`}
              lg={true}
              md={true}
              minWidth={DISCOVER_CARD_MIN_WIDTH}
              xs={true}
            >
              <Skeleton height='150px' />
            </Grid>
          ))}
      <Box
        borderBottom={`1px solid ${palette.colors.grey[100]}`}
        height='1px'
        ml={3}
        mt={2}
        width='100%'
      />
    </>
  );
};

export default DashboardTicketCounterCards;
