import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useLocation, useNavigate } from 'react-router';
import { ReactSVG } from 'react-svg';
import { styled, Typography } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import {
  IconBulb,
  IconMessage2,
  IconTemplate,
  IconX,
} from '@tabler/icons-react';

import {
  Button,
  EllipsisWithTooltip,
  IconButton,
  LoadingIndicator,
  Skeleton,
  Tooltip,
  VerticalTabBar,
} from '@forethought-technologies/forethought-elements';
import BuilderTopBar, {
  PublishButtonContainer,
} from '../builder-top-bar/BuilderTopbar';
import { WidgetToggle } from '../builder-top-bar/WorkflowPublishButtons';
import CanvasTagger from '../canvas-tagger/CanvasTagger';
import { TEMPLATE_INTENT_WORKFLOW_ID_PREFIX } from '../constants';
import IntentEmailToggleBar from '../email-builder-page/IntentEmailToggleBar';
import {
  useGetAutoflowWorkflowOrAutoflowTemplate,
  useGetIsTemplateIntentWorkflow,
  useGetTemplateAutoflowWorkflow,
} from '../hooks';
import { WorkflowBuilderProps } from '../types';
import { Features, Pages } from '../workflow-builder/appCuesIdentifiers';
import { createIcon } from '../workflow-builder/business-logic/createIcon';
import { ProductBanner } from '../workflow-builder/ProductBanner';
import { WidgetModeButtonGroup } from '../workflow-builder/WidgetModeButtonGroup';
import WorkflowPublishButton from '../workflow-builder/workflow-publish-button/WorkflowPublishButton';
import { HistoryButtonWrapper } from '../workflow-builder/WorkflowBuilder';
import { WorkflowRecommendationContent } from '../WorkflowDescription';
import ActionDrawer from './action-drawer';
import { EditToolDrawer } from './EditToolDrawer';
import { getWorkflowValueFromSearch, validateAutoflowPolicy } from './helpers';
import { useGetUsableTools } from './hooks';
import { TemplateDrawer } from './template-drawer';
import VersionHistoryDrawer from './version-history-drawer';
import debounce from 'lodash/fp/debounce';
import aaBadgeSrc from 'src/assets/images/autonomous-agent-badge.svg';
import historyIcon from 'src/assets/images/history-icon.svg';
import SupportGPTIcon from 'src/assets/images/support-gpt.svg';
import { RichTextEditor } from 'src/components/rich-text-editor/RichTextEditor';
import { EditorRef } from 'src/components/rich-text-editor/types';
import { TemplateBadge } from 'src/components/template-badge';
import { useFlamethrowerTrackingEventAction } from 'src/hooks/hooks';
import { NONE_RTE_INPUT_DEBOUNCE_DELAY } from 'src/pages/solve-config/constants';
import { selectUserRole } from 'src/reducers/userReducer/userReducer';
import {
  selectCanvasWorkflowBuilder,
  selectIntentData,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  useChangeWorkflowPublishStatusMutation,
  useDiscardAutoflowWorkflowChangesMutation,
  useUpdatePolicyDescriptionMutation,
} from 'src/services/workflowBuilderAutoflowApi.ts/workflowBuilderAutoflowApi';
import {
  CanvasWorkflowBuilderState,
  selectIsDuplicatedPendingCustomization,
  selectIsWorkflowActive,
  selectWorkflowRecommendation,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { loadDraftWorkflow } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { useAppDispatch } from 'src/store/hooks';
import { getAppCuesId } from 'src/utils/appCuesUtil';
import {
  FlamethrowerTrackingApplications,
  FlamethrowerTrackingEventTypes,
  Routes,
  UserRoles,
} from 'src/utils/enums';

const useGetIntentPhrases = () => {
  const intent = useSelector(selectIntentData);
  const { template } = useGetTemplateAutoflowWorkflow();

  return (
    template?.template_intent_phrases ??
    intent?.phrases?.map(phrase => phrase.phrase_text) ??
    []
  );
};

const AUTOFLOW_TABS = [
  {
    component: WorkflowRecommendationContent,
    Icon: createIcon('IconSupportGPT'),
    label: 'Summary Insights',
    title: 'Summary Insights',
  },
  {
    component: ActionDrawer,
    Icon: IconBulb,
    label: 'Available actions',
    title: 'Available actions',
  },
  {
    component: TemplateDrawer,
    Icon: IconTemplate,
    label: 'Templates',
    title: 'Templates',
  },
];

const AutonomousAgentTab = ({ onTabClick, view }: WorkflowBuilderProps) => {
  const isDuplicatedPendingCustomization = useSelector(
    selectIsDuplicatedPendingCustomization,
  );
  const [tabIndex, setTabIndex] = useState(0);

  const editorRef = useRef<EditorRef>(null);
  const discardedRef = useRef(false);
  const isTemplateIntentWorkflow = useGetIsTemplateIntentWorkflow();
  const workflowRecommendation = useSelector(selectWorkflowRecommendation);

  const phrases = useGetIntentPhrases();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isCurrentWorkflowActive = useSelector(selectIsWorkflowActive);
  const canvasData: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );

  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};

  const { palette } = useTheme();
  const { search } = useLocation();

  const intentId = getWorkflowValueFromSearch(search, 'intentId');
  const intentWorkflowId = getWorkflowValueFromSearch(search, 'workflowId');

  const { autoflowWorkflow, isFetching, isLoading } =
    useGetAutoflowWorkflowOrAutoflowTemplate(intentWorkflowId);

  const [, { isLoading: isLoadingChangeWorkflowPublishStatusMutation }] =
    useChangeWorkflowPublishStatusMutation();
  const dispatchTrackingAction = useFlamethrowerTrackingEventAction(
    FlamethrowerTrackingApplications.WORKFLOW_BUILDER_AUTOFLOW_CANVAS,
  );
  const userRole = useSelector(selectUserRole);
  const shouldShowWorkflowRecommendation =
    userRole === UserRoles.ROLE_SUPER_ADMIN && workflowRecommendation;

  const [isVersionHistoryPanelOpen, setIsVersionHistoryPanelOpen] =
    useState(false);

  const [editToolId, setEditToolId] = useState('');
  const [editorKey, setEditorKey] = useState(0);
  const { tools } = useGetUsableTools();

  const isVersionHistoryEnabled = featureFlags.includes(
    'autoflow_version_history',
  );
  const isAutoflowTemplatesEnabled =
    featureFlags.includes('autoflow_templates');

  const [
    updatePolicyDescription,
    { data: updatedData, isLoading: isLoadingPolicyDescriptionMutation },
  ] = useUpdatePolicyDescriptionMutation();

  const [discardAutoflowWorkflowChanges] =
    useDiscardAutoflowWorkflowChangesMutation();

  useEffect(() => {
    if (intentWorkflowId && !isTemplateIntentWorkflow) {
      // need draft WF data to fetch workflow types
      dispatch(loadDraftWorkflow(intentWorkflowId));
    }
  }, [dispatch, intentWorkflowId, isTemplateIntentWorkflow]);

  const {
    policy_description: policy = '',
    status,
    unsaved_changes: hasUnsavedChanges = false,
  } = autoflowWorkflow ?? {};

  useEffect(() => {
    if (discardedRef.current) {
      // syncs the editor to the discarded state
      discardedRef.current = false;
      setEditorKey(prev => prev + 1);
    }
  }, [policy]);

  const tabs = useMemo(() => {
    const props = { intentWorkflowId, setEditToolId };

    return AUTOFLOW_TABS.filter(tab => {
      if (tab.label === 'Templates' && !isAutoflowTemplatesEnabled) {
        return false;
      }

      if (
        tab.label === 'Summary Insights' &&
        !shouldShowWorkflowRecommendation
      ) {
        return false;
      }
      return true;
    }).map(tab => ({
      ...tab,
      component: <tab.component {...props} />,
    }));
  }, [
    intentWorkflowId,
    isAutoflowTemplatesEnabled,
    shouldShowWorkflowRecommendation,
    setEditToolId,
  ]);

  const toolBeingEdited = useMemo(() => {
    return tools.find(tool => tool.tool_id === editToolId);
  }, [tools, editToolId]);

  const isAutoflowWorkflow = status === 'enabled';

  const debouncedSavePolicyDescription = useMemo(
    () =>
      debounce(NONE_RTE_INPUT_DEBOUNCE_DELAY, async (editedPolicy: string) => {
        await updatePolicyDescription({
          body: { policy_description: editedPolicy },
          intentWorkflowId,
        });
      }),
    [intentWorkflowId, updatePolicyDescription],
  );

  const handlePreview = (channel: string) => {
    const previewPath = generatePath(Routes.WORKFLOW_BUILDER_PREVIEW, {
      channel: channel,
    });

    navigate({
      pathname: previewPath,
      search: `?intentId=${
        isTemplateIntentWorkflow
          ? TEMPLATE_INTENT_WORKFLOW_ID_PREFIX +
            autoflowWorkflow?.intent_workflow_id
          : intentId
      }&mode=autoflow`,
    });
  };

  const { intent_title: intentTitle } = canvasData;

  const shouldShowSaving =
    isLoadingChangeWorkflowPublishStatusMutation ||
    isLoadingPolicyDescriptionMutation ||
    isFetching;

  const isActionDrawerExpanded = tabIndex !== -1;

  return (
    <>
      <BuilderTopBar
        contentRight={
          shouldShowSaving ? (
            <Box
              alignItems='center'
              aria-busy
              display='flex'
              gap='2px'
              justifyContent='center'
              key='123'
              minWidth='276px'
            >
              <Typography
                color={palette.colors.purple[500]}
                variant='font14Bold'
              >
                Saving
              </Typography>
              <LoadingIndicator
                color={palette.colors.purple[500]}
                size='large'
              />
            </Box>
          ) : (
            <PublishButtonContainer
              minWidth={hasUnsavedChanges ? '450px' : 'auto'}
            >
              {!isTemplateIntentWorkflow && <CanvasTagger />}
              {isVersionHistoryEnabled && !isTemplateIntentWorkflow && (
                <Tooltip tooltipContent='Show version history'>
                  <HistoryButtonWrapper
                    shouldShowVersionHistory={Boolean(
                      isVersionHistoryPanelOpen,
                    )}
                  >
                    <IconButton
                      aria-label='History'
                      data-appcues-target={getAppCuesId({
                        componentType: 'VersionHistory',
                        featureName: Features.CANVAS,
                        pageName: Pages.SOLVE,
                        subType: 'ToggleButton',
                      })}
                      onClick={() => {
                        dispatchTrackingAction(
                          FlamethrowerTrackingEventTypes.WORKFLOW_VERSION_CONTROL_PANEL_OPENED,
                          {
                            intent_id: intentWorkflowId || '',
                            intent_name: intentTitle || '',
                            org_id: canvasData.org_id,
                          },
                        );
                        setIsVersionHistoryPanelOpen(true);
                        setTabIndex(-1);
                      }}
                      variant='ghost'
                    >
                      <ReactSVG src={historyIcon} />
                    </IconButton>
                  </HistoryButtonWrapper>
                </Tooltip>
              )}
              {hasUnsavedChanges && (
                <>
                  <EllipsisWithTooltip tooltipContent='You have unsaved changes'>
                    <Typography color={palette.text.secondary} variant='font14'>
                      You have unsaved changes
                    </Typography>
                  </EllipsisWithTooltip>
                  <Button
                    onClick={() => {
                      discardAutoflowWorkflowChanges(intentWorkflowId);
                      discardedRef.current = true;
                    }}
                    variant='secondary'
                  >
                    Discard draft
                  </Button>
                </>
              )}
              {isCurrentWorkflowActive &&
                isAutoflowWorkflow &&
                !hasUnsavedChanges &&
                !isTemplateIntentWorkflow && <WidgetToggle />}
              {view !== 'slack' && (
                <Button
                  onClick={() => {
                    handlePreview(view || 'widget');
                  }}
                  variant='secondary'
                >
                  Preview
                </Button>
              )}
              <WorkflowPublishButton
                hasUnsavedChanges={hasUnsavedChanges}
                validationMessage={
                  validateAutoflowPolicy(
                    updatedData?.policy_description ?? policy,
                  ) ||
                  (isDuplicatedPendingCustomization &&
                    'Start from scratch or start from existing workflow to publish')
                }
              />
            </PublishButtonContainer>
          )
        }
        onTabClick={view => onTabClick && onTabClick(view)}
        view={view}
      />
      <Box display='flex' height='100%'>
        {!isTemplateIntentWorkflow && view !== 'voice' && (
          <Box
            alignItems='flex-start'
            display='flex'
            justifyContent='center'
            maxWidth='150px'
            pt={3}
            width='100%'
          >
            <WidgetModeButtonGroup />
          </Box>
        )}
        <Box
          alignItems='center'
          display='flex'
          flexDirection='column'
          flexGrow='1'
          height='100%'
          justifyContent='flex-start'
          overflow='auto'
          pb='60px'
          pt={isTemplateIntentWorkflow ? 0 : 3}
        >
          {isTemplateIntentWorkflow && (
            <Box
              sx={{
                alignItems: 'center',
                background:
                  'linear-gradient(270deg, rgba(189, 0, 255, 0.10) 0%, rgba(56, 102, 245, 0.10) 100%);',
                display: 'flex',
                justifyContent: 'center',
                mb: 4,
                py: 1,
                width: '100%',
              }}
            >
              <Box
                alignItems='center'
                display='inline-flex'
                gap={3}
                width='710px'
              >
                <TemplateBadge />
                <Typography variant='font14'>
                  This is a read-only template. &lsquo;Clone template&rsquo; and
                  adapt the policy to your needs.
                </Typography>
              </Box>
            </Box>
          )}
          {view !== 'widget' && (
            <ProductBanner
              onStartFromScratch={() => {
                discardedRef.current = true;
              }}
              width='710px'
            />
          )}
          <Box
            alignItems='flex-start'
            display='flex'
            gap='20px'
            mb={5}
            width='710px'
          >
            <IconMessage2 color={palette.colors.grey[600]} size={20} />
            <Box
              maxWidth='100%'
              overflow='hidden'
              textOverflow='ellipsis'
              width='100%'
            >
              <Box display='flex' mb='6px'>
                <Typography
                  color={palette.colors.grey[600]}
                  variant='font12Medium'
                >
                  Customer Issue
                </Typography>
              </Box>

              {isLoading ? (
                <Skeleton variant='text' width={710} />
              ) : (
                <Typography noWrap variant='font14'>
                  <i>{phrases.map(phrase => `'${phrase}'`).join(', ')}</i>
                </Typography>
              )}
            </Box>
          </Box>
          <Box
            alignItems='center'
            display='flex'
            justifyContent='center'
            mb={5}
            py={3}
            sx={{
              '& .ProseMirror': {
                fontSize: '20px',
                lineHeight: '30px',
                padding: '0 !important',
              },
              '& .remirror-editor-wrapper': {
                padding: '0 !important',
              },
              '& .remirror-mention-atom': {
                fontSize: '20px',
                lineHeight: '30px',
              },
              transition: 'all 0.1s ease-in-out',
            }}
            width='100%'
          >
            <FilledJourneyStepCard>
              {isLoading ? (
                <Skeleton variant='text' width={710} />
              ) : (
                <RichTextEditor
                  editable={
                    !isTemplateIntentWorkflow &&
                    !isDuplicatedPendingCustomization
                  }
                  editorRef={editorRef}
                  initialContent={policy}
                  // required for rerendering the editor
                  key={String(editorKey) + autoflowWorkflow?.intent_workflow_id}
                  label='AutoFlow policy'
                  onChange={debouncedSavePolicyDescription}
                  placeholder='Start typing your policy here...'
                  shouldReplaceNewlines
                  shouldShowToolbar={false}
                  shouldValidateHrefLength
                  withEmojiButton={false}
                  withExtraMentions
                />
              )}
            </FilledJourneyStepCard>
          </Box>
        </Box>
        <Box display={toolBeingEdited ? 'flex' : undefined}>
          {!Boolean(editToolId) ? (
            isActionDrawerExpanded ? (
              <Box
                bgcolor={palette.colors.white}
                height={view === 'email' ? 'calc(100% - 81px)' : '100%'}
              >
                {view === 'email' && <IntentEmailToggleBar />}
                <VerticalTabBar
                  menuColor={palette.colors.white}
                  menuWidth={80}
                  setTabIndex={setTabIndex}
                  tabBodyWidth={400}
                  tabIndex={tabIndex}
                  tabs={tabs}
                  titleEndAdornment={
                    <IconButton
                      aria-label='Close drawer'
                      onClick={() => setTabIndex(-1)}
                      variant='ghost'
                    >
                      <IconX color={palette.colors.black} size={20} />
                    </IconButton>
                  }
                />
              </Box>
            ) : (
              <FloatingDiv>
                {shouldShowWorkflowRecommendation && (
                  <FloatingButton onClick={() => setTabIndex(0)}>
                    <img
                      alt=''
                      height='24px'
                      src={SupportGPTIcon}
                      width='24px'
                    />
                  </FloatingButton>
                )}

                <FloatingButton
                  onClick={() =>
                    setTabIndex(shouldShowWorkflowRecommendation ? 1 : 0)
                  }
                >
                  <IconBulb color={palette.colors.grey[700]} size={24} />
                </FloatingButton>
                {isAutoflowTemplatesEnabled && (
                  <FloatingButton
                    onClick={() =>
                      setTabIndex(shouldShowWorkflowRecommendation ? 2 : 1)
                    }
                  >
                    <IconTemplate color={palette.colors.grey[700]} size={24} />
                  </FloatingButton>
                )}
              </FloatingDiv>
            )
          ) : (
            <EditToolDrawer
              intentWorkflowId={intentWorkflowId}
              key={editToolId}
              onClose={() => setEditToolId('')}
              tool={toolBeingEdited}
            />
          )}
        </Box>
        {isVersionHistoryPanelOpen && isVersionHistoryEnabled && (
          <VersionHistoryDrawer
            intentWorkflowId={intentWorkflowId}
            setIsVersionHistoryPanelOpen={setIsVersionHistoryPanelOpen}
          />
        )}
      </Box>
    </>
  );
};

interface FilledJourneyStepCardProps extends BoxProps {
  children: React.ReactNode;
}

const FilledJourneyStepCard = ({
  children,
  ...boxProps
}: FilledJourneyStepCardProps) => {
  const { palette } = useTheme();

  return (
    <Box alignItems='flex-start' display='flex' width='710px' {...boxProps}>
      <img alt='' src={aaBadgeSrc} width='24px' />
      <Box display='flex' flex={1} flexDirection='column' ml={1}>
        <Typography variant='font20'>Autoflow policy</Typography>
        <Box
          pt='6px'
          sx={{
            color: palette.colors.black,
            fontSize: '20px',
            fontWeight: '400',
            lineHeight: '30px',
            whiteSpace: 'break-spaces',
          }}
          width='100%'
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

const FloatingButton = styled('button')`
  border-radius: 24px;
  background: #fff;
  box-shadow: 2px 8px 16px 0px rgba(3, 17, 38, 0.11),
    0px 0px 1px 0px rgba(0, 12, 32, 0.02);
  cursor: pointer;

  padding: 12px;

  &:hover {
    background-color: ${props => props.theme.palette.colors.grey[100]};
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FloatingDiv = styled('div')`
  position: absolute;
  top: 140px;
  right: 24px;
  transition: all 0.1s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

export default AutonomousAgentTab;
