const processChannels = (channels: string[]) => {
  // Take a set of channels that are all lowercase and have underscores,
  // and return a string with the first letter of each word capitalized and spaces instead of underscores.
  if (!channels.length) {
    return '';
  }
  const prefix = '\n\nIncluded channels are: ';
  return (
    prefix +
    channels
      .map(channel => {
        return channel
          .split('_')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      })
      .join(', ')
  );
};

export default processChannels;
