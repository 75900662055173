import { ListOption } from '@forethought-technologies/forethought-elements';
import {
  FieldPredictorConfig,
  JiraTicketField,
  KustomerTicketField,
  TriageHelpdeskTicketFields,
  TriageKustomerTicketFields,
  TriageSalesforceTicketFields,
  ZendeskTicketField,
} from './types';
import { Helpdesk } from 'src/components/app/types';

export const isSalesforceTicketFields = (
  ticketFields: TriageHelpdeskTicketFields | undefined,
): ticketFields is TriageSalesforceTicketFields => {
  return (
    (ticketFields as TriageSalesforceTicketFields)?.case_origins !== undefined
  );
};

export const isKustomerTicketFields = (
  ticketFields: TriageHelpdeskTicketFields | undefined,
): ticketFields is TriageKustomerTicketFields => {
  return (ticketFields as TriageKustomerTicketFields) !== undefined;
};

export const getFieldPredictorOutputField = (
  helpdesk: Helpdesk | undefined | null,
  fieldsToPredict: FieldPredictorConfig,
): Record<string, string> | undefined => {
  switch (helpdesk) {
    case 'zendesk':
      return fieldsToPredict?.outputs[0]?.written_object
        .custom_fields as Record<string, string>;
    case 'salesforce':
      return fieldsToPredict?.outputs[0]?.written_object as Record<
        string,
        string
      >;
    case 'kustomer':
      return fieldsToPredict?.outputs[0]?.written_object
        .custom_fields as Record<string, string>;
    case 'jira': {
      const customFields =
        fieldsToPredict?.outputs[0]?.written_object.custom_fields;
      if (customFields && typeof customFields === 'object') {
        const [key, value] = Object.entries(customFields)[0] || [];
        return key && value ? { [key]: value as string } : undefined;
      }
      return undefined;
    }
    default:
      return;
  }
};

export const getDataSourcePredictedField = (
  fieldToPredictOutputFieldId: string,
  ticketFields: TriageHelpdeskTicketFields | undefined,
): ListOption | undefined => {
  if (isSalesforceTicketFields(ticketFields)) {
    const foundSalesforceField = ticketFields.output_fields?.find(
      field => field.name === fieldToPredictOutputFieldId,
    );

    if (foundSalesforceField) {
      return {
        label: foundSalesforceField.label,
        value: foundSalesforceField.name,
      };
    }
    return;
  }

  const foundZendeskField = ticketFields?.output_fields?.find(
    (field): field is ZendeskTicketField =>
      'name' in field && String(field.id) === fieldToPredictOutputFieldId,
  );

  if (foundZendeskField) {
    return {
      label: foundZendeskField.name,
      value: foundZendeskField.id.toString(),
    };
  }

  const foundKustomerField = ticketFields?.output_fields?.find(
    (field): field is KustomerTicketField =>
      'title' in field && field.id === fieldToPredictOutputFieldId,
  );

  if (foundKustomerField) {
    return {
      label: foundKustomerField.title || foundKustomerField.helpdesk_field_id,
      value: foundKustomerField.id,
    };
  }

  const foundJiraField = ticketFields?.output_fields?.find(
    (field): field is JiraTicketField =>
      'title' in field && field.id === fieldToPredictOutputFieldId,
  );

  if (foundJiraField) {
    return {
      label: foundJiraField.title || foundJiraField.helpdesk_field_id,
      value: foundJiraField.id,
    };
  }
};
