import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, styled } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { ButtonStepFieldsType } from '../solve-workflows/types/EditButtonStepTypes';
import profileBot from './assets/icons/profile-bot.svg';
import StepContainer from './workflow-builder/revamped/StepContainer';
import StepHeader from './workflow-builder/revamped/StepHeader';
import TextBox from './workflow-builder/revamped/TextBox';
import BranchStepValidIndicator from './BranchStepValidIndicator';
import TextStep from './TextStep';
import UndefinedContextVariablesTooltip from './UndefinedContextVariablesTooltip';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { selectCanvasWorkflowBuilder } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  selectActionCaseMap,
  selectArticleSuggestionFeedbackSettings,
  setArticleSuggestionActionCase,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { ActionTransitionBranch } from 'src/types/workflowBuilderAPITypes';
import { QuasiStepTypes, StepTypes } from 'src/utils/enums';

interface DynamicArticleSuggestionStepProps {
  allStepsWithinAction: string[];
  isBeingEdited: boolean;
  isCanvasDisabled: boolean;
  stepId: string;
  transitionBranches: ActionTransitionBranch[] | undefined;
}

export const DynamicArticleSuggestionStep: React.FC<
  React.PropsWithChildren<DynamicArticleSuggestionStepProps>
> = ({
  allStepsWithinAction,
  isBeingEdited,
  isCanvasDisabled,
  stepId,
  transitionBranches,
}) => {
  const dispatch = useDispatch();
  const canvasData = useSelector(selectCanvasWorkflowBuilder);
  const actionCaseMap = useSelector(selectActionCaseMap);
  const articleSuggestionFeedbackSettings = useSelector(
    selectArticleSuggestionFeedbackSettings,
  );

  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });

  const buttonStepId =
    allStepsWithinAction.find(
      stepId => canvasData.steps[stepId].step_type === StepTypes.BUTTONS,
    ) || '';
  const buttonStep = !buttonStepId ? undefined : canvasData.steps[buttonStepId];
  const buttonStepFields = buttonStep
    ? (buttonStep?.step_fields as ButtonStepFieldsType)
    : undefined;
  const shouldDisplayButtonPrompt =
    articleSuggestionFeedbackSettings && isBeingEdited
      ? articleSuggestionFeedbackSettings.show_buttons
      : buttonStep !== undefined && buttonStepFields !== undefined;

  const articleSuggestionStepId =
    allStepsWithinAction.find(
      stepId =>
        canvasData.steps[stepId].step_type === StepTypes.ARTICLE_SUGGESTION,
    ) || '';

  /**
   * Helpful text step is part of the dynamic article suggestion step, so we render them inside this component
   * @returns a text step if a helpful text is configured for the dynamic article suggestion under the selected branch
   */
  const getHelpfulTextStep = () => {
    const isBranchChosen = canvasData.actionCaseMap[stepId] !== undefined;
    if (!isBranchChosen) {
      return null;
    }
    const isYesBranchChosen = canvasData.actionCaseMap[stepId];
    const transitionParentStepId = isYesBranchChosen
      ? transitionBranches?.find(
          transition => transition.display_name === 'Yes',
        )?.parent_step_id
      : transitionBranches?.find(transition => transition.display_name === 'No')
          ?.parent_step_id;
    const transitionParentStep = canvasData.steps[transitionParentStepId || ''];
    if (!transitionParentStep) {
      return null;
    }
    const isTextStep =
      transitionParentStep.step_type === StepTypes.TEXT_MESSAGE;
    if (isTextStep) {
      return (
        <Box display='flex' marginLeft='-40px'>
          <Image src={profileBot} />
          <TextStep
            contextVariables={contextVariables}
            isCanvasDisabled={isCanvasDisabled}
            isInAction={true}
            shouldShowEditMenu={false}
            step={transitionParentStep}
            stepId={transitionParentStepId || ''}
          />
        </Box>
      );
    }
  };

  return (
    <>
      <StepContainer hasGradientBorder>
        <StepHeader stepType={QuasiStepTypes.DYNAMIC_ARTICLE_SUGGESTION}>
          <Box ml='auto'>
            <UndefinedContextVariablesTooltip
              contextVariables={contextVariables}
              stepId={articleSuggestionStepId}
            />
          </Box>
        </StepHeader>
        <Box mb={1}>
          <Typography variant='font16Bold'>
            Dynamic Article Suggestion
          </Typography>
        </Box>
      </StepContainer>
      {shouldDisplayButtonPrompt && (
        <StepContainer paddingTop='0'>
          <StepHeader stepType={QuasiStepTypes.FEEDBACK} />
          <Box display='flex' flexDirection='column' gap={1}>
            <TextBox
              isRichText
              value={
                articleSuggestionFeedbackSettings?.button_prompt ??
                buttonStepFields?.prompt
              }
              variant='dark'
            />
            {buttonStepFields ? (
              buttonStepFields.options.map((option, index) => {
                const transitionBranch = buttonStep?.transitions.find(
                  transition => {
                    const conditionExpr = transition.condition_expression;
                    return (
                      conditionExpr?.field ===
                        buttonStepFields.output_variable &&
                      conditionExpr?.values?.includes(option.value)
                    );
                  },
                );
                const branchStepId = transitionBranch?.step_id;
                return (
                  <TextBox
                    index={index}
                    isSelected={
                      option.value === 'yes_resolve'
                        ? actionCaseMap?.[stepId] === true
                        : actionCaseMap?.[stepId] === false
                    }
                    key={option.value}
                    onClick={() => {
                      dispatch(
                        setArticleSuggestionActionCase({
                          stepId: stepId,
                          value: option.value === 'yes_resolve',
                        }),
                      );
                    }}
                    placeholder='Empty option'
                    validIndicator={
                      <BranchStepValidIndicator
                        transitionBranchStepId={branchStepId}
                        undefinedTransitionCvs={[]}
                      />
                    }
                    value={
                      (option.value === 'yes_resolve'
                        ? articleSuggestionFeedbackSettings?.helpful_button_text
                        : articleSuggestionFeedbackSettings?.not_helpful_button_text) ??
                      option.display_name
                    }
                  />
                );
              })
            ) : (
              <>
                <TextBox
                  index={0}
                  placeholder='Empty option'
                  validIndicator={
                    <BranchStepValidIndicator
                      transitionBranchStepId={null}
                      undefinedTransitionCvs={[]}
                    />
                  }
                  value={articleSuggestionFeedbackSettings?.helpful_button_text}
                />
                <TextBox
                  index={0}
                  placeholder='Empty option'
                  validIndicator={
                    <BranchStepValidIndicator
                      transitionBranchStepId={null}
                      undefinedTransitionCvs={[]}
                    />
                  }
                  value={
                    articleSuggestionFeedbackSettings?.not_helpful_button_text
                  }
                />
              </>
            )}
          </Box>
        </StepContainer>
      )}
      {shouldDisplayButtonPrompt && getHelpfulTextStep()}
    </>
  );
};

const Image = styled('img')`
  width: 32px;
  height: 32px;
  margin-right: 8px;
  margin-top: 18px;
`;
