import { useSelector } from 'react-redux';

import { datePickerRangeOptions } from 'src/constants/discover';
import { useGetTopicDetailMetrics } from 'src/hooks/discover/useGetTopicDetailMetrics';
import { selectGlobalDiscoverOptions } from 'src/slices/ui/uiSlice';
import { dateRangeToTimeFilter } from 'src/utils/discover/helpers';

const useAutomationTopic = () => {
  // This hook is meant to be used in GlobalDiscover, to get the topic to automate

  // Constants to make metric query happy
  const dateRange = datePickerRangeOptions[2].value;
  const timeFilter = dateRangeToTimeFilter(dateRange);

  const { topicIDToAutomate, topicToAutomate } = useSelector(
    selectGlobalDiscoverOptions,
  );
  const { data: topicDetailMetricData, loading } = useGetTopicDetailMetrics({
    dataFilterQuery: [],
    interval: 'daily',
    timeFilter: timeFilter,
    topicId: topicIDToAutomate,
  });
  const topic = topicToAutomate ?? topicDetailMetricData?.topic ?? null;
  const topicId = topicIDToAutomate ?? topicToAutomate?.topic_id;
  if (!topicId || topic?.topic_id !== topicId) {
    return {
      loading: true,
      topic: null,
      topicId: undefined,
    };
  }
  return {
    loading,
    topic,
    topicId,
  };
};

export default useAutomationTopic;
