import { FormikErrors } from 'formik';
import { styled, useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import {
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  CreateModelFieldItem,
  CreateModelFormValue,
} from '../../triage-models-overview-page/types';

interface CreateModelTextFieldProps {
  disabled: boolean;
  errors: FormikErrors<CreateModelFormValue>;
  item: CreateModelFieldItem;
  setFieldValue: (field: string, value: string) => void;
  values: CreateModelFormValue;
}

const CreateModelTextField = ({
  disabled,
  errors,
  item,
  setFieldValue,
  values,
}: CreateModelTextFieldProps) => {
  const { palette } = useTheme();
  const lightColor = palette.colors.grey[400];
  const redColor = palette.colors.red[500];

  return (
    <Box display='flex' flexDirection='column' key={item.key} rowGap={1}>
      <Typography variant='font16Bold'>{item.label}</Typography>
      <Label htmlFor={item.key}>{item.description}</Label>
      <TextField
        aria-label={item.label}
        disabled={disabled}
        error={Boolean(errors[item.key])}
        maxLength={item.maxLength ?? 100}
        multiline={Boolean(item.rows)}
        onChange={e => {
          setFieldValue(item.key, e.target.value);
        }}
        placeholder={item.placeholder}
        rows={item.rows}
        value={values[item.key]}
      />
      {Boolean(item.info) && (
        <Typography color={lightColor} variant='font12'>
          {item.info}
        </Typography>
      )}
      {Boolean(errors[item.key]) && (
        <Typography color={redColor} variant='font12'>
          {errors[item.key]}
        </Typography>
      )}
    </Box>
  );
};

const Label = styled('label')(({ theme }) => ({
  color: theme.palette.colors.grey[600],
  fontSize: '12px',
}));

export default CreateModelTextField;
