import { createAsyncThunk } from '@reduxjs/toolkit';

import { DiscoverActions } from '../actionTypes';
import {
  TopicPeriodicalFilter,
  TopicTimeFilter,
} from 'src/components/app/types';
import {
  ArticleIntegrations,
  DiscoverArticle,
  DiscoverArticlePublishRequest,
  DiscoverRecommendationDetailsResponse,
  DiscoverRecommendationsResponse,
  DiscoverReportedTicket,
  DiscoverTicketDetailsResponse,
  DiscoverTopic,
  KnowledgeBaseSections,
  KnowledgeGapArticleRecommendations,
  PatchArticleRequest,
  PatchTopicRequest,
  PostDiscoverReportedTicketRequest,
} from 'src/reducers/discoverReducer/types';
import { Filters } from 'src/services/apiInterfaces';
import {
  getAllTopicsApi,
  getArticleIntegrationsApi,
  getCustomIntentsApi,
  getDashboardMetricsApi,
  getDiscoverRecommendationDetailsApi,
  getDiscoverRecommendationsApi,
  getKnowledgeBaseSectionsApi,
  getKnowledgeGapRecommendationsApi,
  getTicketDetailsByIdApi,
  getTopicMetricsByIdApi,
  getTopicTicketsApi,
  patchArticleApi,
  patchTopicApi,
  postReportedTopicApi,
  publishArticleApi,
} from 'src/services/discover/api';
import { DiscoverErrorResponse } from 'src/types/DiscoverTypes';
import { CanvasIntentsResponse } from 'src/types/workflowBuilderAPITypes';
import { isTypedError } from 'src/utils/discover/helpers';

export const getTicketDetails = createAsyncThunk<
  DiscoverTicketDetailsResponse,
  {
    ticketId: string;
  },
  {
    rejectValue: DiscoverErrorResponse;
  }
>(
  DiscoverActions.GET_TICKET_DETAILS,
  async (
    params: {
      ticketId: string;
    },
    { rejectWithValue },
  ) => {
    try {
      return await getTicketDetailsByIdApi(params);
    } catch (error) {
      if (isTypedError(error)) {
        return rejectWithValue(error);
      }
      throw error;
    }
  },
);

export const getTopicMetricsById = createAsyncThunk(
  DiscoverActions.GET_TOPIC_METRICS,
  async (
    params: {
      dataFilterQuery: Filters[];
      interval: TopicPeriodicalFilter;
      timeFilter: TopicTimeFilter;
      topicId: string;
    },
    { rejectWithValue },
  ) => {
    try {
      return {
        data: await getTopicMetricsByIdApi(params),
        request: params,
      };
    } catch (error) {
      if (isTypedError(error)) {
        return rejectWithValue(error);
      }
      throw error;
    }
  },
);

export const getAllTopics = createAsyncThunk(
  DiscoverActions.GET_ALL_TOPICS,
  async (
    params: {
      dataFilterQuery: Filters[];
      timeFilter: TopicTimeFilter;
    },
    { rejectWithValue },
  ) => {
    try {
      return { data: await getAllTopicsApi(params), request: params };
    } catch (error) {
      if (isTypedError(error)) {
        return rejectWithValue(error);
      }
      throw error;
    }
  },
);

export const getDashboardMetrics = createAsyncThunk(
  DiscoverActions.GET_DASHBOARD_METRICS,
  async (
    params: {
      dataFilterQuery: Filters[];
      interval: TopicPeriodicalFilter;
      timeFilter: TopicTimeFilter;
    },
    { rejectWithValue },
  ) => {
    try {
      return {
        data: await getDashboardMetricsApi(params),
        request: params,
      };
    } catch (error) {
      if (isTypedError(error)) {
        return rejectWithValue(error);
      }
      throw error;
    }
  },
);

export const getTopicTickets = createAsyncThunk(
  DiscoverActions.GET_TOPIC_TICKETS,
  async (
    params: {
      dataFilterQuery: Filters[];
      searchText?: string;
      timeFilter: TopicTimeFilter;
      topicId: string;
    },
    { rejectWithValue },
  ) => {
    try {
      return { data: await getTopicTicketsApi(params), request: params };
    } catch (error) {
      if (isTypedError(error)) {
        return rejectWithValue(error);
      }
      throw error;
    }
  },
);

export const getDiscoverRecommendations = createAsyncThunk<
  DiscoverRecommendationsResponse,
  void,
  {
    rejectValue: DiscoverErrorResponse;
  }
>(DiscoverActions.GET_RECOMMENDATIONS, async (_, { rejectWithValue }) => {
  try {
    return await getDiscoverRecommendationsApi();
  } catch (error) {
    if (isTypedError(error)) {
      return rejectWithValue(error);
    }
    throw error;
  }
});

export const getDiscoverRecommendationDetails = createAsyncThunk<
  DiscoverRecommendationDetailsResponse,
  { topicId: string },
  {
    rejectValue: DiscoverErrorResponse;
  }
>(
  DiscoverActions.GET_RECOMMENDATION_DETAILS,
  async ({ topicId }, { rejectWithValue }) => {
    try {
      return await getDiscoverRecommendationDetailsApi(topicId);
    } catch (error) {
      if (isTypedError(error)) {
        return rejectWithValue(error);
      }
      throw error;
    }
  },
);

export const patchTopic = createAsyncThunk<
  DiscoverTopic,
  { body: PatchTopicRequest; topicId: string },
  { rejectValue: DiscoverErrorResponse }
>(
  DiscoverActions.PATCH_TOPIC,
  async ({ body, topicId }, { rejectWithValue }) => {
    try {
      return await patchTopicApi(topicId, body);
    } catch (error) {
      if (isTypedError(error)) {
        return rejectWithValue(error);
      }
      throw error;
    }
  },
);

export const patchArticle = createAsyncThunk<
  DiscoverArticle,
  { articleId: string; body: PatchArticleRequest },
  { rejectValue: DiscoverErrorResponse }
>(
  DiscoverActions.PATCH_ARTICLE,
  async ({ articleId, body }, { rejectWithValue }) => {
    try {
      return await patchArticleApi(articleId, body);
    } catch (error) {
      if (isTypedError(error)) {
        return rejectWithValue(error);
      }
      throw error;
    }
  },
);

export const getCustomIntents = createAsyncThunk<CanvasIntentsResponse, void>(
  DiscoverActions.GET_CUSTOM_INTENTS,
  async () => {
    return await getCustomIntentsApi();
  },
);

export const postReportedTopic = createAsyncThunk<
  DiscoverReportedTicket,
  { body: PostDiscoverReportedTicketRequest; topicId: string }
>(DiscoverActions.POST_REPORTED_TOPIC, async ({ body, topicId }) => {
  return await postReportedTopicApi(topicId, body);
});

export const getKnowledgeBaseSections = createAsyncThunk<
  KnowledgeBaseSections | null,
  void,
  { rejectValue: DiscoverErrorResponse }
>(
  DiscoverActions.GET_KNOWLEDGE_BASE_SECTIONS,
  async (_, { rejectWithValue }) => {
    try {
      return await getKnowledgeBaseSectionsApi();
    } catch (error) {
      if (isTypedError(error)) {
        return rejectWithValue(error);
      }
      throw error;
    }
  },
);

export const getArticleIntegrations = createAsyncThunk<
  ArticleIntegrations,
  void,
  { rejectValue: DiscoverErrorResponse }
>(DiscoverActions.GET_ARTICLE_INTEGRATIONS, async (_, { rejectWithValue }) => {
  try {
    return await getArticleIntegrationsApi();
  } catch (error) {
    if (isTypedError(error)) {
      return rejectWithValue(error);
    }
    throw error;
  }
});

export const publishArticle = createAsyncThunk<
  DiscoverArticle,
  { articleId: string } & DiscoverArticlePublishRequest,
  { rejectValue: DiscoverErrorResponse }
>(
  DiscoverActions.PUBLISH_ARTICLE,
  async ({ articleId, ...body }, { rejectWithValue }) => {
    try {
      return await publishArticleApi(articleId, body);
    } catch (error) {
      if (isTypedError(error)) {
        return rejectWithValue(error);
      }
      throw error;
    }
  },
);

export const getKnowledgeGapRecommendations = createAsyncThunk<
  KnowledgeGapArticleRecommendations,
  void,
  { rejectValue: DiscoverErrorResponse }
>(
  DiscoverActions.GET_KNOWLEDGE_GAP_RECOMMENDATIONS,
  async (_, { rejectWithValue }) => {
    try {
      return await getKnowledgeGapRecommendationsApi();
    } catch (error) {
      if (isTypedError(error)) {
        return rejectWithValue(error);
      }
      throw error;
    }
  },
);
