import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import { DateRange } from '../../discover-dashboard-page/types';
import { useGetDataSourcePredictedFields } from '../../triage-config-detail-page/hooks/useGetDataSourcePredictedFields';
import TextWithInfo from '../../triage-model-detail-page/common/TextWithInfo';
import { DATE_RANGE_TOOLTIP, TICKET_FIELD_TOOLTIP } from './constants';
import ExpresssionText from './ExpressionText';
import { TicketFilterItemValue } from 'src/reducers/triageSettingsReducer/types';
import { dateRangeToTimeFilter } from 'src/utils/discover/helpers';

interface AutogeInfoProps {
  dateRange: DateRange;
  filters: TicketFilterItemValue[];
  ticket: string;
}

const AutogenInfo = ({ dateRange, filters, ticket }: AutogeInfoProps) => {
  const { palette } = useTheme();
  const { outputFields } = useGetDataSourcePredictedFields();
  const selectedTicket = outputFields.find(item => item.value === ticket);
  const dateLabel = dateRange
    ? dateRangeToTimeFilter(dateRange)
    : { label: '' };

  return (
    <Box
      display='flex'
      flexDirection='column'
      pt={4}
      rowGap={4}
      sx={{ borderTop: `1px solid ${palette.colors.grey[200]}` }}
    >
      <Typography variant='font16Bold'>
        Select tickets for model generation
      </Typography>
      <Box display='flex' flexDirection='column' rowGap={1}>
        <TextWithInfo
          title='Pick a ticket field'
          tooltip={TICKET_FIELD_TOOLTIP}
          variant='font14Bold'
        />
        <Typography variant='font16'>{selectedTicket?.label}</Typography>
      </Box>

      <Box display='flex' flexDirection='column' rowGap={1}>
        <TextWithInfo
          title='Select a period'
          tooltip={DATE_RANGE_TOOLTIP}
          variant='font14Bold'
        />
        <Typography variant='font16'>{dateLabel.label}</Typography>
      </Box>
      <ExpresssionText items={filters} />
    </Box>
  );
};

export default AutogenInfo;
