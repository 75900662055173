import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Menu } from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import { IconDotsVertical, IconPlus } from '@tabler/icons-react';

import {
  Button,
  Chip,
  Dialog,
  EllipsisWithTooltip,
  IconButton,
  TextField,
} from '@forethought-technologies/forethought-elements';
import { useBrandSidebarButton, useGetSelectedBrand } from '../hooks';
import { EditBrandModal } from './EditBrandModal';
import capitalize from 'lodash/fp/capitalize';
import checkImg from 'src/assets/images/check-gradient.svg';
import { useIsFeatureFlagEnabled } from 'src/hooks/hooks';
import {
  useDeleteBrandMutation,
  useGetBrandsQuery,
} from 'src/services/brand/brandApi';
import { useRequestPlanTierUpgradeMutation } from 'src/services/dashboard-api';
import { setBrandId } from 'src/slices/solve-config/solveConfigSlice';
import { useAppDispatch } from 'src/store/hooks';
import { Brand } from 'src/types/brandTypes';

export const BrandSidebar = ({ children }: React.PropsWithChildren) => {
  const isMultibrandEnabled = useIsFeatureFlagEnabled('multibrand_enabled');
  const [isEditingBrands, setIsEditingBrands] = useState(false);
  const [isContactingUs, setIsContactingUs] = useState(false);
  const { copy, isLoading, onClick, shouldShowUpgradePrompt, useIcon } =
    useBrandSidebarButton({
      onContactUs: () => setIsContactingUs(true),
      onCreateNew: () => setIsEditingBrands(true),
    });

  if (!isMultibrandEnabled) {
    return <>{children}</>;
  }

  return (
    <>
      <Box display='flex' width='100%'>
        <Box
          alignItems='center'
          bgcolor={theme => theme.palette.colors.white}
          display='flex'
          flexDirection='column'
          width='200px'
        >
          <Box
            display='flex'
            flexDirection='column'
            gap={1}
            px='20px'
            py={3}
            width='100%'
          >
            {shouldShowUpgradePrompt && <UpgradePrompt />}
            <Button
              fullWidth
              isLoading={isLoading}
              onClick={onClick}
              startIcon={useIcon ? <IconPlus /> : undefined}
              variant='main'
            >
              {copy}
            </Button>
          </Box>

          <BrandList
            isEditingBrands={isEditingBrands}
            setIsEditingBrands={setIsEditingBrands}
          />
        </Box>

        {children}
      </Box>
      <UpgradeDialogs
        isContactingUs={isContactingUs}
        onClose={() => setIsContactingUs(false)}
      />
    </>
  );
};

const BRAND_MENU_OPTIONS = ['edit', 'delete'] as const;

type BrandActions = Record<
  (typeof BRAND_MENU_OPTIONS)[number],
  (brandId: string) => void
>;

const BrandList = ({
  isEditingBrands,
  setIsEditingBrands,
}: {
  isEditingBrands: boolean;
  setIsEditingBrands: (value: boolean) => void;
}) => {
  const dispatch = useAppDispatch();
  const { data } = useGetBrandsQuery();
  const [, setUrlSearchParams] = useSearchParams();
  const [deleteBrandMutation] = useDeleteBrandMutation();

  const brandActions: BrandActions = useMemo(() => {
    return {
      delete: (brandId: string) => deleteBrandMutation({ brandId }),
      edit: (brandId: string) => {
        dispatch(setBrandId(brandId));
        setUrlSearchParams({ channel: 'widget', tab: 'brand' });
      },
    };
  }, [dispatch, setUrlSearchParams, deleteBrandMutation]);

  return (
    <>
      <MenuList sx={{ width: '100%' }}>
        {data
          ?.toSorted((a, b) => a.brand_id.localeCompare(b.brand_id))
          .map((brand, index) => (
            <BrandListItem
              brand={brand}
              brandActions={brandActions}
              index={index}
              key={brand.brand_id}
            />
          ))}
      </MenuList>
      <EditBrandModal
        isOpen={isEditingBrands}
        onClose={() => setIsEditingBrands(false)}
      />
    </>
  );
};

const BrandListItem = ({
  brand,
  brandActions,
  index,
}: {
  brand: Brand;
  brandActions: BrandActions;
  index: number;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const selectedBrand = useGetSelectedBrand();
  const dispatch = useAppDispatch();
  const {
    brand_id: brandId,
    brand_name: brandName,
    is_default: isDefault,
  } = brand;

  const isCurrentlySelected = selectedBrand?.brand_id === brandId;

  return (
    <MenuItem
      onClick={() => dispatch(setBrandId(brandId))}
      sx={[
        {
          '&:hover': {
            button: { display: 'flex' },
          },
          button: { display: 'none' },
          display: 'flex',
          gap: '6px',
          minHeight: '56px !important',
          px: 2.5,
          py: 1.5,
          width: '100%',
        },
        isCurrentlySelected && {
          '&:hover': {
            bgcolor: theme => theme.palette.colors.blue[100],
          },
          bgcolor: theme => theme.palette.colors.blue[100],
        },
        Boolean(anchorEl) && {
          bgcolor: !isCurrentlySelected ? 'rgba(0, 0, 0, 0.04)' : undefined,
          button: { display: 'flex' },
        },
      ]}
    >
      <BrandIcon index={index} />
      <EllipsisWithTooltip>
        <Typography noWrap variant='font14Medium'>
          {brandName}
        </Typography>
      </EllipsisWithTooltip>
      {isDefault && (
        <Box
          alignItems='center'
          display='flex'
          height='16px'
          justifyContent='center'
          width='16px'
        >
          <Box
            bgcolor={theme => theme.palette.colors.blue[500]}
            borderRadius='50%'
            height='8px'
            width='8px'
          />
        </Box>
      )}
      <Box marginLeft='auto'>
        <EditBrandButton
          anchorEl={anchorEl}
          brandActions={brandActions}
          brandId={brandId}
          isDefault={isDefault}
          setAnchorEl={setAnchorEl}
        />
      </Box>
    </MenuItem>
  );
};

const BrandIcon = ({ index }: { index: number }) => {
  const { palette } = useTheme();
  const colors = [
    palette.colors.blue,
    palette.colors.green,
    palette.colors.purple,
    palette.colors.red,
    palette.colors.yellow,
  ];
  const indexInColorsArray = index % colors.length;

  return (
    <Box
      bgcolor={colors[indexInColorsArray][300]}
      borderRadius='3px'
      display='block'
      height='20px'
      maxWidth='15px'
      minWidth='15px'
    />
  );
};

const EditBrandButton = ({
  anchorEl,
  brandActions,
  brandId,
  isDefault,
  setAnchorEl,
}: {
  anchorEl: HTMLElement | null;
  brandActions: BrandActions;
  brandId: string;
  isDefault: boolean;
  setAnchorEl: (value: HTMLElement | null) => void;
}) => {
  return (
    <>
      <IconButton
        aria-label='menu'
        onClick={e => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
        variant='ghost'
      >
        <IconDotsVertical size={20} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        id='brand-menu'
        MenuListProps={{
          'aria-labelledby': 'brand-menu-button',
          role: 'listbox',
          sx: {
            py: 1.5,
          },
        }}
        onClose={e => {
          //Casting is needed as MUI has {} as a type for this event
          const event = e as MouseEvent;
          event?.stopPropagation && event.stopPropagation();
          setAnchorEl(null);
        }}
        open={Boolean(anchorEl)}
        PaperProps={{
          elevation: 1,
          sx: ({ palette }) => ({
            border: `1px solid ${palette.colors.slate[200]}`,
            borderRadius: '4px',
            width: '110px',
          }),
        }}
      >
        {BRAND_MENU_OPTIONS.map(option => (
          <MenuItem
            disabled={option === 'delete' && isDefault}
            key={option}
            onClick={e => {
              e.stopPropagation();
              brandActions[option](brandId);
              setAnchorEl(null);
            }}
            sx={{
              alignItems: 'center',
              display: 'flex',
              padding: '6px 16px',
            }}
          >
            <Typography variant='font14'>{capitalize(option)}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const UpgradePrompt = () => {
  return (
    <>
      <Box>
        <Chip
          label='Upgrade'
          sx={theme => ({
            ...theme.typography.font11,
            '& span': {
              padding: 0,
            },
            color: theme.palette.colors.white,
            px: 1,
            py: 0.5,
          })}
          variant='gradient-blue'
        />
      </Box>
      <Typography
        color={theme => theme.palette.colors.grey[600]}
        variant='font11'
      >
        Contact us to add Multibrand widgets
      </Typography>
    </>
  );
};

const DEFAULT_UPGRADE_MESSAGE =
  'I would like to upgrade my plan to add multibrand widgets.';

const UpgradeDialogs = ({
  isContactingUs,
  onClose,
}: {
  isContactingUs: boolean;
  onClose: () => void;
}) => {
  const [requestPlanTierUpgrade, { isLoading, isSuccess }] =
    useRequestPlanTierUpgradeMutation();
  const [message, setMessage] = useState(DEFAULT_UPGRADE_MESSAGE);
  const errorMessage =
    message.length > 200
      ? 'Please keep the message to 200 characters or less'
      : '';

  const handleClose = () => {
    onClose();
    setMessage(DEFAULT_UPGRADE_MESSAGE);
  };

  return (
    <Dialog
      footer={
        isSuccess ? (
          <Button onClick={handleClose} variant='ghost'>
            Close
          </Button>
        ) : (
          <>
            <Button onClick={handleClose} variant='ghost'>
              Cancel
            </Button>
            <Button
              disabled={Boolean(errorMessage)}
              isLoading={isLoading}
              onClick={async () => {
                await requestPlanTierUpgrade({
                  message,
                }).unwrap();
              }}
              variant='main'
            >
              Send
            </Button>
          </>
        )
      }
      hideCloseButton
      onClose={handleClose}
      open={isContactingUs}
      title={isSuccess ? '' : 'Upgrade to add Multibrand Widgets'}
    >
      <Box alignItems='center' display='flex' flexDirection='column' gap={3.5}>
        {isSuccess ? (
          <>
            <img src={checkImg} />
            <Box
              alignItems='center'
              display='flex'
              flexDirection='column'
              gap={0.5}
            >
              <Typography variant='font16Bold'>Message sent</Typography>
              <Typography variant='font14'>
                We will reach out to help upgrade your plan
              </Typography>
            </Box>
          </>
        ) : (
          <TextField
            error={errorMessage}
            label='Your Customer Success Manager will be in touch shortly.'
            multiline
            onChange={e => setMessage(e.target.value)}
            placeholder='Enter your message here'
            rows={4}
            value={message}
          />
        )}
      </Box>
    </Dialog>
  );
};
