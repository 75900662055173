import { ERROR_MESSAGE, GENERATING_MODEL_MESSAGE } from './constants';
import { TicketFilterItemValue } from './types';
import { FieldOption } from 'src/components/expression-builder/ExpressionBuilder';
import { TriageAutoGenStatus } from 'src/reducers/triageSettingsReducer/types';
import { QueryExpressionTypes } from 'src/types/queryExpressionTypes';

const validatedValues = (
  expression: Partial<TicketFilterItemValue['expression']>,
) => {
  if (expression.operator !== 'empty') {
    return expression;
  }
  return {
    ...expression,
    values: [],
  };
};

export const updateArrayItem = (
  array: TicketFilterItemValue[],
  index: number,
  newItem: Partial<TicketFilterItemValue['expression']>,
) => {
  return array.map((item, i) => {
    if (i === index) {
      return {
        ...item,
        expression: {
          ...item.expression,
          ...validatedValues(newItem),
        },
      };
    }
    return item;
  });
};

export const addDefaultField = (
  fieldOptions: FieldOption[],
  expression: TicketFilterItemValue['expression'],
) => {
  if (expression.field) {
    return expression;
  }
  const firstFieldOptionItem = fieldOptions[0];
  return {
    ...expression,
    field: firstFieldOptionItem.value,
  };
};

export const getQueryExpressionFieldType = (
  field: string | undefined,
  fieldOptions: FieldOption[],
) => {
  return fieldOptions.find(data => data.value === field)
    ?.type as QueryExpressionTypes;
};

export const getListTypeFieldOptions = (
  field: string,
  fieldOptions: FieldOption[],
) => fieldOptions.find(option => option.value === field)?.fieldOptions || [];

export const checkModelIsGenerating = (modelStatus: TriageAutoGenStatus) => {
  return modelStatus === 'generating' || modelStatus === 'collecting_data';
};

export const checkModelIsAutogen = (modelStatus: TriageAutoGenStatus) => {
  return checkModelIsGenerating(modelStatus) || modelStatus === 'errored';
};

export const getMessage = ({
  hasError,
  isGenerating,
}: {
  hasError: boolean;
  isGenerating: boolean;
}) => {
  if (isGenerating) {
    return GENERATING_MODEL_MESSAGE;
  }
  if (hasError) {
    return ERROR_MESSAGE;
  }
  return '';
};
