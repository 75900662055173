import { TriagePredictionRun } from 'src/services/triage/types';

export const TABLE_COLUMNS = [
  {
    key: 'name',
    size: 300,
    title: 'Name',
  },
  {
    key: 'status',
    size: 200,
    title: 'Status',
  },
  {
    key: 'model_name',
    size: 300,
    title: 'Model',
  },
  {
    key: 'version_name',
    size: 300,
    title: 'Version',
  },
  {
    key: 'test_set_criteria',
    size: 400,
    title: 'Test set criteria',
  },
  {
    key: 'created_by',
    size: 400,
    title: 'Created by',
  },
  {
    key: 'created_at',
    size: 200,
    title: 'Created at',
  },
  {
    key: 'actions',
    size: 60,
    title: '',
  },
];

export const SIMULATION_TOOLTIP = {
  date_range:
    'The oldest 1,000 tickets within the selected time range are used to run the simulation.',
  ticket_ids: 'Only the first 1000 ticket ids will be used for simulation.',
};

export const TEST_RUN_STATUS_TOOLTIP: Record<
  TriagePredictionRun['status'],
  string
> = {
  failure: 'We encountered a failure, please try again later.',
  pending: 'The simulation is still running, please come back later.',
  success: '',
};
