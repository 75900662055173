import { FC } from 'react';
import { Box, useTheme } from '@mui/material';

import {
  NonIdealState,
  Typography,
} from '@forethought-technologies/forethought-elements';
import iconBrokenSearch from 'src/assets/images/icon-broken-search.svg';

const GeneratedPolicyTab: FC<{ policy?: string; topicName: string }> = ({
  policy,
  topicName,
}) => {
  const { palette } = useTheme();

  return (
    <Box display='flex' flexDirection='column' gap='32px' mt='24px'>
      <Box>
        <Typography color={palette.colors.grey[600]} variant='font12Medium'>
          Topic:
        </Typography>{' '}
        <Typography variant='font12Medium'>{topicName}</Typography>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        sx={{
          '&>.policy-items>ol>li': { fontWeight: 600 },
          '&>.policy-items>ol>li>ol>li': { fontWeight: 400, listStyle: 'disc' },
        }}
      >
        {policy ? (
          <>
            <Typography variant='font14Bold'>
              Generated AutoFlow policy
            </Typography>
            <span
              className='policy-items'
              dangerouslySetInnerHTML={{
                __html: policy,
              }}
            />
          </>
        ) : (
          <Box
            alignItems='center'
            display='flex'
            height='400px'
            justifyContent='center'
            width='100%'
          >
            <NonIdealState
              icon={<img alt='no policy available' src={iconBrokenSearch} />}
              title='No policy has been generated for this topic'
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default GeneratedPolicyTab;
