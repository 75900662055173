import { useSelector } from 'react-redux';

import max from 'lodash/fp/max';
import {
  DISCOVER_SHARED_PARAM_NAMES,
  initialDateRangeValue,
  timeFilterOptions,
} from 'src/constants/discover';
import { useHasPermissionToApplication, useStateParams } from 'src/hooks/hooks';
import { selectDashboardMetrics } from 'src/reducers/discoverReducer/discoverReducer';
import { useGetTicketCountQuery } from 'src/services/discover/discoverApi';
import { TicketCounterResponse } from 'src/types/DiscoverTypes';
import {
  dateRangeDeserialize,
  dateRangeSerialize,
  timeFilterParameterValidator,
} from 'src/utils/discover/helpers';
import { Permission } from 'src/utils/enums';

interface UseTicketCounter extends TicketCounterResponse {
  isError: boolean;
  isLoading: boolean;
}

const useTicketCounter = (): UseTicketCounter => {
  const permission = useHasPermissionToApplication(
    Permission.TRIAGE_SELF_SERVE_WIP,
  );

  // mock data available as mockTicketCountResponse
  const [dateRange] = useStateParams({
    deserialize: dateRangeDeserialize,
    initialState: initialDateRangeValue,
    paramsName: DISCOVER_SHARED_PARAM_NAMES.TIME_FILTER,
    serialize: dateRangeSerialize,
    validator: timeFilterParameterValidator(timeFilterOptions),
  });
  const { data: dashboardMetrics } = useSelector(selectDashboardMetrics);

  const volumeData = dashboardMetrics?.overall_metrics.metrics.find(
    metric => metric?.name === 'volume',
  );

  const {
    data: ticketCounterData,
    isError: isTicketCounterError,
    isFetching: isTicketCounterFetching,
    isLoading: isTicketCounterLoading,
  } = useGetTicketCountQuery(
    {
      dateRange,
    },
    { skip: !permission },
  );

  const allTickets = ticketCounterData?.all_tickets ?? 0;
  const filterTickets = ticketCounterData?.filter_tickets ?? 0;
  const discoverTickets = ticketCounterData?.discover_tickets ?? 0;
  const volume = (volumeData?.value as number | undefined) ?? 0;
  const mlFilter = max<number>([allTickets - filterTickets - volume, 0]) ?? 0;

  const isError = isTicketCounterError || allTickets === 0;

  return {
    all_tickets: allTickets,
    discover_tickets: discoverTickets,
    filter_tickets: filterTickets,
    isError: isError,
    isLoading: isTicketCounterLoading || isTicketCounterFetching,
    ml_tickets: mlFilter,
  };
};

export default useTicketCounter;
