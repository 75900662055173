import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import SuccessCircle from '/src/assets/images/success-circle.svg';
import { selectCurrentUser } from 'src/reducers/userReducer/userReducer';

const CreateSuccess = () => {
  const user = useSelector(selectCurrentUser) || {};
  const userEmail = user?.user?.user?.email;

  return (
    <Box
      alignItems='center'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      minHeight='100svh'
      p={6}
      rowGap={3}
      sx={{ textAlign: 'center' }}
    >
      <Box height='200px' width='200px'>
        <img src={SuccessCircle} />
      </Box>
      <Box display='flex' flexDirection='column' rowGap={1.5}>
        <Typography variant='font24'>
          Model creation process initiated!
        </Typography>
        <Typography variant='font14'>
          This process may take several hours, depending on the number of
          tickets. We will notify you at {userEmail} once the model is ready.
        </Typography>
      </Box>
    </Box>
  );
};

export default CreateSuccess;
