import React, { useMemo, useState } from 'react';
import Box from '@mui/material/Box';

import {
  FilterButton,
  Pagination,
  SearchBar,
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import FilterButtonIcon from '../../discover-filter-button-icon/FilterButtonIcon';
import { DiscoverTopicTicketSkeleton } from '../../discover-skeleton/DiscoverSkeleton';
import { TicketDataSorter } from './helpers';
import ReportTicketModal from './report-ticket-modal';
import TicketCard from './TicketCard';
import TopicDetailTicketDetailDrawer from './topic-detail-ticket-detail-drawer';
import NoTicketIcon from 'src/assets/images/no-ticket-icon.svg';
import { TopicSortFilter, TopicTimeFilter } from 'src/components/app/types';
import SecuredComponent from 'src/components/secured-component/SecuredComponent';
import { sortFilterOptions, TICKETS_PER_PAGE } from 'src/constants/discover';
import { useGetLabelFromValue } from 'src/hooks/discover/useGetLabelFromValue';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { useDebouncedCall } from 'src/pages/events/audit/hooks';
import {
  DiscoverTicket,
  DiscoverTopicAggregatedMetricType,
  DiscoverTopicDetailsResponse,
  DiscoverTopicTicketsResponse,
} from 'src/reducers/discoverReducer/types';
import { getAppCuesId } from 'src/utils/appCuesUtil';

interface TopicDetailTicketSectionProps {
  data: DiscoverTopicTicketsResponse | undefined;
  isLoading: boolean;
  isSearchTicketEnabled?: boolean;
  metricData: DiscoverTopicDetailsResponse | null;
  metricFilter: DiscoverTopicAggregatedMetricType;
  searchText: string;
  setSearchText: (value: string) => void;
  setSortFilterValue: (value: TopicSortFilter) => void;
  sortFilterValue: TopicSortFilter;
  timeFilter: TopicTimeFilter;
  topicId: string;
  topicName: string;
}

const TopicDetailTicketSection = ({
  data,
  isLoading,
  isSearchTicketEnabled,
  metricFilter,
  searchText,
  setSearchText,
  setSortFilterValue,
  sortFilterValue,
  timeFilter,
  topicId,
  topicName,
}: TopicDetailTicketSectionProps) => {
  const { tickets } = data ?? {};
  const [pageIndex, setPageIndex] = useState(0);
  const debounceCall = useDebouncedCall();
  const [localSearchValue, setLocalSearchValue] = useState(searchText);

  const [selectedTicketId, setSelectedTicketId] = useState('');
  const [reportedTicketId, setReportedTicketId] = useState('');

  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const metricFilterLabel = useGetLabelFromValue({
    type: 'metric',
    value: metricFilter,
  });

  const ticketData = useMemo(() => {
    const sorter = new TicketDataSorter(tickets ?? []);

    return sorter
      .handleTicketDateSort(sortFilterValue)
      .handleTicketMetricSort(
        sortFilterValue,
        metricFilter === 'first_contact_resolution'
          ? 'resolved_on_first_contact'
          : metricFilter,
      ).data;
  }, [sortFilterValue, metricFilter, tickets]);

  const { selectedTicket } = useMemo<{
    selectedTicket: DiscoverTicket;
  }>(() => {
    const indexOfSelectedTicket = ticketData.findIndex(
      ticket => ticket.id === selectedTicketId,
    );
    const selectedTicket = ticketData[indexOfSelectedTicket];

    return {
      selectedTicket,
    };
  }, [selectedTicketId, ticketData]);

  const startingTicketIndex = pageIndex * TICKETS_PER_PAGE;
  const pageTicketData = ticketData.slice(
    startingTicketIndex,
    startingTicketIndex + TICKETS_PER_PAGE,
  );
  const isTicketDataAvailable = pageTicketData.length > 0;

  const handleOnSearchBarChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const text = event.target.value;
    setLocalSearchValue(text);
    debounceCall(() => setSearchText(text));
  };

  return (
    <>
      <Box
        alignItems='center'
        display='flex'
        justifyContent='space-between'
        mt='24px'
      >
        <Box columnGap={1} display='grid' gridTemplateColumns='1fr 1fr'>
          <FilterButton
            aria-label='sort ticket'
            disabledTooltipMessage='Not available for this metric'
            initialValue={sortFilterOptions[0].value}
            onChange={value => {
              emitTrackingEventCallback('discover-sorted-tickets', {
                'metric-selected': metricFilter,
                page: 'Topic view',
                'page-area': 'Tickets',
                topic: topicName,
                value: value,
              });

              setSortFilterValue(value as TopicSortFilter);
            }}
            options={sortFilterOptions.map(option => {
              const isDisabled =
                (option.value === 'highest' || option.value === 'lowest') &&
                metricFilter === 'volume';

              return { ...option, disabled: isDisabled };
            })}
            startAdornment={<FilterButtonIcon dropdownType='ticket' />}
            value={sortFilterValue}
          />
          {isSearchTicketEnabled && (
            <SecuredComponent>
              <SearchBar
                onChange={handleOnSearchBarChange}
                placeholder='Search'
                size='small'
                value={localSearchValue}
              />
            </SecuredComponent>
          )}
        </Box>
        <Typography color={theme.palette.colors.grey[800]} variant='font12'>
          {timeFilter.label}
        </Typography>
      </Box>
      {isTicketDataAvailable || isLoading ? (
        <>
          <Box
            data-appcues-target={getAppCuesId({
              componentType: 'list',
              featureName: 'tickets',
              pageName: 'topicdetail',
              subType: 'tickets',
            })}
            mt={3}
          >
            {isLoading ? (
              <DiscoverTopicTicketSkeleton />
            ) : (
              pageTicketData.map(ticketData => (
                <TicketCard
                  key={ticketData.id}
                  metricFilter={metricFilter}
                  metricFilterLabel={metricFilterLabel}
                  onClick={() => {
                    emitTrackingEventCallback('discover-clicked-on-ticket', {
                      page: 'Topic view',
                      'page-area': 'Tickets',
                      topic: topicName,
                      value: ticketData.id,
                    });

                    setSelectedTicketId(ticketData.id);
                  }}
                  searchKeyword={localSearchValue}
                  setReportedTicketId={setReportedTicketId}
                  ticketData={ticketData}
                />
              ))
            )}
          </Box>
          <Box display='flex' justifyContent='center' width='100%'>
            {!isLoading && (
              <Pagination
                count={Math.ceil(ticketData.length / TICKETS_PER_PAGE)}
                defaultPage={0}
                onChange={(_, page) => setPageIndex(page - 1)}
                page={pageIndex + 1}
              />
            )}
          </Box>
        </>
      ) : (
        <Box
          alignItems='center'
          display='flex'
          flexDirection='column'
          mb={7.75}
          mt={5}
        >
          <img src={NoTicketIcon} />
          <Box mb={3} />
          <Typography
            color={theme.palette.colors.grey[800]}
            variant='font16Bold'
          >
            No tickets found
          </Typography>
          <Box mb={1} />
          <Typography color={theme.palette.colors.grey[700]} variant='font14'>
            Try reloading the page later
          </Typography>
        </Box>
      )}
      <TopicDetailTicketDetailDrawer
        isOpen={selectedTicketId !== ''}
        onClose={() => setSelectedTicketId('')}
        ticketData={selectedTicket}
        topicName={topicName}
      />
      {tickets && (
        <ReportTicketModal
          onClose={() => setReportedTicketId('')}
          reportedTicketId={reportedTicketId}
          tickets={tickets}
          topicId={topicId}
        />
      )}
    </>
  );
};

export default TopicDetailTicketSection;
