import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';

import {
  Button,
  Drawer,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { DISCOVER_TEXT } from '../constants';
import DiscoverAutomationCheck from 'src/assets/images/discover-automation-check.svg';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  selectGlobalDiscoverOptions,
  setGlobalDiscoverOptions,
} from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { deriveTopicNameFromTopic } from 'src/utils/discover/helpers';

const PostAutomationActionDrawer = () => {
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const location = useLocation();
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const dispatch = useAppDispatch();
  const { postAutomationActionModalOptions, topicToAutomate } = useSelector(
    selectGlobalDiscoverOptions,
  );

  const { palette } = useTheme();
  const { automatedObjectName, isClassicCreated, isOpen, redirectUrl } =
    postAutomationActionModalOptions;

  const isAutonomousAgentEnabled = featureFlags.includes(
    'autonomous_agent_enabled',
  );

  const renderTitleText = () => {
    if (redirectUrl) {
      if (automatedObjectName) {
        return `You’ve successfully assigned ‘${deriveTopicNameFromTopic(
          topicToAutomate,
        )}’ topic to the ‘${automatedObjectName}‘ workflow!`;
      }
      return `Great job on successfully creating a workflow for the ‘${deriveTopicNameFromTopic(
        topicToAutomate,
      )}’ topic!`;
    }
  };

  const renderSubtitleText = () => {
    if (redirectUrl) {
      if (automatedObjectName) {
        return `You can modify the ‘${automatedObjectName}‘ workflow now, or stay to explore more gaps.`;
      }
      return 'You can go to the new workflow to build now, or stay to explore more gaps.';
    }

    return DISCOVER_TEXT.postAutomationModalInterestedSubtitle;
  };

  const targetUrl = useMemo(() => {
    if (!redirectUrl) {
      return '';
    }
    let targetURL = decodeURIComponent(redirectUrl);
    if (isAutonomousAgentEnabled && isClassicCreated) {
      targetURL = `${targetURL}&mode=classic`;
    } else if (isAutonomousAgentEnabled) {
      targetURL = `${targetURL}&mode=autoflow`;
    }
    return targetURL;
  }, [isAutonomousAgentEnabled, isClassicCreated, redirectUrl]);

  const onContinueToSolveClick = () => {
    emitTrackingEventCallback('discover-continue-to-solve-button-clicked', {
      pathname: location.pathname,
    });
    onClose();
  };

  const onClose = () =>
    dispatch(
      setGlobalDiscoverOptions({
        postAutomationActionModalOptions: {
          automatedObjectName: '',
          isClassicCreated: true,
          isOpen: false,
          redirectUrl: '',
        },
        topicIDToAutomate: null,
        topicToAutomate: null,
      }),
    );

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
          mb: targetUrl ? 0 : 7,
          padding: 3,
        }}
      >
        <Box mb={4}>
          <img src={DiscoverAutomationCheck} />
        </Box>
        <Box mb={1.5} textAlign='center'>
          <Typography variant='font28'>{renderTitleText()}</Typography>
        </Box>
        <Box textAlign='center'>
          <Typography color={palette.colors.grey[700]} variant='font14'>
            {renderSubtitleText()}
          </Typography>
        </Box>
        {targetUrl && (
          <Box display='flex' gap='8px' mt='32px' width='100%'>
            <StyledLink to={targetUrl}>
              <Button
                fullWidth
                onClick={onContinueToSolveClick}
                size='large'
                variant='secondary'
              >
                Go to workflow builder
              </Button>
            </StyledLink>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

const StyledLink = styled(Link)`
  flex: 1;
  text-decoration: none;
`;

export default PostAutomationActionDrawer;
