import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';

import {
  Checkbox,
  Table,
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import DiscoverBookmarkIcon from '../../../discover-bookmark-icon/DiscoverBookmarkIcon';
import DiscoverCard from '../../../discover-card/DiscoverCard';
import DiscoverMetricTooltip from '../../../discover-tooltip/DiscoverMetricTooltip';
import { TopicRendererComponent } from '../../discover-all-topics-page/components/TopicRenderer';
import { EmptyTableStateContainer, TableLabelContainer } from '../styles';
import { BookmarkTableInput, cellRenderer } from '../tableHelpers';
import NoBookmarkIcon from 'src/assets/images/bookmark-empty-state.svg';
import {
  TopicPeriodicalFilter,
  TopicTimeFilter,
} from 'src/components/app/types';
import { metricFilterOptions } from 'src/constants/discover';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { useGenerateLinkWithSearch } from 'src/hooks/useGenerateLinkWithSearch';
import { selectDashboardMetrics } from 'src/reducers/discoverReducer/discoverReducer';
import { DiscoverMetadata } from 'src/reducers/discoverReducer/types';
import { getAppCuesId } from 'src/utils/appCuesUtil';
import {
  comparatorFunction,
  deriveTopicNameFromTopic,
  overrideDiscoverSearchParams,
  replaceIdInRoute,
  TableDataFilter,
} from 'src/utils/discover/helpers';
import { Routes } from 'src/utils/enums';

interface DashboardTabBookmarkedTableProps {
  isMetadataLoading: boolean;
  metadata: DiscoverMetadata | undefined;
  timeFilter: TopicTimeFilter;
  timePeriod: TopicPeriodicalFilter;
}

const DashboardTabBookmarkedTable = ({
  isMetadataLoading,
  metadata,
  timeFilter,
  timePeriod,
}: DashboardTabBookmarkedTableProps) => {
  const pathWithSearch = useGenerateLinkWithSearch(
    Routes.DISCOVER_TOPIC_DETAIL,
  );
  const [shouldShowPercentChange, setShouldShowPercentChange] = useState(false);

  const { data, loading } = useSelector(selectDashboardMetrics);
  const { topic_metric_data_types: availableMetrics } = metadata ?? {
    topic_metric_data_types: [],
  };
  const navigate = useNavigate();

  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const derivedColumns = useMemo(() => {
    return availableMetrics.map(({ data_type: dataType, type }) => {
      const derivedColumn = metricFilterOptions.find(
        ({ value }) => value === type,
      );

      if (!derivedColumn) {
        console.error(`${type} not found in metricFilterOptions`);
        return { id: '', label: 'N/A' };
      }

      const { label, value } = derivedColumn;

      const metricIndicator = dataType === 'seconds' ? 'hrs' : null;

      return {
        cellRenderer: cellRenderer(shouldShowPercentChange, value, dataType),
        comparatorFunction,
        id: value,
        label: (
          <TableLabelContainer>
            {label + (metricIndicator ? ` (${metricIndicator})` : '')}{' '}
            {value !== 'volume' && <DiscoverMetricTooltip metricType={value} />}
          </TableLabelContainer>
        ),
        width: value === 'volume' ? '150px' : '120px',
      };
    });
  }, [availableMetrics, shouldShowPercentChange]);

  const columns = [
    {
      cellRenderer: ({ name, topic }: BookmarkTableInput) => (
        <TopicRendererComponent
          doesArticleHaveGap={topic?.relevant_article_count === 0}
          generatedArticleCount={topic?.generated_article_count}
          isAutomated={Boolean(topic?.is_automated)}
          isBookmarkedTable
          isHidden={Boolean(topic?.is_hidden)}
          isNewTopic={Boolean(topic?.is_new_topic)}
          isRecommended={Boolean(topic?.is_recommended)}
          isSummaryRow={false}
          name={name}
          percentageOfTicketVolume=''
          searchText=''
          topicDisplayName={topic?.topic_display_name}
          topicId={topic?.topic_id}
        />
      ),
      id: 'name',
      label: 'Topic',
      width: '350px',
    },
    ...derivedColumns,
  ];

  const dataFilter = new TableDataFilter(
    data?.watched_topics_metrics ?? [],
    'V1',
  );

  return (
    <DiscoverCard
      featureName='bookmarkedtopics'
      headerComponent={
        <Box
          data-appcues-target={getAppCuesId({
            componentType: 'header',
            featureName: 'bookmarkedtopics',
            pageName: 'discover',
            subType: '',
          })}
          display='flex'
          justifyContent='space-between'
        >
          <Box display='flex' height={24}>
            <Box mr={2.5}>
              <Typography variant='font16Bold'>Bookmarked topics</Typography>
            </Box>
            <Checkbox
              checked={shouldShowPercentChange}
              data-appcues-target={getAppCuesId({
                componentType: 'button',
                featureName: 'bookmarkedtopics',
                pageName: 'discover',
                subType: 'showchange',
              })}
              label='Show % change'
              onChange={() => {
                emitTrackingEventCallback('discover-shown-percent-change', {
                  page: 'Discover Dashboard',
                  'page-area': 'Bookmarked topics',
                  'time-comparison-selected': timePeriod,
                  'time-period-selected': timeFilter,
                  value: shouldShowPercentChange ? 'unselected' : 'selected',
                });

                setShouldShowPercentChange(!shouldShowPercentChange);
              }}
            />
          </Box>
          <Typography color={theme.palette.colors.grey[700]} variant='font12'>
            {timeFilter.label}
          </Typography>
        </Box>
      }
    >
      <Box
        data-appcues-target={getAppCuesId({
          componentType: 'container',
          featureName: 'bookmarkedtopics',
          pageName: 'discover',
          subType: 'table',
        })}
        mt={2}
      >
        <Table
          columns={columns}
          emptyStateProps={{
            children: (
              <EmptyTableStateContainer>
                <Typography variant='font14'>
                  Bookmark topics you would like to monitor by going to “All
                  topics” and clicking on the
                </Typography>
                <DiscoverBookmarkIcon isStatic={true} />
                <Typography variant='font14'>
                  icon next to a topic name
                </Typography>
              </EmptyTableStateContainer>
            ),
            icon: <NoBookMarkImage src={NoBookmarkIcon} />,
            title: 'No bookmarked topics',
          }}
          emptyTableHeight={330}
          isLoading={loading || isMetadataLoading}
          onClickRow={({ id, name, topic }) => {
            const topicName = deriveTopicNameFromTopic(topic);
            emitTrackingEventCallback('discover-clicked-on-bookmarked-topic', {
              page: 'Discover Dashboard',
              'page-area': 'Bookmarked Topics',
              'time-comparison-selected': timePeriod,
              'time-period-selected': timeFilter,
              topic: name,
            });

            navigate(
              overrideDiscoverSearchParams(
                replaceIdInRoute(pathWithSearch, id),
                {
                  name: topicName || name,
                  source: 'dashboard',
                },
              ),
            );
          }}
          onSortCallback={id => {
            emitTrackingEventCallback('discover-sorted-table', {
              'metric-selected': id,
              page: 'Discover Dashboard',
              'page-area': 'Bookmarked Topics',
              'time-comparison-selected': timePeriod,
              'time-period-selected': timeFilter,
            });
          }}
          rows={dataFilter.arrangeForBookmarkedTable()}
        />
      </Box>
    </DiscoverCard>
  );
};

const NoBookMarkImage = styled('img')`
  height: 90px;
  background-color: ${theme.palette.colors.white};
  margin-right: 20px;
`;

export default DashboardTabBookmarkedTable;
