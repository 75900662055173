import {
  formatUnixTimestampToDateAndTime,
  Typography,
} from '@forethought-technologies/forethought-elements';

const TimestampValueCell = ({ value }: { value: string }) => {
  const date = new Date(value);
  const text = formatUnixTimestampToDateAndTime(Number(date) / 1000);

  return <Typography variant='font14'>{text}</Typography>;
};

export default TimestampValueCell;
