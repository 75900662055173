import { useLocation } from 'react-router';

import { dateRangeToTimestamp, isUiQueryError } from '../helpers';
import { InsightsTab } from '../types';
import { ALLTIME_START } from 'src/pages/intent-conversation-analytics/constants';
import useIsTopMetricFiltersEnabled from 'src/pages/intent-conversation-analytics/hooks/useIsInsightTopMetricFiltersEnabled';
import { DateRange } from 'src/types/types';

export const useGetFiltersFromSearch = ({
  dateRange,
  tab,
}: {
  dateRange: DateRange;
  tab?: InsightsTab;
}) => {
  const { search } = useLocation();
  const isTopMetricFiltersEnabled = useIsTopMetricFiltersEnabled();
  // If feature flag is not used, it will not use any of the filters
  if (!isTopMetricFiltersEnabled) {
    return {};
  }
  const filters: Record<string, string | string[]> = {};
  const searchParams = new URLSearchParams(search);
  searchParams.forEach((value, key) => {
    /**
     * Needs to be splitted differently for intent_filters
     * item = ["autonomous_agent_used.true", "workflow_id.57fce4cb-be81-4448-8c93-a5eb291ed6bc", "workflow_id.02bdecc9-5e00-4086-a0dc-24310e539808"]
     * converted to
     * {
     *  autonomous_agent_used: ['true'],
     *  workflow_id: ['57fce4cb-be81-4448-8c93-a5eb291ed6bc', '02bdecc9-5e00-4086-a0dc-24310e539808']
     * }
     */
    if (key === `intent_filters_${tab}`) {
      const item = value.includes(',') ? value.split(',') : [value];
      item.forEach(itemValue => {
        const newKey = itemValue.split('.')[0];
        const newKeyValue = itemValue.split('.')[1];
        if (!filters[newKey]) {
          filters[newKey] = [newKeyValue];
        } else {
          const updatedValue = [...(filters[newKey] as string[]), newKeyValue];
          filters[newKey] = updatedValue;
        }
      });
    } else if (value) {
      const item = value.includes(',') ? value.split(',') : [value];
      filters[key] = item.map(itemValue => {
        if (itemValue.includes('.')) {
          return itemValue.split('.')[1];
        } else {
          return itemValue;
        }
      });
    }
  });

  // get filters based on tab
  if (tab === 'chat') {
    const allowedKeys = [
      'channels',
      'chats_search',
      'chats_search_mode',
      'workflow_id',
      'autonomous_agent_used',
      'csat_ratings',
      'query_exists',
      'relevance_ratings',
      'dropoff_ratings',
      'workflow_tags',
    ];
    const filteredFilters = Object.fromEntries(
      Object.keys(filters)
        .filter(key => allowedKeys.includes(key))
        .map(key => [key, filters[key]]),
    ) as Record<string, string | string[]>;

    const end = Math.floor(Date.now() / 1000);
    const chatsSearchMode = filteredFilters.chats_search_mode?.[0];
    const uiQueryError = isUiQueryError({
      searchQueryMode: chatsSearchMode as string,
      uiQuery: filteredFilters.uiQuery as string,
    });
    const isSearchingByChatId =
      chatsSearchMode === 'conversation_id' &&
      filteredFilters.uiQuery &&
      !uiQueryError;

    const timestamps = dateRangeToTimestamp(dateRange);

    return {
      ...(filteredFilters.autonomous_agent_used?.length === 1 && {
        autonomous_agent_used: filteredFilters.autonomous_agent_used[0],
      }),
      ...(filteredFilters.channels && {
        channels: filteredFilters.channels as string,
      }),
      ...(filteredFilters.workflow_id && {
        workflow_id: filteredFilters.workflow_id,
      }),
      ...(filteredFilters.csat_ratings && {
        csat_ratings: filteredFilters.csat_ratings,
      }),
      ...(filteredFilters.query_exists?.length === 1 && {
        query_exists: filteredFilters.query_exists[0],
      }),
      ...(filteredFilters.relevance_ratings && {
        relevance_ratings: filteredFilters.relevance_ratings,
      }),
      ...(filteredFilters.dropoff_ratings && {
        dropoff_ratings: filteredFilters.dropoff_ratings,
      }),
      ...(filteredFilters.workflow_tags && {
        workflow_tags: filteredFilters.workflow_tags,
      }),
      ...(tab && {
        tab: tab,
      }),
      chat:
        chatsSearchMode === 'chat'
          ? (filteredFilters.chats_search as string)
          : '',
      conversation_id:
        chatsSearchMode === 'conversation_id' &&
        !uiQueryError &&
        filteredFilters.chats_search?.toString() !== 'null'
          ? (filteredFilters.chats_search as string)
          : '',
      user_query:
        chatsSearchMode === 'user_query'
          ? (filteredFilters.chats_search as string)
          : '',
      ...(isSearchingByChatId
        ? // Use maximum date range:
          {
            end_timestamp: end,
            start_timestamp: Number(ALLTIME_START),
          }
        : {
            end_timestamp: timestamps.end_timestamp,
            start_timestamp: timestamps.start_timestamp,
          }),
    };
  }

  if (tab === 'workflow') {
    const allowedKeys = ['channels', 'activate', 'types', 'workflows_search'];
    const filteredFilters = Object.fromEntries(
      Object.keys(filters)
        .filter(key => allowedKeys.includes(key))
        .map(key => [key, filters[key]]),
    ) as Record<string, string | string[]>;

    return {
      ...(filteredFilters.activate?.length === 1 && {
        activate: filteredFilters.activate[0],
      }),
      ...(filteredFilters.types && {
        types: filteredFilters.types,
      }),
      ...(filteredFilters.channels && {
        channels: filteredFilters.channels as string,
      }),
      ...(filteredFilters.workflows_search && {
        user_query: filteredFilters.workflows_search as string,
      }),
      ...(tab && {
        tab: tab,
      }),
    };
  }

  if (tab === 'article') {
    const allowedKeys = ['channels', 'articles_search'];
    const filteredFilters = Object.fromEntries(
      Object.keys(filters)
        .filter(key => allowedKeys.includes(key))
        .map(key => [key, filters[key]]),
    ) as Record<string, string | string[]>;
    return {
      ...(filteredFilters.articles_search && {
        user_query: filteredFilters.articles_search as string,
      }),
      ...(filteredFilters.channels && {
        channels: filteredFilters.channels as string,
      }),
      ...(tab && {
        tab: tab,
      }),
    };
  }

  if (tab === 'topic') {
    const allowedKeys = ['channels', 'workflow_id'];
    const filteredFilters = Object.fromEntries(
      Object.keys(filters)
        .filter(key => allowedKeys.includes(key))
        .map(key => [key, filters[key]]),
    ) as Record<string, string | string[]>;
    return {
      ...(filteredFilters.channels && {
        channels: filteredFilters.channels as string,
      }),
      ...(tab && {
        tab: tab,
      }),
      ...(filteredFilters.workflow_id && {
        workflow_id: filteredFilters.workflow_id,
      }),
    };
  }
};
