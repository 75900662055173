import { ComponentProps, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';
import { Box, styled, useTheme } from '@mui/material';

import {
  Button,
  Colors,
  LoadingIndicator,
  Tabs,
  Toggle,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useGetSelectedBrand } from '../hooks';
import { usePublishedTimestamp } from '../hooks/usePublishedTimestamp';
import { Channel } from '../types';
import { MessageComponent } from './MessageComponent';
import ConfirmModal from 'src/components/email-builder/components/ConfirmModal';
import {
  useIsFeatureFlagEnabled,
  useSolveConfigTrackingEventAction,
} from 'src/hooks/hooks';
import { selectIsSolveLiteEnabled } from 'src/reducers/userReducer/userReducer';
import { useGetPaidPlanDetailsQuery } from 'src/services/dashboard-api';
import { PUBLISHED_RECENTLY } from 'src/slices/solve-config/constants';
import {
  activateWidget,
  deactivateWidget,
  publishConfiguration,
  selectCanPublish,
  selectCanUpdatePublish,
  selectIsActive,
  selectIsSaving,
  selectIsValidationFailing,
  selectLiveVersionExists,
  selectValidationErrors,
  setLiveVersionIsActive,
  showWarningModal,
} from 'src/slices/solve-config/solveConfigSlice';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { Routes, SolveConfigTrackingEventTypes } from 'src/utils/enums';

const SolveConfigHeader = ({
  activeChannelTabIndex,
  channel,
  channelTabs,
  handleChannelTabChange,
}: {
  activeChannelTabIndex: ComponentProps<
    typeof import('@forethought-technologies/forethought-elements').Tabs
  >['value'];
  channel: Channel;
  channelTabs: string[];
  handleChannelTabChange: ComponentProps<
    typeof import('@forethought-technologies/forethought-elements').Tabs
  >['onChange'];
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatchTrackingAction = useSolveConfigTrackingEventAction();
  const { data: paidPlanData } = useGetPaidPlanDetailsQuery();

  const isSaving = useSelector(selectIsSaving);
  const isActive = useSelector(selectIsActive);

  const doesLiveVersionExist = useSelector(selectLiveVersionExists);
  const isValidationFailing = useSelector(selectIsValidationFailing);
  const isSolveLiteEnabled = useSelector(selectIsSolveLiteEnabled);
  const canPublish = useSelector(selectCanPublish);
  const canUpdatePublish = useSelector(selectCanUpdatePublish);
  const selectedBrand = useGetSelectedBrand();
  const isMultibrandEnabled = useIsFeatureFlagEnabled('multibrand_enabled');

  const { publishedTimestamp, setPublishedTimestamp } = usePublishedTimestamp();
  const shouldShowPublishedMessage =
    publishedTimestamp !== -1 && !isSolveLiteEnabled;

  const productName = isSolveLiteEnabled ? 'Solve Lite' : 'Solve';

  const validationErrors = useSelector(selectValidationErrors);

  const [confirmation, setConfirmation] = useState<
    'publish' | 'updatePublish' | 'activate' | 'deactivate' | ''
  >('');

  const handlePreview = () => {
    // if validation is failing, we show the warning modal and exit the function
    if (isValidationFailing) {
      dispatch(showWarningModal());
      return;
    }
    dispatchTrackingAction(SolveConfigTrackingEventTypes.WIDGET_PREVIEW);
    navigate({
      pathname: Routes.SOLVE_PREVIEW,
    });
  };

  const handleConfirmationCancel = () => {
    setConfirmation('');
  };

  const handlePublishClick = () => {
    // if validation is failing, we show the warning modal and exit the function
    if (isValidationFailing) {
      dispatch(showWarningModal());
      return;
    }
    setConfirmation('publish');
  };

  const handleUpdatePublishClick = () => {
    // if validation is failing, we show the warning modal and exit the function
    if (isValidationFailing) {
      dispatch(showWarningModal());
      return;
    }
    setConfirmation('updatePublish');
  };

  const handlePublishConfirm = async ({
    isFirstTime,
  }: {
    isFirstTime: boolean;
  }) => {
    const data = await dispatch(
      publishConfiguration({
        brandId: selectedBrand?.brand_id,
        isMultibrandEnabled,
      }),
    ).unwrap();

    if (isFirstTime && data.acknowledged) {
      /**
       * Should auto activate since this function happens on first publish
       * On fetch the widget config (on refresh) will have active as true
       */
      dispatch(setLiveVersionIsActive(true));
    }
    dispatchTrackingAction(
      SolveConfigTrackingEventTypes.CONFIGURATION_PUBLISHED,
    );
    setConfirmation('');
    setPublishedTimestamp(Date.now());
  };

  const handleActivateConfirm = () => {
    dispatch(activateWidget());
    dispatchTrackingAction(SolveConfigTrackingEventTypes.WIDGET_ACTIVATED);
    setConfirmation('');
  };

  const handleDeactivateConfirm = () => {
    dispatch(deactivateWidget());
    dispatchTrackingAction(SolveConfigTrackingEventTypes.WIDGET_DEACTIVATED);
    setConfirmation('');
  };

  const solveLiteQuotas = paidPlanData?.product_quotas.filter(
    quota => quota.product === 'solve-widget',
  );
  const isTrialOrg = paidPlanData?.is_trial_org;

  const isSolveLiteTrialFinished =
    solveLiteQuotas && solveLiteQuotas.length > 0
      ? solveLiteQuotas[0].quota_status === 'exceeded' && isTrialOrg
      : false;

  const getA11yProps = useCallback(
    (index: number) => ({
      'aria-controls': `configuration-tabpanel-${channelTabs[
        index
      ].toLowerCase()}`,
      id: `configuration-tab-${channelTabs[index].toLowerCase()}`,
    }),
    [channelTabs],
  );

  return (
    <Container>
      <Tabs
        a11yProps={getA11yProps}
        aria-label='Configuration channel'
        onChange={handleChannelTabChange}
        tabs={channelTabs}
        typographyVariant='font16Bold'
        value={activeChannelTabIndex}
      />

      {channel === 'widget' && (
        <Box alignItems='center' display='flex' gap='12px'>
          {canUpdatePublish ? (
            <Typography
              color={theme.palette.colors.grey[500]}
              variant='font14Medium'
            >
              ‘Publish changes’ to make changes visible to others.
            </Typography>
          ) : (
            <MessageComponent
              messages={[
                ...validationErrors,
                ...(shouldShowPublishedMessage ? [PUBLISHED_RECENTLY] : []),
              ]}
            />
          )}
          {doesLiveVersionExist && (
            <Tooltip
              tooltipContent={
                isActive
                  ? 'Available to the public'
                  : 'Unavailable to the public'
              }
            >
              <Box
                sx={{
                  '& .MuiSwitch-root': {
                    overflow: 'visible',
                  },
                }}
              >
                <Toggle
                  checked={isActive && !isSolveLiteTrialFinished}
                  disabled={isSolveLiteTrialFinished}
                  onChange={() => {
                    isActive
                      ? setConfirmation('deactivate')
                      : setConfirmation('activate');
                  }}
                />
              </Box>
            </Tooltip>
          )}
          <SavingAndButtonsContainer>
            {isSaving && (
              <Saving aria-busy>
                Saving
                <LoadingIndicator
                  color={theme.palette.colors.purple[500]}
                  size='large'
                />
              </Saving>
            )}
            <ButtonsContainer isHidden={isSaving}>
              <Button
                disabled={isSaving}
                onClick={handlePreview}
                variant='secondary'
              >
                Preview
              </Button>
              {doesLiveVersionExist ? (
                <Button
                  disabled={!canUpdatePublish}
                  onClick={handleUpdatePublishClick}
                  variant='main'
                >
                  Publish changes
                </Button>
              ) : (
                <Button
                  disabled={!canPublish}
                  onClick={handlePublishClick}
                  variant='main'
                >
                  Publish
                </Button>
              )}
            </ButtonsContainer>
          </SavingAndButtonsContainer>
        </Box>
      )}

      {channel === 'api' && (
        <Box alignItems='center' display='flex' gap='12px'>
          <Button
            onClick={() => {
              navigate({
                pathname: generatePath(Routes.WORKFLOW_BUILDER_PREVIEW, {
                  channel: 'api',
                }),
              });
            }}
            variant='secondary'
          >
            Preview
          </Button>
        </Box>
      )}

      <ConfirmModal
        actions={[
          {
            onClick: handleConfirmationCancel,
            title: 'Cancel',
          },
          {
            onClick: () => handlePublishConfirm({ isFirstTime: true }),
            title: 'Publish now',
          },
        ]}
        description={`You are about to make the ${productName} widget available to the public. This action will publish and display the widget on your website.`}
        isOpen={confirmation === 'publish'}
        onClose={handleConfirmationCancel}
        title={`Publish ${productName}?`}
      />

      <ConfirmModal
        actions={[
          {
            onClick: handleConfirmationCancel,
            title: 'Cancel',
          },
          {
            onClick: async () => {
              await handlePublishConfirm({ isFirstTime: false });
              dispatch(
                setGlobalToastOptions({
                  autoHideDuration: 3000,
                  subtitle: 'Live config will be updated within 5 minutes',
                  title: 'Widget Configuration published',
                  variant: 'main',
                }),
              );
            },
            title: 'Publish changes now',
          },
        ]}
        description={`You are about to update the published ${productName}, and all changes will be made public.`}
        isOpen={confirmation === 'updatePublish'}
        onClose={handleConfirmationCancel}
        title='Publish changes?'
      />

      <ConfirmModal
        actions={[
          {
            onClick: handleConfirmationCancel,
            title: 'Cancel',
          },
          {
            onClick: handleActivateConfirm,
            title: 'Turn on',
          },
        ]}
        description={`You are about to make the ${productName} widget available to the public. \
          This action will display the widget on your website.`}
        isOpen={confirmation === 'activate'}
        onClose={handleConfirmationCancel}
        title={`On: ${productName} activation`}
      />

      <ConfirmModal
        actions={[
          {
            onClick: handleConfirmationCancel,
            title: 'Cancel',
          },
          {
            onClick: handleDeactivateConfirm,
            title: 'Turn off',
          },
        ]}
        description={`You are about to make the ${productName} widget unavailable to the public. \
          This action will remove the widget from your website. You can always make it available again in the future.`}
        isOpen={confirmation === 'deactivate'}
        onClose={handleConfirmationCancel}
        title={`Off: ${productName} deactivation`}
      />
    </Container>
  );
};

export default SolveConfigHeader;

export const Container = styled('div')`
  display: flex;
  padding: 0 25px 0 34px;
  min-height: 48px;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 2px;
  background: ${Colors.ui.backgrounds.default};
`;

const SavingAndButtonsContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonsContainer = styled('div')<{ isHidden: boolean }>`
  visibility: ${props => props.isHidden && 'hidden'};
  display: flex;
  gap: 12px;
`;

const Saving = styled('div')`
  position: absolute;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.palette.colors.purple[500]};
  display: flex;
  align-items: center;
`;
