import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';

import {
  Dialog,
  Drawer,
  Tabs,
} from '@forethought-technologies/forethought-elements';
import DeleteSimulationForm from '../triage-simulations-page/DeleteSimulationForm';
import ManageSimulationForm, {
  SimulationFormValues,
} from '../triage-simulations-page/ManageSimulationForm';
import { TABS } from './constants';
import SimulationLabelsTab from './SimulationLabelsTab';
import SimulationTicketsTab from './SimulationTicketsTab';
import NavbarV2 from 'src/components/app/navbar/navbarV2';
import Spinner from 'src/components/spinner';
import {
  useDeletePredictionTestMutation,
  useGetModelByIdQuery,
  useGetModelsAndVersionsQuery,
  useGetPredictionRunsQuery,
  usePatchPredictionModelMutation,
} from 'src/services/triage/triageApi';
import { Routes } from 'src/utils/enums';

const TriageSimulationsDetailPage = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const modelId = searchParams.get('model_id');
  const [tab, setTab] = useState(0);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const { data: model, isLoading } = useGetModelByIdQuery({
    modelId: modelId || '',
    showAll: true,
  });
  const { data: predictionsData, isLoading: isLoadingPredictions } =
    useGetPredictionRunsQuery(undefined, {
      refetchOnMountOrArgChange: true,
    });
  // Call it on page load, rtk query will get from cache on modal
  useGetModelsAndVersionsQuery();

  const { pathname } = useLocation();

  const simulationId = useMemo(() => {
    const pathSegments = pathname.split('/');
    return pathSegments[pathSegments.length - 1];
  }, [pathname]);

  const selectedSimulation = useMemo(() => {
    return predictionsData?.prediction_tests.find(
      simulation => simulation.prediction_test_id === simulationId,
    );
  }, [simulationId, predictionsData?.prediction_tests]);

  const [deletePredictionTest, { isLoading: isDeleting }] =
    useDeletePredictionTestMutation();

  const [patchPredictionTest, { isLoading: isPatching }] =
    usePatchPredictionModelMutation();

  const handleOnGoBack = () => {
    navigate(Routes.TRIAGE_SIMULATION_RUNS);
  };

  const handleRename = () => {
    setIsEditFormOpen(true);
  };

  const handleDelete = async () => {
    if (selectedSimulation?.prediction_test_id) {
      try {
        await deletePredictionTest(selectedSimulation.prediction_test_id);
        setIsDeleteDialogOpen(false);
      } catch (err) {
        console.error('Failed to delete simulation', err);
      }
    }
  };

  const handleEditFormClose = () => {
    setIsEditFormOpen(false);
  };

  const handleSubmit = async (values: SimulationFormValues) => {
    if (selectedSimulation?.prediction_test_id) {
      try {
        await patchPredictionTest({
          name: values.testName,
          predictionTestId: selectedSimulation.prediction_test_id,
        }).unwrap();
        handleEditFormClose();
      } catch (err) {
        console.error('Failed to update simulation', err);
      }
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (!model) {
    return null;
  }

  const CurrentTab = [SimulationLabelsTab, SimulationTicketsTab][tab];

  return (
    <Box bgcolor='white' height='100%'>
      <NavbarV2
        currentTabOverride={selectedSimulation?.name}
        isInSimulationsDetailPage
        onDeleteModel={() => setIsDeleteDialogOpen(true)}
        onGoBack={handleOnGoBack}
        onRenameModel={handleRename}
      />

      <Box display='flex' flexDirection='column' px='24px' rowGap={4}>
        <Tabs
          a11yProps={index => {
            return {
              'aria-controls': `predictions-tabpanel-${index}`,
              id: `predictions-tab-${index}`,
            };
          }}
          aria-label='labels and tickets'
          onChange={(_, index) => setTab(index)}
          tabs={TABS}
          typographyVariant='font16'
          value={tab}
        />
        <CurrentTab model={model} />
      </Box>
      <Drawer isOpen={isEditFormOpen} onClose={handleEditFormClose}>
        {selectedSimulation && !isLoadingPredictions && (
          <ManageSimulationForm
            currentSimulation={selectedSimulation}
            handleDisable={() => false}
            isEditMode={true}
            isLoading={isPatching}
            onClose={handleEditFormClose}
            onSubmit={handleSubmit}
            selectedModelId={selectedSimulation.model_id}
            selectedVersionId={selectedSimulation.version_id}
            testName={selectedSimulation.name}
          />
        )}
      </Drawer>

      <Dialog
        onClose={() => setIsDeleteDialogOpen(false)}
        open={isDeleteDialogOpen}
        title='Delete this simulation'
      >
        <DeleteSimulationForm
          isLoading={isDeleting}
          onCancel={() => setIsDeleteDialogOpen(false)}
          onDeleteConfirm={handleDelete}
        />
      </Dialog>
    </Box>
  );
};

export default TriageSimulationsDetailPage;
