import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import {
  Drawer,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { checkModelIsAutogen } from '../triage-models-overview-page/AutogenFormSection/helpers';
import ModelDetailHeaderMenu from './common/ModelDetailHeaderMenu';
import ModelsHeaderControl from './common/ModelsHeaderControl';
import ManageModelForm from './drawers-and-dialogs/ManageModelForm';
import {
  createAutogenFilters,
  deriveNewModelFromIntegrationChange,
} from './helpers';
import { tabComponents } from './tabComponents';
import TriageModelTabs from './TriageModelTabs';
import { IntegrationChangeParams } from './types';
import NavbarV2 from 'src/components/app/navbar/navbarV2';
import Spinner from 'src/components/spinner';
import TriageLabelMappingDrawer from 'src/components/triage-label-mapping-drawer';
import { useStateParams } from 'src/hooks/hooks';
import { TriageAutoGenStatus } from 'src/reducers/triageSettingsReducer/types';
import {
  triageApi,
  useGetModelByIdQuery,
  useGetModelVersionsQuery,
  usePatchSelfServeTriageModelMutation,
} from 'src/services/triage/triageApi';
import { Routes } from 'src/utils/enums';

const TriageModelDetailPage = () => {
  const { palette } = useTheme();
  const { modelId = '' } = useParams<'modelId'>();
  const [showLabelMapping, setShowLabelMapping] = useState(false);
  const [showEditModelDrawer, setShowEditModelDrawer] = useState(false);

  const { data: helpdeskData } = triageApi.useGetHelpdeskQuery();

  const {
    data: model,
    isError: modelFetchError,
    isLoading: fetchingModelDetails,
    isUninitialized,
  } = useGetModelByIdQuery({ modelId: modelId || '' });
  // const isMappingValid = useModelValidityToast(model);

  const { data: modelVersions } = useGetModelVersionsQuery(modelId || '');

  const [tabIndex, setTabIndex] = useStateParams({
    deserialize: tab => Number(tab),
    initialState: 0,
    paramsName: 'tab',
    serialize: tab => String(tab),
    validator: (parameter: string) => Boolean(parameter),
  });

  const navigate = useNavigate();
  const [patchModel, { isLoading: isPatching }] =
    usePatchSelfServeTriageModelMutation();

  if (modelFetchError) {
    return (
      <Box p={8}>
        <Typography variant='font16'>Model not found</Typography>
      </Box>
    );
  }

  if (!model) {
    return null;
  }

  if (fetchingModelDetails && isUninitialized) {
    return <Spinner />;
  }

  const handleTabChange = (index: number) => {
    setTabIndex(index);
  };

  const handleOnGoBack = () => navigate(Routes.PREDICTIONS_SETTINGS);

  const CurrentTab = tabComponents[tabIndex];

  const handleTabUpdate = (updateData: IntegrationChangeParams) => {
    if (!model || !helpdeskData) {
      return;
    }
    const newFieldPredictorModel = deriveNewModelFromIntegrationChange(
      updateData,
      model,
      helpdeskData,
    );
    patchModel({
      body: { model: newFieldPredictorModel },
      modelId,
      versionId: model.version_id,
    });
  };

  const hasMultipleVersions = (modelVersions?.length ?? 0) > 1;
  const isAutoGen = checkModelIsAutogen(model.status as TriageAutoGenStatus);

  return (
    <>
      {fetchingModelDetails && <Spinner />}
      <Box
        display='grid'
        gridTemplateRows='69px 49px auto'
        height='100vh'
        sx={{ backgroundColor: palette.colors.white }}
      >
        <NavbarV2
          currentTabOverride={
            hasMultipleVersions ? (
              <Box
                alignItems='center'
                display='flex'
                flex={1}
                justifyContent='space-between'
              >
                {model.model_name}
                <Box ml={1}>
                  <Tooltip tooltipContent='Published Version Name'>
                    <Typography
                      color={palette.colors.grey[400]}
                      component='span'
                      variant='font24'
                    >
                      ({model.version_name})
                    </Typography>
                  </Tooltip>
                </Box>
              </Box>
            ) : (
              model.model_name
            )
          }
          onGoBack={handleOnGoBack}
        >
          <Box
            alignItems='center'
            display='flex'
            justifyContent='space-between'
            mr='16px'
            width='100%'
          >
            <ModelDetailHeaderMenu
              onShowEditModelDrawer={() => setShowEditModelDrawer(true)}
            />
            <TriageLabelMappingDrawer
              modelId={modelId}
              onCloseDrawer={() => setShowLabelMapping(false)}
              showDrawer={showLabelMapping}
              versionId={model.version_id}
            />
            <Drawer
              isOpen={showEditModelDrawer}
              onClose={() => setShowEditModelDrawer(false)}
            >
              <ManageModelForm
                initialValues={{
                  dateRange: {
                    from: new Date(model.autogeneration_data?.start_date || 0),
                    to: new Date(model.autogeneration_data?.end_date || 0),
                  },
                  description: model.model_description,
                  displayName: model.model_name,
                  filters: createAutogenFilters(model.autogeneration_data),
                  isManual: !isAutoGen,
                  selectedTicket: model.autogeneration_data?.ticket_field,
                }}
                isAutoGen={isAutoGen}
                isEdit
                modelId={modelId}
                onClose={() => setShowEditModelDrawer(false)}
                status={model.status}
                versionId={model.version_id}
              />
            </Drawer>
          </Box>
        </NavbarV2>
        <Box
          display='flex'
          justifyContent='space-between'
          px='24px'
          sx={{ borderBottom: `1px solid ${palette.colors.slate[200]}` }}
        >
          <TriageModelTabs
            modelId={modelId}
            onChange={handleTabChange}
            tabIndex={tabIndex}
          />
          <ModelsHeaderControl
            model={model}
            onShowLabelMapping={() => setShowLabelMapping(true)}
          />
        </Box>
        <Box p='24px' sx={{ backgroundColor: palette.colors.white }}>
          <CurrentTab
            isPatching={isPatching}
            model={model}
            modelId={modelId}
            onChange={handleTabUpdate}
            openLabelMapping={() => setShowLabelMapping(true)}
          />
        </Box>
      </Box>
    </>
  );
};

export default TriageModelDetailPage;
