import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IconButton, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { IconPlus, IconX } from '@tabler/icons-react';

import {
  Button,
  Chip,
  Tabs,
} from '@forethought-technologies/forethought-elements';
import { useGetImportedOpenApiSchemaActions } from '../action-builder/hooks';
import ActionBuilderForm from './action-builder-form';
import isEqual from 'lodash/fp/isEqual';
import NavbarV2 from 'src/components/app/navbar/navbarV2';
import { TemplateBadge } from 'src/components/template-badge';
import UnsavedChangesModal from 'src/components/unsaved-changes-modal';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { useGetTicketFieldMappings } from 'src/hooks/useGetTicketFieldMappings';
import {
  postActionBuilderProxyTestAPI,
  useCreateActionMutation,
  useCreateContextVariableMutation,
  useGetActionBuilderActionsQuery,
  useGetActionUsagesQuery,
  useGetAuthConfigsQuery,
  useUpdateActionMutation,
  useUpdateContextVariableMutation,
} from 'src/services/action-builder/actionBuilderApi';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import {
  ContextVariable,
  isTemplateApiActionFields,
  Method,
  SelectedActionDetail,
} from 'src/types/actionBuilderApiTypes';
import {
  actionToSelectedActionDetail,
  buildActionValidationSchema,
  buildRequestBodyForIntegrationTestCall,
  contentTypeToHeader,
  generateNewActionString,
  isDynamicListKvPairs,
  nameKVDictValues,
  replaceActionIdInRoute,
  selectedActionDetailToAction,
} from 'src/utils/actionBuilder/helpers';
import { Routes } from 'src/utils/enums';

const FALLBACK_ACTION_NAME = 'Untitled Request';

const ActionBuilderDetailPageContainer = () => {
  const { data } = useGetActionBuilderActionsQuery({});
  const { contextVariables } = useGetContextVariables();

  if (!data || !contextVariables.length) {
    return null;
  }

  return <ActionBuilderDetailPage />;
};

const ActionBuilderDetailPage = () => {
  const dispatch = useAppDispatch();
  const [createAction] = useCreateActionMutation();
  const [updateAction] = useUpdateActionMutation();
  const [shouldShowUnsavedChangesModal, setShouldShowUnsavedChangesModal] =
    useState(false);

  const [createContextVariable] = useCreateContextVariableMutation();
  const [updateContextVariable] = useUpdateContextVariableMutation();

  const { palette } = useTheme();

  const { actionId } = useParams<{ actionId: string }>();
  const { search } = useLocation();
  const navigate = useNavigate();
  const importedActions = useGetImportedOpenApiSchemaActions();
  const { data } = useGetActionBuilderActionsQuery({});
  const { data: authorizationConfigs = [] } = useGetAuthConfigsQuery();
  const { contextVariables } = useGetContextVariables();
  const currentSelectedImportedAction = importedActions.find(
    action => action.action_id === actionId + search,
  );
  const currentSelectedAction =
    data?.actions.find(action => action.action_id === actionId) ??
    currentSelectedImportedAction;

  const [editingActions, setEditingActions] = useState<SelectedActionDetail[]>([
    actionToSelectedActionDetail({
      action: currentSelectedAction,
      contextVariables,
      tabId: search ? actionId + search : actionId ?? generateNewActionString(),
    }),
  ]);
  const isImportedAction = Boolean(currentSelectedImportedAction);

  const { data: usagesData } = useGetActionUsagesQuery();

  useGetTicketFieldMappings();
  const isTemplateApiAction = isTemplateApiActionFields(
    currentSelectedAction?.action_fields,
  );

  useEffect(() => {
    // syncs between query and state
    if (!currentSelectedAction || !contextVariables?.length) {
      return;
    }

    if (isImportedAction) {
      return;
    }

    setEditingActions(prevEditingActions => {
      const copy = [...prevEditingActions];
      const hasCurrentSelectedAction = Boolean(
        copy.find(action => action.tabId === currentSelectedAction.action_id),
      );

      if (hasCurrentSelectedAction) {
        // dont attempt to reset the state to the cached DB action
        return copy;
      }

      const index = copy.findIndex(
        action => action.tabId === currentSelectedAction.action_id,
      );

      copy[index] = actionToSelectedActionDetail({
        action: currentSelectedAction,
        contextVariables,
        tabId: currentSelectedAction.action_id,
      });

      return copy;
    });
  }, [contextVariables, currentSelectedAction, isImportedAction]);

  const indexOfCurrentEditingAction = useMemo(() => {
    return editingActions.findIndex(
      action => action.tabId === `${actionId}${search.trim()}`,
    );
  }, [actionId, editingActions, search]);

  const setEditingAction = useCallback(
    (updatedSelectedAction: SelectedActionDetail) => {
      setEditingActions(prevEditingActions => {
        const copy = [...prevEditingActions];
        copy[indexOfCurrentEditingAction] = updatedSelectedAction;

        return copy;
      });
    },
    [indexOfCurrentEditingAction],
  );

  const onTestConnection = useCallback(
    async (
      values: SelectedActionDetail,
      idTestValueDict?: Record<string, string>,
    ): Promise<unknown> => {
      if (isTemplateApiActionFields(currentSelectedAction?.action_fields)) {
        const inputValue = Object.values(idTestValueDict ?? {})[0];

        if (inputValue === '') {
          return currentSelectedAction?.action_fields?.mock_responses;
        }

        return currentSelectedAction?.action_fields?.mock_responses[inputValue];
      }

      const { fields } = values;
      const { body, contentType, headers, method, params, url } = fields;
      const urlOptions = {
        authorizationConfigs,
        authorizationId: fields.authorization?.id,
        authorizationIntegration:
          fields.authorization?.type === 'bearer'
            ? null
            : fields.authorization?.id,
        bodyParameters: body,
        headers: nameKVDictValues([
          ...headers,
          contentTypeToHeader(contentType),
        ]),
        idTestValueDict,
        method,
        queryParameters: nameKVDictValues(params),
        url,
      };

      const res = await postActionBuilderProxyTestAPI(
        buildRequestBodyForIntegrationTestCall(urlOptions),
      );
      const proxyResponse: {
        body: unknown;
        error: string | null;
        status_code: number;
      } = await res.json();
      if (proxyResponse.error) {
        throw new Error(
          `Response failed with error code ${proxyResponse.status_code} and message ${proxyResponse.error}`,
        );
      }
      return proxyResponse?.body;
    },
    [authorizationConfigs, currentSelectedAction?.action_fields],
  );

  const defaultResponseValue = useMemo(() => {
    if (isTemplateApiActionFields(currentSelectedAction?.action_fields)) {
      return currentSelectedAction?.action_fields?.mock_responses;
    }

    return null;
  }, [currentSelectedAction?.action_fields]);

  const currentEditingAction =
    editingActions[indexOfCurrentEditingAction] ??
    actionToSelectedActionDetail();

  const onCloseUnsavedChanges = () => setShouldShowUnsavedChangesModal(false);

  const [responseData, setResponseData] =
    useState<unknown>(defaultResponseValue);
  const [isResponseDataLoading, setIsResponseDataLoading] = useState(false);
  const [isResponseError, setIsResponseError] = useState<string | boolean>(
    false,
  );

  // Clear response when switching tabs:
  useEffect(() => {
    setResponseData(defaultResponseValue);
  }, [defaultResponseValue, indexOfCurrentEditingAction]);

  const handleTestConnection = useCallback(
    async (
      values: SelectedActionDetail,
      idTestValueDict?: Record<string, string>,
    ) => {
      setIsResponseDataLoading(true);
      setIsResponseError(false);
      try {
        const data = await onTestConnection(values, idTestValueDict);
        setResponseData(data);
      } catch (error) {
        if (error instanceof Error) {
          setResponseData(null);
          setIsResponseError(error.message);
          return;
        }

        setIsResponseError(true);
      }
      setIsResponseDataLoading(false);
    },
    [onTestConnection],
  );

  const [jmespathQueryTestCvs, setJmespathQueryTestCvs] = useState<
    Partial<Record<string, string>>
  >({});

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{ ...currentEditingAction }}
        onSubmit={async values => {
          const result = await Promise.all(
            values.outputValues.map(async outputValue => {
              if (!isDynamicListKvPairs(outputValue) || outputValue.value) {
                return { outputValue };
              }

              const existingCv = contextVariables.find(
                cv =>
                  cv.context_variable_name ===
                  outputValue.newContextVariableName.trim(),
              );

              if (
                existingCv &&
                existingCv.context_variable_type !== 'DYNAMIC_LIST'
              ) {
                dispatch(
                  setGlobalToastOptions({
                    autoHideDuration: 3000,
                    subtitle: 'Context Variable already exists',
                    title: 'Could not save action',
                    variant: 'danger',
                  }),
                );

                throw new Error('Context Variable already exists');
              }

              const res = existingCv
                ? await updateContextVariable({
                    body: {
                      ...existingCv,
                      configuration_fields: {
                        dynamic_list_config: {
                          json_schema: outputValue.jsonSchema,
                        },
                      },
                    },
                    contextVariableId: existingCv.context_variable_id,
                  })
                : await createContextVariable({
                    configuration_fields: {
                      dynamic_list_config: {
                        json_schema: outputValue.jsonSchema,
                      },
                    },
                    context_variable_name: outputValue.newContextVariableName,
                    context_variable_type: 'DYNAMIC_LIST',
                  });

              if ('data' in res) {
                return {
                  data: res.data,
                  outputValue: {
                    key: outputValue.key,
                    value: res.data.context_variable_id,
                  },
                };
              }

              dispatch(
                setGlobalToastOptions({
                  autoHideDuration: 3000,
                  subtitle:
                    (res?.error as { data?: { error_message?: string } }).data
                      ?.error_message ?? 'Context Variable error',
                  title: 'Could not save action',
                  variant: 'danger',
                }),
              );

              throw res;
            }),
          );

          const treatedOutputValues = result.map(v => v.outputValue);
          const newCvs = result
            .map(cv => cv.data)
            .filter((cv): cv is ContextVariable => Boolean(cv));
          const allContextVariables = [...newCvs, ...contextVariables];

          if (actionId === 'create') {
            const res = await createAction(
              selectedActionDetailToAction({
                ...values,
                outputValues: treatedOutputValues,
              }),
            );

            if ('data' in res) {
              const foundAction = res.data.actions.find(
                action => action.action_name === values.name,
              );

              if (!foundAction) {
                return;
              }

              setEditingAction(
                actionToSelectedActionDetail({
                  action: foundAction,
                  contextVariables: allContextVariables,
                  tabId: foundAction.action_id,
                }),
              );
              navigate(replaceActionIdInRoute(foundAction.action_id), {
                replace: true,
              });
              dispatch(
                setGlobalToastOptions({
                  autoHideDuration: 3000,
                  title: 'Actions successfully created',
                  variant: 'main',
                }),
              );
            }

            return;
          }

          const res = await updateAction({
            actionId: actionId ?? '',
            body: selectedActionDetailToAction({
              ...values,
              outputValues: treatedOutputValues,
            }),
          });

          if ('data' in res) {
            const foundAction = res.data.actions.find(
              action => action.action_name === values.name,
            );

            if (!foundAction) {
              return;
            }

            setEditingAction(
              actionToSelectedActionDetail({
                action: foundAction,
                contextVariables: allContextVariables,
                tabId: foundAction.action_id,
              }),
            );

            dispatch(
              setGlobalToastOptions({
                autoHideDuration: 3000,
                title: 'Actions successfully updated',
                variant: 'main',
              }),
            );
          }
        }}
        validateOnChange
        validationSchema={buildActionValidationSchema(
          responseData,
          data?.actions,
          contextVariables,
          jmespathQueryTestCvs,
          currentSelectedAction,
        )}
      >
        {({ dirty, isValid, values }) => {
          const usages =
            usagesData?.action_id_to_intent_ids[values.actionId ?? ''] ?? [];
          const isActive = usages.length > 0;

          return (
            <Box component={Form} noValidate>
              <NavbarV2
                currentTabOverride={
                  <>
                    {values.name || FALLBACK_ACTION_NAME}
                    {isActive && !isTemplateApiAction && (
                      <Chip
                        label='In use'
                        startAdornment={
                          <Box
                            bgcolor={palette.colors.green[500]}
                            borderRadius={100}
                            height='12px'
                            width='12px'
                          />
                        }
                        sx={{
                          borderColor: palette.colors.slate[200],
                          color: palette.colors.grey[700],
                          fontSize: 12,
                          fontWeight: 500,
                          height: '26px',
                          ml: 1,
                        }}
                        variant='outlined'
                      />
                    )}
                    {isTemplateApiAction && (
                      <Box display='inline-flex' ml={1}>
                        <TemplateBadge />
                      </Box>
                    )}
                  </>
                }
                onGoBack={() => {
                  const createdActionsInTabs =
                    editingActions.filter(action =>
                      Boolean(
                        data?.actions.find(
                          otherAction => action.tabId === otherAction.action_id,
                        ) ??
                          importedActions.find(
                            otherAction =>
                              action.tabId === otherAction.action_id,
                          ),
                      ),
                    ) ?? [];

                  const indexWhereEditing = createdActionsInTabs.findIndex(
                    action => action.tabId === values.tabId,
                  );

                  if (indexWhereEditing !== -1) {
                    createdActionsInTabs[indexWhereEditing] = values;
                  }

                  if (isEqual(editingActions, createdActionsInTabs)) {
                    navigate({
                      pathname: Routes.ACTION_BUILDER,
                      search: `?tab=${
                        isImportedAction ? 'imported' : 'actions'
                      }`,
                    });
                    return;
                  }

                  setShouldShowUnsavedChangesModal(true);
                }}
              />
              <Box
                alignItems='center'
                bgcolor={palette.colors.white}
                display='flex'
                position='sticky'
                px='40px'
                sx={{
                  '@media (max-height: 1080px)': {
                    // when container can be scrolled, show the border
                    borderBottom: '1px solid ' + palette.colors.slate[200],
                  },
                }}
                top='69px' // navbar height
                zIndex={2}
              >
                <ActionTabs
                  editingActions={editingActions}
                  indexOfCurrentEditingAction={indexOfCurrentEditingAction}
                  setEditingAction={setEditingAction}
                  setEditingActions={setEditingActions}
                />
                <Box alignItems='center' display='flex' ml={4}>
                  <IconButton
                    onClick={() =>
                      setEditingActions([
                        ...editingActions,
                        actionToSelectedActionDetail({
                          contextVariables,
                          tabId: generateNewActionString(),
                        }),
                      ])
                    }
                    sx={{ padding: '4px' }}
                  >
                    <IconPlus size={20} />
                  </IconButton>
                </Box>
                {!isTemplateApiAction && (
                  <Box
                    display='flex'
                    flex={1}
                    gap={2}
                    justifyContent='flex-end'
                  >
                    <Button
                      onClick={() => {
                        setEditingActions([
                          ...editingActions,
                          {
                            ...values,
                            actionId: undefined,
                            name: values.name + ' copy',
                            tabId: generateNewActionString(),
                          },
                        ]);
                      }}
                      variant='secondary'
                    >
                      Duplicate
                    </Button>
                    <Button
                      disabled={isActive || !isValid || !dirty}
                      type='submit'
                      variant='main'
                    >
                      Save
                    </Button>
                  </Box>
                )}
              </Box>
              <Box
                my={3}
                px='40px'
                sx={{
                  '& .Mui-disabled:not(button)': {
                    // disabled fields on disabled in read only mode
                    // this is to make that text more legible
                    '-webkit-text-fill-color':
                      palette.text.primary + ' !important',
                    color: palette.text.primary + ' !important',
                  },
                }}
              >
                <ActionBuilderForm
                  isError={isResponseError}
                  isLoading={isResponseDataLoading}
                  jmespathQueryTestCvs={jmespathQueryTestCvs}
                  key={values.tabId}
                  onTestConnection={handleTestConnection}
                  responseData={responseData}
                  setJmespathQueryTestCvs={setJmespathQueryTestCvs}
                />
              </Box>
            </Box>
          );
        }}
      </Formik>
      <UnsavedChangesModal
        isOpen={shouldShowUnsavedChangesModal}
        key={currentEditingAction.tabId}
        onCancel={onCloseUnsavedChanges}
        onClose={onCloseUnsavedChanges}
        onDiscard={() =>
          navigate({
            pathname: Routes.ACTION_BUILDER,
            search: `?tab=${isImportedAction ? 'imported' : 'actions'}`,
          })
        }
      />
    </>
  );
};

const a11yProps = (index: number) => {
  return {
    'aria-controls': `action-builder-tabpanel-${index}`,
    id: `action-builder-tab-${index}`,
  };
};

interface ActionTabsProps {
  editingActions: SelectedActionDetail[];
  indexOfCurrentEditingAction: number;
  setEditingAction: (action: SelectedActionDetail) => void;
  setEditingActions: React.Dispatch<
    React.SetStateAction<SelectedActionDetail[]>
  >;
}

const ActionTabs = ({
  editingActions,
  indexOfCurrentEditingAction,
  setEditingAction,
  setEditingActions,
}: ActionTabsProps) => {
  const { palette } = useTheme();
  const { values } = useFormikContext<SelectedActionDetail>();
  const { actionId } = useParams<{ actionId: string }>();
  const { search } = useLocation();
  const navigate = useNavigate();

  const tabs = useMemo(() => {
    const getMethodColor = (method: Method) => {
      switch (method) {
        case 'POST':
          return palette.colors.green[500];
        case 'GET':
          return palette.colors.blue[500];
        case 'DELETE':
          return palette.colors.red[500];
        case 'PATCH':
          return palette.colors.purple[500];
        case 'PUT':
          return palette.colors.yellow[700];
        default:
          return '';
      }
    };

    return editingActions.map(({ fields, name, tabId }, index) => (
      <React.Fragment key={tabId}>
        <span
          style={{
            color: getMethodColor(
              index === indexOfCurrentEditingAction
                ? values.fields.method
                : fields.method,
            ),
            marginRight: '4px',
          }}
        >
          {index === indexOfCurrentEditingAction
            ? values.fields.method
            : fields.method}
        </span>
        {(index === indexOfCurrentEditingAction ? values.name : name) ||
          FALLBACK_ACTION_NAME}
        <IconButton
          component='span'
          onClick={e => {
            e.stopPropagation();
            setEditingActions(prevEditingActions => {
              const newEditingActions = prevEditingActions.filter(
                ({ tabId: currTabId }) => currTabId !== tabId,
              );
              const tabIdIndex = prevEditingActions.findIndex(
                action => action.tabId === tabId,
              );

              if (!newEditingActions.length) {
                navigate(-1);
              } else {
                navigate(
                  replaceActionIdInRoute(
                    newEditingActions[tabIdIndex + (tabIdIndex === 0 ? 0 : -1)]
                      ?.tabId,
                  ),
                  { replace: true },
                );
              }

              return newEditingActions;
            });
          }}
          sx={{ ml: 2, padding: '4px' }}
        >
          <IconX size={20} />
        </IconButton>
      </React.Fragment>
    ));
  }, [
    editingActions,
    palette.colors.green,
    palette.colors.blue,
    palette.colors.red,
    palette.colors.purple,
    palette.colors.yellow,
    indexOfCurrentEditingAction,
    values.fields.method,
    values.name,
    setEditingActions,
    navigate,
  ]);

  const tabIds = editingActions.map(action => action.tabId);
  const value = tabIds.indexOf(`${actionId}${search.trim()}`);

  useEffect(() => {
    if (!actionId && tabIds[0]) {
      navigate(replaceActionIdInRoute(tabIds[0]), { replace: true });
    }
  }, [actionId, editingActions, navigate, tabIds]);

  return (
    <>
      <Tabs
        a11yProps={a11yProps}
        aria-label='Action builder'
        ContainerProps={{ sx: { '& button': { display: 'inline-block' } } }}
        onChange={(_, value) => {
          // sync state when navigating

          setEditingAction(values);
          navigate(replaceActionIdInRoute(tabIds[value]), { replace: true });
        }}
        tabs={tabs}
        typographyVariant='font12Medium'
        value={value}
      />
    </>
  );
};

export default ActionBuilderDetailPageContainer;
