import { WidgetProps } from '@rjsf/utils';

import { SelectDropdown } from '@forethought-technologies/forethought-elements';

type SelectOption = { label: string; value: string };

export default function SelectWidget({
  onBlur: _ignore,
  onChange,
  options,
  value = '',
  ...rest
}: WidgetProps) {
  return (
    <SelectDropdown
      onChange={e => onChange(e.target.value)}
      options={
        options.enumOptions?.map(item => {
          item.value = item.value.toString();
          return item;
        }) as SelectOption[]
      }
      value={String(value)}
      {...rest}
    />
  );
}
