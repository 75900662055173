import Box from '@mui/material/Box';

import { DiscoverTopicMetricDetailCardSkeleton } from '../../discover-skeleton/DiscoverSkeleton';
import TopicMetricCardDetail from './TopicMetricCardDetail';
import { TopicTimeFilter } from 'src/components/app/types';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import {
  DiscoverMetadata,
  DiscoverTopicAggregatedMetricType,
  DiscoverTopicDetailsResponse,
} from 'src/reducers/discoverReducer/types';
import { getAppCuesId } from 'src/utils/appCuesUtil';

interface TopicDetailChartSectionProps {
  chartType?: string;
  data: DiscoverTopicDetailsResponse | null;
  isLoading: boolean;
  metadata: DiscoverMetadata | undefined;
  metricFilter: DiscoverTopicAggregatedMetricType;
  timeFilter: TopicTimeFilter;
  topicName: string;
}

const TopicDetailChartSection = ({
  chartType,
  data,
  isLoading,
  metadata,
  metricFilter,
  timeFilter,
  topicName,
}: TopicDetailChartSectionProps) => {
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const focusedChart =
    data?.charts.data.find(chart => chart?.name === metricFilter) || null;
  const xLabel = data?.charts.x_axis_label;
  const focusedMetric =
    data?.metrics.find(metric => metric?.name === metricFilter) || null;
  const volumeMetricValue = data?.metrics.find(
    metric => metric?.name === 'volume',
  )?.value;

  const chartTrackingEvent = () => {
    emitTrackingEventCallback('discover-graph-analyzed', {
      'metric-selected': metricFilter,
      page: 'Topic view',
      'time-period-selected': timeFilter,
      topic: topicName,
    });
  };

  if (isLoading) {
    return <DiscoverTopicMetricDetailCardSkeleton />;
  }

  if (focusedMetric) {
    return (
      <Box
        data-appcues-target={getAppCuesId({
          componentType: 'chart',
          featureName: 'charts',
          pageName: 'topicdetail',
          subType: metricFilter,
        })}
        mt={3}
      >
        <TopicMetricCardDetail
          chart={focusedChart}
          chartTrackingEvent={chartTrackingEvent}
          chartType={chartType}
          isFocusedMetric
          metadata={metadata}
          metric={focusedMetric}
          timeFilter={timeFilter}
          volumeMetricValue={volumeMetricValue}
          xLabel={xLabel}
        />
      </Box>
    );
  }

  return null;
};

export default TopicDetailChartSection;
