import { useCallback, useState } from 'react';
import { FieldArray, Formik, useField } from 'formik';
import * as Yup from 'yup';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { IconPlus, IconTrash } from '@tabler/icons-react';

import {
  Button,
  IconButton,
  TextField,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import isEqual from 'lodash/fp/isEqual';
import BaseModal from 'src/components/base-modal';
import { BaseModalProps } from 'src/components/base-modal/BaseModal';
import UnsavedChangesModal from 'src/components/unsaved-changes-modal';
import {
  useCreateBrandMutation,
  useDeleteBrandMutation,
  useGetBrandsQuery,
  useUpdateBrandMutation,
} from 'src/services/brand/brandApi';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { Brand } from 'src/types/brandTypes';

const useUpdateBrands = () => {
  const { data: brands = [] } = useGetBrandsQuery();
  const [createBrandMutation, { isLoading: isLoadingCreate }] =
    useCreateBrandMutation();
  const [updateBrandMutation, { isLoading: isLoadingUpdate }] =
    useUpdateBrandMutation();
  const [deleteBrandMutation, { isLoading: isLoadingDelete }] =
    useDeleteBrandMutation();
  const isLoading = isLoadingCreate || isLoadingUpdate || isLoadingDelete;

  return [
    useCallback(
      async ({
        data,
        onError,
        onSuccess,
      }: {
        data: Brand[];
        onError: (error: unknown) => void;
        onSuccess: () => void;
      }) => {
        const removedBrands = brands.filter(brand =>
          data.every(b => b.brand_id !== brand.brand_id),
        );

        try {
          const createUpdatePromises = data.map(brand => {
            if (brand.brand_id) {
              return updateBrandMutation({
                body: {
                  brand_name: brand.brand_name,
                  is_default: brand.is_default,
                },
                brandId: brand.brand_id,
              }).unwrap();
            }

            return createBrandMutation({
              brand_name: brand.brand_name,
              is_default: false,
            }).unwrap();
          });
          const deletePromises = removedBrands.map(brand =>
            deleteBrandMutation({ brandId: brand.brand_id }).unwrap(),
          );
          await Promise.all([...createUpdatePromises, ...deletePromises]);
          onSuccess();
        } catch (error) {
          onError(error);
        }
      },
      [brands, createBrandMutation, deleteBrandMutation, updateBrandMutation],
    ),
    { isLoading },
  ] as const;
};

const validationSchema = Yup.object().shape({
  brands: Yup.array().of(
    Yup.object().shape({
      brand_name: Yup.string()
        .max(50, 'Brand name must be 20 characters or less')
        .required('Brand name is required'),
    }),
  ),
});

export const EditBrandModal = ({
  isOpen,
  onClose,
}: Pick<BaseModalProps, 'isOpen' | 'onClose'>) => {
  return (
    <BaseModal
      headerTitle={<Typography variant='font24'>Branded widget</Typography>}
      hideCloseButton
      isOpen={isOpen}
      maxWidth='sm'
      onClose={onClose}
      PaperProps={{ sx: { height: '100%' } }}
    >
      <Box
        display='flex'
        flex={1}
        flexDirection='column'
        justifyContent='space-between'
        pb={3}
        px={3}
      >
        <BrandForm onClose={onClose} />
      </Box>
    </BaseModal>
  );
};

const BrandForm = ({ onClose }: Pick<BaseModalProps, 'onClose'>) => {
  const dispatch = useAppDispatch();
  const { data: brands = [] } = useGetBrandsQuery();
  const [shouldShowUnsavedChangesModal, setShouldShowUnsavedChangesModal] =
    useState(false);
  const [updateBrands, { isLoading }] = useUpdateBrands();

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          brands,
        }}
        onSubmit={async (values, { resetForm }) => {
          updateBrands({
            data: values.brands,
            onError: error => {
              console.error(error);
              dispatch(
                setGlobalToastOptions({
                  autoHideDuration: 3000,
                  title: 'Something went wrong editing brands',
                  variant: 'danger',
                }),
              );
              resetForm();
            },
            onSuccess: () => {
              dispatch(
                setGlobalToastOptions({
                  autoHideDuration: 3000,
                  title: 'Brands saved',
                  variant: 'main',
                }),
              );
              onClose?.();
            },
          });
        }}
        validationSchema={validationSchema}
      >
        {({ dirty, handleSubmit, initialValues, isValid, values }) => (
          <>
            <Box display='flex' flexDirection='column' gap={3}>
              <Typography variant='font14' whiteSpace='pre-wrap'>
                Create distinct appearances for various brands or user segments.
                This branded widget customization enables a personalized look
                and feel for different brands or segments.{'\n\n'}Each brand
                name unlocks tailored workflows and analytics. Widget settings
                can be easily duplicated from a sidebar menu, streamlining the
                setup for each brand.
              </Typography>
              <Box
                borderTop={theme =>
                  '1px solid ' + theme.palette.colors.slate[200]
                }
                display='flex'
                flexDirection='column'
                gap={1.5}
                pt={3}
              >
                <Box display='flex' flexDirection='column'>
                  <Typography variant='font16Bold'>
                    How many branded widgets are there and what are their names?
                  </Typography>
                  <Typography
                    color={theme => theme.palette.colors.grey[600]}
                    variant='font12'
                  >
                    Each branded widget has its own configuration.
                  </Typography>
                </Box>
                <BrandSettings />
              </Box>
            </Box>
            <Box display='flex' gap={2} marginTop='auto'>
              <Button
                onClick={() => {
                  if (isEqual(initialValues, values)) {
                    onClose?.();
                    return;
                  }
                  setShouldShowUnsavedChangesModal(true);
                }}
                variant='ghost'
              >
                Cancel
              </Button>
              <Button
                disabled={!isValid || !dirty || isLoading}
                fullWidth
                isLoading={isLoading}
                onClick={() => handleSubmit()}
                type='submit'
                variant='main'
              >
                Save
              </Button>
            </Box>
          </>
        )}
      </Formik>
      <UnsavedChangesModal
        isOpen={shouldShowUnsavedChangesModal}
        onCancel={() => setShouldShowUnsavedChangesModal(false)}
        onClose={onClose}
        onDiscard={() => onClose?.()}
      />
    </>
  );
};

const BrandSettings = () => {
  const [{ value }] = useField<Partial<Brand>[]>('brands');

  return (
    <FieldArray
      name='brands'
      render={arrayHelpers => (
        <>
          {value.map((_, index) => (
            <Row
              index={index}
              key={index}
              onRemove={() => arrayHelpers.remove(index)}
            />
          ))}
          <Box>
            <Button
              onClick={() => arrayHelpers.push({ brand_name: '' })}
              startIcon={<IconPlus />}
              variant='ghost'
            >
              Create a branded widget
            </Button>
          </Box>
        </>
      )}
    />
  );
};

interface RowProps {
  index: number;
  onRemove: () => void;
}

const Row = ({ index, onRemove }: RowProps) => {
  const [nameField, meta, nameCallbacks] = useField<string>(
    `brands.${index}.brand_name`,
  );

  const [defaultField] = useField<boolean | undefined>(
    `brands.${index}.is_default`,
  );

  return (
    <Box alignItems='flex-start' display='flex' gap={2}>
      <TextField
        aria-label={`Brand name ${index}`}
        description='Keep the name under 50 characters'
        error={meta.touched && meta.error}
        name={`brands.${index}.brand_name`}
        onChange={e => {
          nameCallbacks.setTouched(true);
          nameCallbacks.setValue(e.target.value);
        }}
        placeholder='Enter brand name'
        value={nameField.value}
      />
      <Tooltip
        tooltipContent={
          defaultField.value ? 'Cannot delete default brand' : false
        }
      >
        <IconButton
          aria-label='Remove Brand'
          disabled={defaultField.value}
          onClick={onRemove}
          variant='ghost'
        >
          <IconTrash size={20} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
