import { TitleFieldProps } from '@rjsf/utils';

import { Typography } from '@forethought-technologies/forethought-elements';
import { HIDDEN_TITLE_IDS } from './constants';

export default function TitleFieldTemplate({ id, title }: TitleFieldProps) {
  if (HIDDEN_TITLE_IDS.includes(id)) {
    return null;
  }
  return title ? <Typography variant='font16Bold'>{title}</Typography> : null;
}
