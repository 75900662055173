import { useEffect, useMemo } from 'react';
import { MRT_Row } from 'material-react-table';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import { AutomationTabComponentProps } from '../types';
import DiscoverPolicyRecommendationTable from './DiscoverPolicyRecommendationTable';
import debounce from 'lodash/fp/debounce';
import { DISCOVER_CARD_TEXT } from 'src/components/global-discover/constants';
import { DEBOUNCED_AUTOMATION_TRACKING_EVENT_TIMEOUT } from 'src/constants/discover';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { useGetRecommendedPoliciesQuery } from 'src/services/discover/discoverApi';
import { setGlobalDiscoverOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { PolicyRecommendation } from 'src/types/DiscoverTypes';

const DiscoverPolicyRecommendationTab = ({
  setHasScrolled,
}: AutomationTabComponentProps) => {
  const { palette } = useTheme();
  const {
    data: policyRecommendationData,
    isError,
    isLoading,
  } = useGetRecommendedPoliciesQuery();

  const dispatch = useAppDispatch();
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const handleMouseEnter = useMemo(() => {
    return debounce(
      DEBOUNCED_AUTOMATION_TRACKING_EVENT_TIMEOUT,
      (row: MRT_Row<PolicyRecommendation>) =>
        emitTrackingEventCallback('discover-recommendation-hovered', {
          page: 'Automation',
          'page-area': 'Recommendation',
          'topic-name': row.original.topic.topic_name,
        }),
    );
  }, [emitTrackingEventCallback]);

  useEffect(() => {
    setHasScrolled(false);
  }, [setHasScrolled]);

  const handleScroll = (scroll: number) => {
    setHasScrolled(Boolean(scroll));
  };

  const handleAutomationClick = (row: MRT_Row<PolicyRecommendation>) => {
    emitTrackingEventCallback('discover-automate-button-clicked', {
      page: 'Automation',
      'page-area': 'Recommendation',
      'topic-name': row.original.topic.topic_name,
    });

    dispatch(
      setGlobalDiscoverOptions({
        topicToAutomate: row.original.topic,
      }),
    );
  };

  const hasData = Boolean(policyRecommendationData?.policies?.length);
  const pt = hasData ? 4 : undefined;

  return (
    <Box display='flex' flexDirection='column' gap={1} height='71vh' pt={pt}>
      {hasData && (
        <Typography color={palette.colors.grey[600]} variant='font16'>
          {DISCOVER_CARD_TEXT.policyHelperGuide}
        </Typography>
      )}
      <DiscoverPolicyRecommendationTable
        data={policyRecommendationData?.policies}
        isError={Boolean(isError)}
        loading={isLoading}
        onMouseEnter={handleMouseEnter}
        onScroll={handleScroll}
        onSelect={handleAutomationClick}
      />
    </Box>
  );
};

export default DiscoverPolicyRecommendationTab;
