import Box from '@mui/material/Box';

import { DISCOVER_MODAL_TEXT, DISCOVER_TEXT } from '../constants';
import AutomationMetric from './AutomationMetric';
import SummaryContainer from './SummaryContainer';
import { DiscoverTopicActionableValue } from 'src/reducers/discoverReducer/types';
import { useGetMetadataQuery } from 'src/services/discover/discoverApi';
import {
  getCostReductionCopy,
  getDiscoverBannerValues,
} from 'src/utils/discover/helpers';

interface ChatBannerStatsProps {
  values?: DiscoverTopicActionableValue | null;
}
const ChatBannerStats = ({ values }: ChatBannerStatsProps) => {
  const { data: metadata } = useGetMetadataQuery();
  const { tooltip: costReductionTooltip } = getCostReductionCopy(
    metadata?.ticket_cost_info,
  );
  if (!values) {
    return null;
  }
  const { costValue, volumeValue } = getDiscoverBannerValues(values);

  return (
    <SummaryContainer
      appCues={{
        componentType: 'information',
        featureName: 'automationmodal',
        pageName: 'solve',
        subType: 'savings',
      }}
      summary={DISCOVER_TEXT.automationChatTopicSummary}
    >
      <Box display='flex' flexDirection='column' gap='11px'>
        {volumeValue && (
          <AutomationMetric
            title={DISCOVER_MODAL_TEXT.chatTitle}
            tooltip={DISCOVER_MODAL_TEXT.chatTooltip}
            value={volumeValue}
          />
        )}
        {costValue && (
          <AutomationMetric
            title={DISCOVER_MODAL_TEXT.costTitle}
            tooltip={costReductionTooltip}
            value={costValue}
          />
        )}
      </Box>
    </SummaryContainer>
  );
};

export default ChatBannerStats;
