import { Box } from '@mui/material';

import OverviewHeader from '../triage-model-detail-page/common/OverviewHeader';
import { DataItem } from '../triage-model-detail-page/common/OverviewItem';
import { labelOverviewInfoMap } from './constants';
import { NA } from 'src/constants/solve';
import { LabelOverallMetric } from 'src/services/triage/types';

const SimulationLabelsOverview = ({
  data,
}: {
  data: LabelOverallMetric;
  isLoading?: boolean;
}) => {
  // const { palette } = useTheme();
  // const colors = [
  //   palette.colors.purple[500],
  //   palette.colors.red[500],
  //   palette.colors.slate[100],
  //   palette.colors.red[500],
  // ];

  const overviewData = Object.keys(data)
    .map(key => {
      const typedKey = key as keyof LabelOverallMetric;
      const value = data[typedKey];
      const target = labelOverviewInfoMap[typedKey];
      if (!key || !target) {
        return null;
      }

      return {
        key: target.key,
        tooltip: target.tooltip,
        value: value ? target.formatValue(value) : NA,
      };
    })
    .filter((item): item is DataItem => item !== null);

  // const donutData: DonutGraphDataType = {
  //   values: [
  //     {
  //       label: 'total test tickets',

  //       value: data.total_tickets_count,
  //     },
  //     {
  //       label: 'predicted tickets',

  //       value: data.predictions_count,
  //     },
  //     {
  //       label: 'correct predictions',

  //       value: data.correct_predictions_count,
  //     },
  //     {
  //       label: 'incorrect predictions',

  //       value: data.predictions_count - data.correct_predictions_count,
  //     },
  //   ],
  // };

  // const subValues = [
  //   {
  //     title: 'total test tickets',
  //     tooltipText:
  //       'Tickets where the Triage model successfully predicted and assigned a label',
  //     value: data.total_tickets_count,
  //   },
  //   {
  //     title: 'predicted tickets',
  //     tooltipText:
  //       'Tickets where the Triage model successfully predicted and assigned a label',
  //     value: data.predictions_count,
  //   },
  //   {
  //     color: palette.colors.purple[500],
  //     title: 'correct predictions',
  //     tooltipText:
  //       'Tickets where the Triage model successfully predicted and assigned a label',
  //     value: data.correct_predictions_count,
  //   },
  //   {
  //     color: palette.colors.red[500],
  //     title: 'incorrect predictions',
  //     value: data.predictions_count - data.correct_predictions_count,
  //   },
  // ];
  return (
    <Box display='flex' sx={{ margin: '24px 0px 32px 0px' }}>
      <OverviewHeader
        data={overviewData}
        gridTemplateColumns='1fr 1fr 1fr 1fr 1fr'
        title='All labels overview'
      />
      {/* <Box width='66%'>
        <SimulationDonutCard
          colors={colors}
          donut={donutData}
          donutText='accuracy'
          isFetching={isLoading}
          isLoading={isLoading}
          subValues={subValues}
          title='Accuracy'
        />
      </Box> */}
    </Box>
  );
};

export default SimulationLabelsOverview;
