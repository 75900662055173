import moment from 'moment';

import { CustomRangeModifier } from 'src/reducers/pageSettingsReducer/pageSettingsReducer';

export const convertNumberOfDaysToTimeStamp = (numberOfDays: number) =>
  numberOfDays * 24 * 60 * 60 * 1000;

export const getCurrentMonthRange = () => {
  const lastDayOfMonth = new Date(moment().endOf('month').valueOf());
  // temporary patch to preserve the old timestamp, will fix in datepicker revamp
  lastDayOfMonth.setHours(11, 59, 0, 0);
  const today = new Date(Date.now());

  const endDate =
    today.getDate() < lastDayOfMonth.getDate() ? today : lastDayOfMonth;
  const startDate = new Date(moment().startOf('month').valueOf());
  // temporary patch to preserve the old timestamp, will fix in datepicker revamp
  startDate.setHours(12, 0, 0, 0);

  return { from: startDate, to: endDate };
};

export const getPreviousMonthRange = () => {
  const currentMonth = new Date().getMonth();
  const previousMonth = moment().month(currentMonth - 1);
  const startDate = new Date(previousMonth.startOf('month').valueOf());
  // temporary patch to preserve the old timestamp, will fix in datepicker revamp
  startDate.setHours(12, 0, 0, 0);
  const endDate = new Date(previousMonth.endOf('month').valueOf());
  // temporary patch to preserve the old timestamp, will fix in datepicker revamp
  endDate.setHours(11, 59, 0, 0);

  return { from: startDate, to: endDate };
};

// gets the time range from (29 days ago 0:00:00) to (today 23:59:59)
const getLastNDaysTimeRange = (numDays: number) => {
  const todayTimestamp = new Date().setHours(23, 59, 59, 0);
  const thirtyDaysAgoTimestamp =
    new Date().setHours(0, 0, 0) - convertNumberOfDaysToTimeStamp(numDays - 1);

  return {
    from: new Date(thirtyDaysAgoTimestamp),
    to: new Date(todayTimestamp),
  };
};

export const getTodayTimeRange = () => {
  const to = new Date().setHours(23, 59, 59, 0);
  const from = new Date().setHours(0, 0, 0);

  return {
    from: new Date(from),
    to: new Date(to),
  };
};

const getCurrentMonthRangeRevamped = () => {
  const lastDayOfMonth = new Date(moment().endOf('month').valueOf());
  const today = new Date(Date.now());

  const endDate =
    today.getDate() < lastDayOfMonth.getDate() ? today : lastDayOfMonth;
  const startDate = new Date(moment().startOf('month').valueOf());

  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 0);

  return { from: startDate, to: endDate };
};

const getPreviousMonthRangeRevamped = () => {
  const currentMonth = new Date().getMonth();
  const previousMonth = moment().month(currentMonth - 1);

  const startDate = new Date(previousMonth.startOf('month').valueOf());
  const endDate = new Date(previousMonth.endOf('month').valueOf());

  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 0);

  return { from: startDate, to: endDate };
};

export const datePickerRangeOptions = [
  {
    label: 'Last 7 days',
    value: {
      from: new Date(Date.now() - convertNumberOfDaysToTimeStamp(7)),
      to: new Date(),
    },
  },
  {
    label: 'Last 14 days',
    value: {
      from: new Date(Date.now() - convertNumberOfDaysToTimeStamp(14)),
      to: new Date(),
    },
  },
  {
    label: 'Last 30 days',
    value: {
      from: new Date(Date.now() - convertNumberOfDaysToTimeStamp(30)),
      to: new Date(),
    },
  },
  {
    label: 'This Month',
    value: getCurrentMonthRange(),
  },
  {
    label: 'Previous Month',
    value: getPreviousMonthRange(),
  },
];

export const last90DaysTimeRange = getLastNDaysTimeRange(90);

export const last30DaysTimeRange = getLastNDaysTimeRange(30);

export const last14DaysTimeRange = getLastNDaysTimeRange(14);

export const datePickerRangeOptionsRevamp = [
  {
    label: 'Today',
    value: getTodayTimeRange(),
  },
  {
    label: 'Last 7 days',
    value: getLastNDaysTimeRange(7),
  },
  {
    label: 'Last 14 days',
    value: getLastNDaysTimeRange(14),
  },
  {
    label: 'Last 30 days',
    value: last30DaysTimeRange,
  },
  {
    label: 'This Month',
    value: getCurrentMonthRangeRevamped(),
  },
  {
    label: 'Previous Month',
    value: getPreviousMonthRangeRevamped(),
  },
];

export const constructTimeRangeQueryParams = (
  timeRange: CustomRangeModifier,
  channel: string,
) => {
  const { from, to } = timeRange;
  const start = from ? moment(from.setHours(12, 0, 0, 0)).unix() : 0;
  const end = to ? moment(to.setHours(23, 59, 59, 999)).unix() : 9999999999;

  const formattedTimeRange = {
    channel,
    end: String(end),
    start: String(start),
  };
  const queryParams = new URLSearchParams(formattedTimeRange);

  return queryParams;
};

export const constructTimeRangeQueryParamsRevamped = (
  timeRange: CustomRangeModifier,
  channel: string,
) => {
  const { from, to } = timeRange;
  const start = from ? moment(from).unix() : 0;
  const end = to ? moment(to).unix() : 9999999999;

  const formattedTimeRange = {
    channel,
    end: String(end),
    start: String(start),
  };
  const queryParams = new URLSearchParams(formattedTimeRange);

  return queryParams;
};

export const constructDefaultTimeRangeQueryParams = (
  timeRange: CustomRangeModifier,
) => {
  const { from, to } = timeRange;
  const start = from ? moment(from).unix() : 0;
  const end = to ? moment(to).unix() : 9999999999;

  const formattedTimeRange = {
    end: String(end),
    start: String(start),
  };

  const queryParams = new URLSearchParams(formattedTimeRange);

  return queryParams;
};

export const formatTimeForSafari = (time: string): string => {
  /**
   * expected input time format example: 2024-05-16 20:55:14.858260 UTC
   * this is not a valid format for Safair Date constructor, we need to replace - with /, and
   * remove the milliseconds after the dot.
   * expected output time format example : 2024/05/16 20:55:14 UTC
   */
  const result = time.replace(/-/g, '/').replace(/\.[0-9]*/, '');

  return result;
};
