import React, {
  Dispatch,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import CVListOptionsPicker from './CVListOptionsPicker';
import { DynamicListOptionsViewer } from './DynamicListOptionsModal';
import * as Styled from './styles';
import keyBy from 'lodash/fp/keyBy';
import { setContextVariablesSettingsErrorState } from 'src/actions/action-builder-actions/actionBuilderActions';
import PlusIcon from 'src/assets/images/light-purple-plus-icon.svg';
import { ListOption } from 'src/components/reusable-components/autocomplete-dropdown/types';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import GlobalCVRow from 'src/pages/action-builder/context-variables-settings/GlobalCVRow';
import {
  createContextVariableID,
  cvValidators,
} from 'src/pages/action-builder/context-variables-settings/helpers';
import UniversalCVRow from 'src/pages/action-builder/context-variables-settings/UniversalCVRow';
import { WorkflowContextTooltip } from 'src/pages/action-builder/context-variables-settings/WorkflowContextTooltip';
import { UpdateFieldMappingParams } from 'src/pages/workflow-builder-config/context-variables/ContextVariablesPage';
import {
  CreateTicketFieldMappingRequest,
  TicketCustomField,
} from 'src/pages/workflow-builder-edit/types';
import { useGetContextVariableUsagesQuery } from 'src/services/action-builder/actionBuilderApi';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  ActionBuilderSettingsVariablesDict,
  ContextVariableConfigurationDict,
} from 'src/utils/actionBuilder/types';

interface ContextVariablesSettingsProps {
  customTicketFields: Array<TicketCustomField>;
  deleteTicketFieldMapping: (contextVariableId: string) => void;
  height?: string;
  setVariables: Dispatch<
    React.SetStateAction<ActionBuilderSettingsVariablesDict[]>
  >;
  shouldShowSkeletons: boolean;
  ticketFieldMappings: Array<CreateTicketFieldMappingRequest>;
  updateTicketFieldMappings: (mapping: UpdateFieldMappingParams) => void;
  variables: ActionBuilderSettingsVariablesDict[];
}

export const ContextVariablesSettings: FC<
  React.PropsWithChildren<ContextVariablesSettingsProps>
> = ({
  customTicketFields,
  deleteTicketFieldMapping,
  height = '56vh',
  setVariables,
  shouldShowSkeletons,
  ticketFieldMappings,
  updateTicketFieldMappings,
  variables,
}) => {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isSolveEmailEnabled = featureFlags.includes('solve_email_enabled');
  const { contextVariables: persistedContextVariables } =
    useGetContextVariables();
  const { data: contextVariablesUsagesData } =
    useGetContextVariableUsagesQuery();
  const { context_variable_usage_by_entity_type: cvUsageByEntityType } =
    contextVariablesUsagesData ?? {};

  const { list_cvs_values_usage_in_expression: listCvItemsUsageByEntityId } =
    contextVariablesUsagesData ?? {};

  const [selectedListOptionPickerIndex, setSelectedListOptionPickerIndex] =
    useState(-1);
  const [dynamicListOptionsViewerIndex, setDynamicListOptionsViewerIndex] =
    useState(-1);

  const persistedCVMap = keyBy(
    'context_variable_id',
    persistedContextVariables,
  );

  const tableData = variables;

  const validationArray = useMemo(() => {
    const validationArray = cvValidators(variables);

    return validationArray;
  }, [variables]);

  useEffect(() => {
    const hasValidationError = validationArray.some(value => value !== null);

    dispatch(setContextVariablesSettingsErrorState(hasValidationError));
  }, [validationArray, dispatch]);

  const updateVariables = useCallback(
    (
      value: string | ListOption[] | boolean | ContextVariableConfigurationDict,
      idx: number,
      key: keyof ActionBuilderSettingsVariablesDict,
    ) => {
      setVariables(variables => {
        const currentVariables = [...variables];
        currentVariables[idx] = { ...currentVariables[idx], [key]: value };
        return currentVariables;
      });
    },
    [setVariables],
  );
  const onFieldRemoval = useCallback(
    (idx: number) => {
      setVariables(variables => {
        const currentVariables = [...variables];
        currentVariables.splice(idx, 1);
        // Add empty field if user deletes last field
        if (currentVariables.length < 1) {
          currentVariables.push({
            displayName: '',
            id: createContextVariableID(),
            isUniversal: false,
            isWorkflowContext: false,
            type: 'SHORT_TEXT',
          });
        }
        return currentVariables;
      });
    },
    [setVariables],
  );

  const createContextVariable = () =>
    setVariables(prevVariables => [
      ...prevVariables,
      {
        displayName: '',
        id: createContextVariableID(),
        isUniversal: false,
        isWorkflowContext: false,
        type: 'SHORT_TEXT',
      },
    ]);

  const onCloseListOptionsPicker = () => setSelectedListOptionPickerIndex(-1);
  const onCloseDynamicListOptionsViewer = () =>
    setDynamicListOptionsViewerIndex(-1);
  const updateListOptions = (rowIdx: number) => (options: ListOption[]) =>
    updateVariables(options, rowIdx, 'listTypeOptions');
  const isListOptionPickerOpen = selectedListOptionPickerIndex !== -1;
  const isDynamicListOptionsViewerOpen = dynamicListOptionsViewerIndex !== -1;

  return (
    <>
      <Styled.ContextVariablesSettingsContainer height={height}>
        <Styled.Spacer height='10px' />
        <Styled.FieldHeadings includesExtraColumn={isSolveEmailEnabled}>
          <Typography color={palette.colors.grey[500]} variant='font14Button'>
            Display Name
          </Typography>
          <Typography color={palette.colors.grey[500]} variant='font14Button'>
            Type
          </Typography>
          {isSolveEmailEnabled && (
            <Typography color={palette.colors.grey[500]} variant='font14Button'>
              Mapping
            </Typography>
          )}
          <Box
            alignItems='center'
            display='flex'
            gap={'4px'}
            paddingLeft={'28px'}
          >
            <Typography color={palette.colors.grey[500]} variant='font14Button'>
              Pre-defined
            </Typography>
            <WorkflowContextTooltip />
          </Box>
        </Styled.FieldHeadings>
        <Styled.FieldsContainer>
          {tableData.map(
            (
              {
                configurationFields,
                displayName,
                id,
                isSystemContext,
                isUniversal,
                isWorkflowContext,
                listTypeOptions,
                type,
              },
              idx,
            ) => (
              <Styled.FieldItemContainer
                data-appcues-target={`${displayName}CvRow`}
                includesExtraColumn={isSolveEmailEnabled}
                isUniversal={isUniversal}
                key={id}
              >
                {isUniversal || isSystemContext ? (
                  <UniversalCVRow
                    customTicketFields={customTicketFields}
                    deleteTicketFieldMapping={deleteTicketFieldMapping}
                    displayName={displayName}
                    id={id}
                    isPersistedCVWorkflowContext={
                      !!persistedCVMap[id]?.is_workflow_context
                    }
                    isSystemContext={!!isSystemContext}
                    isWorkflowContext={!!isWorkflowContext}
                    rowIdx={idx}
                    shouldShowSkeletons={shouldShowSkeletons}
                    ticketFieldMappings={ticketFieldMappings}
                    type={type}
                    updateTicketFieldMappings={updateTicketFieldMappings}
                    updateVariables={updateVariables}
                    usageByEntity={
                      cvUsageByEntityType?.[id]?.usage_by_entity_type || null
                    }
                  />
                ) : (
                  <GlobalCVRow
                    configurationFields={configurationFields}
                    customTicketFields={customTicketFields}
                    deleteTicketFieldMapping={deleteTicketFieldMapping}
                    displayName={displayName}
                    errMsg={validationArray[idx] || ''}
                    id={id}
                    isPersistedCVWorkflowContext={
                      !!persistedCVMap[id]?.is_workflow_context
                    }
                    isWorkflowContext={!!isWorkflowContext}
                    listTypeOptions={listTypeOptions}
                    onFieldRemoval={onFieldRemoval}
                    rowIdx={idx}
                    setDynamicListOptionsViewerIndex={
                      setDynamicListOptionsViewerIndex
                    }
                    setSelectedListOptionPickerIndex={
                      setSelectedListOptionPickerIndex
                    }
                    shouldShowSkeletons={shouldShowSkeletons}
                    ticketFieldMappings={ticketFieldMappings}
                    type={type}
                    updateTicketFieldMappings={updateTicketFieldMappings}
                    updateVariables={updateVariables}
                    usageByEntity={
                      cvUsageByEntityType?.[id]?.usage_by_entity_type || null
                    }
                  />
                )}
              </Styled.FieldItemContainer>
            ),
          )}
          <Styled.Button onClick={createContextVariable}>
            <Styled.AddIcon src={PlusIcon} />
            Add Context
          </Styled.Button>
          <Styled.Spacer height='120px' />
        </Styled.FieldsContainer>
      </Styled.ContextVariablesSettingsContainer>
      <CVListOptionsPicker
        isOpen={isListOptionPickerOpen}
        onClose={onCloseListOptionsPicker}
        options={
          tableData[selectedListOptionPickerIndex]?.listTypeOptions ?? []
        }
        updateListOptions={updateListOptions(selectedListOptionPickerIndex)}
        usageByEntity={
          (listCvItemsUsageByEntityId || {})[
            tableData[selectedListOptionPickerIndex]?.id
          ]
        }
      />
      <DynamicListOptionsViewer
        displayName={tableData[dynamicListOptionsViewerIndex]?.displayName}
        isOpen={isDynamicListOptionsViewerOpen}
        onClose={onCloseDynamicListOptionsViewer}
        options={
          tableData[dynamicListOptionsViewerIndex]?.configurationFields
            ?.dynamicListConfig?.context_variables ?? []
        }
      />
    </>
  );
};
