import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, styled } from '@mui/material';
import { IconX } from '@tabler/icons-react';

import {
  Checkbox,
  SelectDropdown,
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import ContextMention from '../context-mention-input';
import { Add } from '../handoff-configuration/kustomer/agent-chat-handoff/KustomerChatCustomAttributes';
import { Spacer } from '../handoff-configuration/styles';
import { FieldNameToIdx } from '../handoff-configuration/zendesk/constants';
import {
  formatContextVariableForPersistence,
  validateZdMessagingCustomizationData,
} from '../handoff-configuration/zendesk/helpers';
import TagInputWithContextVariables from '../tag-input-with-context-variables/TagInputWithContextVariables';
import { cvRegex } from './utils';
import isEqual from 'lodash/fp/isEqual';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import StyledButton, { SecondaryButton } from 'src/components/styled-button';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import {
  selectActionSettingsConfigurationFields,
  selectCanvasIsLoadingActionCustomization,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  selectAddingAction,
  selectHandoffCustomization,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  addAction,
  getHandoffCustomization,
  updateActionSettings,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import {
  ZendeskCustomField,
  ZendeskMessagingHandoffActionSettings,
} from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

interface Props {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
}

const ZendeskMessagingHandoffCustomizationPanel: React.FC<
  React.PropsWithChildren<Props>
> = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  const dispatch = useDispatch();
  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });
  const addingAction = useSelector(selectAddingAction);
  const isLoadingActionCustomization = useSelector(
    selectCanvasIsLoadingActionCustomization,
  );
  const actionSettingsConfigurationFields = useSelector(
    selectActionSettingsConfigurationFields,
  ) as ZendeskMessagingHandoffActionSettings;
  const defaultZendeskMessagingHandoffCustomizationSettings = useSelector(
    selectHandoffCustomization,
  ) as Partial<ZendeskMessagingHandoffActionSettings>;

  const defaultData = useMemo(
    () => ({
      auth_token: '',
      available_ticket_fields:
        defaultZendeskMessagingHandoffCustomizationSettings.available_ticket_fields ||
        [],
      custom_fields_and_values: [],
      prepend_transcript: false,
      question_variable: 'free_form_query',
      ticket_tags: [],
    }),
    [defaultZendeskMessagingHandoffCustomizationSettings],
  );

  const initialCustomizationData: ZendeskMessagingHandoffActionSettings =
    addingAction ? defaultData : actionSettingsConfigurationFields;

  const [customizationData, setCustomizationData] =
    useState<ZendeskMessagingHandoffActionSettings>(initialCustomizationData);

  const errorMessages = validateZdMessagingCustomizationData({
    allowableContextVariables: contextVariables,
    customizationData,
  });

  const ticketTagErrorExists = errorMessages[FieldNameToIdx.TICKET_TAGS].some(
    (err: string) => !!err,
  );

  const {
    available_ticket_fields: availableTicketFields = [],
    custom_fields_and_values: customFieldsAndValues = [],
  } = customizationData || {};

  const customFieldOptions =
    availableTicketFields.map(ticketField => ({
      label: ticketField.title,
      value: ticketField.id.toString(),
    })) || [];

  const emptyCustomField = {
    custom_field_id: '',
    custom_field_value: '',
  };

  const isPristine = addingAction
    ? false
    : isEqual(customizationData, initialCustomizationData);

  const formattedTags = useMemo(
    () =>
      customizationData?.ticket_tags.map(tag => {
        if (cvRegex.test(tag)) {
          const cvId = tag.substring(2, tag.length - 2);
          const cv = contextVariables.find(
            cv => cv.context_variable_id === cvId,
          );
          return `{{${cv?.context_variable_name || ''}}}`;
        }
        return tag;
      }),
    [contextVariables, customizationData?.ticket_tags],
  );

  const areCustomFieldsValid = () => {
    if (!customFieldsAndValues.length) {
      return true;
    }
    return customFieldsAndValues.every(
      customField =>
        customField.custom_field_id && customField.custom_field_value,
    );
  };

  const save = () => {
    if (addingAction) {
      dispatch(
        addAction({
          ...addingAction,
          data: {
            ...addingAction.data,
            configuration_fields: { ...customizationData },
          },
        }),
      );
    } else {
      dispatch(
        updateActionSettings({
          action_type: ActionBuilderActionTypes.ZENDESK_MESSAGING_HANDOFF,
          configuration_fields: { ...customizationData },
        }),
      );
    }

    dispatchActionEditedTrackingEvent({
      actionType: ActionBuilderActionTypes.ZENDESK_MESSAGING_HANDOFF,
    });
    setActionPanelVisibilityParameters('hidden');
    setAreActionSettingsUnsaved(false);
    dispatch(setCustomizableActionId(''));
    onDismiss();
  };

  useEffect(() => {
    if (!isPristine) {
      setAreActionSettingsUnsaved(true);
    } else {
      setAreActionSettingsUnsaved(false);
    }
  }, [isPristine, setAreActionSettingsUnsaved]);

  useEffect(() => {
    if (addingAction) {
      dispatch(
        getHandoffCustomization(
          ActionBuilderActionTypes.ZENDESK_MESSAGING_HANDOFF,
        ),
      );
    }
  }, [dispatch, addingAction]);

  useEffect(() => {
    if (!isLoadingActionCustomization) {
      setCustomizationData(
        addingAction ? defaultData : actionSettingsConfigurationFields,
      );
    }
  }, [
    defaultData,
    actionSettingsConfigurationFields,
    addingAction,
    isLoadingActionCustomization,
  ]);

  if (!customizationData) {
    return <LoadingSkeleton />;
  }

  const handleTagAddition = (tag: string) => {
    setCustomizationData(handoffData => {
      return {
        ...handoffData,
        ticket_tags: [
          ...(handoffData?.ticket_tags || []),
          formatContextVariableForPersistence(contextVariables, tag),
        ],
      };
    });
  };

  const handleTagDeletion = (idx: number) => {
    setCustomizationData(handoffData => {
      return {
        ...handoffData,
        ticket_tags: handoffData?.ticket_tags.filter(
          (_, currentIndex) => currentIndex !== idx,
        ),
      };
    });
  };

  const onCustomFieldIdChange = (newId: string, idx: number) => {
    const updatedCustomFields = customFieldsAndValues.map(
      (field, currentIndex) => {
        if (currentIndex === idx) {
          return {
            custom_field_id: Number(newId),
            custom_field_value: '',
          };
        }
        return field;
      },
    );

    setCustomizationData(handoffData => {
      return {
        ...handoffData,
        custom_fields_and_values: updatedCustomFields,
      };
    });
  };

  const onCustomFieldValueChange = (newValue: string, idx: number) => {
    const updatedCustomFields = customFieldsAndValues.map(
      (field, currentIndex) => {
        if (currentIndex === idx) {
          return {
            ...field,
            custom_field_value: newValue,
          };
        }
        return field;
      },
    );

    setCustomizationData(handoffData => {
      return {
        ...handoffData,
        custom_fields_and_values: updatedCustomFields,
      };
    });
  };

  const getTicketFieldOptions = (fieldId: number) => {
    for (const field of availableTicketFields) {
      if (field.id === fieldId) {
        return field.custom_field_options;
      }
    }

    return [];
  };

  const removeCustomField = (idx: number) => {
    const updatedCustomFields = customFieldsAndValues.filter(
      (_, currentIndex) => currentIndex !== idx,
    );

    setCustomizationData(handoffData => {
      return {
        ...handoffData,
        custom_fields_and_values: updatedCustomFields,
      };
    });
  };

  const addCustomField = () => {
    setCustomizationData(handoffData => {
      return {
        ...handoffData,
        custom_fields_and_values: [
          ...customFieldsAndValues,
          {
            ...emptyCustomField,
            custom_field_id: customFieldsAndValues.length,
          },
        ],
      };
    });
  };

  const getInputComponent = (
    customField: ZendeskCustomField,
    index: number,
  ): JSX.Element => {
    const options = getTicketFieldOptions(customField.custom_field_id);

    if (options.length) {
      return (
        <Box
          sx={{
            backgroundColor: theme.palette.colors.white,
            flex: '1 1 50%',
          }}
        >
          <ContextVariableSelectDropdown
            additionalOptions={options.map(option => {
              return {
                category: 'Custom Field Options',
                label: option.name,
                value: option.value,
              };
            })}
            aria-label='Select a Value...'
            id={customField.custom_field_id.toString()}
            onChange={value => onCustomFieldValueChange(value, index)}
            shouldIncludeSystemContextVariables
            shouldProvideCVIdFormatting
            value={customField.custom_field_value.toString()}
          />
        </Box>
      );
    } else {
      return (
        <Box
          sx={{
            backgroundColor: theme.palette.colors.white,
            flex: '1 1 50%',
          }}
        >
          <ContextMention
            onChange={value => {
              onCustomFieldValueChange(value, index);
            }}
            placeholder='Enter value'
            value={customField.custom_field_value.toString()}
          />
        </Box>
      );
    }
  };

  if (isLoadingActionCustomization) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', mb: 6.25 }}>
        <Typography variant='font20'>Zendesk Messaging Handoff</Typography>
        <Spacer height='20px' />
        <Section>
          <Typography variant='font14Bold'>Question Variable</Typography>
          <ContextVariableSelectDropdown
            label=''
            onChange={value => {
              setCustomizationData({
                ...customizationData,
                question_variable: value,
              });
            }}
            shouldIncludeSystemContextVariables
            value={customizationData.question_variable}
          />
        </Section>
        <Section>
          <Checkbox
            checked={customizationData.prepend_transcript}
            label='Prepend transcript to question'
            onChange={() => {
              setCustomizationData({
                ...customizationData,
                prepend_transcript: !customizationData.prepend_transcript,
              });
            }}
          />
        </Section>
        <Section>
          <Typography variant='font14Bold'>Ticket tags</Typography>
          <TagInputWithContextVariables
            contextVariables={contextVariables}
            errorMessages={errorMessages}
            errorTag={FieldNameToIdx.TICKET_TAGS}
            handleTagAddition={handleTagAddition}
            handleTagDeletion={handleTagDeletion}
            label=''
            tags={formattedTags || []}
          />
        </Section>
        <Section>
          <ContextVariableSelectDropdown
            label='Authorization token'
            onChange={authToken => {
              setCustomizationData({
                ...customizationData,
                auth_token: authToken,
              });
            }}
            value={customizationData.auth_token || ''}
          />
        </Section>
        <Section>
          <Typography variant='font14Bold'>Custom Fields</Typography>
          {customFieldsAndValues.map((customField, index) => (
            <Box
              key={`${customField.custom_field_id}`}
              sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Box
                  sx={{
                    backgroundColor: theme.palette.colors.white,
                    flex: '1 1 50%',
                  }}
                >
                  <SelectDropdown
                    aria-label='Select a field...'
                    disablePortal
                    id={`select-${customField.custom_field_id}`}
                    menuMaxHeight={180}
                    onChange={event => {
                      onCustomFieldIdChange(event.target.value, index);
                    }}
                    options={customFieldOptions}
                    placeholder='Select a field...'
                    value={customField.custom_field_id.toString()}
                  />
                </Box>
                <Spacer width='8px' />
                {getInputComponent(customField, index)}
                <Spacer width='8px' />
                <IconX
                  cursor='pointer'
                  onClick={() => removeCustomField(index)}
                />
              </Box>
            </Box>
          ))}
          <Add isDisabled={false} onClick={addCustomField}>
            + Add field
          </Add>
        </Section>
      </Box>

      {/* Save and Cancel Buttons */}
      <Box
        alignItems='center'
        display='flex'
        justifyContent='flex-end'
        py={1.25}
        sx={{
          borderBottom: '8px',
          borderTop: `1px solid ${theme.palette.colors.slate[200]}`,
          height: '40px',
        }}
      >
        <SecondaryButton
          onClick={() => {
            onDiscard();
          }}
        >
          Cancel
        </SecondaryButton>
        <StyledButton
          disabled={
            isPristine ||
            ticketTagErrorExists ||
            !areCustomFieldsValid() ||
            hasWorkflowConflict
          }
          onClick={save}
        >
          Save
        </StyledButton>
      </Box>
    </>
  );
};

export default ZendeskMessagingHandoffCustomizationPanel;

const Section = styled('section')`
  border-top: 1px solid ${theme.palette.colors.grey[100]};
  border-left: none;
  border-right: none;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
