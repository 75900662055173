import {
  DiscoverMetricDataType,
  DiscoverTopicAggregatedMetricType,
} from 'src/reducers/discoverReducer/types';
import { getDisplayableDataByMetricType } from 'src/utils/discover/helpers';

export const getMetricValue = ({
  dataType,
  metric,
  value,
}: {
  dataType: DiscoverMetricDataType | 'unknown';
  metric: DiscoverTopicAggregatedMetricType;
  value: number | null;
}) => {
  if (metric === 'volume') {
    return (value || 0).toLocaleString();
  }
  return getDisplayableDataByMetricType({
    dataType,
    metric,
    value,
  });
};
