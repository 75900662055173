import {
  DiscoverAutomationValueType,
  DiscoverTopic,
} from 'src/reducers/discoverReducer/types';
import { PolicyRecommendation } from 'src/types/DiscoverTypes';

export const TABLE_COLUMNS: {
  key: keyof DiscoverTopic;
  size: number;
  target?: 'cost' | 'volume';
  title: string;
}[] = [
  {
    key: 'topic_name',
    size: 300,
    title: 'Topic',
  },
  {
    key: 'actionable_value',
    size: 200,
    target: 'volume',
    title: 'Ticket reduction/year',
  },
  {
    key: 'actionable_value',
    size: 200,
    target: 'cost',
    title: 'Cost reduction/year',
  },
];

type POLICY_COLUMN_KEYS =
  | keyof PolicyRecommendation
  | `topic.${keyof DiscoverTopic}`
  | `topic.actionable_value.solve_workflow.${DiscoverAutomationValueType}`;

export const POLICY_TABLE_COLUMNS: {
  key: POLICY_COLUMN_KEYS;
  size: number;
  title: string;
}[] = [
  {
    key: 'topic.topic_name',
    size: 260,
    title: 'Topic for generated policy',
  },
  {
    key: 'policy_description',
    size: 300,
    title: 'AutoFlow policy',
  },
  {
    key: 'topic.actionable_value.solve_workflow.volume',
    size: 175,
    title: 'Ticket coverage/year',
  },
  {
    key: 'topic.actionable_value.solve_workflow.cost',
    size: 175,
    title: 'Potential saving/year',
  },
  {
    key: 'created_date',
    size: 175,
    title: 'Time created',
  },
];
