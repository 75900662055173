import { useMemo, useState } from 'react';
import { createMRTColumnHelper, MRT_Row } from 'material-react-table';

import { Drawer } from '@forethought-technologies/forethought-elements';
import TableDownloadButton from '../common/table-download-button';
import TextWithInfo from '../triage-model-detail-page/common/TextWithInfo';
import TriageModelTable from '../triage-model-detail-page/common/TriageModelTable';
import ValueCell from '../triage-model-detail-page/common/ValueCell';
import { labelTableInfoMap } from './constants';
import SimulationLabelsDetails from './SimulationLabelsDetails';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';
import { LabelMetrics } from 'src/services/triage/types';
interface LabelsTableProps {
  data: LabelMetrics[];
  isDataLoading: boolean;
  model: VersionedTriageModel;
}

const SIMULATION_LABEL_COLUMNS: {
  key: keyof LabelMetrics;
  size?: number;
  title: string;
  tooltip: string | null;
}[] = [
  {
    key: 'label_name',
    size: 300,
    title: 'Labels',
    tooltip: null,
  },
  {
    key: 'description',
    size: 300,
    title: 'Description',
    tooltip: null,
  },
  {
    key: 'predictions_count',
    size: 240,
    title: labelTableInfoMap.predictions_count.key,
    tooltip: labelTableInfoMap.predictions_count.tooltip,
  },
  {
    key: 'accurate_count',
    size: 150,
    title: labelTableInfoMap.accurate_count.key,
    tooltip: labelTableInfoMap.accurate_count.tooltip,
  },
  {
    key: 'accuracy',
    size: 150,
    title: labelTableInfoMap.accuracy.key,
    tooltip: labelTableInfoMap.accuracy.tooltip,
  },
  {
    key: 'recall',
    size: 150,
    title: labelTableInfoMap.recall.key,
    tooltip: labelTableInfoMap.recall.tooltip,
  },
];

const percentages: Array<Partial<keyof LabelMetrics>> = ['accuracy', 'recall'];

const SimulationLabelsTable = ({
  data,
  isDataLoading,
  model,
}: LabelsTableProps) => {
  const predictionTestId = window.location.pathname.split('/')[2];
  const [searchText, setSearchText] = useState('');
  const [selectedLabel, setSelectedLabel] = useState<LabelMetrics | null>(null);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const columnHelper = createMRTColumnHelper<LabelMetrics>();
  const columns = useMemo(() => {
    return SIMULATION_LABEL_COLUMNS.map(item => {
      return columnHelper.accessor(item.key, {
        Cell: ({ row }: { row: MRT_Row<LabelMetrics> }) => {
          if (item.key === 'recall') {
            const value = row.original[item.key];
            // If some reason the value is over 100% cap it
            const transformValue = value >= 1 ? 1 : value;
            return (
              <ValueCell
                isLoading={isDataLoading}
                isPercentage={percentages.includes(item.key)}
                itemKey={item.key}
                value={transformValue}
              />
            );
          }
          return (
            <ValueCell
              isLoading={isDataLoading}
              isPercentage={percentages.includes(item.key)}
              itemKey={item.key}
              value={row.original[item.key]}
            />
          );
        },
        header: item.title,
        Header: (
          <TextWithInfo
            textColor={['grey', 800]}
            title={item.title}
            tooltip={item.tooltip || ''}
            variant='font14Bold'
          />
        ),
        size: item.size,
      });
    });
  }, [columnHelper, isDataLoading]);

  const rows = useMemo(() => {
    if (!searchText) {
      return data;
    }
    return data.filter(item => {
      const text = searchText.toLowerCase();
      const hasName = item.label_name.toLowerCase().includes(text);
      const hasDescription = item.description.toLowerCase().includes(text);

      return hasName || hasDescription;
    });
  }, [searchText, data]);

  const handleRowClick = (label: LabelMetrics) => {
    setSelectedLabel(label);
    setDrawerIsOpen(true);
  };

  const handleClose = () => {
    setDrawerIsOpen(false);
    setSelectedLabel(null);
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  return (
    <>
      <TriageModelTable<LabelMetrics, string | number>
        columns={columns}
        Control={
          <TableDownloadButton
            data_export_type='triage_llm_simulation_labels_table'
            requestData={{
              prediction_test_id: predictionTestId,
            }}
          />
        }
        data={rows}
        FilterComponent={null}
        initialSorting={[{ desc: false, id: 'label_name' }]}
        initialState={{
          columnPinning: {
            left: ['label_name'],
          },
        }}
        isLoading={isDataLoading}
        onSearch={handleSearch}
        options={{
          muiTableBodyRowProps: ({ row }) => ({
            onClick: () => handleRowClick(row.original),
            sx: {
              cursor: 'pointer',
            },
          }),
        }}
      />
      {selectedLabel && (
        <Drawer isOpen={drawerIsOpen} onClose={handleClose} width='434px'>
          <SimulationLabelsDetails label={selectedLabel} model={model} />
        </Drawer>
      )}
    </>
  );
};

export default SimulationLabelsTable;
