import React, { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Tooltip } from '@forethought-technologies/forethought-elements';
import { useGetUndefinedContextVariableIdsOnCurrentAction } from '../hooks/useGetUndefinedContextVariableIdsOnCurrentAction';
import tooltipInfoIcon from 'src/assets/images/tooltip-info.svg';
import ContextVariableAutocomplete from 'src/components/context-variable-autocomplete';
import {
  Label,
  Required,
  Row,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';

type Props = {
  allowSpaceInQuery?: boolean;
  disabled?: boolean;
  disablePortal?: boolean;
  errorMessage?: string;
  filterFn?: (cv: ContextVariable) => boolean;
  hasError?: boolean;
  isMatchingNewStyle?: boolean;
  label?: string;
  onBlur?: () => void;
  onChange: (value: string) => void;
  placeholder?: string;
  required?: boolean;
  tooltip?: string;
  value: string;
};

export const ContextMention: FC<React.PropsWithChildren<Props>> = ({
  errorMessage = '',
  hasError = Boolean(errorMessage),
  label = '',
  filterFn,
  onChange,
  onBlur,
  placeholder = 'Enter value here',
  tooltip,
  value,
  required = false,
  disabled = false,
  disablePortal,
}) => {
  const undefinedContextVariableIds =
    useGetUndefinedContextVariableIdsOnCurrentAction();
  const errorMessageId = uuidv4();
  const { palette } = useTheme();

  return (
    <ContextVariableAutocomplete
      aria-errormessage={errorMessageId}
      aria-invalid={hasError}
      aria-label={label}
      disabled={disabled}
      disablePortal={disablePortal}
      error={hasError ? errorMessage : hasError}
      filterFn={filterFn}
      label={
        label ? (
          <Row>
            <Label>{label}</Label>
            {required && (
              <>
                <Spacer width='4px' />
                <Required color={palette.colors.grey[400]}>Required</Required>
              </>
            )}
            {tooltip && (
              <Tooltip tooltipContent={tooltip}>
                <Icon src={tooltipInfoIcon} />
              </Tooltip>
            )}
          </Row>
        ) : undefined
      }
      onBlur={onBlur}
      onChange={onChange}
      placeholder={placeholder}
      shouldIncludeSystemContextVariables
      undefinedContextVariables={undefinedContextVariableIds}
      value={value}
    />
  );
};

export default ContextMention;

const Icon = styled('img')`
  margin-bottom: 8px;
  margin-left: 4px;
`;
