import { useCallback, useRef, useState } from 'react';
import { useEffect, useMemo } from 'react';
import Pusher from 'pusher-js';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { getChatUrl } from '../events/error/utils';
import { WORKFLOW_BUILDER_QUERY_PARAM_KEY } from '../workflow-builder/intent-workflows-table/constants';
import { getWorkflowIdByChannel } from '../workflow-builder/intent-workflows-table/helper';
import { useSolveMetricsQueryParams } from '../workflow-builder/intent-workflows-table/hooks/useSolveMetricsQueryParams';
import { getWorkflowValueFromSearch } from './autonomous-agent/helpers';
import { JOURNEY_MAP } from './email-builder-page/intent-email-builder/constants';
import { TEMPLATE_INTENT_ID, WIDGET, WidgetMode } from './constants';
import { BuilderView, EmailMode } from './types';
import { formatIntentNames } from './utils';
import { duplicateClassicToProduct } from 'src/actions/workflow-builder/workflowBuilderActions';
import { INTENT_FILTER_PARAM_NAME } from 'src/components/dashboard-pages/solve-insights/constants';
import { dateRangeToTimestamp } from 'src/components/dashboard-pages/solve-insights/helpers';
import useTabs from 'src/components/dashboard-pages/solve-insights/hooks/useTabs';
import {
  useGetIntentsQueryWithProduct,
  useLoadWorkflowBuilderFromURL,
} from 'src/hooks/hooks';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { transformActionsForCanvas } from 'src/reducers/actionBuilderReducer/actionBuilderReducer';
import {
  selectCanvasWorkflowBuilder,
  selectIntentTitle,
  selectInvalidGoToSteps,
  selectIsBranchValid,
  selectUndefinedContextVariables,
  selectUnsupportedSteps,
  selectVersion,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useGetActionBuilderActionsQuery } from 'src/services/action-builder/actionBuilderApi';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { useGetWorkflowsForIntentQuery } from 'src/services/workflow-builder-canvas/workflowBuilderCanvasApi';
import { useGetHandoffConfigurationsQuery } from 'src/services/workflow-builder-metrics';
import {
  useGetAutoflowTemplatesQuery,
  useGetAutoflowWorkflowQuery,
} from 'src/services/workflowBuilderAutoflowApi.ts/workflowBuilderAutoflowApi';
import {
  CanvasWorkflowBuilderState,
  setTargetStepId,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  getIntent,
  getLatestWorkflowVersions,
  loadDraftWorkflow,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import {
  getIntentEmailConfiguration,
  obtainIntentEmailConfiguration,
  selectEmailBuilderState,
} from 'src/slices/email-builder/emailBuilderSlice';
import {
  selectEmailWorkflowState,
  validateEmailWorkflow,
} from 'src/slices/email-workflow/emailWorkflowSlice';
import { useAppDispatch } from 'src/store/hooks';
import { SolveWidgetProduct } from 'src/types/types';
import { CHANNEL_TO_PRODUCT_MAP } from 'src/utils/constants';
import { CommonIntentWorkflowType, Routes } from 'src/utils/enums';
import { handleSwapWhenHovering } from 'src/utils/solve/stepUtils';
import { last30DaysTimeRange } from 'src/utils/timeRangeHelpers';

export function useGetBuilderQueryParams() {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const isWorkflowBuilder = pathname === Routes.WORKFLOW_BUILDER_EDIT;

  const view = useMemo(() => {
    const query = new URLSearchParams(search);
    const configurationId = query.get('configurationId') || '';
    const view = (query.get('view') as BuilderView) || (WIDGET as BuilderView);
    const workflowId = query.get('workflowId') || '';
    const intentId = query.get('intentId') || '';
    const stepId = query.get('stepId') || '';

    return { configurationId, intentId, stepId, view, workflowId };
  }, [search]);
  const { data, isLoading } = useGetIntentsQueryWithProduct(undefined, {
    skip: !!view.intentId || !isWorkflowBuilder, // Skip the API call if view.intentId is set.
  });

  useEffect(() => {
    if (!isWorkflowBuilder) {
      return;
    }

    if (!view.intentId && !isLoading && data) {
      const query = new URLSearchParams(search);
      const intentId =
        data.intents.find(
          intent =>
            getWorkflowIdByChannel(view.view, intent) === view.workflowId,
        )?.intent_definition_id || '';
      query.set('intentId', intentId);
      navigate(
        {
          search: query.toString(),
        },
        { replace: true },
      );
    }
  }, [view, data, isLoading, search, navigate, isWorkflowBuilder]);
  return view;
}

/**
 * Construct the URL for cases where we are not able te get intent id and workflow id right away.
 * This behavior can occur whenever a new intent workflow is created or new a common intent workflow
 * is added.
 */
export function useConstructUrlOnIntetWorkflowCreation() {
  const navigate = useNavigate();
  const canvasData = useSelector(selectCanvasWorkflowBuilder);
  const {
    intentId: intentIdQueryParam,
    view: currentView,
    workflowId: workflowIdQueryParam,
  } = useGetBuilderQueryParams();
  const workflowBuilderDataIntentId = canvasData.intent_id;
  const workflowBuilderDataWorkflowId =
    currentView === 'api'
      ? canvasData.api_workflow_id
      : canvasData.intent_workflow_id;

  const isDataLoadedCorrectly =
    workflowBuilderDataIntentId && workflowBuilderDataWorkflowId;

  const areQueryParamsIncomplete = !workflowIdQueryParam;

  const shouldUpdateQueryParams =
    isDataLoadedCorrectly && areQueryParamsIncomplete;

  useEffect(() => {
    if (shouldUpdateQueryParams) {
      const queryParams = {
        /**
         * If a common intent is added we will get the intent id for free from the URL,
         * if not, we will need to get from the API request.
         */
        intentId: intentIdQueryParam
          ? intentIdQueryParam
          : workflowBuilderDataIntentId || '',
        view: currentView,
        workflowId: workflowBuilderDataWorkflowId,
      };
      navigate(
        {
          search: `?${new URLSearchParams(queryParams).toString()}`,
        },
        { replace: true },
      );
    }
  }, [
    shouldUpdateQueryParams,
    workflowBuilderDataIntentId,
    workflowIdQueryParam,
    intentIdQueryParam,
    workflowBuilderDataWorkflowId,
    currentView,
    navigate,
  ]);
}

export const useIntentEmailConfiguration = ({
  configurationId,
  intentId,
  isEmailBuilderView,
}: {
  configurationId: string;
  intentId: string;
  isEmailBuilderView: boolean;
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmailBuilderView) {
      return;
    }
    if (configurationId) {
      dispatch(getIntentEmailConfiguration({ configurationId }));
      return;
    } else {
      dispatch(
        obtainIntentEmailConfiguration({ intentDefinitionId: intentId }),
      );
    }
  }, [configurationId, dispatch, intentId, isEmailBuilderView]);

  return useSelector(selectEmailBuilderState);
};

export const useGetIntentTitle = (intentId: string) => {
  const dispatch = useDispatch();
  const intentTitle = useSelector(selectIntentTitle);
  const isTemplateIntentWorkflow = useGetIsTemplateIntentWorkflow();
  const { template } = useGetTemplateAutoflowWorkflow();

  useEffect(() => {
    if (intentId && !isTemplateIntentWorkflow) {
      dispatch(getIntent(intentId));
    }
  }, [dispatch, intentId, intentTitle, isTemplateIntentWorkflow]);

  return template?.name ?? intentTitle;
};

export const useGetValidationErrors = () => {
  const dispatch = useDispatch();
  const { emailWorkflow } = useSelector(selectEmailWorkflowState);
  const { email_workflow_id, last_modified_date, step_map, version } =
    emailWorkflow || {};

  useEffect(() => {
    if (email_workflow_id) {
      dispatch(
        validateEmailWorkflow({
          emailWorkflowId: email_workflow_id,
          version: version || -1,
        }),
      );
    }
  }, [dispatch, last_modified_date, step_map, version, email_workflow_id]);
};

export const useGoBackToLandingPage = () => {
  const navigate = useNavigate();

  return useCallback(() => {
    const search = window.localStorage.getItem(
      WORKFLOW_BUILDER_QUERY_PARAM_KEY,
    );
    window.localStorage.removeItem(WORKFLOW_BUILDER_QUERY_PARAM_KEY);
    navigate({
      pathname: Routes.WORKFLOW_BUILDER,
      search: search ?? '',
    });
  }, [navigate]);
};

export const useCheckForToasts = () => {
  const location = useLocation();

  const [toastMessage, setToastMessage] = useState<string | null>(null);

  useEffect(() => {
    const checkForToasts = () => {
      const query = new URLSearchParams(location.search);
      if (query.get('from') === 'createIntent') {
        setToastMessage('New intent is added.');
      } else if (query.get('from') === 'updateIntent') {
        setToastMessage('Intent has been updated.');
      }
    };

    checkForToasts();
  }, [location.search]);

  return { setToastMessage, toastMessage };
};

export const useInitPusher = () => {
  useEffect(() => {
    if (!window.pusher) {
      window.pusher = new Pusher(PUSHER_KEY, {
        cluster: PUSHER_CLUSTER,
      });
    }
  }, []);
};

export const useWorkflowBuilderMode = () => {
  const navigate = useNavigate();
  const version = useSelector(selectVersion);
  const { search } = useLocation();
  const { data } = useGetIntentsQueryWithProduct();
  const { intents = [] } = data ?? {};
  const intentId = getWorkflowValueFromSearch(search, 'intentId');
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isAutonomousAgentEnabled = featureFlags.includes(
    'autonomous_agent_enabled',
  );

  const selectedIntent = useMemo(() => {
    return intents?.find(intent => intent.intent_definition_id === intentId);
  }, [intentId, intents]);
  const isAutoflow = selectedIntent?.is_autoflow;
  const isHandoff = selectedIntent?.is_handoff;

  const isKnowledge =
    selectedIntent?.intent_definition_id ===
    CommonIntentWorkflowType.KNOWLEDGE_ARTICLE;
  const isOtherQuestions =
    selectedIntent?.intent_definition_id ===
    CommonIntentWorkflowType.GENERAL_HANDOFF;

  const updateWorkflowBuilderMode = useCallback(
    (mode: WidgetMode) => {
      const mergedParams = new URLSearchParams({
        ...Object.fromEntries(new URLSearchParams(search)),
        ...{ mode },
      }).toString();
      navigate({
        search: `?${mergedParams.toString()}`,
      });
    },
    [navigate, search],
  );

  const getMode = () => {
    const paramMode = new URLSearchParams(search).get('mode');
    if (paramMode) {
      return paramMode as WidgetMode;
    }

    /**
     * If mode does not exist in the URL, we will go based off this logic
     * 1. Return classic for handoffs/knowledge/other questions
     * 2. isAutoflow = true => "autoflow"
     * 3. isAutoflow = false
     *    a. classic workflow version <= 2 aka has no live workflow && autoflow is enabled => "autoflow"
     *    b. other cases are all "classic"
     */
    if (isHandoff || isKnowledge || isOtherQuestions) {
      return 'classic';
    }
    if (isAutoflow || (version <= 2 && isAutonomousAgentEnabled)) {
      return 'autoflow';
    }

    return 'classic';
  };

  return [getMode(), updateWorkflowBuilderMode] as const;
};

export const useEmailMode = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const emailMode = (query.get('emailMode') || JOURNEY_MAP) as EmailMode;
  const { intentId } = useGetBuilderQueryParams();

  const { data: workflowsForIntent, isLoading } = useGetWorkflowsForIntentQuery(
    {
      intentDefinitionId: intentId,
    },
    {
      skip: !intentId,
    },
  );

  const setEmailMode = useCallback(
    (tab: EmailMode) => {
      const queryParams = new URLSearchParams(search);
      const workflowId =
        tab === 'Interactive'
          ? workflowsForIntent?.interactive_email_workflow_id
          : workflowsForIntent?.widget_workflow_id;
      queryParams.set('workflowId', workflowId as string);
      queryParams.set('emailMode', tab);
      navigate({
        search: queryParams.toString(),
      });
    },
    [navigate, search, workflowsForIntent],
  );

  return { emailMode, isLoading, setEmailMode };
};

export const useInitCanvas = (emailMode?: EmailMode) => {
  const dispatch = useAppDispatch();
  const isTemplate = useGetIsTemplateIntentWorkflow();
  const { isLoading: isContextVariablesLoading } = useGetContextVariables();
  const {
    intentId,
    stepId,
    view,
    workflowId: selectedWorkflowId,
  } = useGetBuilderQueryParams();
  const canvasData: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );

  useLoadWorkflowBuilderFromURL();

  useEffect(() => {
    if (stepId) {
      dispatch(setTargetStepId(stepId));
    }
    // we should only set the targetStepId once since we only want to direct to the step once when the page loads
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isTemplate) {
      return;
    }

    if (selectedWorkflowId) {
      dispatch(loadDraftWorkflow(selectedWorkflowId));
      dispatch(getLatestWorkflowVersions(selectedWorkflowId));
    }
    if (emailMode === 'Interactive') {
      dispatch(
        duplicateClassicToProduct({ intentId, product: 'interactive_email' }),
      );
    }

    if (view === 'api' || view === 'slack') {
      dispatch(duplicateClassicToProduct({ intentId, product: view }));
    }
  }, [dispatch, isTemplate, selectedWorkflowId, intentId, emailMode, view]);

  useEffect(() => {
    if (isTemplate) {
      return;
    }
    if (canvasData?.intent_id) {
      dispatch(getIntent(canvasData?.intent_id as string));
    }
  }, [dispatch, canvasData?.intent_id, isTemplate]);

  return (
    (canvasData.intent_workflow_id === selectedWorkflowId &&
      canvasData.intent !== null &&
      !isContextVariablesLoading) ||
    isTemplate
  );
};

export const useGetIsTemplateIntentWorkflow = () => {
  const { search } = useLocation();

  const intentId = getWorkflowValueFromSearch(search, 'intentId');

  return intentId.startsWith(TEMPLATE_INTENT_ID);
};

export const useGetTemplateAutoflowWorkflow = (intentWorkflowId = '') => {
  const { search } = useLocation();
  const workflowId =
    intentWorkflowId || getWorkflowValueFromSearch(search, 'workflowId');

  const { data, isLoading } = useGetAutoflowTemplatesQuery(undefined, {
    skip: !workflowId,
  });

  return {
    isLoading,
    template: data?.find(
      template => template.intent_workflow_id === workflowId,
    ),
  };
};

export const useGetAutoflowWorkflowOrAutoflowTemplate = (
  intentWorkflowId = '',
) => {
  const isTemplateIntentWorkflow = useGetIsTemplateIntentWorkflow();

  const {
    data,
    isLoading: isLoadingAutoflowWorkflow,
    ...rest
  } = useGetAutoflowWorkflowQuery(intentWorkflowId, {
    skip: !intentWorkflowId || isTemplateIntentWorkflow,
  });
  const { isLoading: isLoadingAutoflowTemplate, template } =
    useGetTemplateAutoflowWorkflow();

  return {
    autoflowWorkflow: template ?? data,
    isLoading: isLoadingAutoflowWorkflow || isLoadingAutoflowTemplate,
    ...rest,
  };
};

export const useGetHandoffConfigurationForWorkflow = () => {
  const { intentId, view } = useGetBuilderQueryParams();
  const { data: handoffConfigurationsResponse } =
    useGetHandoffConfigurationsQuery();

  const handoffConfig = useMemo(() => {
    const handoffConfigurations =
      handoffConfigurationsResponse?.configurations ?? [];
    return handoffConfigurations.find(
      handoffConfiguration =>
        handoffConfiguration.intent_definition_id === intentId &&
        handoffConfiguration.product === CHANNEL_TO_PRODUCT_MAP[view],
    );
  }, [handoffConfigurationsResponse?.configurations, intentId, view]);

  return handoffConfig;
};

export const useSwapItem = ({
  accept = 'SWAP_ITEM',
  index,
  swap,
}: {
  accept?: string;
  index: number;
  swap: (dragIndex: number, hoverIndex: number) => void;
}) => {
  const ref = useRef<HTMLImageElement>(null);

  const [{ handlerId }, drop] = useDrop({
    accept,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },

    hover(item: { index: number }, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset();

      if (!clientOffset) {
        return;
      }

      const newIndex = handleSwapWhenHovering({
        clientOffset,
        dragIndex,
        hoverBoundingRect,
        hoverIndex,
        swap,
      });

      if (newIndex === undefined) {
        return;
      }

      item.index = newIndex;
    },
  });

  const [{ opacity }, drag, dragPreviewRef] = useDrag({
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.3 : 1,
    }),
    item: () => {
      return { index };
    },
    type: accept,
  });

  drag(drop(ref));

  return { dragPreviewRef, handlerId, opacity, ref };
};

export const useGetCanvasActions = (
  {
    product,
    shouldReturnAll,
  }: {
    product: SolveWidgetProduct;
    shouldReturnAll?: boolean;
  } = { product: 'workflow_builder' },
) => {
  const { data: featureFlagsData, isLoading: isFeatureFlagsLoading } =
    useGetFeatureFlagsQuery();

  const {
    data: actionBuilderActionsData,
    isLoading: isActionBuilderActionsLoading,
  } = useGetActionBuilderActionsQuery({
    product,
    shouldReturnAll,
  });
  const { actions = [] } = actionBuilderActionsData ?? {};

  const transformedActions = useMemo(() => {
    return transformActionsForCanvas(actions, featureFlagsData?.feature_flags);
  }, [actions, featureFlagsData?.feature_flags]);

  return {
    actions: transformedActions,
    isLoading: isFeatureFlagsLoading || isActionBuilderActionsLoading,
  } as const;
};

export const useWorkflowInvalidMessages = () => {
  const { data } = useGetIntentsQueryWithProduct();
  const { intents = [] } = data ?? {};
  const { data: actionBuilderActionsData, isLoading } =
    useGetActionBuilderActionsQuery({
      shouldReturnAll: true,
    });
  const canvasData: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );

  const invalidGoToSteps = useSelector(selectInvalidGoToSteps);
  const invalidIntentNames = invalidGoToSteps.map(invalidGoToStep => {
    const stepReferenced = canvasData.steps[invalidGoToStep];
    const intentWorkflowId = (
      stepReferenced.step_fields as { intent_workflow_id: string }
    ).intent_workflow_id;
    const foundIntent = intents.find(
      intent =>
        intent.intent_workflow_id === intentWorkflowId ||
        intent.interactive_email_workflow_id === intentWorkflowId ||
        intent.api_workflow_id === intentWorkflowId,
    );
    return foundIntent?.intent_name || '';
  });

  const dedupedInvalidIntentName = new Set(invalidIntentNames);

  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });

  const [isAllBranchesFilled] = useSelector(
    selectIsBranchValid(canvasData.entryStepId),
  );
  const undefinedCvs: string[] = useSelector(selectUndefinedContextVariables);
  const unsupportedSteps = useSelector(selectUnsupportedSteps);
  const unsupportedStepNames = unsupportedSteps.map(unsupportedStep => {
    for (const canvasAction of Object.values(
      canvasData.canvas_action_id_to_action_component,
    )) {
      if (canvasAction.steps.includes(unsupportedStep)) {
        const foundAction = actionBuilderActionsData?.actions.find(
          action => action.action_id === canvasAction.action_id,
        );

        if (foundAction) {
          return `${foundAction.action_name} action`;
        }
      }
    }
    const stepReferenced = canvasData.steps[unsupportedStep];

    return `${stepReferenced.step_type} step`;
  });
  // an action could have multiple unsupported steps
  // we only want to show the action name once
  const dedupedUnsupportedStepNames = new Set(unsupportedStepNames);

  const undefinedContextVariableNames = undefinedCvs
    .map(
      id =>
        contextVariables.find(
          contextVariable => contextVariable.context_variable_id === id,
        )?.context_variable_name,
    )
    .map(name => `$${name}`)
    .join(', ');

  return {
    invalidIntentNames: formatIntentNames(Array.from(dedupedInvalidIntentName)),
    isAllBranchesFilled,
    isLoading,
    undefinedContextVariableNames,
    unsupportedStepNames: Array.from(dedupedUnsupportedStepNames).join(', '),
  };
};

export const useGetDeleteWorkflowMessage = () => {
  const { data } = useGetFeatureFlagsQuery();
  const isSolveWidgetEnabled = data?.feature_flags.includes(
    'solve_widget_enabled',
  );
  const isSolveEmailEnabled = data?.feature_flags.includes(
    'solve_email_enabled',
  );
  const isSolveApiEnabled = data?.feature_flags.includes('solve_api');
  const isSolveSlackEnabled = data?.feature_flags.includes('solve_slack');
  const isSolveVoiceEnabled = data?.feature_flags.includes('solve_voice');

  const enabledChannels = [];
  if (isSolveWidgetEnabled) {
    enabledChannels.push('Widget');
  }
  if (isSolveEmailEnabled) {
    enabledChannels.push('Email');
  }
  if (isSolveApiEnabled) {
    enabledChannels.push('API');
  }

  if (isSolveSlackEnabled) {
    enabledChannels.push('Slack');
  }

  if (isSolveVoiceEnabled) {
    enabledChannels.push('Voice');
  }

  let message = 'This operation will delete the intent for';

  if (enabledChannels.length === 1) {
    message += ` ${enabledChannels[0]} channel`;
  } else if (enabledChannels.length === 2) {
    message += ` both ${enabledChannels[0]} and ${enabledChannels[1]} channels`;
  } else {
    const lastChannel = enabledChannels.pop();
    const channels = enabledChannels.join(', ');
    message += ` ${channels}, and ${lastChannel} channels`;
  }

  return message;
};

export const useBuildInsightsChatNavigationCallback = (intentId?: string) => {
  const { data } = useGetIntentsQueryWithProduct();
  const navigate = useNavigate();
  const { search } = useLocation();

  const { channel } = useSolveMetricsQueryParams();
  // get the index of the Solve Insights Chats tab
  const { tabIdToIndexMap } = useTabs();
  const chatsTabIndex = tabIdToIndexMap.chat;

  return useCallback(() => {
    const conversationUrl = getChatUrl({
      channel,
      chatsTabIndex,
      // fixes weird issue where navigating with no id param
      // crashes the page. It is serialized to an empty string
      conversationId: 'null',
    });
    const currentQuery = new URLSearchParams(search);
    const defaultTimestamp = dateRangeToTimestamp(last30DaysTimeRange);
    const startTime = Number(
      currentQuery.get('start') || defaultTimestamp.start_timestamp,
    );
    const endTime = Number(
      currentQuery.get('end') || defaultTimestamp.end_timestamp,
    );
    const tab = 'chat';

    navigate(
      conversationUrl +
        `&date=${startTime * 1000}_${endTime * 1000}${
          intentId
            ? `&${INTENT_FILTER_PARAM_NAME}_${tab}=workflow_id.${
                data?.intents.find(
                  intent => intent.intent_definition_id === intentId,
                )?.intent_workflow_id
              }`
            : ''
        }`,
    );
  }, [channel, chatsTabIndex, data?.intents, intentId, navigate, search]);
};
