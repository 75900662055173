import React from 'react';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Button } from '@forethought-technologies/forethought-elements';
import voiceLines from 'src/assets/images/voice-lines.svg';
import { componentText } from 'src/pages/workflow-builder-edit/customization-panel/set-context-variable-settings-panel/componentText';
import PhoneNumberInput from 'src/pages/workflow-builder-edit/customization-panel/set-context-variable-settings-panel/PhoneNumberInput';
import { useInitializeVoicePreviewMutation } from 'src/services/voice-preview/voicePreviewApi';
import { selectIntent } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { isPhoneNumberValid } from 'src/utils/validators';

export const VoicePreviewSection = () => {
  const [, { data, isSuccess }] = useInitializeVoicePreviewMutation({
    fixedCacheKey: 'voice-preview',
  });

  return (
    <Box
      alignItems='center'
      alignSelf='center'
      display='flex'
      flex={1}
      flexDirection='column'
      height='100%'
      justifyContent='center'
      p='28px 48px'
    >
      {isSuccess ? (
        <VoiceCallSuccess fromNumber={data.from_number} />
      ) : (
        <VoicePreviewForm />
      )}
    </Box>
  );
};
const VoicePreviewForm = () => {
  const theme = useTheme();
  const [initializeVoicePreviewMutation] = useInitializeVoicePreviewMutation({
    fixedCacheKey: 'voice-preview',
  });

  return (
    <VoiceContainer
      description="Fill out the form below and click 'Next' to make a preview
          voice call. Note that executing this flow will not create a ticket in
          your help desk."
      title='Preview Solve Voice'
    >
      <Formik
        initialValues={{ to_number: '' }}
        onSubmit={async (values, { setFieldError, setSubmitting }) => {
          try {
            await initializeVoicePreviewMutation(values).unwrap();
          } catch (error) {
            setFieldError('to_number', 'Invalid phone number');
          }
          setSubmitting(false);
        }}
      >
        {({ errors, handleChange, handleSubmit, isSubmitting, values }) => {
          const isValid =
            isPhoneNumberValid(values.to_number) && !errors.to_number;

          return (
            <Form
              onSubmit={handleSubmit}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box display='flex' flexDirection='column' gap={3} pb='20px'>
                <Box display='flex' flexDirection='column'>
                  <Typography component='label' mb={1} variant='font14Medium'>
                    Your phone number
                  </Typography>
                  <PhoneNumberInput
                    aria-label='phone'
                    autofillColor={theme.palette.colors.grey[100]}
                    backgroundColor={theme.palette.colors.white}
                    borderColor={theme.palette.colors.grey[300]}
                    isPhoneNumberValid={isValid}
                    onChange={(value: string) =>
                      handleChange('to_number')(value)
                    }
                    placeholderColor={theme.palette.text.primary}
                    textColor={theme.palette.text.primary}
                    validationMessageText={
                      errors.to_number || componentText.phone_error
                    }
                    value={values.to_number}
                  />
                  {(isValid || !values.to_number) && (
                    <Typography fontSize={'0.75rem'} mt='3px'>
                      We will call this number to preview the voice call.
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  justifyContent: 'end',
                  marginTop: '24px',
                }}
              >
                <Button
                  disabled={isSubmitting}
                  fullWidth
                  isLoading={isSubmitting}
                  size='large'
                  type='submit'
                  variant='main'
                >
                  Call
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </VoiceContainer>
  );
};

const VoiceCallSuccess = ({ fromNumber }: { fromNumber: string }) => {
  const [searchParams] = useSearchParams();
  const intent = useSelector(selectIntent);
  const isIntentBeingPreviewed =
    searchParams.get('intentId') === intent?.intent_id;

  return (
    <VoiceContainer
      description={
        <>
          Your voice call has been successfully initialized. You will receive a
          phone call from{' '}
          <strong style={{ color: 'black' }}>{fromNumber}</strong> to preview
          your workflows.
        </>
      }
      title='Voice preview initialized'
    >
      <Box display='flex' flexDirection='column' gap={3}>
        <img src={voiceLines} style={{ alignSelf: 'center' }} />
        <Typography variant='font14'>
          {isIntentBeingPreviewed ? (
            <>
              You can say <strong>{intent?.phrases?.[0].phrase_text}</strong>,
              or <strong>{intent?.phrases?.[1].phrase_text}</strong> to preview
              this intent.
            </>
          ) : (
            <>
              Speak a phrase to initiate a preview call and experience the
              interaction as a live customer would.
            </>
          )}
        </Typography>
      </Box>
    </VoiceContainer>
  );
};

const VoiceContainer = ({
  children,
  description,
  title,
}: React.PropsWithChildren<{
  description: React.ReactNode;
  title: string;
}>) => {
  return (
    <Box
      bgcolor='white'
      border='1px solid rgba(0, 0, 0, 0.10)'
      boxShadow='0px 12px 48px 0px rgba(0, 0, 0, 0.10)'
      display='flex'
      flexDirection='column'
      maxWidth='700px'
      padding='40px 48px 48px'
    >
      <Box display='flex' flexDirection='column' gap={1} pb='20px'>
        <Typography variant='font24'>{title}</Typography>
        <Typography
          color={theme => theme.palette.colors.grey[600]}
          variant='font14'
        >
          {description}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};
