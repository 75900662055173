import { Field } from '../apiInterfaces';
import { SSEL_BASE_URL } from '../constants';
import { dashboardApi } from '../dashboard-api';
import { getDiscoverFilterFieldsAPI, getDiscoverFilterValuesAPI } from './api';
import {
  DiscoverAllTopicsRequest,
  DiscoverGetFilterFieldsResponse,
  DiscoverGetFiltersRequest,
  DiscoverGetFiltersResponse,
  DiscoverTopicAllTicketsRequest,
  DiscoverTopicAllTicketsResponse,
} from './types';
import { createOption } from 'src/components/analytic-filter/helper';
import { FilterOption } from 'src/components/analytic-filter/types';
import { dateRangeToTimestamp } from 'src/components/dashboard-pages/solve-insights/helpers';
import {
  AutomateTopicRequest,
  DiscoverAllTopicsResponse,
  DiscoverMetadata,
  DiscoverTicket,
  DiscoverTopic,
} from 'src/reducers/discoverReducer/types';
import {
  DiscoverAutomationsByTypeResponse,
  DiscoverRecommendationsPoliciesResponse,
  TicketCounterRequest,
  TicketCounterResponse,
} from 'src/types/DiscoverTypes';
import { constructQueryParamWithTimeFilter } from 'src/utils/discover/helpers';

const discoverRoute = '/ssel/discover/v1';
const discoverV0Route = '/ssel/discover';

const discoverApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    automateTopic: builder.mutation<DiscoverTopic, AutomateTopicRequest>({
      invalidatesTags: ['Automations'],
      query: body => ({
        body,
        method: 'POST',
        url: `${discoverV0Route}/topics/automate`,
      }),
    }),
    getAllFilterFields: builder.query<DiscoverGetFilterFieldsResponse, void>({
      query: () => ({
        url: '/analytics/filter-fields?curate_fields=True',
      }),
    }),
    getAllFilters: builder.query<
      DiscoverGetFiltersResponse,
      DiscoverGetFiltersRequest
    >({
      query: ({ filters }) => {
        return {
          body: filters,
          method: 'POST',
          url: '/analytics/v2/filter-values',
        };
      },
    }),
    getAllTopics: builder.query<
      DiscoverAllTopicsResponse,
      DiscoverAllTopicsRequest
    >({
      query: ({ dataFilterQuery, keyword_search, timeFilter }) => {
        const params = constructQueryParamWithTimeFilter(
          timeFilter,
          keyword_search,
        );
        const url = `${discoverRoute}/topics?${params}`;
        return {
          body: dataFilterQuery.length
            ? { filters: dataFilterQuery }
            : undefined,
          method: 'POST',
          url,
        };
      },
    }),
    getAllTopicTickets: builder.query<
      DiscoverTopicAllTicketsResponse,
      DiscoverTopicAllTicketsRequest
    >({
      query: ({ dataFilterQuery, searchText, timeFilter, topicId }) => {
        const params = constructQueryParamWithTimeFilter(
          timeFilter,
          searchText,
        );
        const url = `${discoverRoute}/topics/${topicId}/tickets?${params}`;
        return {
          body: dataFilterQuery.length
            ? { filters: dataFilterQuery }
            : undefined,
          method: 'POST',
          url,
        };
      },
    }),
    getArticleTickets: builder.query<{ tickets: DiscoverTicket[] }, string>({
      query: id => ({
        method: 'GET',
        url: `${SSEL_BASE_URL}/discover/v1/article/${id}/tickets`,
      }),
    }),
    getAutomations: builder.query<
      DiscoverAutomationsByTypeResponse[],
      { includeAutomationValue: boolean }
    >({
      providesTags: ['Automations'],
      query: ({ includeAutomationValue }) => ({
        method: 'GET',
        params: { include_automation_value: includeAutomationValue },
        url: SSEL_BASE_URL + '/discover/automations',
      }),
    }),
    getFilterOptions: builder.query<FilterOption[], void>({
      queryFn: async () => {
        const filterFields = await getDiscoverFilterFieldsAPI();

        // flatten object to a single list and remove non STRING field_types
        const flattenedFilterFields = Object.entries(filterFields)
          .flatMap(([key, value]: [string, (Field & { category: string })[]]) =>
            value.map(value => ({ ...value, category: key })),
          )
          .filter(field => field.field_type === 'STRING');

        const results = await Promise.all(
          flattenedFilterFields.map(async field => {
            const fieldValueChoices = await getDiscoverFilterValuesAPI(
              field.category,
              field.field_id,
            );
            const values = fieldValueChoices?.values ?? [];
            if (values.length) {
              return createOption(field.category, field, values);
            } else {
              return null;
            }
          }),
        );

        return {
          data: results.filter(
            (result): result is FilterOption => result !== null,
          ),
        };
      },
    }),
    getMetadata: builder.query<DiscoverMetadata, void>({
      query: () => ({
        method: 'GET',
        url: `${SSEL_BASE_URL}/discover/metadata`,
      }),
    }),
    getRecommendedPolicies: builder.query<
      DiscoverRecommendationsPoliciesResponse,
      void
    >({
      providesTags: ['Automations'],
      query: () => ({
        method: 'POST',
        url: `${SSEL_BASE_URL}/discover/v1/policies/recommendations`,
      }),
    }),
    getTicketCount: builder.query<TicketCounterResponse, TicketCounterRequest>({
      query: ({ dateRange }) => {
        const { end_timestamp: end_date, start_timestamp: start_date } =
          dateRangeToTimestamp(dateRange);
        const body = {
          end_date,
          start_date,
        };
        return {
          body,
          method: 'POST',
          url: `${SSEL_BASE_URL}/discover/v1/tickets`,
        };
      },
    }),
  }),
});

export const {
  useAutomateTopicMutation,
  useGetAllFilterFieldsQuery,
  useGetAllFiltersQuery,
  useGetAllTopicsQuery,
  useGetAllTopicTicketsQuery,
  useGetArticleTicketsQuery,
  useGetAutomationsQuery,
  useGetFilterOptionsQuery,
  useGetMetadataQuery,
  useGetRecommendedPoliciesQuery,
  useGetTicketCountQuery,
} = discoverApi;

export { discoverApi };
