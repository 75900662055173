import { Box } from '@mui/material';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';

interface DeleteSimulationModalProps {
  isLoading?: boolean;
  onCancel: () => void;
  onDeleteConfirm?: () => void;
}

const DeleteSimulationForm = ({
  isLoading,
  onCancel,
  onDeleteConfirm,
}: DeleteSimulationModalProps) => {
  return (
    <Box display='flex' flexDirection='column' rowGap={2}>
      <Box display='flex' flexDirection='column'>
        <Box mb={4}>
          <Typography variant='font14'>
            This simulation will be permanently deleted and will not be
            retrievable.
          </Typography>
        </Box>

        <Box
          display='flex'
          justifyContent='flex-end'
          sx={{ gap: 1 }}
          width='100%'
        >
          <Button disabled={isLoading} onClick={onCancel} variant='secondary'>
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            isLoading={isLoading}
            onClick={onDeleteConfirm}
            variant='main'
          >
            Delete
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DeleteSimulationForm;
