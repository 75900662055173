import { styled } from '@mui/material/styles';

import automatedDiscoverIcon from 'src/assets/images/automated-discover.svg';
import hollowAutomatedDiscoverIcon from 'src/assets/images/automated-discover-hollow.svg';

const DiscoverAutomatedBadge = ({
  hollow = false,
  size = 18,
}: {
  hollow?: boolean;
  size?: number;
}) => {
  return (
    <DiscoverBadgeStyled
      size={size}
      src={hollow ? hollowAutomatedDiscoverIcon : automatedDiscoverIcon}
    />
  );
};

const DiscoverBadgeStyled = styled('img')(
  ({ size }: { size: number; src: string }) => ({
    height: `${size}px`,
    width: `${size}px`,
  }),
);

export default DiscoverAutomatedBadge;
