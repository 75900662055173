import { useState } from 'react';
import { Formik, FormikErrors } from 'formik';
import { useNavigate } from 'react-router';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { TOAST_TIMEOUT } from '../../triage-config-detail-page/helpers';
import AutogenFormSection from '../../triage-models-overview-page/AutogenFormSection';
import AutogenDeleteSection from '../../triage-models-overview-page/AutogenFormSection/AutogenDeleteSection';
import AutogenFooter from '../../triage-models-overview-page/AutogenFormSection/AutogenFooter';
import { checkModelIsAutogen } from '../../triage-models-overview-page/AutogenFormSection/helpers';
import {
  createModelFields,
  createModelFormValue,
} from '../../triage-models-overview-page/constants';
import CreateSuccess from '../../triage-models-overview-page/CreateSuccess';
import HelpDesk from '../../triage-models-overview-page/HelpDesk';
import {
  createAutogenRequest,
  getTriageModelsPageUrl,
} from '../../triage-models-overview-page/helpers';
import { CreateModelFormValue } from '../../triage-models-overview-page/types';
import {
  hasModelInfoChanged,
  invalidFormElementSubmitted,
  modelFormValidator,
} from '../helpers';
import CreateModelTextField from './CreateModelFieldItem';
import useSelfServeEvents from 'src/hooks/triage/useSelfServeEvents';
import {
  TriageAutoGenStatus,
  TriageModelStatus,
  VersionedTriageModel,
} from 'src/reducers/triageSettingsReducer/types';
import {
  useCreateAutoGenModelMutation,
  useCreateModelMutation,
  useUpdateModelInfoMutation,
} from 'src/services/triage/triageApi';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { TRIAGE_LLM_TRACKING_EVENTS } from 'src/utils/constants';

interface ManageModelFormProps {
  initialValues?: Partial<CreateModelFormValue>;
  isAutoGen?: boolean;
  isEdit?: boolean;
  modelId?: string;
  onClose?: () => void;
  status?: TriageAutoGenStatus | TriageModelStatus;
  versionId?: string;
}

const ManageModelForm: React.FC<ManageModelFormProps> = ({
  initialValues = createModelFormValue,
  isAutoGen,
  isEdit = false,
  modelId,
  onClose,
  status,
  versionId,
}: ManageModelFormProps) => {
  const { palette } = useTheme();
  const [isAutogenSuccessOpen, setIsAutogenSuccessOpen] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const emitTrackingEventCallback = useSelfServeEvents({});
  const [createModel, { isLoading: isCreating }] = useCreateModelMutation();
  const [updateModelInfo, { isLoading: isUpdatingModelInfo }] =
    useUpdateModelInfoMutation();
  const [mutateAutogen, { isLoading: isAutogenLoading }] =
    useCreateAutoGenModelMutation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [initialFormValues] = useState(initialValues);

  const greyColor = palette.colors.grey[600];

  const handleError = (
    errorMessage: string | undefined,
    setErrors: (errors: FormikErrors<CreateModelFormValue>) => void,
  ) => {
    setErrors({
      displayName: errorMessage,
    });
  };

  const handleSubmitAutogenCreate = (
    values: CreateModelFormValue,
    setErrors: (errors: FormikErrors<CreateModelFormValue>) => void,
  ) => {
    mutateAutogen(createAutogenRequest(values))
      .unwrap()
      .then(() => {
        if (!isEdit) {
          setIsAutogenSuccessOpen(true);
        } else {
          onClose?.();
          dispatch(
            setGlobalToastOptions({
              autoHideDuration: TOAST_TIMEOUT,
              title: 'Model creation process initiated',
              variant: 'main',
            }),
          );
        }
      })
      .catch(error => {
        const errorMessage = error?.data?.message;

        if (errorMessage) {
          handleError(errorMessage, setErrors);
        }
      });
  };

  const handleSubmitModelCreate = (
    values: CreateModelFormValue,
    setErrors: (errors: FormikErrors<CreateModelFormValue>) => void,
  ) => {
    createModel({
      model_description: values.description,
      model_name: values.displayName,
    })
      .unwrap()
      .then(payload => {
        emitTrackingEventCallback(
          TRIAGE_LLM_TRACKING_EVENTS.CREATE_MODEL_SUCCESS,
          {
            model_id: payload.model_id,
            model_name: payload.model_name,
          },
        );

        navigate(getTriageModelsPageUrl(payload.model_id));
      })
      .catch(error => {
        const errorMessage = error?.data?.message;

        if (errorMessage) {
          handleError(errorMessage, setErrors);
        }
      });
  };

  const handleSubmitModelPatch = (values: CreateModelFormValue) => {
    const patchBody: Partial<VersionedTriageModel> = {};
    if (values.displayName !== initialFormValues.displayName) {
      patchBody.model_name = values.displayName;
    }
    if (values.description !== initialFormValues.description) {
      patchBody.model_description = values.description;
    }

    if (Object.keys(patchBody).length === 0) {
      return;
    }

    if (!modelId || !versionId) {
      return;
    }

    updateModelInfo({
      description: values.description,
      modelId,
      name: values.displayName,
      versionId,
    })
      .unwrap()
      .then(() => {
        emitTrackingEventCallback(
          TRIAGE_LLM_TRACKING_EVENTS.EDIT_MODEL_SUCCESS,
        );
        onClose?.();
      })
      .catch(console.error);
  };

  const handleSubmit = (
    values: CreateModelFormValue,
    {
      setErrors,
    }: { setErrors: (errors: FormikErrors<CreateModelFormValue>) => void },
  ) => {
    if (!values.isManual) {
      handleSubmitAutogenCreate(values, setErrors);
      return;
    }

    if (isEdit && modelId) {
      handleSubmitModelPatch(values);
    } else {
      handleSubmitModelCreate(values, setErrors);
    }
  };

  if (isAutogenSuccessOpen) {
    return <CreateSuccess />;
  }

  if (showDelete) {
    return (
      <AutogenDeleteSection
        modelId={modelId}
        onClose={() => {
          setShowDelete(false);
          onClose?.();
        }}
      />
    );
  }

  const statusIsAutoGen = isEdit
    ? checkModelIsAutogen(status as TriageAutoGenStatus)
    : true;
  const autoGenEditMode = isAutoGen && isEdit && statusIsAutoGen;

  return (
    <Box position='relative'>
      <Formik
        initialValues={initialValues as CreateModelFormValue}
        onSubmit={(values, setErrors) => handleSubmit(values, setErrors)}
        validate={values => modelFormValidator(values)}
        validateOnChange={true}
      >
        {({ errors, handleSubmit, setFieldValue, values }) => {
          const hasChanges = hasModelInfoChanged(values, initialFormValues);
          const hasErrors = Boolean(Object.entries(errors).length);

          return (
            <Form
              noValidate
              onSubmit={event => {
                // This is to prevent form submission when
                // the user selects a date
                if (invalidFormElementSubmitted(event)) {
                  return;
                }
                event.preventDefault();
                handleSubmit();
              }}
            >
              <Box
                display='flex'
                flexDirection='column'
                pb={autoGenEditMode ? 16 : 2}
                rowGap='32px'
              >
                <Typography variant='font24'>
                  {isEdit ? 'Edit Model' : 'Create Model'}
                </Typography>
                {(!isAutoGen || !isEdit) &&
                  createModelFields.map((item, index) => {
                    const disabled =
                      isCreating || isUpdatingModelInfo || isAutogenLoading;
                    return (
                      <CreateModelTextField
                        disabled={disabled}
                        errors={errors}
                        item={item}
                        key={index}
                        setFieldValue={setFieldValue}
                        values={values}
                      />
                    );
                  })}
                {isAutoGen && statusIsAutoGen && (
                  <AutogenFormSection
                    description={values.description}
                    displayName={values.displayName}
                    errors={{
                      filters: errors.filters as string,
                      selectedTicket: errors.selectedTicket,
                    }}
                    filters={values.filters}
                    isEdit={isEdit}
                    isLoading={isAutogenLoading}
                    isManual={values.isManual}
                    selectedDateRange={values.dateRange}
                    selectedTicket={values.selectedTicket}
                    setFilters={filters => setFieldValue('filters', filters)}
                    setIsManual={isManual =>
                      setFieldValue('isManual', isManual)
                    }
                    setSelectedDateRange={dateRange =>
                      setFieldValue('dateRange', dateRange)
                    }
                    setSelectedTicket={ticket =>
                      setFieldValue('selectedTicket', ticket)
                    }
                    status={status as TriageAutoGenStatus}
                  />
                )}

                <Box display='flex' flexDirection='column' rowGap={1}>
                  <Typography variant='font16Bold'>
                    Helpdesk Integration
                  </Typography>
                  <HelpDesk />
                </Box>
                <Typography color={greyColor} variant='font12'>
                  After creating the model, configure it to the Helpdesk output
                  field in the ‘Configuration’ tab.
                </Typography>
              </Box>
              {!autoGenEditMode && (
                <Button
                  color='primary'
                  disabled={(isEdit && !hasChanges) || hasErrors}
                  isLoading={
                    isUpdatingModelInfo || isCreating || isAutogenLoading
                  }
                  type='submit'
                  variant='main'
                >
                  {isEdit ? 'Save Changes' : 'Create'}
                </Button>
              )}
              {autoGenEditMode && (
                <AutogenFooter
                  disabled={hasErrors}
                  initialValues={initialValues}
                  isLoading={isAutogenLoading}
                  onShowDelete={() => setShowDelete(true)}
                  status={status as TriageAutoGenStatus}
                  values={values}
                />
              )}
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

const Form = styled('form')`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 32px;
`;

export default ManageModelForm;
