import { useState } from 'react';
import { Box } from '@mui/material';
import { IconPlus } from '@tabler/icons-react';

import { Button, Drawer } from '@forethought-technologies/forethought-elements';
import { dateRangeToTimestamp } from '../solve-insights/helpers';
import ManageSimulationForm from './ManageSimulationForm';
import { TriageSimulateRequest } from 'src/reducers/triageSettingsReducer/types';
import { useSimulateTriageTicketsMutation } from 'src/services/triage/triageApi';
import { DateRange } from 'src/types/types';
import { last30DaysTimeRange } from 'src/utils/timeRangeHelpers';

const TriageCreateRun = ({
  existingSimulationNames,
}: {
  existingSimulationNames: string[];
}) => {
  const [testSelfServeModel] = useSimulateTriageTicketsMutation();
  const [isOpen, setIsOpen] = useState(false);

  const [testName, setTestName] = useState<string>('');
  const [selectedModelId, setSelectedModelId] = useState<string>('');
  const [selectedVersionId, setSelectedVersionId] = useState<string>('');
  const [testType, setTestType] = useState<string>('dateRange');
  const [dateRange, setDateRange] = useState<DateRange>(last30DaysTimeRange);
  const [ticketIds, setTicketIds] = useState<string>('');

  const handleDisable = () => {
    const isFieldsFilledBeforeType = !!selectedModelId && !!selectedVersionId;
    const isTestTypeFilled =
      testType === 'dateRange' || (testType === 'ids' && !!ticketIds);
    return !(isFieldsFilledBeforeType && isTestTypeFilled);
  };

  const handleOnClose = () => {
    setTestName('');
    setSelectedModelId('');
    setSelectedVersionId('');
    setTestType('dateRange');
    setDateRange(last30DaysTimeRange);
    setTicketIds('');

    setIsOpen(false);
  };

  const handleClick = () => {
    let ticketIdsToArray: string[] = [];
    if (ticketIds) {
      // remove white space
      const cleanTicketIdString = ticketIds.replaceAll(/\s/g, '');
      ticketIdsToArray = cleanTicketIdString.split(',');
      // get unique ticket ids
      ticketIdsToArray = [...new Set(ticketIdsToArray)];
      // Only get the first 1000 ticket ids
      ticketIdsToArray = ticketIdsToArray.slice(0, 1000);
    }
    const hasTickets = ticketIdsToArray.length > 0;
    const timestamp = dateRangeToTimestamp(dateRange);
    const payload: TriageSimulateRequest = {
      end_datetime: hasTickets ? null : timestamp.end_timestamp,
      model_id: selectedModelId,
      name: testName,
      start_datetime: hasTickets ? null : timestamp.start_timestamp,
      ticket_ids: ticketIdsToArray,
      version_id: selectedVersionId,
    };
    testSelfServeModel(payload);
    // Close and refresh table
    handleOnClose();
  };

  return (
    <Box display='flex' justifyContent='flex-end'>
      <Button onClick={() => setIsOpen(true)} variant='main'>
        <IconPlus />
        Create a new simulation
      </Button>
      <Drawer isOpen={isOpen} onClose={handleOnClose}>
        <ManageSimulationForm
          dateRange={dateRange}
          existingSimulationNames={existingSimulationNames}
          handleDisable={handleDisable}
          isEditMode={false}
          onClose={handleOnClose}
          onSubmit={handleClick}
          selectedModelId={selectedModelId}
          selectedVersionId={selectedVersionId}
          setDateRange={setDateRange}
          setSelectedModelId={setSelectedModelId}
          setSelectedVersionId={setSelectedVersionId}
          setTestName={setTestName}
          setTestType={setTestType}
          setTicketIds={setTicketIds}
          testName={testName}
          testType={testType}
          ticketIds={ticketIds}
        />
      </Drawer>
    </Box>
  );
};

export default TriageCreateRun;
