import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TRIAGE_LLM_SUPPORTED_HELPDESKS } from '../../triage-models-overview-page/constants';
import { formatTicketFields } from '../helpers';
import { getPredictedFields } from 'src/actions/triageSettings/triageSettings';
import {
  selectHelpdesk,
  selectIsHelpdeskNotConfigured,
  selectTicketFields,
} from 'src/reducers/triageSettingsReducer/triageSettingsReducer';
import {
  SupportedHelpdesk,
  TriagePermissions,
} from 'src/reducers/triageSettingsReducer/types';

const isSupportedHelpdesk = (
  helpdesk: string | undefined,
): helpdesk is SupportedHelpdesk => {
  return TRIAGE_LLM_SUPPORTED_HELPDESKS.includes(helpdesk || '');
};

export const useGetDataSourcePredictedFields = () => {
  const dispatch = useDispatch();
  const { data: ticketFieldsData, loading } = useSelector(selectTicketFields);
  const isHelpdeskNotConfigured = useSelector(selectIsHelpdeskNotConfigured);
  const helpdesk = useSelector(selectHelpdesk);

  useEffect(() => {
    dispatch(
      getPredictedFields({
        helpdesk,
        isHelpdeskNotConfigured,
      }),
    );
  }, [dispatch, helpdesk, isHelpdeskNotConfigured]);

  if (loading) {
    return {
      loading: true,
      origins: [],
      outputFields: [],
      permissions: undefined as TriagePermissions | undefined,
      preselectedOrigins: [],
    };
  }

  if (helpdesk?.name && isSupportedHelpdesk(helpdesk.name)) {
    return formatTicketFields(
      ticketFieldsData?.ticket_fields,
      helpdesk.name as SupportedHelpdesk,
    );
  }

  return {
    loading: false,
    origins: [],
    outputFields: [],
    permissions: undefined as TriagePermissions | undefined,
    preselectedOrigins: [],
  };
};
