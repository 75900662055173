import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';

import { RADIO_LABEL_STYLE } from './constants';

interface CreateModelOptionsProps {
  disabled?: boolean;
  isLoading: boolean;
  onChange?: (value: boolean) => void;
  value: boolean;
}

const CreateModelOptions = ({
  disabled,
  isLoading,
  onChange,
  value,
}: CreateModelOptionsProps) => {
  return (
    <Box display='flex' flexDirection='column'>
      <Box>
        <FormControl sx={{ width: '100%' }}>
          <FormLabel id='auto-gen-create-type-group-label'>
            <Typography color='black' variant='font16Bold'>
              How to create model detail
            </Typography>
          </FormLabel>
          <RadioGroup
            aria-labelledby='auto-gen-create-type-group-label'
            defaultValue={value ? 'manual' : 'auto-gen'}
            name='auto-gen-create-type'
            onChange={(_, value) => {
              onChange?.(value === 'manual');
            }}
            sx={{ flexDirection: 'row', justifyContent: 'space-between' }}
            value={value ? 'manual' : 'auto-gen'}
          >
            <FormControlLabel
              control={<Radio disabled={isLoading} />}
              disabled={disabled}
              label='Manual Creation'
              sx={RADIO_LABEL_STYLE}
              value='manual'
            />
            <FormControlLabel
              control={<Radio disabled={isLoading} />}
              disabled={disabled}
              label='Auto-generate using help desk tickets'
              sx={RADIO_LABEL_STYLE}
              value='auto-gen'
            />
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
};

export default CreateModelOptions;
