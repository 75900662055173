import { Box } from '@mui/material';

import {
  Drawer,
  Typography,
} from '@forethought-technologies/forethought-elements';
import TicketDrawerDetails from 'src/components/global-discover/article/article-drawer/TicketDrawerDetails';
import Spinner from 'src/components/spinner';
import { useGetTicketDetails } from 'src/hooks/discover/useGetTicketDetails';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { DiscoverTicket } from 'src/reducers/discoverReducer/types';

interface TopicDetailTicketDetailDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  ticketData?: DiscoverTicket;
  topicName: string;
}

const TopicDetailTicketDetailDrawer = ({
  isOpen,
  onClose,
  ticketData,
  topicName,
}: TopicDetailTicketDetailDrawerProps) => {
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const { id: ticketId } = ticketData ?? {};
  const { data: fineTicketDetails, loading } = useGetTicketDetails({
    ticketId,
  });
  const isFetchingNewTicket = fineTicketDetails?.ticket_id !== ticketId;

  if (!ticketData) {
    return null;
  }
  const ticket = {
    ...ticketData,
    body: fineTicketDetails?.ticket_html_body || '',
    replies: fineTicketDetails?.replies || [],
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => {
        emitTrackingEventCallback('discover-clicked-on-close-ticket', {
          page: 'Topic view',
          'page-area': 'Tickets',
          topic: topicName,
          value: ticketId,
        });
        onClose();
      }}
      width='620px'
    >
      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
        height='100%'
        p='40px'
        sx={{ overflowY: 'scroll' }}
        width='100%'
      >
        <Typography variant='font16Bold'>Ticket Details</Typography>
        {loading || isFetchingNewTicket ? (
          <Spinner />
        ) : (
          <TicketDrawerDetails ticket={ticket} />
        )}
      </Box>
    </Drawer>
  );
};

export default TopicDetailTicketDetailDrawer;
