import React, { useState } from 'react';
import { yupToFormErrors } from 'formik';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { IconCirclePlus } from '@tabler/icons-react';

import { VerticalTabBar } from '@forethought-technologies/forethought-elements';
import ArticlePickerRevamped from '../../article-picker/ArticlePickerRevamped';
import { getTransitionsFromConfig } from '../../conditions/conditionEditorHelpers';
import { CanvasModes } from '../../constants';
import IntentEmailToggleBar from '../../email-builder-page/IntentEmailToggleBar';
import { cleanURL } from '../../embed-video/utils';
import { useGetBuilderQueryParams } from '../../hooks';
import { ImagePicker } from '../../image-picker/ImagePicker';
import { AttachmentAnalysisUploadForm } from './form/AttachmentAnalysisUploadForm';
import EmbedStepForm from './form/EmbedStepForm';
import ActionTabContent from './ActionTabContent';
import AddTabContent from './AddTab';
import { createIcon } from './createIcon';
import {
  ConditionStepForm,
  CsatTriggerPointForm,
  FormContainer,
  FormStepForm,
  OptionsForm,
} from './form';
import { createNewTransitionsFromButtonOptions } from './helpers';
import IntentRoutingTabContent from './IntentRoutingTab';
import {
  BusinessLogicProps,
  FormWithErrors,
  SaveArg,
  UpdateArg,
} from './types';
import unset from 'lodash/fp/unset';
import {
  selectEditingStepId,
  selectIsFallback,
  selectMode,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  selectEditingStepFields,
  setOutputVariableValue,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  addAttachmentAnalysisUploadStep,
  addButtonsStep,
  addCondition,
  addCsatTriggerPointStep,
  addEmbedStep,
  addFormStep,
  updateWorkflowStep,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { AppDispatch } from 'src/store/hooks';
import { Step } from 'src/types/workflowBuilderAPITypes';
import { StepTypes } from 'src/utils/enums';
import { isOnlyInteractiveEmail } from 'src/utils/solve/intentUtils';
import {
  isAttachmentUploadAnalysisStepFields,
  isConditionStepFields,
  isCsatTriggerPointStepFields,
  isEmbedStepFields,
  isFormStepFields,
  isOptionsStepFields,
} from 'src/utils/solve/stepUtils';

const BUSINESS_LOGIC_TABS = [
  {
    component: AddTabContent,
    Icon: IconCirclePlus,
    label: 'Add',
    title: 'Business Logic',
  },
  {
    component: ActionTabContent,
    Icon: createIcon('IconBolt'),
    label: 'Actions',
    title: 'Actions',
  },
  {
    component: ArticlePickerRevamped,
    Icon: createIcon('IconArticle'),
    label: 'Articles',
    title: 'Articles',
  },
  {
    component: IntentRoutingTabContent,
    Icon: createIcon('IconRoute'),
    label: 'Routes',
    title: 'Routes',
  },
] as const;

const optionsValidationSchema = Yup.object().shape({
  options: Yup.array()
    .of(
      Yup.object().shape({
        display_name: Yup.string()
          .trim()
          .min(1, 'Option must be at least 1 character')
          .required(),
        value: Yup.string().trim().min(1).required(),
      }),
    )
    .test('cannotBeEqual', 'Options cannot have the same value', v => {
      const values = v?.map(v => v.value);
      return values?.length === new Set(values).size;
    })
    .required()
    .min(2),
  prompt: Yup.string()
    .trim()
    .min(1, 'Prompt must be at least 1 character')
    .required(),
});

const formValidationSchema = Yup.object().shape({
  form_fields: Yup.array()
    .of(
      Yup.object().shape({
        output_variable: Yup.string()
          .trim()
          .min(1, 'Context variable is required')
          .required(),
      }),
    )
    .test(
      'unique',
      'Labels must be unique',
      formFields =>
        !formFields?.some(
          (currentFormField, currentIndex) =>
            currentFormField.placeholder &&
            formFields.findIndex(
              formField =>
                formField.placeholder === currentFormField.placeholder,
            ) !== currentIndex,
        ),
    ),
  prompt: Yup.string()
    .trim()
    .min(1, 'Prompt must be at least 1 character')
    .required(),
});

const attachmentAnalysisUploadSchema = Yup.object().shape({
  num_files: Yup.number()
    .min(1, 'Files must be greater or equal to one')
    .max(10, 'Files must be less than or equal to ten')
    .required(),
  output_variable: Yup.string()
    .trim()
    .min(1, 'Attachment output is required')
    .required(),
});

const csatTriggerPointValidationSchema = Yup.object().shape({
  delay: Yup.number().min(0, 'delay must be at least 0 second').required(),
  should_use_delay: Yup.boolean().required(),
});

const embedValidationSchema = Yup.object().shape({
  embed_url: Yup.string()
    .trim()
    .min(1, 'Embed url must be at least 1 character')
    .required()
    .test('is-valid-embed-url', 'Embed URL is invalid', value =>
      Boolean(cleanURL(value || '')),
    ),
});

const conditionsSchema = Yup.array().of(
  Yup.object({
    contextVariable: Yup.string().required('Context variable is required'),
    operator: Yup.string().required('Operator is required'),
    value: Yup.mixed().when('isArray', {
      is: Array.isArray,
      otherwise: Yup.string().when('operator', {
        is: (operator: string) =>
          operator === 'is empty' || operator === 'is not empty',
        otherwise: schema => {
          return schema.trim().min(1, 'Value is required').required();
        },
        then: schema => {
          return schema;
        },
      }),
      then: Yup.array()
        .min(1, 'Value is required')
        .of(Yup.string().trim().min(1).required()),
    }),
  }),
);

const conditionValidationSchema = Yup.object().shape({
  compositeConditions: Yup.array().when(
    'otherwiseCondition.isOtherwiseSelected',
    {
      is: true,
      otherwise: schema =>
        schema
          .of(Yup.object({ conditions: conditionsSchema }))
          .min(2)
          .required(),
      then: schema =>
        schema
          .of(Yup.object({ conditions: conditionsSchema }))
          .min(1)
          .required(),
    },
  ),
  conditionName: Yup.string()
    .trim()
    .min(1, 'Condition name must be at least 1 character')
    .required(),
  otherwiseCondition: Yup.object().shape({
    isOtherwiseSelected: Yup.bool().required(),
  }),
});

const modeComponentMap: Partial<
  Record<
    CanvasModes,
    {
      Form: React.FC<React.PropsWithChildren<FormWithErrors>>;
      label: string;
      onSaveStep: (fields: SaveArg) => (dispatch: AppDispatch) => void;
      onUpdateStep: (fields: {
        fields: UpdateArg;
        oldStep: Step;
        stepId: string;
      }) => (dispatch: AppDispatch) => void;
      schema: Yup.AnySchema;
    }
  >
> = {
  [CanvasModes.BUTTON]: {
    Form: OptionsForm,
    label: 'Options',
    onSaveStep: fields => dispatch =>
      isOptionsStepFields(fields) && dispatch(addButtonsStep(fields)),
    onUpdateStep:
      ({ fields, oldStep, stepId }) =>
      dispatch => {
        const oldStepFields = oldStep.step_fields;
        if (
          !isOptionsStepFields(fields) ||
          !isOptionsStepFields(oldStepFields)
        ) {
          return;
        }

        // Create transitions from buttonOptions
        const mappedTransitions = createNewTransitionsFromButtonOptions(
          fields.options,
          oldStep.transitions,
          oldStepFields.output_variable,
        );

        // filter out emtpy transitions
        const newTransitions = mappedTransitions.filter(
          item => Object.keys(item).length !== 0,
        );

        // clear transition id from step fields
        const buttonOptionsToUpdate = fields.options.map(
          unset('transition_id'),
        );

        dispatch(
          updateWorkflowStep({
            condition_name: null,
            step_fields: {
              ...oldStep.step_fields,
              options: buttonOptionsToUpdate,
              prompt: fields.prompt,
            },
            stepId,
            transitions: newTransitions,
          }),
        );
        // Update output variable so that the old branch doesn't show up
        dispatch(
          setOutputVariableValue({
            outputVariable: oldStepFields.output_variable,
            stepId,
            value: '',
          }),
        );
      },
    schema: optionsValidationSchema,
  },
  [CanvasModes.CSAT_TRIGGER_POINT]: {
    Form: CsatTriggerPointForm,
    label: 'CSAT survey trigger point',
    onSaveStep: fields => dispatch => {
      if (!isCsatTriggerPointStepFields(fields)) {
        return;
      }
      dispatch(addCsatTriggerPointStep({ ...fields }));
    },
    onUpdateStep:
      ({ fields, oldStep, stepId }) =>
      dispatch => {
        if (!isCsatTriggerPointStepFields(fields)) {
          return;
        }

        dispatch(
          updateWorkflowStep({
            condition_name: oldStep.condition_name,
            step_fields: {
              ...oldStep.step_fields,
              delay: fields.delay,
              negative_rating_routing_intent:
                fields.negative_rating_routing_intent,
              should_use_delay: fields.should_use_delay,
            },
            stepId,
            transitions: oldStep.transitions,
          }),
        );
      },
    schema: csatTriggerPointValidationSchema,
  },
  [CanvasModes.FORM]: {
    Form: FormStepForm,
    label: 'Forms',
    onSaveStep: fields => dispatch => {
      if (!isFormStepFields(fields)) {
        return;
      }

      dispatch(
        addFormStep({
          formFields: fields.form_fields,
          ...fields,
        }),
      );
    },
    onUpdateStep:
      ({ fields, oldStep, stepId }) =>
      dispatch => {
        if (!isFormStepFields(fields)) {
          return;
        }

        dispatch(
          updateWorkflowStep({
            condition_name: oldStep.condition_name,
            step_fields: {
              ...oldStep.step_fields,
              form_fields: fields.form_fields.map(myForm => ({
                ...myForm,
                prepopulated_field: myForm.output_variable,
              })),
              pre_populate_fields: true,
              prompt: fields.prompt,
            },
            stepId,
            transitions: oldStep.transitions,
          }),
        );
      },
    schema: formValidationSchema,
  },
  [CanvasModes.ATTACHMENT_ANALYSIS_UPLOAD]: {
    Form: AttachmentAnalysisUploadForm,
    label: 'Attachment',
    onSaveStep: fields => dispatch => {
      if (!isAttachmentUploadAnalysisStepFields(fields)) {
        return;
      }

      dispatch(addAttachmentAnalysisUploadStep(fields));
    },
    onUpdateStep:
      ({ fields, oldStep, stepId }) =>
      dispatch => {
        if (!isAttachmentUploadAnalysisStepFields(fields)) {
          return;
        }

        dispatch(
          updateWorkflowStep({
            condition_name: oldStep.condition_name,
            step_fields: fields,
            stepId,
            transitions: oldStep.transitions,
          }),
        );
      },
    schema: attachmentAnalysisUploadSchema,
  },
  [CanvasModes.EMBED]: {
    Form: EmbedStepForm,
    label: 'Add video link',
    onSaveStep: fields => dispatch => {
      if (!isEmbedStepFields(fields)) {
        return;
      }

      dispatch(
        addEmbedStep({
          is_entry_step: fields.isEntryStep,
          parent_step_id: fields.parentStepId,
          step_fields: {
            embed_url: cleanURL(fields.embed_url),
          },
          step_type: StepTypes.EMBED,
        }),
      );
    },
    onUpdateStep:
      ({ fields, oldStep, stepId }) =>
      dispatch => {
        if (!isEmbedStepFields(fields)) {
          return;
        }

        dispatch(
          updateWorkflowStep({
            condition_name: oldStep.condition_name,
            step_fields: {
              embed_url: cleanURL(fields.embed_url),
            },
            stepId,
            transitions: oldStep.transitions,
          }),
        );
      },
    schema: embedValidationSchema,
  },
  [CanvasModes.CONDITION]: {
    Form: ConditionStepForm,
    label: 'Condition',
    onSaveStep: fields => dispatch => {
      if (!isConditionStepFields(fields)) {
        return;
      }

      dispatch(
        addCondition({
          compositeConditionsConfig: fields,
          shouldSqueezeIntoEntry: fields.isEntryStep,
          squeezeStepParentId: fields.parentStepId,
        }),
      );
    },
    onUpdateStep:
      ({ fields, stepId }) =>
      dispatch => {
        if (!isConditionStepFields(fields)) {
          return;
        }

        dispatch(
          updateWorkflowStep({
            condition_name: null,
            step_fields: { condition_name: fields.conditionName },
            stepId,
            transitions: getTransitionsFromConfig(fields),
          }),
        );
      },
    schema: conditionValidationSchema,
  },
};

const BusinessLogic = ({ ...rest }: BusinessLogicProps) => {
  const { palette } = useTheme();
  const [tabIndex, setTabIndex] = useState(0);
  const mode = useSelector(selectMode);
  const fields = useSelector(selectEditingStepFields);
  const editingStepId = useSelector(selectEditingStepId);
  const isFallback = useSelector(selectIsFallback);
  const { intentId, view } = useGetBuilderQueryParams();
  const isOnlyInteractive = isOnlyInteractiveEmail(intentId);

  const isDefaultMode = mode === CanvasModes.MESSAGE;

  const tabs = BUSINESS_LOGIC_TABS.filter(tab =>
    tab.label === 'Articles' ? !isFallback : true,
  ).map(tab => ({
    ...tab,
    component: <tab.component {...rest} />,
  }));

  const renderMainComponent = () => {
    if (!isDefaultMode) {
      if (mode === CanvasModes.IMAGE) {
        return (
          <>
            <ImagePicker
              hasWorkflowConflict={rest.hasWorkflowConflict}
              key={editingStepId} // Needed to clear `altText` state
              onDiscard={rest.cancelSqueeze}
              shouldSqueezeIntoEntry={rest.shouldSqueezeIntoEntry}
              squeezeStepParentId={rest.squeezeStepParentId}
            />
            <VerticalTabBar
              menuWidth={80}
              setTabIndex={setTabIndex}
              tabBodyWidth={400}
              tabIndex={tabIndex}
              tabs={tabs}
            />
          </>
        );
      }

      const { schema, ...props } = modeComponentMap[mode] ?? {
        Form: React.Fragment,
        label: '',
      };

      let errors = null;

      try {
        schema?.validateSync(fields, { abortEarly: false });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          errors = yupToFormErrors(error);
        }
      }

      return <FormContainer {...props} {...rest} errorObject={errors} />;
    }

    return (
      <VerticalTabBar
        menuWidth={80}
        setTabIndex={setTabIndex}
        tabBodyWidth={400}
        tabIndex={tabIndex}
        tabs={tabs}
      />
    );
  };

  return (
    <Box
      bgcolor={palette.background.paper}
      display={mode === CanvasModes.ACTION_EDIT ? 'none' : 'flex'}
      flexDirection='column'
      height='calc(100vh - 113px)'
      width={isDefaultMode || mode === CanvasModes.IMAGE ? undefined : '700px'}
    >
      {view === 'email' && !isOnlyInteractive ? (
        <>
          <IntentEmailToggleBar />
          {/* fixes container scroll height issue with interactive email */}
          <Box height='calc(100vh - 194px)'>{renderMainComponent()}</Box>
        </>
      ) : (
        renderMainComponent()
      )}
    </Box>
  );
};

export default BusinessLogic;
