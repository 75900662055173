import { captureMessage } from '@sentry/react';

import {
  getArticleSuggestionActionKeyId,
  getStepIdToTransitionForDynamicArticleSuggestion,
  getStepIdToTransitionFromBranch,
  isDynamicArticleSuggestion,
} from './workflowBuilderReducerHelpers';
import { getIsTerminalAction } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducerHelpers';
import { CanvasWorkflowBuilderState } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { Action } from 'src/types/actionBuilderApiTypes';
import {
  ActionStepList,
  ButtonsStepFields,
  Step as StepData,
  Transition,
} from 'src/types/workflowBuilderAPITypes';
import { StepTypes } from 'src/utils/enums';
import {
  getGranularArticleSuggestionV2ParentStepId,
  isEntryStepOfGranularArticleSuggestionV2,
} from 'src/utils/granularArticleSuggestionV2Utils';

type NextStepIdParams = {
  canvasWorkflowBuilder: CanvasWorkflowBuilderState;
  chatOrder: string[];
  stepData: StepData;
};

interface OrderableChat {
  /**
   * Gets the chat step id, or the entry step id for a group of steps (action).
   * @param {NextStepIdParams} params - All parameters needed to determine the next step id.
   * @returns The applicable step id, or null if not applicable.
   */
  getNextStepId(params: NextStepIdParams): string | null;
}

class ArticleSuggestionAction implements OrderableChat {
  private readonly actions: Array<Action>;

  constructor(actions: Array<Action>) {
    this.actions = actions;
  }

  getNextStepId(params: NextStepIdParams): string | null {
    const canvasWorkflowBuilder = params.canvasWorkflowBuilder;
    const canvasActionIds: Record<Action['action_id'], ActionStepList> =
      canvasWorkflowBuilder.canvas_action_id_to_action_component;
    const chatOrder = params.chatOrder;

    const actionId = getArticleSuggestionActionKeyId(
      canvasActionIds,
      this.actions,
      chatOrder[chatOrder.length - 1],
    );

    const isYesBranchChosen =
      canvasWorkflowBuilder.actionCaseMap[chatOrder[chatOrder.length - 1]];
    const isBranchChosen =
      canvasWorkflowBuilder.actionCaseMap[
        canvasActionIds[actionId]['entry_step_id'] || ''
      ] !== undefined;

    let stepId = undefined;
    if (isDynamicArticleSuggestion(canvasWorkflowBuilder, actionId)) {
      stepId = getStepIdToTransitionForDynamicArticleSuggestion(
        canvasWorkflowBuilder,
        actionId,
        isBranchChosen,
        isYesBranchChosen,
      );
      return stepId || null;
    } else {
      stepId = getStepIdToTransitionFromBranch(
        canvasWorkflowBuilder,
        actionId,
        isYesBranchChosen,
      );
    }

    if (!isBranchChosen) {
      return null;
    }
    return stepId || null;
  }
}

class GranularArticleSuggestionV2Action implements OrderableChat {
  getNextStepId(params: NextStepIdParams): string | null {
    const chatOrder = params.chatOrder;
    const currentStepId = chatOrder[chatOrder.length - 1];

    const conditionStepId =
      getGranularArticleSuggestionV2ParentStepId(currentStepId);

    return conditionStepId;
  }
}

class NonBranchingStep implements OrderableChat {
  private getConditionalNonBranchingNextStepId(
    canvasWorkflowBuilder: CanvasWorkflowBuilderState,
    stepData: StepData,
    chatOrder: string[],
  ): string | null {
    const currentStepId = chatOrder[chatOrder.length - 1];
    const selectedConditionIndex =
      canvasWorkflowBuilder.selectedConditions[currentStepId];

    if (selectedConditionIndex == undefined) {
      // User has not selected any condition, stop traversing:
      return null;
    }

    const stepId = stepData.transitions[selectedConditionIndex].step_id;

    // If the transition doesn't have `stepId` yet, stop traversing:
    return stepId ?? null;
  }

  private getUnconditionalNonBranchingNextStepId(
    stepData: StepData,
  ): string | null {
    return stepData.transitions[0].step_id ?? null;
  }

  getNextStepId(params: NextStepIdParams): string | null {
    const stepData = params.stepData;

    // If step has transitions added via Conditions UI:
    if (stepData.condition_name) {
      return this.getConditionalNonBranchingNextStepId(
        params.canvasWorkflowBuilder,
        stepData,
        params.chatOrder,
      );
    } else if (stepData.transitions[0].step_id) {
      return this.getUnconditionalNonBranchingNextStepId(stepData);
    } else {
      return null;
    }
  }
}

class ButtonsStep implements OrderableChat {
  private stepHasConditionalTransitions(stepData: StepData): boolean {
    return stepData.transitions.some(
      ({ condition_expression }) => condition_expression !== null,
    );
  }

  private findNextTransition(
    canvasWorkflowBuilder: CanvasWorkflowBuilderState,
    stepData: StepData,
    chatOrder: string[],
  ): Transition | null {
    const currentStepId = chatOrder[chatOrder.length - 1];

    const matchingTransition = stepData.transitions.find(
      ({ condition_expression }) =>
        condition_expression?.field !== undefined &&
        condition_expression?.values?.includes(
          canvasWorkflowBuilder.outputVariableValuesMap[currentStepId]?.[
            condition_expression.field
          ],
        ),
    );

    return matchingTransition ?? null;
  }

  getNextStepId(params: NextStepIdParams): string | null {
    const canvasWorkflowBuilder = params.canvasWorkflowBuilder;
    const stepFields = params.stepData.step_fields as ButtonsStepFields;
    const chatOrder = params.chatOrder;
    const currentStepId = chatOrder[chatOrder.length - 1];

    // If there is no output variable value, that means that user has not
    // selected any option, so we stop traversing:
    if (
      canvasWorkflowBuilder.outputVariableValuesMap[currentStepId]?.[
        stepFields.output_variable
      ] === undefined
    ) {
      return null;
    }

    const stepData = params.stepData;

    // If there are conditional transitions in current step:
    if (this.stepHasConditionalTransitions(stepData)) {
      const matchingTransition = this.findNextTransition(
        canvasWorkflowBuilder,
        stepData,
        chatOrder,
      );

      // Transition is found for currently selected option, continue
      // traversing:
      if (matchingTransition && matchingTransition.step_id) {
        const stepId = matchingTransition.step_id;

        return stepId;
      }
    }

    return null;
  }
}

class ConditionStep implements OrderableChat {
  getNextStepId(params: NextStepIdParams): string | null {
    const canvasWorkflowBuilder = params.canvasWorkflowBuilder;
    const chatOrder = params.chatOrder;
    const stepData = params.stepData;
    const currentStepId = chatOrder[chatOrder.length - 1];

    const selectedConditionIndex =
      canvasWorkflowBuilder.selectedConditions[currentStepId];

    if (selectedConditionIndex == undefined) {
      // User has not selected any condition, stop traversing:
      return null;
    }

    const stepId = stepData.transitions[selectedConditionIndex].step_id;

    // If the transition doesn't have `stepId` yet, stop traversing:
    return stepId ?? null;
  }
}

class OrderableChatFactory {
  canvasActionIds: Record<Action['action_id'], ActionStepList>;
  actions: Array<Action>;

  constructor(
    canvasActionIds: Record<Action['action_id'], ActionStepList>,
    actions: Array<Action>,
  ) {
    this.canvasActionIds = canvasActionIds;
    this.actions = actions;
  }

  build(stepData: StepData, chatOrder: string[]): OrderableChat | null {
    const validStepTypes = [
      StepTypes.TEXT_MESSAGE,
      StepTypes.BUTTONS,
      StepTypes.FORM,
      StepTypes.CONDITION,
      StepTypes.SHOPIFY_TRACKING,
      StepTypes.FLAMETHROWER_API_CALL,
      StepTypes.SNOWFLAKE_DATA_QUERY,
      StepTypes.ARTICLE,
      StepTypes.HYPERLINK_REDIRECT,
      StepTypes.TRIGGER_EVENT,
      StepTypes.ZENDESK_CHAT_HANDOFF,
      StepTypes.ZENDESK_TICKET_CREATION,
      StepTypes.SALESFORCE_CASE_CREATION,
      StepTypes.SALESFORCE_CHAT_HANDOFF,
      StepTypes.SALESFORCE_MESSAGING_HANDOFF,
      StepTypes.ARTICLE_SUGGESTION,
      StepTypes.SNAPENGAGE_CHAT_HANDOFF,
      StepTypes.ZENDESK_ATTACHMENT_UPLOAD,
      StepTypes.GORGIAS_CHAT_HANDOFF,
      StepTypes.FRESHCHAT_CHAT_HANDOFF,
      StepTypes.ZENDESK_MESSAGING_HANDOFF,
      StepTypes.VARIABLE_MAPPING,
      StepTypes.IMAGE,
      StepTypes.EMBED,
      StepTypes.LIVE_CHAT_CHAT_HANDOFF,
      StepTypes.PARSE_JWT,
      StepTypes.SET_CONTEXT_VARIABLE,
      StepTypes.SUNCO_LIVE_CHAT,
      StepTypes.DYNAMIC_CARD,
      StepTypes.KUSTOMER_CHAT_HANDOFF,
      StepTypes.KUSTOMER_CONVERSATION_CREATION,
      StepTypes.CSAT_TRIGGER_POINT,
      StepTypes.END_INTERACTIVE_EMAIL_CHAT,
      StepTypes.ATTACHMENT_ANALYSIS_UPLOAD,
      StepTypes.ATTACHMENT_UPLOAD,
    ];

    const nonBranchingStepTypes = validStepTypes.filter(
      stepType =>
        stepType !== StepTypes.BUTTONS && stepType !== StepTypes.CONDITION,
    );

    const actionId = getArticleSuggestionActionKeyId(
      this.canvasActionIds,
      this.actions,
      chatOrder[chatOrder.length - 1],
    );

    if (
      !stepData.transitions.length ||
      !validStepTypes.includes(stepData.step_type)
    ) {
      return null;
    }

    if (actionId) {
      return new ArticleSuggestionAction(this.actions);
    }

    if (
      isEntryStepOfGranularArticleSuggestionV2(chatOrder[chatOrder.length - 1])
    ) {
      return new GranularArticleSuggestionV2Action();
    }

    // @ts-expect-error TypeScript sucks
    if (nonBranchingStepTypes.includes(stepData.step_type)) {
      return new NonBranchingStep();
    }

    if (stepData.step_type === StepTypes.BUTTONS) {
      return new ButtonsStep();
    }

    if (stepData.step_type === StepTypes.CONDITION) {
      return new ConditionStep();
    }

    return null;
  }
}

export const getChatOrder = (
  canvasWorkflowBuilder: CanvasWorkflowBuilderState,
  actions: Action[],
  terminateStepId?: string,
): string[] => {
  const chatOrder: string[] = [];

  const canvasActionIds: Record<Action['action_id'], ActionStepList> =
    canvasWorkflowBuilder.canvas_action_id_to_action_component;

  if (!!canvasWorkflowBuilder.entryStepId) {
    let stepId: string | null = canvasWorkflowBuilder.entryStepId;

    const chatFactory = new OrderableChatFactory(canvasActionIds, actions);

    while (stepId !== null && stepId !== terminateStepId) {
      const stepData = canvasWorkflowBuilder.steps[stepId];

      if (!stepData) {
        captureMessage('Step not found in getChatOrder', {
          extra: {
            stepId,
          },
          level: 'error',
        });
        break;
      }

      chatOrder.push(stepId);

      const chat = chatFactory.build(stepData, chatOrder);

      if (!chat || getIsTerminalAction(stepId, canvasActionIds)) {
        break;
      }

      const params: NextStepIdParams = {
        canvasWorkflowBuilder: canvasWorkflowBuilder,
        chatOrder: chatOrder,
        stepData: stepData,
      };
      stepId = chat.getNextStepId(params);
    }
  }

  return chatOrder;
};
