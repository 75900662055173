import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';

import { DiscoverAutomationsTabName } from '../types';
import DiscoverPolicyAutomationBanner from './DiscoverPolicyAutomationBanner';
import DiscoverPostAutomationBanner from './DiscoverPostAutomationBanner';
import KnowledgeGapBanner from './KnowledgeGapBanner';
import debounce from 'lodash/fp/debounce';
import { DEBOUNCED_AUTOMATION_TRACKING_EVENT_TIMEOUT } from 'src/constants/discover';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { selectKnowledgeGapRecommendations } from 'src/reducers/discoverReducer/discoverReducer';
import { DiscoverTopicActionableValue } from 'src/reducers/discoverReducer/types';

interface BannerSectionProps {
  automationValue?: DiscoverTopicActionableValue | null;
  hasScrolled: boolean;
  isAutomationsLoading: boolean;
  tab: DiscoverAutomationsTabName;
}

const BannerSection = React.memo(
  ({
    automationValue,
    hasScrolled,
    isAutomationsLoading,
    tab,
  }: BannerSectionProps) => {
    const emitTrackingEventCallback = useEmitTrackingEventCallback();
    const {
      data: knowledgeGapRecommendationsData,
      loading: isKnowledgeGapRecommendationsLoading,
    } = useSelector(selectKnowledgeGapRecommendations);
    const {
      banner_recommendation_value: knowledgeGapRecommendationBannerData,
    } = knowledgeGapRecommendationsData ?? {};

    const onMouseEnter = useMemo(() => {
      return debounce(DEBOUNCED_AUTOMATION_TRACKING_EVENT_TIMEOUT, () =>
        emitTrackingEventCallback('discover-automation-banner-hovered', {
          page: 'Automation',
          'page-area': tab,
        }),
      );
    }, [emitTrackingEventCallback, tab]);

    let bannerComponent: React.ReactNode = null;
    switch (tab) {
      case 'Knowledge gap':
        bannerComponent = (
          <KnowledgeGapBanner
            hasScrolled={hasScrolled}
            isLoading={isKnowledgeGapRecommendationsLoading}
            knowledgeGapRecommendationBannerData={
              knowledgeGapRecommendationBannerData
            }
          />
        );
        break;
      case 'Realized impact':
        bannerComponent = (
          <DiscoverPostAutomationBanner
            automationValue={automationValue}
            hasScrolled={hasScrolled}
            isLoading={isAutomationsLoading}
          />
        );
        break;
      case 'Workflow gaps':
        bannerComponent = (
          <DiscoverPolicyAutomationBanner hasScrolled={hasScrolled} />
        );
        break;
    }
    return <Box onMouseEnter={onMouseEnter}>{bannerComponent}</Box>;
  },
);

BannerSection.displayName = 'BannerSection';

export default BannerSection;
